<!--
 * @Description: 基础日期
 * @Author: luocheng
 * @Date: 2022-05-09 15:01:13
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-04 15:30:51
-->
<template>
	<div class="common-month-filter" :class="[statusConfig.transparent ? 'transparent-bg' : '', element.id]">
		<!-- 单日、月 -->
		<el-date-picker
			v-if="statusConfig.type === 'date' || statusConfig.type === 'month'"
			v-model="dateResult"
			:type="statusConfig.type"
			:value-format="statusConfig.valueFormat"
			@change="onSign"
			:placeholder="$t('form.CommonBaseDate.199253-0')"
			:clearable="clearable"
			>{{ dataValue }}
		</el-date-picker>
		<!-- 日期、月份 段 -->
		<el-date-picker
			v-else
			v-model="dateResult"
			:type="statusConfig.type"
			:value-format="statusConfig.valueFormat"
			:range-separator="$t('form.CommonBaseDate.199253-1')"
			:start-placeholder="$t('form.CommonBaseDate.199253-2')"
			:end-placeholder="$t('form.CommonBaseDate.199253-3')"
			:picker-options="pickerOptions"
			@change="onBetween"
		>
		</el-date-picker>
	</div>
</template>

<script>
import { DatePicker } from 'element-ui';
import eventBus from '@/plugins/eventBus';
import { addZero, getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
	name: 'CommonBaseDate',
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	components: {
		'el-date-picker': DatePicker
	},
	data() {
		return {
			dateResult: '',
			pickerOptions: {},
			// quarterList: [[1,2,3], [4,5,6], [7,8,9], [10,11,12]]
			result: ''
		};
	},
	computed: {
		...mapState([
			'componentData', // 组件数据
			'curComponent', // 当前组件
			'originComponentData',
			'sContainer'
		]),
		statusConfig() {
			return this.element?.statusConfig || {};
		},
		shortcuts() {
			return this.statusConfig?.shortcuts || [];
		},
		// 影藏边框
		hideBorder() {
			return this.statusConfig?.hideBorder;
		},
		// 高度
		selectHeight() {
			return this.statusConfig?.selectHeight;
		},
		// 隐藏应用
		hideIcon() {
			return this.statusConfig?.hideIcon;
		},
		dateType() {
			return this.statusConfig?.type;
		},
		// 默认当月/当日
		todDay() {
			return this.statusConfig.toDay;
		},
		clearable() {
			return this.statusConfig.clearable;
		},
		dataValue() {
			if (this.element.valueOrign === 'database' && ['date', 'month'].includes(this.statusConfig?.type)) {
				const originComponent = getComponentById(this.componentData, this.element.database?.containerKey);
				if (this.element.database?.databaseType === 'value' && !this.result) {
					if (Array.isArray(originComponent.resolveData)) {
						this.changeDate(originComponent.resolveData[0]?.[this.element.database?.bindUUID]);
					} else if (originComponent.resolveData instanceof Object) {
						this.changeDate(originComponent.resolveData[this.element.database?.bindUUID]);
					} else if (originComponent.resolveData instanceof String) {
						this.changeDate(originComponent.resolveData);
					}
				}
			}
			return '';
		}
	},
	watch: {
		shortcuts: {
			handler() {
				this.pickerOptions.shortcuts = [];
				if (Array.isArray(this.shortcuts)) {
					this.initShortcuts(this.shortcuts);
				}
			},
			deep: true,
			immediate: true
		},
		selectHeight: {
			handler(newVal) {
				this.$nextTick(() => {
					if (this.dateType === 'date' || this.dateType === 'month') {
						const select = document.querySelector(`.${this.element.id} .el-date-editor .el-input__inner`);
						const selectIcon = document.querySelector(`.${this.element.id} .el-date-editor .el-input__prefix`);
						const selectIconI = document.querySelector(`.${this.element.id} .el-date-editor .el-input__prefix i`);
						const statusHeight = isNaN(+newVal) ? '36px' : `${newVal}px`;
						select.style.height = statusHeight;
						selectIcon.style.height = statusHeight;
						selectIcon.style.lineHeight = statusHeight;
						selectIconI.style.lineHeight = statusHeight;
					} else {
						const dateEditor = document.querySelector(`.${this.element.id} .el-date-editor`);
						const select = document.querySelector(`.${this.element.id} .el-date-editor .el-range-input`);
						const selectIcon = document.querySelector(`.${this.element.id} .el-date-editor .el-input__icon `);
						const statusHeight = isNaN(+newVal) ? '36px' : `${newVal}px`;
						const selectSeparator = document.querySelector(`.${this.element.id} .el-range-separator`);
						select.style.height = statusHeight;
						dateEditor.style.height = statusHeight;
						selectIcon.style.height = statusHeight;
						selectIcon.style.lineHeight = statusHeight;
						selectIcon.style.overflow = 'hidden';
						selectSeparator.style.height = statusHeight;
						selectSeparator.style.lineHeight = statusHeight;
						// selectIconI.style.lineHeight = statusHeight;
					}
				});
			},
			deep: true,
			immediate: true
		},
		hideBorder: {
			handler(newVal) {
				let select = null;
				this.$nextTick(() => {
					if (this.statusConfig.type === 'date' || this.statusConfig.type === 'month') {
						select = document.querySelector(`.${this.element.id} .el-date-editor .el-input__inner`);
					} else {
						select = document.querySelector(`.${this.element.id} .el-date-editor`);
					}
					select.style.border = newVal ? 'none' : '1px solid #DCDFE6';
				});
			},
			deep: true,
			immediate: true
		},
		hideIcon: {
			handler(newVal) {
				this.$nextTick(() => {
					let selectIcon = null;
					if (this.statusConfig.type === 'date' || this.statusConfig.type === 'month') {
						selectIcon = document.querySelector(`.${this.element.id} .el-date-editor .el-input__prefix`);
					} else {
						selectIcon = document.querySelector(`.${this.element.id} .el-date-editor .el-input__icon`);
					}
					selectIcon.style.display = newVal ? 'none' : 'block';
				});
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.$nextTick(() => {
			const select = document.querySelector(`.${this.element.id} .el-date-editor .el-input__inner`);
			const selectIcon = document.querySelector(`.${this.element.id} .el-date-editor .el-input__prefix`);
			const selectIconI = document.querySelector(`.${this.element.id} .el-date-editor .el-input__prefix i`);
			const selectList = document.querySelectorAll(`.${this.element.id} .el-date-editor .el-range-input`);
			let { hideBorder, selectHeight = 36, hideIcon, type } = this.statusConfig;
			const statusHeight = isNaN(+selectHeight) ? '36px' : `${selectHeight}px`;
			if (select && selectIconI && selectIcon) {
				selectIcon.style.height = statusHeight;
				selectIcon.style.lineHeight = statusHeight;
				selectIconI.style.lineHeight = statusHeight;
				selectIcon.style.display = hideIcon ? 'none' : 'block';
				select.style.height = statusHeight;
				select.style.border = hideBorder ? 'none' : '1px solid #DCDFE6';
				if (type !== 'date' && type !== 'month') {
					// 时间段
					const selectDuring = document.querySelector(`.${this.element.id} .el-date-editor`);
					selectDuring.style.border = hideBorder ? 'none' : '1px solid #DCDFE6';
				}
			}
			// 时间段
			if (selectList?.length) {
				const dateEditor = document.querySelector(`.${this.element.id} .el-date-editor`);
				if (dateEditor) {
					dateEditor.style.overflow = 'hidden';
				}
				selectList.forEach((ele) => {
					ele.style.height = statusHeight;
				});
			}
			// 默认暴露值
			let resolveData = '';
			const { valueFormat } = this.statusConfig;
			// 固定默认值 @刘添豪
			if (
				['date', 'month'].includes(this.statusConfig?.type) &&
				this.statusConfig?.inputDefault &&
				new Date(this.statusConfig?.inputDefault).getTime()
			) {
				const targetDate = new Date(this.statusConfig?.inputDefault);
				const year = targetDate.getFullYear();
				const month = targetDate.getMonth() + 1;
				const day = targetDate.getDate();
				const hour = targetDate.getHours();
				const minutes = targetDate.getMinutes();
				const second = targetDate.getSeconds();
				const withZero = `${year}-${addZero(month)}-${addZero(day)}`;
				const full = `${year}-${month}-${day}`;
				if (type === 'date') {
					if (valueFormat === 'yyyy-MM-dd') {
						resolveData = withZero;
					} else if (valueFormat === 'yyyy-M-d') {
						resolveData = full;
					} else if (valueFormat === 'yyyy-MM-dd HH:mm') {
						resolveData = `${withZero} ${addZero(hour)}:${addZero(minutes)}`;
					} else if (valueFormat === 'yyyy-M-d H:m') {
						resolveData = `${full} ${hour}:${minutes}`;
					} else if (valueFormat === 'yyyy-MM-dd HH:mm:ss') {
						resolveData = `${withZero} ${addZero(hour)}:${addZero(minutes)}:${addZero(second)}`;
					} else if (valueFormat === 'yyyy-M-d H:m:s') {
						resolveData = `${withZero} ${hour}:${minutes}:${second}`;
					} else if (valueFormat === 'timestamp') {
						resolveData = targetDate.getTime();
					}
				} else {
					if (valueFormat === 'yyyy-MM-dd') {
						resolveData = `${year}-${addZero(month)}-01`;
					} else if (valueFormat === 'yyyy-M-d') {
						resolveData = `${year}-${month}-1`;
					} else if (valueFormat === 'yyyy-MM-dd HH:mm') {
						resolveData = `${year}-${addZero(month)}-01 00:00`;
					} else if (valueFormat === 'yyyy-M-d H:m') {
						resolveData = `${year}-${month}-1 00:00`;
					} else if (valueFormat === 'yyyy-MM-dd HH:mm:ss') {
						resolveData = `${year}-${addZero(month)}-01 00:00:00`;
					} else if (valueFormat === 'yyyy-M-d H:m:s') {
						resolveData = `${year}-${month}-1 00:00:00`;
					} else if (valueFormat === 'timestamp') {
						resolveData = new Date(`${year}-${month}-1 00:00:00`).getTime();
					}
				}
				if (valueFormat === 'yyyy-MM') {
					resolveData = `${year}-${addZero(month)}`;
				} else if (valueFormat === 'yyyy-M') {
					resolveData = `${year}-${month}`;
				}
			} else if (['date', 'month'].includes(this.statusConfig?.type)) {
				// 日期/月份
				const toDay = new Date();
				const year = toDay.getFullYear();
				const month = toDay.getMonth() + 1;
				const day = toDay.getDate();
				const hour = toDay.getHours();
				const minutes = toDay.getMinutes();
				const second = toDay.getSeconds();
				const withZero = `${year}-${addZero(month)}-${addZero(day)}`;
				const full = `${year}-${month}-${day}`;
				if (type === 'date') {
					if (valueFormat === 'yyyy-MM-dd') {
						resolveData = withZero;
					} else if (valueFormat === 'yyyy-M-d') {
						resolveData = full;
					} else if (valueFormat === 'yyyy-MM-dd HH:mm') {
						resolveData = `${withZero} ${addZero(hour)}:${addZero(minutes)}`;
					} else if (valueFormat === 'yyyy-M-d H:m') {
						resolveData = `${full} ${hour}:${minutes}`;
					} else if (valueFormat === 'yyyy-MM-dd HH:mm:ss') {
						resolveData = `${withZero} ${addZero(hour)}:${addZero(minutes)}:${addZero(second)}`;
					} else if (valueFormat === 'yyyy-M-d H:m:s') {
						resolveData = `${withZero} ${hour}:${minutes}:${second}`;
					} else if (valueFormat === 'timestamp') {
						resolveData = toDay.getTime();
					}
				} else {
					if (valueFormat === 'yyyy-MM-dd') {
						resolveData = `${year}-${addZero(month)}-01`;
					} else if (valueFormat === 'yyyy-M-d') {
						resolveData = `${year}-${month}-1`;
					} else if (valueFormat === 'yyyy-MM-dd HH:mm') {
						resolveData = `${year}-${addZero(month)}-01 00:00`;
					} else if (valueFormat === 'yyyy-M-d H:m') {
						resolveData = `${year}-${month}-1 00:00`;
					} else if (valueFormat === 'yyyy-MM-dd HH:mm:ss') {
						resolveData = `${year}-${addZero(month)}-01 00:00:00`;
					} else if (valueFormat === 'yyyy-M-d H:m:s') {
						resolveData = `${year}-${month}-1 00:00:00`;
					} else if (valueFormat === 'timestamp') {
						resolveData = new Date(`${year}-${month}-1 00:00:00`).getTime();
					}
				}
				if (valueFormat === 'yyyy-MM') {
					resolveData = `${year}-${addZero(month)}`;
				} else if (valueFormat === 'yyyy-M') {
					resolveData = `${year}-${month}`;
				}
			} else if (['daterange', 'monthrange'].includes(this.statusConfig?.type) && this.statusConfig.dayType) {
				// 新增的特殊时间段暴露值@蒲亚军
				let startObj = null; // 开始
				let todayObj = null; // 结束
				if (isNaN(+this.statusConfig.dayType)) {
					if (this.statusConfig.dayType === '_targetWeek') {
						// 本周
						const current = new Date();
						const currentDay = current.getDay() || 7;
						const currentStart = new Date(
							`${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()} 00:00:00`
						);
						startObj = new Date(currentStart - (currentDay - 1) * (24 * 60 * 60 * 1000));
						todayObj = new Date(+startObj + 6 * (24 * 60 * 60 * 1000) + 86399999);
					} else if (this.statusConfig.dayType === '_targetMonth') {
						// 本月
						const current = new Date();
						const year = current.getFullYear();
						const month = current.getMonth() + 1;
						startObj = new Date(`${year}/${month}/1 00:00:00`);
						todayObj = new Date(year, month, 0, 23, 59, 59);
					} else if (this.statusConfig.dayType === '_targetQuarter') {
						// 本季度
						const quarterList = [
							[1, 2, 3],
							[4, 5, 6],
							[7, 8, 9],
							[10, 11, 12]
						];
						const current = new Date();
						const currentYear = current.getFullYear();
						const currentMonth = current.getMonth() + 1;
						const quarter = quarterList.find((ele) => ele.includes(currentMonth));
						startObj = new Date(`${currentYear}/${quarter[0]}/1 00:00:00`);
						todayObj = new Date(`${currentYear}/${quarter[2]}/${[6, 9].includes(+quarter[2]) ? 30 : 31} 23:59:59`);
					} else if (this.statusConfig.dayType === '_targetYear') {
						// 本年
						const current = new Date();
						const year = current.getFullYear();
						startObj = new Date(`${year}/1/1 00:00:00`);
						todayObj = new Date(`${year}/12/31 23:59:59`);
					} else if (this.statusConfig.dayType === 'monthToNow') {
						// 本月至今
						// 本月
						const current = new Date();
						const year = current.getFullYear();
						const month = current.getMonth() + 1;
						const day = current.getDate();
						startObj = new Date(`${year}/${month}/1 00:00:00`);
						todayObj = new Date(`${year}/${month}/${day} 23:59:59`);
					} else if (this.statusConfig.dayType === 'yearToNow') {
						// 本年至今
						const current = new Date();
						const year = current.getFullYear();
						const month = current.getMonth() + 1;
						const day = current.getDate();
						startObj = new Date(`${year}/1/1 00:00:00`);
						todayObj = new Date(`${year}/${month}/${day} 23:59:59`);
					}
				} else {
					// 常规暴露值
					todayObj = new Date();
					startObj = new Date(todayObj - (this.statusConfig.dayType - 1) * 24 * 60 * 60 * 1000);
				}
				const todayYear = todayObj.getFullYear();
				const todayMonth = todayObj.getMonth() + 1;
				const todayDay = todayObj.getDate();
				const todayHour = todayObj.getHours();
				const todayMinutes = todayObj.getMinutes();
				const todaySecond = todayObj.getSeconds();
				const startYear = startObj.getFullYear();
				const startMonth = startObj.getMonth() + 1;
				const startDay = startObj.getDate();
				const startHour = startObj.getHours();
				const startMinutes = startObj.getMinutes();
				const startSecond = startObj.getSeconds();
				if (valueFormat === 'yyyy-MM-dd') {
					resolveData = [
						`${startYear}-${addZero(startMonth)}-${addZero(startDay)}`,
						`${todayYear}-${addZero(todayMonth)}-${addZero(todayDay)}`
					];
				} else if (valueFormat === 'yyyy-M-d') {
					resolveData = [`${startYear}-${startMonth}-${startDay}`, `${todayYear}-${todayMonth}-${todayDay}`];
				} else if (valueFormat === 'yyyy-MM-dd HH:mm') {
					resolveData = [
						`${startYear}-${addZero(startMonth)}-${addZero(startDay)} ${addZero(startHour)}:${addZero(startMinutes)}`,
						`${todayYear}-${addZero(todayMonth)}-${addZero(todayDay)} ${addZero(todayMinutes)}:${addZero(todayMinutes)}`
					];
				} else if (valueFormat === 'yyyy-M-d H:m') {
					resolveData = [
						`${startYear}-${startMonth}-${startDay} ${startHour}:${startMinutes}`,
						`${todayYear}-${todayMonth}-${todayDay} ${todayHour}:${todayMinutes}`
					];
				} else if (valueFormat === 'yyyy-MM-dd HH:mm:ss') {
					resolveData = [
						`${startYear}-${addZero(startMonth)}-${addZero(startDay)} ${addZero(startHour)}:${addZero(
							startMinutes
						)}:${addZero(startSecond)}`,
						`${todayYear}-${addZero(todayMonth)}-${addZero(todayDay)} ${addZero(todayHour)}:${addZero(
							todayMinutes
						)}:${addZero(todaySecond)}`
					];
				} else if (valueFormat === 'yyyy-M-d H:m:s') {
					resolveData = [
						`${startYear}-${startMonth}-${startDay} ${startHour}:${startMinutes}:${startSecond}`,
						`${todayYear}-${todayMonth}-${todayDay} ${todayHour}:${todayMinutes}:${todaySecond}`
					];
				} else if (valueFormat === 'yyyy-M') {
					resolveData = [`${startYear}-${startMonth}`, `${todayYear}-${todayMonth}`];
				} else if (valueFormat === 'yyyy-MM') {
					resolveData = [`${startYear}-${addZero(startMonth)}`, `${todayYear}-${addZero(todayMonth)}`];
				}
			} else {
				resolveData = [];
			}
			this.dateResult = resolveData;
			this.element.resolveData = {
				// date: this.statusConfig?.type?.includes('range') ? [] : ''
				date: resolveData
			};
			this.onSearch();
		});
	},
	methods: {
		changeDate(val) {
			this.result = val;
			this.dateResult = val;
			this.element.resolveData = {
				// date: this.statusConfig?.type?.includes('range') ? [] : ''
				date: val
			};
			this.onSearch();
		},
		/**
		 * @desc: 单日月
		 * @param {String} val
		 */
		onSign(val) {
			this.element.resolveData = {
				date: val || []
			};
			this.onSearch();
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element,
				resolveData: {
					date: val || []
				}
			});
		},
		/**
		 * @desc: 时间段 格式化暴露出去的结果数据
		 */
		onBetween(val) {
			console.log(val, '---1111111-----');
			let result = val;
			// 暴露数据
			const { valueFormat = 'yyyy-MM-dd' } = this.statusConfig;
			try {
				let endTime = result[1];
				const times = endTime.toString().split(' ');
				let timeEnd = times[1];
				if (valueFormat === 'yyyy-MM-dd HH:mm' || valueFormat === 'yyyy-M-d H:m') {
					timeEnd = '23:59';
				} else if (valueFormat === 'yyyy-MM-dd HH:mm:ss' || valueFormat === 'yyyy-M-d H:m:s') {
					timeEnd = '23:59:59';
				}
				if (timeEnd) {
					endTime = `${times[0]} ${timeEnd}`;
				}
				// 月份段，则将结束时间由结束月一日改为最后一天
				if (this.statusConfig.type === 'monthrange') {
					const arrList = endTime.split(' ');
					if (arrList.length > 0) {
						const dateMap = arrList[0]?.split('-');
						if (dateMap.length) {
							// let monthDayCount = 30;
							if ([1, 3, 5, 7, 8, 10, 12].includes(+dateMap[1])) {
								// monthDayCount = 31;
							} else if (+dateMap[1] === 2) {
								// 平闰年
								if ((+dateMap[0] % 4 === 0 && +dateMap[0] && 100 !== 0) || +dateMap[0] % 400 === 0) {
									// monthDayCount = 29;
								}
								// monthDayCount = 28;
							}
							arrList.splice(0, 1, `${dateMap[0]}-${dateMap[1]}`);
							endTime = arrList.join(' ');
							// console.log(monthDayCount, '---endTime----monthDayCount-');
						}
					}
				}
				result.splice(1, 1, endTime);
			} catch (e) {
				console.log(e);
			}
			this.element.resolveData = {
				date: result || []
			};
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element,
				resolveData: {
					date: val || []
				}
			});
			this.onSearch();
		},
		/**
		 * @desc: 搜索
		 */
		onSearch() {
			eventBus.$emit('databaseTrigger', {
				componentId: this.element.id,
				action: 'any',
				output: this.dateResult,
				isInit: false,
				noUpdate: false
			});
		},
		/**
		 * @desc: 操作快捷操作项
		 * @param {Array} arr
		 */
		initShortcuts(arr) {
			const shortcuts = [];
			const that = this;
			arr.forEach((ele) => {
				// 仅在时间段使用今天 近三天 和近一周
				if (ele === 'targetDay' && this.statusConfig.type === 'daterange') {
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-4'),
						onClick(picker) {
							const start = new Date();
							picker.$emit('pick', [that.setStartDate(start, true), that.setEndDate(new Date())]);
						}
					});
				} else if (ele === 'threeDays' && this.statusConfig.type === 'daterange') {
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-5'),
						onClick(picker) {
							const start = new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000);
							picker.$emit('pick', [that.setStartDate(start, true), that.setEndDate(new Date())]);
						}
					});
				} else if (ele === 'targetWeek' && this.statusConfig.type === 'daterange') {
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-6'),
						onClick(picker) {
							const start = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);
							picker.$emit('pick', [that.setStartDate(start, true), that.setEndDate(new Date())]);
						}
					});
				} else if (ele === 'targetYear') {
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-7'),
						onClick(picker) {
							const end = new Date();
							const start = new Date(new Date().getFullYear(), 0);
							picker.$emit('pick', [that.setStartDate(start), that.setEndDate(end)]);
						}
					});
				} else if (ele === 'threeMonth') {
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-8'),
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setMonth(start.getMonth() - 3);
							picker.$emit('pick', [that.setStartDate(start), that.setEndDate(end)]);
						}
					});
				} else if (ele === 'sixMonth') {
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-9'),
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setMonth(start.getMonth() - 6);
							picker.$emit('pick', [that.setStartDate(start), that.setEndDate(end)]);
						}
					});
				} else if (ele === 'oneYear') {
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-10'),
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setMonth(start.getMonth() - 12);
							picker.$emit('pick', [that.setStartDate(start), that.setEndDate(end)]);
						}
					});
				} else if (ele === 'targetMonth') {
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-11'),
						onClick(picker) {
							const start = new Date();
							picker.$emit('pick', [
								that.setStartDate(new Date(start - 30 * 24 * 60 * 60 * 1000), true),
								that.setEndDate(new Date())
							]);
						}
					});
				} else if (ele === '_targetMonth') {
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-12'),
						onClick(picker) {
							const current = new Date();
							const year = current.getFullYear();
							const month = current.getMonth() + 1;
							picker.$emit('pick', [that.setStartDate(current), that.setEndDate(new Date(year, month, 0))]);
						}
					});
				} else if (ele === '_targetWeek') {
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-13'),
						onClick(picker) {
							const current = new Date();
							const currentDay = current.getDay() || 7;
							const currentStart = new Date(
								`${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()} 00:00:00`
							);
							const start = new Date(currentStart - (currentDay - 1) * (24 * 60 * 60 * 1000));
							picker.$emit('pick', [
								that.setStartDate(start, true),
								that.setEndDate(new Date(+start + 6 * (24 * 60 * 60 * 1000)))
							]);
						}
					});
				} else if (ele === '_targetQuarter') {
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-14'),
						onClick(picker) {
							const quarterList = [
								[1, 2, 3],
								[4, 5, 6],
								[7, 8, 9],
								[10, 11, 12]
							];
							const current = new Date();
							const currentYear = current.getFullYear();
							const currentMonth = current.getMonth() + 1;
							const quarter = quarterList.find((ele) => ele.includes(currentMonth));
							const start = new Date(`${currentYear}/${quarter[0]}/1`);
							const end = new Date(`${currentYear}/${quarter[2]}/${[6, 9].includes(+quarter[2]) ? 30 : 31}`);
							picker.$emit('pick', [that.setStartDate(start), that.setEndDate(end)]);
						}
					});
				} else if (ele === '_targetYear') {
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-15'),
						onClick(picker) {
							const year = new Date().getFullYear();
							const start = new Date(`${year}/1/1 00:00:00`);
							const end = new Date(`${year}/12/31 23:59:59`);
							picker.$emit('pick', [that.setStartDate(start), that.setEndDate(end)]);
						}
					});
				} else if (ele === 'monthToNow') {
					// 本月至今
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-16'),
						onClick(picker) {
							const current = new Date();
							picker.$emit('pick', [that.setStartDate(current), that.setEndDate(new Date())]);
						}
					});
				} else if (ele === 'yearToNow') {
					// 本年至今
					shortcuts.push({
						text: this.$t('form.CommonBaseDate.199253-17'),
						onClick(picker) {
							const year = new Date().getFullYear();
							const start = new Date(`${year}/1/1 00:00:00`);
							const end = new Date();
							picker.$emit('pick', [that.setStartDate(start), that.setEndDate(end)]);
						}
					});
				}
			});
			this.pickerOptions = {
				...this.pickerOptions,
				shortcuts
			};
		},
		/**
		 * @desc: 设置开始日期为 1日 00:00:00
		 * @param {Object<Date>} date
		 * @param {Boolean} ignorDate 是否忽略设置天
		 */
		setStartDate(date, ignorDate = false) {
			if (!ignorDate) {
				date.setDate(1);
			}
			date.setHours(0);
			date.setMinutes(0);
			date.setSeconds(0);
			return date;
		},
		/**
		 * @desc: 设置结束日期为  X日 23:59:59
		 * @param {Object<Date>} date
		 */
		setEndDate(date) {
			date.setHours(23);
			date.setMinutes(59);
			date.setSeconds(59);
			return date;
		}
	}
};
</script>

<style lang="less" scoped>
.common-month-filter {
	width: 100%;
	display: flex;
	&.transparent-bg {
		:deep(.el-date-editor) {
			input {
				background: transparent;
				color: #fff;
				&::-webkit-input-placeholder {
					color: #fff;
				}
				&::-moz-placeholder {
					/* Mozilla Firefox 19+ */
					color: #fff;
				}
				&:-moz-placeholder {
					/* Mozilla Firefox 4 to 18 */
					color: #fff;
				}
				&:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					color: #fff;
				}
			}
			.el-input__prefix,
			.el-input__suffix {
				color: #fff;
			}
		}
		:deep(.el-date-editor, .el-range-editor, .el-input__inner, .el-date-editor--monthrange, .el-range-editor--small) {
			background: transparent;
			color: #fff;
			&::-webkit-input-placeholder {
				color: #fff;
			}
			&::-moz-placeholder {
				/* Mozilla Firefox 19+ */
				color: #fff;
			}
			&:-moz-placeholder {
				/* Mozilla Firefox 4 to 18 */
				color: #fff;
			}
			&:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: #fff;
			}
			.el-range-separator {
				color: #fff;
				font-weight: bold;
			}
			.el-input__prefix,
			.el-input__suffix,
			i {
				color: #fff;
			}
		}
	}
	:deep(.el-date-editor.el-input, .el-date-editor.el-input__inner) {
		flex: 1;
	}
}
</style>
