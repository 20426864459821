<!--
    @name: widget-radio
    @description：widget-radio
    @author: ZengWei
    @date: 2022-03-25 09:34
-->
<template>
	<div
		:class="{
			'radio-group': true,
			custom: element.config.__slot__?.prepend || element.config.__slot__?.append
		}"
	>
		<div
			class="prepend"
			v-if="element.config.__slot__?.prepend"
			:style="{
				padding: disabled || element.config.disabled ? '0 0 0 12px' : '0 20px'
			}"
		>
			{{ element.config.__slot__?.prepend }}
		</div>
		<div
			v-if="disabled || element.config.disabled"
			class="input-show"
			:style="{
				padding: disabled || element.config.disabled ? '5px 0' : '5px 12px',
				paddingLeft: !element.config.__slot__?.prepend ? '12px' : '0',
				paddingRight: !element.config.__slot__?.append ? '12px' : '0'
			}"
		>
			{{ getRadioValue }}
		</div>
		<el-radio-group
			v-else
			v-model="element.value"
			:disabled="disabled || element.config.disabled"
			@change="triggerInteractive"
		>
			<template v-if="element.config.__config__.optionType === 'default'">
				<el-radio v-for="(val, key) in element.config.__slot__.options" :label="'' + val.value" :key="key"
					>{{ getLabel(val) }}
				</el-radio>
			</template>
			<template v-else>
				<el-radio-button v-for="(val, key) in element.config.__slot__.options" :label="'' + val.value" :key="key"
					>{{ getLabel(val) }}
				</el-radio-button>
			</template>
		</el-radio-group>
		<div
			class="append"
			v-if="element.config.__slot__?.append"
			:style="{
				padding: disabled || element.config.disabled ? '0 12px 0 0' : '0 20px'
			}"
		>
			{{ element.config.__slot__?.append }}
		</div>
	</div>
</template>

<script>
import { RadioGroup, Radio, RadioButton } from 'element-ui';
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';

export default {
	name: 'WidgetRadio',
	components: {
		'el-radio-group': RadioGroup,
		'el-radio': Radio,
		'el-radio-button': RadioButton
	},
	mixins: [formItemMinix],
	props: ['element', 'disabled', 'displayData'],
	data() {
		return {};
	},
	computed: {
		getRadioValue() {
			const data = this.element.config.__slot__.options;
			const value = this.element.value;
			if (Array.isArray(data) && data?.length && value) {
				const obj = data.find((item) => item?.value?.toString() === value.toString());
				const result = this.$getLocalizedLabel({
					zhLabel: obj?.label,
					enLabel: obj?.enLabel
				});
				if (result) {
					return result;
				} else {
					return '-';
				}
			} else {
				return '-';
			}
		}
	},
	created() {
		this.triggerInteractive(this.element.value);
	},
	methods: {
		triggerInteractive(val) {
			this.triggerFlowBranch(val); // 值改变触发流程分支
			this.$emit('change-calc');
			const interact = this.element.config.__config__.interact;
			const effect = interact.effect;
			const condition = interact.condition;
			if (effect === 'showhide') {
				let trueData = condition.filter((item) => {
					if (typeof item.condV === 'string' && item.condV.indexOf(',')) {
						let arr = item.condV.split(',');
						return arr.includes(val) || arr.includes(val.toString());
					}
					return item.condV == val;
				});
				let compList = Array.from(condition, (item) => item.comp).flat();
				let compShowHide = {};
				for (let item of compList) {
					compShowHide[item] = false;
				}
				if (trueData.length) {
					for (let eleItem of trueData) {
						let compShow = eleItem.comp;
						for (let item of compShow) {
							compShowHide[item] = true;
						}
					}
				}
				this.$emit('trigger-active', compShowHide);
			}
		},
		getLabel(item) {
			return this.$getLocalizedLabel({
				zhLabel: item.label,
				enLabel: item.enLabel
			});
		}
	}
};
</script>

<style lang="less" scoped>
.radio-group {
	.el-radio-group {
		margin-top: -3px;
	}
	.prepend {
		background: #f5f7fa;
		padding: 0 20px;
		border-radius: 4px 0 0 4px;
		box-sizing: border-box;
		height: 36px;
	}
	.append {
		background: #f5f7fa;
		padding: 0 20px;
		border-radius: 0 4px 4px 0;
		box-sizing: border-box;
		height: 36px;
	}
}
.custom {
	display: flex;
	align-items: center;
	.input-show {
		flex: 1;
	}
	.el-radio-group {
		padding: 0 8px;
	}
}
</style>
