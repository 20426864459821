<!--
 * @Description: 表格视图
 * @Author: luocheng
 * @Date: 2022-03-02 10:21:21
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-04 17:15:15
-->
<template>
	<div class="table-view" ref="tableBox" id="table-view">
		<el-table
			ref="tableRef"
			class="table"
			:data="tableData"
			:header-cell-style="{ background: '#f5f5f5', border: 'none' }"
			:height="tableHeight"
			@selection-change="onSelectionChange"
			highlight-current-row
		>
			<el-table-column v-if="statusConfig.batchAction" type="selection" :width="samll ? 35 : 55"> </el-table-column>
			<el-table-column
				prop="name"
				:label="$t('components.TableView.193459-0')"
				sortable
				:sort-method="(a, b) => a.name.localeCompare(b.name)"
			>
				<section class="name-slot" slot="header">
					<template v-if="!samll">
						<span class="name">
							<template v-if="!selectList || !selectList.length || !needShowBatch">{{
								$t('components.TableView.193459-0')
							}}</template>
							<template v-else
								>{{ $t('components.TableView.193459-1') }}{{ selectList.length
								}}{{ $t('components.TableView.193459-2') }}</template
							>
						</span>
						<ul class="header-actions" v-if="selectList && selectList.length && needShowBatch">
							<li
								class="action-item"
								v-if="selectList.some((el) => +el.type_id !== 3)"
								@click="onBatchAction('download')"
							>
								{{ $t('components.TableView.193459-3') }}
							</li>
							<li class="action-item" @click="onBatchAction('move')">{{ $t('components.TableView.193459-4') }}</li>
							<li class="action-item" @click="onBatchAction('copy')">{{ $t('components.TableView.193459-5') }}</li>
							<li class="action-item delete" @click="onBatchAction('delete')">
								{{ $t('components.TableView.193459-6') }}
							</li>
						</ul>
					</template>
					<span v-else>
						<template v-if="!statusConfig.batchAction">{{ $t('components.TableView.193459-0') }}</template>
						<template v-else-if="!selectList || !selectList.length">{{ $t('components.TableView.193459-7') }}</template>
						<template v-else
							>{{ $t('components.TableView.193459-1') }}{{ selectList.length
							}}{{ $t('components.TableView.193459-2') }}</template
						>
					</span>
				</section>
				<template slot-scope="{ row }">
					<div class="custom-file" style="cursor: pointer" @click="onTabRow(row)">
						<div
							class="upload-area"
							v-if="+row.type_id === 4"
							@drop.prevent="handleDrop(row, $event)"
							@dragover.prevent="dragover = true"
							@dragleave.prevent="dragover = false"
						>
							<i class="iconfont" :class="[getClass(row)]"></i>
							<span style="font-size: 14px; line-height: 20px">{{ row.name }}</span>
							<!-- <i style="margin-left: 12px" class="iconfont iconxinzeng5" /> -->
						</div>
						<template v-else>
							<i class="iconfont" :class="[getClass(row)]"></i>
							<span style="font-size: 14px; line-height: 20px">{{ row.name }}</span>
						</template>
					</div>
				</template>
			</el-table-column>
			<el-table-column
				v-if="!samll"
				prop="created_at"
				:label="$t('components.TableView.193459-8')"
				width="180"
				sortable
			>
			</el-table-column>
			<el-table-column
				v-if="!samll"
				prop="file_size"
				:label="$t('components.TableView.193459-9')"
				width="100"
				sortable
				:sort-method="sizeSort"
			>
				<template slot-scope="{ row }">
					{{ row.file_size | filterSize }}
				</template>
			</el-table-column>
			<el-table-column
				v-if="!samll"
				prop="user_name"
				:label="$t('components.TableView.193459-10')"
				width="100"
				sortable
				:sort-method="(a, b) => a.user_name.localeCompare(b.user_name)"
			>
			</el-table-column>
			<el-table-column
				v-if="statusConfig.showActions && !samll"
				prop="action"
				:label="$t('components.TableView.193459-11')"
				width="80"
			>
				<template slot-scope="{ row }">
					<ActionMenu :rowData="row" @action="onAction"></ActionMenu>
				</template>
			</el-table-column>
		</el-table>
		<div class="action-wrap" v-if="selectList && selectList.length && samll && needShowBatch">
			<ul class="action-list">
				<li class="action-item" @click="onBatchAction('move')">
					<i class="iconfont iconyidong1"></i>
					<span>{{ $t('components.TableView.193459-4') }}</span>
				</li>
				<li class="action-item" v-if="selectList.some((el) => +el.type_id !== 3)" @click="onBatchAction('download')">
					<i class="iconfont iconxiazai1"></i>
					<span>{{ $t('components.TableView.193459-3') }}</span>
				</li>
				<li class="action-item" @click="onBatchAction('copy')">
					<i class="iconfont iconfuzhi"></i>
					<span>{{ $t('components.TableView.193459-5') }}</span>
				</li>
				<li
					class="action-item"
					v-if="selectList.length === 1"
					@click.stop="
						onAction({
							type: 'modify',
							rowData: selectList[0]
						})
					"
				>
					<i class="iconfont iconzhongmingming1"></i>
					<span>{{ $t('components.TableView.193459-12') }}</span>
				</li>
				<li
					class="action-item"
					v-if="selectList.length === 1 && +selectList?.[0].type_id === 4 && selectList?.[0].path"
					@click.stop="
						onAction({
							type: 'replace',
							rowData: selectList[0]
						})
					"
				>
					<i class="iconfont icontihuan"></i>
					<span>{{ $t('components.TableView.193459-13') }}</span>
				</li>
				<li class="action-item" @click="onBatchAction('delete')">
					<i class="iconfont iconshanchu4"></i>
					<span>{{ $t('components.TableView.193459-6') }}</span>
				</li>
			</ul>
		</div>
		<!-- {{ $t('components.TableView.193459-12') }} -->
		<Rename
			v-if="currenData && showRename"
			v-model="showRename"
			:fileName="currenData && currenData.name"
			@nameResult="onRename"
		>
		</Rename>
		<LinkModel
			v-if="currenData && showLinkModify"
			v-model="showLinkModify"
			:inputData="currenData"
			@submit="onModifyRow"
		>
		</LinkModel>
		<CreatePlaceholder
			v-if="currenData && showPlaceholderModify"
			v-model="showPlaceholderModify"
			:fileName="currenData && currenData.name"
			@nameResult="onRename"
		>
		</CreatePlaceholder>
		<!-- 移动文件 -->
		<!-- 操作文件(批量) -->
		<OperationFile
			v-if="showBatchOperation"
			v-model="showBatchOperation"
			:type="batchAction"
			:movedData="selectList"
			@updateList="onBatchUpdate"
			:isGroup="isGroup"
			:groupComponents="groupComponents"
			:componentList="componentList"
		>
		</OperationFile>
		<div class="upload-com">
			<input type="file" ref="fileChose" />
			<FileUpload ref="uploadCom" @extra-files="onUploadSuccess" />
		</div>
	</div>
</template>

<script>
import ActionMenu from './ActionMenu';
import Rename from './Rename';
import OperationFile from './OperationFile';
import LinkModel from './LinkModel';
import CreatePlaceholder from './CreatePlaceholder';
import { downloadFileBlob } from '@/utils/tools';
import FileUpload from './FileUpload';
import mixin from '../mixin';
import { dataInterface } from '@/apis/data';

export default {
	name: 'TableView',
	mixins: [mixin],
	props: {
		tableData: {
			// 列表数据
			type: Array,
			required: true,
			default: () => []
		},
		// 是否在组合内
		isGroup: {
			type: Boolean
		},
		// 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
		componentList: {
			default: null
		},
		mode: {
			type: String,
			default: 'normal'
		}
	},
	components: {
		ActionMenu,
		Rename,
		OperationFile,
		LinkModel,
		CreatePlaceholder,
		FileUpload
	},
	data() {
		return {
			tableHeight: 800,
			// 当前操作行
			currenData: {},
			// 重命名
			showRename: false,
			showLinkModify: false,
			showPlaceholderModify: false,
			// 选中
			selectList: [],
			// 批量操作
			showBatchOperation: false,
			batchAction: 'move',
			dragover: false,
			// 是否需要显示批量
			needShowBatch: true
		};
	},
	inject: ['element'],
	computed: {
		actionConfig() {
			return this.element?.actionConfig;
		},
		statusConfig() {
			return this.element?.statusConfig;
		},
		database() {
			return this.element?.database;
		},
		samll() {
			return this.mode === 'samll';
		}
	},
	filters: {
		filterSize(size) {
			if (!size) return '-';
			return `${(size / 1024 / 1024).toFixed(2)} M`;
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.resizeHeight();
		});
		window.onresize = () => {
			this.resizeHeight();
		};
	},
	methods: {
		/**
		 * @description: 表格排序方法
		 * @param {*} obj1
		 * @param {*} obj2
		 * @return {*}
		 */
		sizeSort(obj1, obj2) {
			var val1 = obj1.file_size;
			var val2 = obj2.file_size;
			return val1 - val2;
		},
		/**
		 * @description: 选择文件上传
		 * @param {Object} row 文件对象
		 */
		handleChoseFile(row) {
			if (!this.$refs.fileChose) return;
			this.$refs.fileChose.click();
			this.$refs.fileChose.onchange = (e) => {
				const files = e.target.files;
				if (files.length) {
					const file = files[0];
					const reg = new RegExp(`^${row.name}` + '\\.[a-z1-9A-z]+$');
					if (reg.test(file.name) || row.name === file.name) {
						file.sourceID = row.id;
						this.$refs?.uploadCom?.$refs?.upload?.$refs['upload-inner'].uploadFiles([file]);
					} else {
						this.$message(this.$t('components.TableView.193459-14'));
					}
				}
				e.target.files = null;
			};
		},
		/**
		 * @description: 拖拽文件上传
		 * @param {Object} row
		 * @param {EventObject} e
		 * @return {void}
		 */
		handleDrop(row, e) {
			e.preventDefault();
			console.log(e);
			this.dragover = false;
			if (e.dataTransfer.files.length) {
				const file = e.dataTransfer.files[0];
				const reg = new RegExp(`^${row.name}` + '\\.[a-z1-9A-z]+$');
				if (reg.test(file.name) || row.name === file.name) {
					if (row.path) {
						this.$confirm(this.$t('components.TableView.193459-15'), this.$t('components.TableView.193459-16'), {
							confirmButtonText: this.$t('components.TableView.193459-17'),
							cancelButtonText: this.$t('components.TableView.193459-18'),
							type: 'warning'
						})
							.then(() => {
								file.sourceID = row.id;
								this.$refs?.uploadCom?.$refs?.upload?.$refs['upload-inner'].uploadFiles([file]);
							})
							.catch(() => {
								// nothing todo
							});
					} else {
						file.sourceID = row.id;
						this.$refs?.uploadCom?.$refs?.upload?.$refs['upload-inner'].uploadFiles([file]);
					}
				} else {
					this.$message(this.$t('components.TableView.193459-14'));
				}
			}
		},
		/**
		 * @description: 上传更新文件
		 * @param {Object} row
		 * @return {void}
		 */
		updateFile(row) {
			if (+row.type_id === 4 && row.path) {
				this.$confirm(this.$t('components.TableView.193459-15'), this.$t('components.TableView.193459-16'), {
					confirmButtonText: this.$t('components.TableView.193459-17'),
					cancelButtonText: this.$t('components.TableView.193459-18'),
					type: 'warning'
				})
					.then(() => {
						this.handleChoseFile(row);
					})
					.catch(() => {
						// nothing todo
					});
			}
		},
		/**
		 * @desc: 上传成功
		 * @param {Object} data 上传成功的返回数据
		 */
		onUploadSuccess(data) {
			const { param = [], canPost = false } = this.getQuery(this.actionConfig?.upload?.paramsConfig || []);
			if (!canPost) {
				return false;
			}
			this.modifyData({
				__method_name__: 'updateData',
				data_id: data.sourceID, // 更新数据
				name: data.name,
				path: data.filepath,
				hash: data.file_md5,
				file_size: data.filesize,
				ext: data.fileext,
				full_data: data,
				...param
			});
		},
		/**
		 * @desc: 点击行
		 * @param {Object} row 行数据
		 */
		onTabRow(row) {
			this.$emit('selectFile', row);
			if (+row.type_id === 4 && !row.path) {
				this.handleChoseFile(row);
			}
		},
		/**
		 * @description: 文件操作
		 * @param {Object} data
		 */
		async onAction(data) {
			const { type, rowData } = data;
			this.currenData = rowData;
			this.doResolveData(rowData);
			if (!type || !rowData) {
				this.$message.error(this.$t('components.TableView.193459-19'));
				return false;
			}
			if (type === 'delete') {
				// 删除
				this.doDelete();
			} else if (type === 'modify') {
				if (+rowData.type_id === 3) {
					// 编辑重命名链接
					this.showLinkModify = true;
				} else if (+rowData.type_id === 4) {
					// 编辑重命名链接
					this.showPlaceholderModify = true;
				} else {
					// 编辑文件重命名
					this.showRename = true;
				}
			} else if (type === 'move') {
				// 移动
				this.selectList = [rowData];
				this.batchAction = 'move';
				this.showBatchOperation = true;
				this.needShowBatch = false;
			} else if (type === 'download') {
				// 下载
				let url = rowData.path || rowData.url;
				if (!url) {
					this.$message.error(this.$t('components.TableView.193459-20'));
					return;
				}
				if (url.indexOf('http') === -1) {
					url = process.env.VUE_APP_BASE_URL + url;
				}
				const loading = this.$loading();
				await downloadFileBlob(url, rowData.name);
				loading.close();
			} else if (type === 'replace') {
				this.updateFile(this.currenData);
			} else {
				// this.$message.warning("功能开发中...");
				this.doCustomBtn(type, rowData);
			}
		},
		/**
		 * @desc: 重命名
		 * @param {String} name 新名成
		 */
		onRename(name) {
			this.modifyData({
				__method_name__: 'updateData',
				name,
				data_id: this.currenData.id
			});
		},
		/**
		 * @description: 修改链接数据
		 * @param {Object} data 操作数据对象
		 */
		onModifyRow(data) {
			this.modifyData({
				__method_name__: 'updateData',
				...data,
				data_id: this.currenData.id
			});
		},
		/**
		 * @desc: 删除
		 */
		doDelete(deleteIds = []) {
			const confirmStr = Array.isArray(deleteIds) && deleteIds.length ? this.$t('components.TableView.193459-21') : '';
			this.$confirm(confirmStr || this.$t('components.TableView.193459-22', [this.currenData.name]))
				.then(() => {
					this.modifyData({
						__method_name__: 'deleteData',
						data_id: Array.isArray(deleteIds) && deleteIds.length ? deleteIds : this.currenData.id
					});
				})
				.catch(() => {});
		},
		/**
		 * @desc: 图表类名
		 * @param {Object} row 行数据
		 */
		getClass(row) {
			const { type_id, ext } = row;
			if (+type_id === 1) return 'iconwenjianjia';
			if (+type_id === 3) return 'iconlianjie';
			if (+type_id === 4) return 'iconzhanweifu';
			if (ext === 'xlsx' || ext === 'xls') {
				return 'iconexcle1x';
			} else if (ext === 'doc' || ext === 'docx') {
				return 'iconword1x';
			} else if (ext === 'txt') {
				return 'icontxt1x';
			} else if (ext === 'pptx' || ext === 'ppt') {
				return 'iconppt1x';
			} else if (['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].includes(ext)) {
				return 'iconzhaopian1x';
			} else if (ext === 'pdf') {
				return 'iconpdf1x';
			}
			return 'iconother1x';
		},
		/**
		 * @desc: 选择表格
		 */
		onSelectionChange(val) {
			this.needShowBatch = true;
			this.selectList = val;
			this.doResolveData(val);
		},
		/**
		 * @desc: 计算表格高度
		 */
		resizeHeight() {
			const tableBox = document.querySelector('#table-view');
			if (tableBox) {
				this.tableHeight = tableBox.offsetHeight || '100%';
			}
		},
		/**
		 * @desc: 更新列表
		 */
		updateList() {
			this.$emit('updateList');
			this.currenData = null;
		},
		/**
		 * @desc: 创建数据或编辑数据
		 * @param {Object} params 参数
		 */
		modifyData(params) {
			// TODO NET
			const { objectData = {} } = this.database;
			if (!objectData) {
				this.$message.error(this.$t('components.TableView.193459-23'));
				return;
			}
			const loading = this.$loading();
			dataInterface({
				...params,
				object_uuid: objectData.uuid
			})
				.then((res) => {
					loading.close();
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success(this.$t('components.TableView.193459-24'));
						this.showRename = false;
						this.showLinkModify = false;
						this.showPlaceholderModify = false;
						this.$emit('updateList');
					}
				})
				.catch((err) => {
					console.log(err);
					this.$message.error(this.$t('components.TableView.193459-23'));
					loading.close();
				});
		},
		/**
		 * @desc: 批量操作
		 * @param {String} type 操作类型
		 */
		onBatchAction(type) {
			this.batchAction = type;
			if (type === 'download') {
				// 下载
				this.doBatchDownload();
			} else if (type === 'move') {
				// 移动
				this.showBatchOperation = true;
			} else if (type === 'copy') {
				// 复制
				this.showBatchOperation = true;
			} else if (type === 'delete') {
				// 删除
				this.doDelete(this.selectList.map((ele) => ele.id));
			}
		},
		/**
		 * @description: 批量下载
		 * 后端@廖伟
		 */
		doBatchDownload() {
			if (!this.selectList?.length) {
				this.$message.warning(this.$t('components.TableView.193459-25'));
				return;
			}
			if (!this.element?.database?.objectData) {
				this.$message.error(this.$t('components.TableView.193459-26'));
			}
			this.$loading({
				text: this.$t('components.TableView.193459-27')
			});
			const data_id = this.selectList
				.filter((ele) => {
					return ele.id && +ele.type_id !== 3 && (+ele.type_id === 1 || ele.path);
				})
				.map((ele) => ele.id);
			dataInterface({
				__method_name__: 'globalFunctionCall',
				typeName: 'PublicFunc',
				type: 'behavior',
				funcName: 'NetdiskBatchFileDownload',
				payload: {
					object_uuid: this.element?.database?.objectData?.uuid, // 下载对象uuid
					data_id, // 下载数据id，可传数组
					parent_id_field: '', // 父id字段 默认: parent_id，若填写则为字段UUID
					name_field: '', // name字段 默认: name，若填写则为字段UUID
					file_path_field: '', // 文件path字段 默认: path，若填写则为字段UUID
					is_children: true, // 是否递归子文件夹 默认 true
					keep_structure: true // 保持文件夹结构 默认 true
				}
			})
				.then(async (res) => {
					this.$loading().close();
					if (res.status === 200 && res.data.code === 200) {
						const path = res.data?.__adds__?.[0]?.payload?.url;
						if (!path) {
							this.$message.error(this.$t('components.TableView.193459-28'));
							return;
						}
						await downloadFileBlob(path, '');
						this.$message.success(this.$t('components.TableView.193459-29'));
						return;
					}
					// this.$message.error(this.$t('components.TableView.193459-28'));
				})
				.catch((err) => {
					console.log(err, '---111---');
					this.$loading().close();
					this.$message.error(this.$t('components.TableView.193459-30'));
				});
		},
		/**
		 * @desc: 批量操作操作回调
		 */
		onBatchUpdate() {
			this.selectList = [];
			this.updateList();
		}
	}
};
</script>

<style lang="less" scoped>
.table-view {
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	.table {
		flex-grow: 1;
		height: 10px;
	}
	.action-wrap {
		width: 100%;
		.action-list {
			margin-top: 4px;
			display: flex;
			padding: 0 16px;
			border-radius: 6px;
			background: #f2f7ff;
			align-items: center;
			justify-content: space-between;
			.action-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 4px 12px;
				box-sizing: border-box;
				border-radius: 6px;
				color: #387ffc;
				&:hover {
					background: #d1e3ff;
				}
				.iconfont {
					font-size: 18px;
				}
				span {
					margin-top: 4px;
					line-height: 18px;
					font-size: 12px;
				}
			}
		}
	}
	:deep(.el-table) {
		td.el-table__cell,
		.el-table th.el-table__cell.is-leaf {
			border: none;
		}
		&.el-table--small tbody .el-table__cell {
			height: 52px;
		}
		.name-slot {
			display: inline-flex;
			.header-actions {
				flex: 1;
				display: flex;
				box-sizing: border-box;
				padding-left: 12px;
				.action-item {
					padding: 0 12px;
					color: @theme;
					&.delete {
						color: @dangerColor;
					}
				}
			}
		}
		.custom-file {
			display: flex;
			align-items: center;
			i {
				flex-shrink: 0;
			}
			span {
				line-height: 20px;
				font-size: 14px;
				flex-shrink: 0;
			}
			.upload-area {
				display: flex;
				align-items: center;
				padding: 6px;
				flex-grow: 1;
				box-sizing: border-box;
				border-radius: 6px;
				border: 1px dashed #639dff;
				background: #f2f7ff;
			}
		}
		.iconfont {
			margin-right: 5px;
			font-size: 20px;
			line-height: 20px;
		}
		.iconwenjianjia {
			color: rgb(249, 194, 10);
		}
		.iconexcle1x {
			color: rgb(48, 165, 92);
		}
		.iconword1x {
			color: rgb(45, 133, 254);
		}
		.icontxt1x {
			color: rgb(45, 133, 254);
		}
		.iconzhaopian1x {
			color: rgb(44, 162, 92);
		}
		.iconpdf1x {
			color: rgb(226, 52, 45);
		}
		.iconppt1x {
			color: rgb(246, 123, 30);
		}
		.iconother1x {
			color: rgb(171, 189, 204);
		}
	}
	.upload-com {
		width: 0;
		height: 0;
		overflow: hidden;
	}
}
</style>
