<!--
 * @Description: 下拉框
 * @Author: luocheng
 * @Date: 2021-12-13 09:12:59
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-06 11:13:56
-->
<template>
	<div class="common-form" :class="[statusConfig.transparent ? 'transparent-form' : '', element.id]">
		<span class="label" v-if="statusConfig.showLabel && statusConfig.label" :style="labelStyle">{{
			$i18n.locale === 'en' ? statusConfig.enLabel : statusConfig.label
		}}</span>
		<el-select
			v-model="selectData"
			:placeholder="statusConfig.placeholder || ($i18n.locale === 'en' ? 'Please select' : '请选择')"
			:clearable="statusConfig.clearable"
			:filterable="statusConfig.filterable"
			:disabled="statusConfig.disabled"
			:multiple="statusConfig.multiple"
			:size="statusConfig.selectSize"
			:allow-create="statusConfig.allowCreate"
			:no-data-text="statusConfig.noDataText"
			:prefixIcon="statusConfig.prefixIcon"
			@change="onChange"
			popper-class="custom-select-class"
		>
			<template v-slot:prefix v-if="statusConfig.prefixIcon">
				<i :class="statusConfig.prefixIcon" v-if="statusConfig.prefixIcon.indexOf('el-')" :style="iconStyle"></i>
				<i class="iconfont" :class="statusConfig.prefixIcon" v-else :style="iconStyle"></i>
			</template>
			<el-option
				v-for="(option, index) in options"
				:key="`${option.value}${index}`"
				:label="option.label"
				:value="option.value"
			>
			</el-option>
		</el-select>
	</div>
</template>

<script>
import { Select, Option } from 'element-ui';
import { mapState } from 'vuex';
import { getComponentById } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import { triggerEvents } from '@/utils/events';

export default {
	name: 'CommonSelect',
	components: {
		'el-select': Select,
		'el-option': Option
	},
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 是否在组合内
		isGroup: {
			type: Boolean
		},
		// 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
		componentList: {
			default: null
		}
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			selectData: '',
			inited: false,
			options: []
		};
	},
	computed: {
		...mapState(['componentData', 'subsidiaryComponentData']),
		// 取值组件列表
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return (
					this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData ||
					this.componentList ||
					this.componentData ||
					[]
				);
			}
			return this.componentList || this.componentData || [];
		},
		statusConfig() {
			return (this.element && this.element.statusConfig) || {};
		},
		// label样式
		labelStyle() {
			const { showLabel, labelWidth, labelPosition, selectSize, selectHeight = 36 } = this.statusConfig;
			if (!showLabel) return {};
			let lineHeight = '36px';
			let fontSize = 14;
			if (isNaN(selectHeight)) {
				if (selectSize === 'medium') {
					lineHeight = '36px';
				} else if (selectSize === 'small') {
					lineHeight = '32px';
					fontSize = fontSize * 0.8;
				} else if (selectSize === 'mini') {
					lineHeight = '28px';
					fontSize = fontSize * 0.7;
				}
			} else {
				lineHeight = `${selectHeight}px`;
			}
			return {
				width: isNaN(+labelWidth) ? labelWidth : labelWidth + 'px',
				textAlign: labelPosition,
				lineHeight,
				fontSize: `${fontSize}px`
			};
		},
		inputColor() {
			return this.statusConfig?.inputColor;
		},
		// icon样式
		iconStyle() {
			const { selectSize } = this.statusConfig;
			let colorObj = { color: this.statusConfig?.inputColor || '#fff' };
			if (selectSize === 'medium') {
				return {
					...colorObj,
					lineHeight: '36px'
				};
			}
			if (selectSize === 'small') {
				return {
					...colorObj,
					lineHeight: '32px'
				};
			}
			if (selectSize === 'mini') {
				return {
					...colorObj,
					lineHeight: '28px'
				};
			}
			return {
				...colorObj,
				lineHeight: '32px'
			};
		},
		// 数据仓库
		database() {
			return this.element?.database;
		},
		// 字段对象
		containerData() {
			let container = getComponentById(this.subComponentData, this.database.containerKey);
			if (!container && this.isGroup && this.groupComponents.length) {
				container = getComponentById(this.groupComponents, this.database.containerKey);
			}
			if ((!container || !container.containerData) && !this.fullData) return null;
			const result = (container && container.containerData) || this.fullData;
			return result;
		},
		selectHeight() {
			return this.statusConfig?.selectHeight;
		},
		hideBorder() {
			return this.statusConfig?.hideBorder;
		}
	},
	created() {
		this.options = this.getOptions();
	},
	watch: {
		options: {
			handler(n, o) {
				// option 如果变更了
				const isDifferent = n?.map((el) => el.value)?.toString() !== o?.map((el) => el.value)?.toString();
				if (this.options.length && this.statusConfig.defaultSelect && isDifferent) {
					// this.onChange(this.options[0].value)
					this.selectData = this.options[0].value;
					this.inited = true;
					this.onChange(this.options[0].value);
				}
			},
			deep: true,
			immediate: true
		},
		subComponentData: {
			handler() {
				// newVal, oldVal
				// console.log(newVal, '---newVal----oldVal--', oldVal);
				this.options = this.getOptions();
			},
			deep: true
		},
		selectHeight(newVal) {
			this.$nextTick(() => {
				const select = document.querySelector(`.${this.element.id} .el-select .el-input input`);
				const selectIcon = document.querySelector(`.${this.element.id} .el-select .el-input .el-input__suffix i`);
				const statusHeight = isNaN(+newVal) ? '36px' : `${newVal}px`;
				select.style.height = statusHeight;
				selectIcon.style.lineHeight = statusHeight;
			});
		},
		hideBorder(newVal) {
			this.$nextTick(() => {
				const select = document.querySelector(`.${this.element.id} .el-select .el-input input`);
				select.style.border = newVal ? 'none' : '1px solid #DCDFE6';
			});
		},
		inputColor(newVal) {
			this.$nextTick(() => {
				const selectIcon = document.querySelector(`.${this.element.id} .el-select .el-input el-input__suffix`);
				const select = document.querySelector(`.${this.element.id} .el-select .el-input input`);
				select.style.color = newVal;
				selectIcon.style.color = newVal;
			});
		}
	},
	mounted() {
		this.$nextTick(() => {
			const { hideBorder, inputColor, selectHeight = 36 } = this.statusConfig;

			const select = document.querySelector(`.${this.element.id} .el-select .el-input input`);
			const selectIcon = document.querySelector(`.${this.element.id} .el-select .el-input .el-input__suffix i`);
			const statusHeight = isNaN(+selectHeight) ? '36px' : `${selectHeight}px`;
			if (inputColor) {
				select.style.color = inputColor;
			}
			select.style.height = statusHeight;
			select.style.border = hideBorder ? 'none' : '1px solid #DCDFE6';
			selectIcon.style.lineHeight = statusHeight;
			selectIcon.style.color = this.statusConfig?.inputColor || '#fff';
		});
	},
	methods: {
		/**
		 * @description: 获取参数
		 */
		getOptions() {
			if (!this.database || !this.database.containerKey) return [];
			let container = getComponentById(this.subComponentData, this.database.containerKey);
			if (!container && this.isGroup && this.groupComponents.length) {
				container = getComponentById(this.groupComponents, this.database.containerKey);
			}
			if (!container) return [];
			const containerData = container && container.containerData;
			if (!containerData || !Array.isArray(containerData) || !containerData.length) return [];
			const { optionLabel, optionValue, optionValueUUID } = this.statusConfig;
			const { metadata } = container;
			if (!optionLabel || !optionValue || !metadata) return [];
			const metaKeys = Object.keys(metadata);
			const metaValues = Object.values(metadata);
			const options = containerData.map((ele) => {
				return {
					label: ele[metaKeys[metaValues.findIndex((ele) => ele === optionLabel)]],
					value: optionValueUUID
						? ele[optionValueUUID]
						: ele[metaKeys[metaValues.findIndex((ele) => ele === optionValue)]],
					...ele
				};
			});
			if (this.statusConfig.needAll) {
				options.unshift({
					label: '全部',
					value: ''
				});
			}
			return options;
		},
		/**
		 * @desc: 选中
		 * @param {String, Array} val 选中的值
		 */
		onChange(val) {
			let resolveData = null;
			if (val) {
				// 多选
				if (this.statusConfig.multiple) {
					resolveData = [];
					val.forEach((ele) => {
						const obj = this.options.find((item) => item.value === ele.value);
						if (obj) {
							resolveData.push(obj);
						}
					});
				} else {
					const obj = this.options.find((item) => item.value === val);
					if (obj) {
						resolveData = JSON.parse(JSON.stringify(obj));
					}
				}
			} else {
				resolveData = {
					label: '',
					value: ''
				};
			}
			this.element.resolveData = resolveData;
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element,
				resolveData
			});
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
			eventBus.$emit('databaseTrigger', {
				componentId: this.element.id,
				action: 'any',
				output: resolveData
			});
			// 事件
			triggerEvents(this.element, 'change', this.subComponentData, this);
		}
	}
};
</script>

<style lang="less" scoped>
.common-form {
	display: flex;
	&.transparent-form {
		:deep(.el-input) {
			background: transparent;
			input {
				background: transparent;
				color: #fff;
				&::-webkit-input-placeholder {
					color: #fff;
				}
				&::-moz-placeholder {
					/* Mozilla Firefox 19+ */
					color: #fff;
				}
				&:-moz-placeholder {
					/* Mozilla Firefox 4 to 18 */
					color: #fff;
				}
				&:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					color: #fff;
				}
			}
			.el-select__caret,
			.el-input__icon,
			.el-icon-arrow-up {
				color: #fff;
			}
		}
	}
	.el-select {
		flex: 1;
	}
	// .el-input__prefix{
	// 	i{
	// 		// line-height: 32px;
	// 	}
	// }
}
</style>
<style lang="less">
// 超长内容限制，最大宽度待确认
.el-select-dropdown.el-popper.custom-select-class {
	max-width: 900px;
}
</style>
