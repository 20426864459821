import eventBus from '@/plugins/eventBus';

export default class ModelEvents {
	constructor() {}

	static getEvents(viewer, comp) {
		viewer.on(viewer.EventType.modelLoaded, (modelId) => {
			eventBus.$emit('ViewerGIS.onModelLoaded', comp.element.id, modelId);
			comp.onAction('onModelLoaded');
		});
		viewer.on(viewer.EventType.showModel, (modelId, isShow) => {
			eventBus.$emit('ViewerGIS.onShowModel', comp.element.id, modelId, isShow);
		});
		viewer.on(viewer.EventType.featureSelect, (list) => {
			if (list.length === 0) return;

			comp.setResolveData('gis_featureSelected_modelId', list[list.length - 1].modelId);
			comp.setResolveData('gis_featureSelected_dbid', list[list.length - 1].dbid);
			comp.onAction('onFeatureSelected');

			eventBus.$emit('databaseTrigger', {
				componentId: comp.element.id,
				isSearch: true,
				action: 'any',
				output: comp.element.resolveData
			});
		});

		return {
			'ViewerGIS.createModel': (eid, modelId, isLocation, cb) => {
				if (eid !== comp.element.id) return;

				viewer.Model.createModel(modelId, { isLocation }).then((res) => {
					if (!res) return console.error('已添加相同modelId模型');

					const tree = viewer.Model.getTreeByKey(modelId);

					cb && cb(res, tree);
				});
			},
			'ViewerGIS.removeModel': (eid, modelId) => {
				if (eid !== comp.element.id) return;

				viewer.Model.removeModel(modelId);
			},
			'ViewerGIS.locationModel': (eid, modelId) => {
				if (eid !== comp.element.id) return;

				viewer.Model.locationModel(modelId);
			},
			'ViewerGIS.showModel': (eid, modelId, isShow) => {
				if (eid !== comp.element.id) return;

				viewer.Model.showModel(modelId, isShow);
			},
			'ViewerGIS.getModelKeys': (eid, cb) => {
				if (eid !== comp.element.id) return;

				const keys = viewer.Model.getModelKeys();
				cb && cb(keys);
			},
			'ViewerGIS.getModelInfo': (eid, cb) => {
				if (eid !== comp.element.id) return;

				const info = viewer.Model.getModelInfo();

				for (let item of info) {
					const tree = viewer.Model.getTreeByKey(item.modelId);
					item.tree = tree;
				}

				cb && cb(info);
			},
			'ViewerGIS.getSelectFeature': (eid, cb) => {
				if (eid !== comp.element.id) return;

				const dbids = viewer.Model.getSelectFeature();
				cb && cb(dbids);
			},
			'ViewerGIS.selectFeature': (eid, modelId, dbids, isLocation = false) => {
				if (eid !== comp.element.id) return;

				viewer.Model.selectFeature(modelId, dbids, isLocation);
			},
			'ViewerGIS.insulateFeature': (eid, modelId, dbids, isLocation = false, showShadows = false) => {
				if (eid !== comp.element.id) return;
				//开启隔离阴影
				viewer.Model.setShadows(showShadows);

				viewer.Model.insulateFeature(modelId, dbids, isLocation);
			},
			'ViewerGIS.setShadows': (eid, bol) => {
				if (eid !== comp.element.id) return;
				//开启隔离阴影
				viewer.Model.setShadows(bol);
			},
			'ViewerGIS.showFeature': (eid, modelId, dbids, isShow, isLocation = false) => {
				if (eid !== comp.element.id) return;

				viewer.Model.showFeature(modelId, dbids, isShow, isLocation);
			},
			'ViewerGIS.showAllFeature': (eid, modelId, isShow) => {
				if (eid !== comp.element.id) return;

				viewer.Model.showAllFeature(modelId, isShow);
			},
			'ViewerGIS.locationFeature': (eid, modelId, dbids, duration) => {
				if (eid !== comp.element.id) return;

				viewer.Model.locationFeature(modelId, dbids, duration);
			},
			'ViewerGIS.getModelTree': (eid, modelId, cb) => {
				if (eid !== comp.element.id) return;

				const tree = viewer.Model.getTreeByKey(modelId);
				cb && cb(tree);
			},
			'ViewerGIS.setFeatureColor': (eid, modelId, dbids, color) => {
				if (eid !== comp.element.id) return;

				viewer.Model.setFeatureColor(modelId, dbids, color);
			},
			'ViewerGIS.clearFeatureColor': (eid, modelId, dbids) => {
				if (eid !== comp.element.id) return;

				viewer.Model.clearFeatureColor(modelId, dbids);
			},
			'ViewerGIS.setFeatureKeeping': (eid, type, data, showShadows = false) => {
				if (eid !== comp.element.id) return;

				//开启隔离阴影
				viewer.Model.setShadows(showShadows);

				viewer.Model.setFeatureKeeping(type, data);
			},
			'ViewerGIS.clearFeatureKeeping': (eid) => {
				if (eid !== comp.element.id) return;

				viewer.Model.clearFeatureKeeping();
			}
		};
	}
}
