<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2022-11-11 11:31:07
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-04 16:55:00
 * @FilePath: \dataview-viewer-test\src\custom-component\weather\WeatherDay.vue
 * @Description: 天气分析组件，天视图
-->

<template>
	<div class="weather-day-container">
		<header class="waether-day-header">
			{{ $t('weather.WeatherDay.740282-0') }}
		</header>
		<section class="mian-info" v-if="weatherData">
			<div class="left-side">
				<span class="temperature-info">{{ weatherData.tem }}°</span>
				<span class="weather-info">{{ weatherData.wea }}</span>
				<span class="air-quality" :class="getAirLeave(weatherData.air_level)">{{ weatherData.air_level }}</span>
			</div>
			<div class="right-side">
				<img class="image-icon" :src="getWeatherIcon(weatherData.wea_img)" alt="" srcset="" />
			</div>
		</section>
		<section class="other-info" v-if="weatherData">
			<div class="other-info-item">
				<div class="icon">
					<svg width="26" height="26">
						<use :xlink:href="'#iconwendu2'"></use>
					</svg>
				</div>
				<div class="mesaage">{{ $t('weather.WeatherDay.740282-1') }} {{ weatherData.humidity | humidityFilter }}</div>
			</div>
			<div class="other-info-item">
				<div class="icon">
					<svg width="26" height="26">
						<use :xlink:href="'#iconfengsu1'"></use>
					</svg>
				</div>
				<div class="mesaage">{{ $t('weather.WeatherDay.740282-2') }} {{ weatherData.win_meter || '-' }}</div>
			</div>
			<div class="other-info-item">
				<div class="icon">
					<svg width="26" height="26">
						<use :xlink:href="'#iconqiya'"></use>
					</svg>
				</div>
				<div class="mesaage">{{ $t('weather.WeatherDay.740282-3') }} {{ weatherData.pressure || '-' }}hpa</div>
			</div>
		</section>
		<!-- 此提示音暂时不存在 -->
		<!-- <p class="tips">现在温度比较舒适~</p> -->
		<section ref="chartWarp" class="chart-warp"></section>
	</div>
</template>

<script>
import * as eCharts from 'echarts';
import Minin from '../functionCom/mixin';
import { dataInterface } from '@/apis/data/index';
import { nextTick } from 'vue';
import eventBus from '@/plugins/eventBus';
/**
 * @description 天气
 */
export default {
	name: 'WeatherDay',
	props: {
		scaleHeight: {
			type: Number,
			default: 1
		},
		scaleWidth: {
			type: Number,
			default: 1
		}
	},
	mixins: [Minin],
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			weatherData: null,
			list: []
		};
	},
	watch: {
		'element.style.color': {
			/**
			 * @description: 改变图表字体颜色
			 * @param {*} n
			 * @param {*} o
			 */
			handler(n, o) {
				if (n !== o) {
					this.reSetChart();
				}
			}
		}
	},
	mounted() {
		this.$nextTick().then(() => {
			this.getWeatherInfo();
			// 盒子大小监听
			const obseserve = new ResizeObserver((entries) => {
				for (const entry of entries) {
					if (entry) {
						this.resizeChart();
					}
				}
			});
			obseserve.observe(this.$refs.chartWarp);
			this.resizeObseserve = obseserve;
		});
	},
	beforeDestroy() {
		if (this.resizeObseserve) {
			this.resizeObseserve.disconnect();
		}
	},
	filters: {
		/**
		 * @description: 百分比过滤器
		 * @param {*} value
		 * @return {String}
		 */
		humidityFilter(value) {
			if (value.endsWith('%')) {
				return value;
			} else {
				return `${value}%`;
			}
		}
	},
	methods: {
		/**
		 * @description: 重新设置图表大小
		 */
		resizeChart() {
			if (this.chart && this.chart.resize) {
				this.chart.resize();
			}
		},
		/**
		 * @description: 重置图表
		 */
		reSetChart() {
			const option = {
				title: {
					show: false
				},
				grid: {
					left: 30,
					top: 10,
					right: 10,
					bottom: 25
				},
				tooltip: {},
				legend: {
					data: [this.$t('weather.WeatherDay.740282-4')],
					show: false
				},
				textStyle: {
					color: this.element?.style?.color || '#111'
				},
				xAxis: {
					data: this.list.map((el) => el.hours),
					axisTick: {
						show: false
					}
				},
				yAxis: {
					axisLine: {
						lineStyle: {
							type: 'dashed'
						}
					}
				},
				series: [
					{
						itemStyle: {
							color: '#4FB5FF'
						},
						markPoint: {
							symbol: 'rect'
						},
						name: this.$t('weather.WeatherDay.740282-4'),
						type: 'line',
						data: this.list.map((el) => el.tem)
					}
				]
			};
			if (this.chart) {
				this.chart.setOption(option);
			}
		},
		// 获取天气情况
		getWeatherInfo() {
			const REQDATA = {
				__method_name__: 'dataList',
				object_uuid: 'object635246a1edca4',
				view_uuid: 'view63564a83d4f60',
				transcode: 0
			};
			const REQDATA1 = {
				__method_name__: 'dataList',
				object_uuid: 'object635246aa0934f',
				view_uuid: 'view6355f43015b84',
				transcode: 0
			};
			Promise.all([dataInterface(REQDATA), dataInterface(REQDATA1)]).then((res) => {
				const res1 = res[0];
				const res2 = res[1];
				if (res1.status === 200 && res1.data.code === 200 && res2.status === 200 && res2.data.code === 200) {
					this.weatherData = res1.data.data[0];
					this.setFiledList(res1.data.data[0]);
					this.$store.commit('modifyComponent', {
						component: {
							...this.element,
							resolveData: this.weatherData,
							metadata: this.metadata
						},
						containerId: null,
						isModify: true,
						pageUUID: this.EDITOR_pageUUID
					});
					nextTick().then(() => {
						eventBus.$emit('databaseTrigger', {
							componentId: this.element.id,
							isSearch: true,
							action: 'any',
							output: this.weatherData
						});
					});
					this.list = res2.data.data;
					this.initChart();
				}
			});
		},
		/**
		 * @description: 设置字段列表
		 */
		setFiledList(data) {
			const fieldList = [];
			this.metadata = {};
			for (let key in data) {
				fieldList.push({
					name: key,
					uuid: key,
					// 无用配置暂时冗余
					renderConfig: {
						isArchiTree: true,
						forView: true,
						width: '',
						type: 1
					}
				});
				this.$set(this.metadata, key, key);
			}
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					metadata: this.metadata,
					database: {
						...this.element.database,
						fieldList
					}
				},
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @description: 初始化图表
		 */
		initChart() {
			const chart = eCharts.init(this.$refs.chartWarp);
			const option = {
				title: {
					show: false
				},
				grid: {
					left: 30,
					top: 10,
					right: 10,
					bottom: 25
				},
				tooltip: {},
				legend: {
					data: [this.$t('weather.WeatherDay.740282-4')],
					show: false
				},
				textStyle: {
					color: this.element?.style?.color || '#111'
				},
				xAxis: {
					data: this.list.map((el) => el.hours),
					axisTick: {
						show: false
					}
				},
				yAxis: {
					axisLine: {
						lineStyle: {
							type: 'dashed'
						}
					}
				},
				series: [
					{
						itemStyle: {
							color: '#4FB5FF'
						},
						markPoint: {
							symbol: 'rect'
						},
						name: this.$t('weather.WeatherDay.740282-4'),
						type: 'line',
						data: this.list.map((el) => el.tem)
					}
				]
			};
			chart.setOption(option);
			this.chart = chart;
		},
		/**
		 * @description: 获取空气质量class
		 * @param {*} value
		 * @return {String}
		 */
		getAirLeave(value) {
			switch (value) {
				case this.$t('weather.WeatherDay.740282-5'): {
					return 'you';
				}
				case this.$t('weather.WeatherDay.740282-6'): {
					return 'liang';
				}
				case this.$t('weather.WeatherDay.740282-7'): {
					return 'qingdu';
				}
				case this.$t('weather.WeatherDay.740282-8'): {
					return 'zhongdu';
				}
				case this.$t('weather.WeatherDay.740282-9'): {
					return 'yangzhong';
				}
				default: {
					return '';
				}
			}
		},
		/**
		 * @description: 获取天气图片地址
		 * @param {String} value
		 * @return {Sting}
		 */
		getWeatherIcon(value) {
			switch (value) {
				case 'xue': {
					return 'https://static.bimcc.com/openim/16666805710583B0E54685A560079C4A30EBE71725863.png';
				}
				case 'lei': {
					return 'https://static.bimcc.com/openim/16666804388090F4B6C84914228FA2B1C71A60AE133CF.png';
				}
				case 'shachen': {
					return 'https://static.bimcc.com/openim/166668054866456246AB98A8EB099E37D53E93D7FE239.png';
				}
				case 'wu': {
					return 'https://static.bimcc.com/openim/16666805910002E910859D9FDC0B1E9783DCAF73F87B8.png';
				}
				case 'bingbao': {
					return 'https://static.bimcc.com/openim/16666806552111F093B534457E1329714C513FF268792.png';
				}
				case 'yun': {
					return 'https://static.bimcc.com/openim/1666680635113CF0AEC47417D4900684150D460183712.png';
				}
				case 'yu': {
					return 'https://static.bimcc.com/openim/1666680619224A00881EE12063060CC6A22AAE3CFEA7B.png';
				}
				case 'yin': {
					return 'https://static.bimcc.com/openim/16666806059531A508F3C8FB1CBA54D019A986A9EDADE.png';
				}
				case 'qing': {
					return 'https://static.bimcc.com/openim/16666805231437BA6305DB10FC134C2309660A027A857.png';
				}
			}
		}
	}
};
</script>

<style lang="less" scoped>
// 注释掉颜色是因为@林冉，需要颜色可配置
.weather-day-container {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	text-align: left;
	display: flex;
	flex-direction: column;
	.waether-day-header {
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.05em;
		// color: #272829;
	}
	.mian-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 24px;
		.left-side {
			display: flex;
			align-items: flex-end;
			.temperature-info {
				font-style: normal;
				font-weight: 500;
				font-size: 48px;
				letter-spacing: 0.05em;
				// color: #272829;
			}
			.weather-info {
				margin-left: 16px;
				font-style: normal;
				font-weight: 500;
				font-size: 24px;
				letter-spacing: 0.05em;
				// color: #272829;
			}
			.air-quality {
				margin-left: 38px;
				padding: 6px 18px;
				border-radius: 4px;
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				text-align: center;
				color: #ffffff;
			}
			.you {
				background: #81da7f;
			}
			.liang {
				background: #ffbd16;
			}
			.qingdu {
				background: #8a99d7;
			}
			.zhongdu {
				background: #7b69ea;
			}
			.yangzhong {
				background: #e24c4c;
			}
		}
		.right-side {
			display: flex;
			align-items: center;
			padding-right: 32px;
			box-sizing: border-box;
			.image-icon {
				width: 98px;
				height: 89px;
			}
		}
	}
	.other-info {
		margin-top: 22px;
		display: flex;
		align-items: center;
		.other-info-item {
			display: flex;
			align-items: center;
			& + .other-info-item {
				margin-left: 56px;
			}
		}
		.icon {
			padding: 2px;
		}
		.mesaage {
			margin-left: 12px;
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			letter-spacing: 0.05em;
			// color: #4E5466;
		}
	}
	.tips {
		margin-top: 16px;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 27px;
		letter-spacing: 0.05em;
		// color: #4E5466;
	}
	.chart-warp {
		flex-grow: 1;
		height: 10px;
		margin-top: 10px;
	}
}
</style>
