<!--
 * @Description: 大屏
 * @Author: luocheng
 * @Date: 2022-04-28 17:03:58
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-03-13 15:00:56
 * @LastEditTime: 2022-09-14 15:38:48
-->
<template>
	<div
		class="screen-parser screen-container"
		:style="{
			backgroundColor: +isTransparent === 1 ? 'transparent' : customEditorConfig.backgroundColor
		}"
	>
		<!-- eslint-disable -->
		<template v-for="(item, index) in customComponentList">
			<AnimateCom :animations="item.animations" :key="item.id">
				<ShapeBox
					:key="item.id"
					:index="index"
					:defaultStyle="item.style || {}"
					:element="item"
					:style="getBoxStyle(item.style, item.specialStyle.fixedSize, false, item)"
					v-if="
						item.statusConfig &&
						item.statusConfig.isShow &&
						judgingArchiAPermission(item.archiLimit, item.authConfig && item.authConfig.list) &&
						judgingUILogic(item.UILogic, componentList)
					"
					@click.native.stop="onClickBox(item, 'click')"
					@dblclick.native.stop="onDblClickBox(item)"
					@mouseleave.native.stop="onClickBox(item, 'mouseleave')"
					@mouseenter.native.stop="onClickBox(item, 'mouseenter')"
					v-show="item.statusConfig && (item.statusConfig.displayShow || item.statusConfig.displayShow === undefined)"
				>
					<component
						:is="item.component"
						:index="index"
						:element="item"
						:id="'component' + item.id"
						:style="getComStyle(item.style, item.specialStyle.fixedSize, item)"
						:propValue="item.propValue"
						:scaleHeight="+scaleHeight"
						:scaleWidth="+scaleWidth"
						:ratio="+ratio"
						:isPreview="true"
					>
					</component>
				</ShapeBox>
			</AnimateCom>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { getStyle } from '@/utils/style';
import ShapeBox from './ShapeBox';
import {
	getComponentById,
	judgingEventLogic,
	getStatusVal,
	doEEActionHandle,
	getQueryValue,
	judgingArchiAPermission,
	judgingUILogic,
	doFrontOperation,
	openUrl
} from '@/utils/tools';
import AnimateCom from '@/components/parser/screen/AnimateCom.vue';
import { dataInterface } from '@/apis/data';
// 注册组件
const componentsList = require.context('@/custom-component', true, /\.+vue$/);
const customComponents = {};
componentsList.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsList(fileName).default;
});
//中控台组件
const componentsConsole = require.context('@/console-components', true, /\.+vue$/);
componentsConsole.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsConsole(fileName).default;
});

export default {
	name: 'ScreenParser',
	components: {
		ShapeBox,
		AnimateCom,
		...customComponents
	},
	data() {
		return {
			componentList: [],
			scaleHeight: 1,
			scaleWidth: 1,
			ratio: 1,
			// 单双击事件
			timer: null,
			isTransparent: 0
		};
	},
	inject: ['EDITOR_pageUUID'],
	computed: {
		...mapState([
			'componentData', // 图层列表
			'editorConfig',
			'subsidiaryComponentData'
		]),
		// 嵌入页面的参数获取
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return (
					this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData ||
					this.componentList ||
					this.componentData ||
					[]
				);
			}
			return this.componentList || this.componentData || [];
		},
		// Y额面列表
		customComponentList() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || [];
			}
			// console.log(this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || [], 'thsaasassis.componentData')
			// console.log(this.componentData, 'this.componentData')
			return this.componentData || [];
		},
		// 页面配置
		customEditorConfig() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.editorConfig || {};
			}
			return this.editorConfig;
		},
		originHeight() {
			return this.editorConfig.height;
		},
		originWidth() {
			return this.editorConfig.width;
		}
	},
	created() {
		this.componentList = this.customComponentList;
		this.isTransparent = this.$route.query._isTransparent || 0;
	},
	watch: {
		// 组件列表
		customComponentList: {
			handler() {
				this.componentList = this.customComponentList;
			},
			deep: true
		},
		originWidth() {
			sessionStorage.setItem('screenViewOriginWidth', this.originWidth);
		}
	},
	mounted() {
		this.getSizeScale();
		window.addEventListener('resize', this.getSizeScale);
		// 全屏变化回调
		if (typeof document.fullScreen !== 'undefined') {
			document.addEventListener('fullscreenchange', function () {
				this.doResize();
				this.getSizeScale();
			});
		} else if (typeof document.webkitIsFullScreen !== 'undefined') {
			document.addEventListener('webkitfullscreenchange', function () {
				this.doResize();
				this.getSizeScale();
			});
		} else if (typeof document.mozFullScreen !== 'undefined') {
			document.addEventListener('mozfullscreenchange', function () {
				this.doResize();
				this.getSizeScale();
			});
		}
		this.$nextTick(() => {});
	},
	methods: {
		judgingArchiAPermission,
		judgingUILogic,
		/**
		 * @desc: 获取UI判断逻辑(拟弃用)
		 */
		getUILogicStatus(item) {
			const { UILogic } = item;
			if (!UILogic || !UILogic.enable) return true;
			const { logicConfigs } = UILogic;
			const logicList = [];
			for (let i = 0; i < logicConfigs.length; i++) {
				const item = logicConfigs[i];
				const { dataAttr, field, itemLogic, logicRule, type, value, valueType, componentId, dataOrigin, valueField } =
					item;
				// 逻辑关系
				let itemBoolean = true;
				let logicValue = null;
				if (type === 'userInfo') {
					// 用户信息
					const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
					logicValue = userInfo && userInfo[field];
					if (dataAttr === 'length') {
						logicValue = logicValue.length;
					}
				} else if (type === 'framework') {
					// 架构信息
					const targetArchi = localStorage.getItem('targetArchi') && JSON.parse(localStorage.getItem('targetArchi'));
					logicValue = targetArchi && targetArchi[field];
					if (dataAttr === 'length') {
						logicValue = logicValue.length;
					}
				} else if (type === 'component') {
					// 组件
					let originComponent = getComponentById(this.componentData, componentId);
					if (!originComponent && this.isGroup && this.groupComponents.length) {
						originComponent = getComponentById(this.groupComponents, componentId);
					}
					if (!originComponent) return false;
					let logicData = null;
					if (dataOrigin === 'resolveData') {
						// 暴露值
						logicData = originComponent?.resolveData;
					} else if (dataOrigin === 'componentData') {
						// 组件数据
						logicData = originComponent?.fullData || originComponent?.containerData;
					}
					if (dataAttr === 'value') {
						logicValue = logicData;
						if (valueField) {
							// 取值字段
							logicValue = logicData?.[valueField];
						}
					} else if (dataAttr === 'length') {
						try {
							logicValue = logicData.length;
						} catch (e) {
							console.log(e, '---999----');
						}
					}
				}
				// 逻辑关系
				if (logicRule === '===') {
					if (valueType === 'string') {
						itemBoolean = `${logicValue}` === `${value}`;
					} else if (valueType === 'number') {
						itemBoolean = +logicValue === +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() === value;
					}
				} else if (logicRule === '!=') {
					if (valueType === 'string') {
						itemBoolean = `${logicValue}` !== `${value}`;
					} else if (valueType === 'number') {
						itemBoolean = +logicValue !== +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() !== value;
					}
				} else if (logicRule === '>') {
					if (valueType === 'number') {
						itemBoolean = +logicValue > +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() > value;
					}
				} else if (logicRule === '<') {
					if (valueType === 'number') {
						itemBoolean = +logicValue < +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() < value;
					}
				} else if (logicRule === '<=') {
					if (valueType === 'number') {
						itemBoolean = +logicValue <= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() <= value;
					}
				} else if (logicRule === '>=') {
					if (valueType === 'number') {
						itemBoolean = +logicValue >= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() >= value;
					}
				} else if (logicRule === 'like') {
					if (valueType === 'string') {
						itemBoolean = logicValue.toString()?.includes(value.toString());
					}
				} else if (logicRule === 'whereIn') {
					// 包含
					try {
						let valArr = [];
						if (Array.isArray(logicValue)) {
							valArr = logicValue;
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						} else if (logicValue.toString()?.startsWith('[') && logicValue.toString()?.endsWith(']')) {
							valArr = JSON.parse(logicValue);
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						} else if (value.toString()?.startsWith('[') && value.toString()?.endsWith(']')) {
							valArr = JSON.parse(value);
							itemBoolean = valArr.includes(logicValue) || valArr.includes(+logicValue);
						} else {
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						}
					} catch (err) {
						console.log(err);
					}
				}
				logicList.push({
					itemBoolean,
					itemLogic
				});
			}
			if (logicList.length === 1) {
				return logicList[0].itemBoolean;
			}
			// 组合逻辑判断
			let prevLogicBoolean = null;
			for (let j = 1; j < logicList.length; j++) {
				const prev = logicList[j - 1];
				const prevItemLogic = prev.itemLogic;
				if (prevItemLogic === 'and') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean;
					} else {
						prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
					}
					if (!prevLogicBoolean) {
						return false;
					}
				} else if (prevItemLogic === 'or') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean;
					} else {
						prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean;
					}
					if (!prevLogicBoolean) {
						return false;
					}
				}
			}
			return true;
		},
		/**
		 * @desc: 获取缩放比例
		 */
		getSizeScale() {
			const ratio = 1;
			const curHeight = document.documentElement.clientHeight * ratio;
			const curWidth = document.documentElement.clientWidth * ratio;
			try {
				this.scaleHeight = (curHeight / this.originHeight).toFixed(2);
				this.scaleWidth = (curWidth / this.originWidth).toFixed(2);
			} catch {
				this.scaleHeight = 1;
				this.scaleWidth = 1;
			}
			// console.log(this.scaleHeight, '----缩放宽高----', this.scaleWidth);
			// console.log(this.originHeight, '----11111---', this.originWidth);
		},
		/**
		 * @desc: 根据图层的样式对象获取可渲染的组件样式
		 * @param {Object/null} styleObj 样式对象
		 * @param { Boolean } fixedSize 是固定尺寸
		 * @return {Object} 壳渲染组件
		 */
		getComStyle(styleObj, fixedSize, item) {
			if (!styleObj || !styleObj.backgroundColor) return {};
			let excludesArr = ['top', 'left', 'rotate', 'height', 'width', 'fontSize', 'lineHeight'];
			let styleResult = getStyle(styleObj, excludesArr);
			excludesArr.forEach((ele) => {
				let val = styleObj[ele] || 0;
				if (ele !== 'rotate') {
					if (ele === 'height' || ele === 'top') {
						if (!isNaN(+val)) {
							val = `${val * (+fixedSize || this.scaleHeight)}px`;
						}
					} else if (ele === 'fontSize') {
						val = `${val}px`;
					} else {
						if (!isNaN(+val)) {
							val = `${val * (+fixedSize || this.scaleWidth)}px`;
						}
					}
				} else {
					val = styleObj[ele] && styleObj[ele] !== '0' ? 'rotate(' + styleObj[ele] + 'deg)' : '';
				}
				// TIPS 忽略backgrond的设置
				if (ele !== 'background') {
					styleResult = {
						...styleResult,
						[ele]: val
					};
				}
			});

			const { id } = item;
			if (id === 'CommonScreenContainer-1659244834467') {
				const { width, height, left, top } = item.style || {};
				console.log(`原始信息：
					宽高比：${window.innerWidth / window.innerHeight}
					width: ${width},
					height: ${height},
					left: ${left},
					top: ${top}
				`);
				console.log(`计算比例:
					scaleHeight:${this.scaleHeight},
					scaleWidth: ${this.scaleWidth},
					ratio: ${this.ratio}
				`);
				console.log(`计算结果:
					width: ${styleResult.width},
					height: ${styleResult.height},
					left: ${styleResult.left},
					top: ${styleResult.top}
				`);
			}
			return {
				...styleResult,
				top: 0,
				left: 0
			};
		},
		/**
		 * @desc: 获取组件盒子的样式
		 * @param {Object} style 组件样式
		 * @return {Object} 格式化后样式对象
		 */
		getBoxStyle(styleObj, fixedSize, isLayout, item) {
			if (isLayout) {
				// 布局样式
				return this.getComStyle(styleObj, isLayout);
			}
			let result = {};
			['width', 'height', 'top', 'left', 'mixBlendMode', 'rotate'].forEach((ele) => {
				let val = styleObj[ele] || 0;
				if (ele !== 'rotate') {
					if (ele === 'height' || ele === 'top') {
						if (!isNaN(+val)) {
							val = `${(val * (+fixedSize || this.scaleHeight)).toFixed(2)}px`;
						}
					} else if (ele === 'fontSize') {
						val = `${val}px`;
					} else {
						if (!isNaN(+val)) {
							val = `${(val * (+fixedSize || this.scaleWidth)).toFixed(2)}px`;
						}
					}
					result[ele] = val;
				} else if (ele == 'mixBlendMode' && val && val != 'normal') {
					result.mixBlendMode = val;
				} else if (!result.mixBlendMode) {
					result.transform = styleObj[ele] && styleObj[ele] !== '0' ? 'rotate(' + styleObj[ele] + 'deg)' : '';
				}
			});
			if (item.specialStyle?.useZIndex) {
				result = {
					...result,
					zIndex: item.specialStyle?.zIndex ?? 'inherit'
				};
			}
			return {
				...result,
				// top: 0,
				// left: 0,
				zoom: 1
			};
		},
		/**
		 * @desc: 触发点击事件
		 * @param {Object} element 点击的组件
		 *  触发方式实质就是在改变指定的组件状态
		 */
		onClickBox(element, eventType) {
			if (!element.events || !element.events[eventType] || !element.events[eventType].effects) {
				return;
			}
			const click = element.events[eventType] || {};
			if (this.timer) {
				clearTimeout(this.timer);
			}
			if (element.events.dblclick) {
				this.timer = setTimeout(() => {
					this._triggerEvent(click, element);
				}, 300);
			} else {
				this._triggerEvent(click, element);
			}
		},
		/**
		 * @description: 双击
		 * @param {Object} element 点击的组件
		 */
		onDblClickBox(element) {
			if (!element.events || !element.events.dblclick || !element.events.dblclick.effects) {
				return;
			}
			const dblclick = element.events.dblclick || {};
			if (this.timer) {
				clearTimeout(this.timer);
			}
			this._triggerEvent(dblclick, element);
		},
		/**
		 * @description: 触发事件
		 * @param {Object} eventConfig 事件配置
		 * @param {Object} element 点击的组件
		 */
		async _triggerEvent(eventConfig, element) {
			const { pattern, effects = [], specialEventList = [], frontOperation = [] } = eventConfig;
			let canNext = true;
			if (frontOperation && frontOperation.length) {
				// 触发预处理事件、行为
				let componentData = this.sContainer ? this.originComponentData : this.customComponentList;
				window.$EditorDebug.startFrontOperation(element);
				canNext = await doFrontOperation(frontOperation, {
					isGroup: element.isGroup,
					componentList: this.customComponentList,
					componentData,
					groupComponents: this.customComponentList
				});
				window.$EditorDebug.resultFrontOperation(canNext, element);
			}
			if (!canNext) {
				console.log(this.$t('components.OperationFile.193459-12'));
				this.$message.error(this.$t('components.OperationFile.193459-12'));
				return false;
			}
			if (!pattern) {
				const { actionType, linkType, queryList = [], linkUrl = '', pageId } = eventConfig;
				if (actionType === 'pageStatus') {
					// 修改页面状态
					this.updatePageStatus(eventConfig.stateMachine || []);
				} else if (actionType === 'jumpPage') {
					if (linkType === 'projectPage') {
						const query = {};
						queryList.forEach((queryItem) => {
							let component = getComponentById(this.customComponentList, queryItem.componentId);
							this.$set(query, queryItem.key, component?.resolveData[queryItem.feild]);
						});
						this.$router.push({
							path: pageId,
							query
						});
						return;
					} else if (linkType === 'outPage') {
						openUrl(linkUrl, pageId);
						// window.open(linkUrl);
					} else if (linkType === 'custom') {
						// 暂未处理
						// const customLink = getLinkByTemplate(linkTemplate, output)
						// window.open(customLink);
					}
				} else if (actionType === 'eeAction') {
					// 触发后端事件 暂未处理
					// console.log(ele, '0000001111111111', output);
					this.doEEAction(eventConfig, element);
					// 搜集参数
				} else {
					effects.forEach((ele) => {
						this.$store.commit('triggerEvents', {
							config: ele,
							element,
							EDITOR_pageUUID: this.EDITOR_pageUUID
						});
					});
				}
			} else if (pattern === 'special') {
				specialEventList.forEach((ele) => {
					const { actionType, linkType, queryList = [], linkUrl = '', pageId } = ele;
					if (actionType === 'pageStatus') {
						// 修改页面状态
						this.updatePageStatus(ele.stateMachine || []);
					} else if (actionType === 'jumpPage') {
						if (linkType === 'projectPage') {
							const query = {};
							queryList.forEach((queryItem) => {
								let component = getComponentById(this.customComponentList, queryItem.componentId);
								this.$set(query, queryItem.key, component?.resolveData[queryItem.feild]);
							});
							this.$router.push({
								path: pageId,
								query
							});
							return;
						} else if (linkType === 'outPage') {
							openUrl(linkUrl, pageId);
							// window.open(linkUrl);
						} else if (linkType === 'custom') {
							// 暂未处理
							// const customLink = getLinkByTemplate(linkTemplate, output)
							// window.open(customLink);
						}
					} else if (actionType === 'eeAction') {
						// 触发后端事件 暂未处理
						// console.log(ele, '0000001111111111', output);
						// this.doEEAction(element, output, type);
						this.doEEAction(ele, element);
						// 搜集参数
					} else {
						ele.effects.forEach((effect) => {
							this.$store.commit('triggerEvents', {
								config: effect,
								element,
								EDITOR_pageUUID: this.EDITOR_pageUUID
							});
						});
					}
				});
			}
			const behaviors = eventConfig?.behaviors || [];
			// 触发行为
			behaviors.forEach((behavior) => {
				this.$store.commit('triggerEvents', {
					config: {
						behavior,
						isBehavior: true
					},
					element,
					EDITOR_pageUUID: this.EDITOR_pageUUID
				});
			});
		},
		/**
		 * @description: 触发后端事件
		 * @return {*}
		 */
		doEEAction(ele, element) {
			// 触发后端事件
			window.$EditorDebug.startEEAction(element);
			const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } = ele;
			if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
				this.$message.error('事件配置错误！');
				window.$EditorDebug.failEEAction(element);
				return false;
			}
			this.loading = true;
			const sourceParams = this.getBindParams(element);
			// 蓝图
			if (eeType === 'blueprint') {
				dataInterface(sourceParams, `/api${interfaceUrl}`)
					.then((res) => {
						if (res.status === 200 && res.data.code === 200) {
							this.$message.success('操作成功！');
							window.$EditorDebug.successEEAction(element, `/api${interfaceUrl}`);
							doEEActionHandle(res.data?.__adds__);
						}
						this.loading = false;
					})
					.catch(() => {
						this.loading = false;
						window.$EditorDebug.errorEEAction(element, `/api${interfaceUrl}`);
					});
				return true;
			}
			// 数仓
			const data_id = sourceParams?.data_id;
			dataInterface({
				__method_name__: 'customEventCall',
				object_uuid: objectUUID,
				view_uuid: viewUUID,
				...sourceParams,
				data_id, // 参数配置
				event: eventName
			})
				.then((res) => {
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success('操作成功！');
						window.$EditorDebug.successEEAction(element, eventName);
						doEEActionHandle(res.data?.__adds__);
					}
					this.loading = false;
				})
				.catch(() => {
					this.loading = false;
					window.$EditorDebug.errorEEAction(element, eventName);
				});
			return true;
		},
		/**
		 * @desc: 后端事件获取绑定参数
		 * @param {Object} comp 组件数据
		 * @return {Object}
		 */
		getBindParams(comp) {
			if (!comp) return {};
			const sourceConfig = comp.sourceConfig || [];
			const sourceParams = {};
			for (let i = 0; i < sourceConfig.length; i++) {
				const {
					componentId,
					field,
					key,
					originType = '',
					urlParamKey = '',
					systemKey = '',
					systemCode = '',
					fixedValue = '',
					statusCode = ''
				} = sourceConfig[i];
				if (originType === 'url' && urlParamKey) {
					// 从url获取参数
					const result = getQueryValue(urlParamKey);
					this.$set(sourceParams, key, result);
				} else if (originType === 'system') {
					// 系统参数
					try {
						let obj = sessionStorage.getItem(systemKey);
						if (!obj) {
							obj = localStorage.getItem(systemKey);
						}
						if (!obj) break;
						const result = JSON.parse(obj);
						if (result && Object.prototype.toString.call(result) === '[object Object]') {
							const queryVal = result[systemCode];
							this.$set(sourceParams, key, queryVal);
						}
					} catch (err) {
						console.log(err, '99999999');
					}
				} else if (originType === 'fixed') {
					// 固定值
					this.$set(sourceParams, key, fixedValue);
				} else if (originType === 'pageStatus') {
					// 页面状态
					const statusCodeValue =
						this._PageCustomStatus[statusCode] === undefined
							? this._APPCustomStatus[statusCode] || null
							: this._PageCustomStatus[statusCode];
					this.$set(sourceParams, key, statusCodeValue || '');
				} else if (componentId && field && key) {
					// 普通从组件获取
					let sourceComponent = getComponentById(this.subComponentData || this.componentData, componentId);
					if (!sourceComponent && this.isGroup && this.groupComponents.length) {
						sourceComponent = getComponentById(this.groupComponents, componentId);
					}
					const componentName = componentId.toString().split('-')[0];
					if (
						field === 'DATAVIEWSELECT' &&
						['CommonTree', 'CommonTableContainer', 'CommonLoopContainer'].includes(componentName)
					) {
						this.$set(sourceParams, key, sourceComponent?.resolveData || []);
					} else {
						const result = sourceComponent?.resolveData?.[field] || '';
						this.$set(sourceParams, key, result);
					}
				}
			}
			return sourceParams;
		},
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr) {
			let componentData = this.sContainer ? this.originComponentData : this.customComponentList;
			arr.forEach((ele) => {
				if (!judgingEventLogic(ele.logics, componentData)) return;
				const { value } = getStatusVal(ele, componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			});
		}
	},
	beforeDestroy() {
		sessionStorage.removeItem('screenViewOriginWidth');
	}
};
</script>

<style lang="less" scoped>
.screen-parser {
	position: relative;
	height: 100%;
	width: 100%;
	transition: all 0.3s;
	overflow: hidden;
}
</style>
