<!--
 * @Description: 走马灯/swiper
 * @Author: zyf
 * @Date: 2023-08-23 15:12:22
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-12-16 15:28:55
-->
<template>
	<ConstomSwiper
		v-loading="loading"
		v-if="carouselShow"
		:statusConfig="statusConfig"
		:swiperList="computedSwipeOptions"
		@onItem="onItem"
	>
	</ConstomSwiper>
</template>

<script>
import { dataInterface } from '@/apis/data';
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import ConstomSwiper from '@/components/constomSwiper/Index.vue';
import eventBus from '@/plugins/eventBus';
import { removeEventBus } from '@/utils/tools';
import { doFrontOperation, getComponentById, getLinkByTemplate, doEEActionHandle, openUrl } from '@/utils/tools';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';

export default {
	name: 'NewCommonSwiper',
	mixins: [CustomComponentMixin, databaseTriggerDebug],
	components: {
		ConstomSwiper
	},
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		scaleWidth: {
			type: Number,
			default: 1
		},
		scaleHeight: {
			type: Number,
			default: 1
		}
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			type: 'fade',
			loading: false,
			swiperOptions: [],
			metadata: [],
			carouselShow: true
		};
	},
	computed: {
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		// 参数配置
		paramsConfig() {
			return this.element?.paramsConfig || [];
		},
		swiperConfig() {
			return this.element?.swiperConfig;
		},
		carouselHeight() {
			const { height = '100%' } = this.statusConfig;
			return isNaN(height) ? height : `${this.scaleHeightSize(height)}px`;
		},
		// 渲染所需的轮播图数据
		computedSwipeOptions() {
			const data = this.swiperOptions;

			let limit = Number(this.statusConfig.limit || 1) || 1;
			if (limit === 1) {
				return data;
			}
			// 进行拆分
			const RES = [];
			let temp = [];
			let current = 0;
			for (let i = 0; i < data.length; i++) {
				const index = Math.floor(i / limit);
				if (index !== current) {
					RES.push([...temp]);
					current = index;
					temp = [];
				}
				temp.push(data[i]);
				if (index === current && i === data.length - 1) {
					RES.push([...temp]);
				}
			}
			return RES;
		},
		useAllImages() {
			return !!this?.statusConfig?.useAllImages;
		},
		// 数据源配置
		database() {
			return this.element && this.element.database;
		}
	},
	watch: {
		swiperConfig: {
			handler() {
				this.swiperOptions = [];
				this.resetSwiper();
			},
			deep: true,
			immediate: true
		},
		// 数据绑定
		database: {
			handler() {
				let container = getComponentById(this.subComponentData, this.database.containerKey);
				if (!container && this.isGroup && this.groupComponents.length) {
					container = getComponentById(this.groupComponents, this.database.containerKey);
				}
				this.metadata = container?.metadata || {};
				// 兼容复制出来配置的情况
				this.element.metadata = this.metadata;
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		window.addEventListener('resize', () => {
			this.carouselShow = false;
			this.$nextTick(() => {
				this.carouselShow = true;
			});
		});
		const databaseTrigger = {
			[this.element.id]: (data) => {
				const { valueType, databaseConfig } = this.swiperConfig;
				if (valueType === 'fixed') return;
				for (let i = 0; i < this.paramsConfig.length; i++) {
					const { originType, componentId } = this.paramsConfig[i];
					if (originType === 'component' && componentId === data.componentId) {
						// this.getDynamic(this.swiperConfig.databaseConfig);
						if (valueType === 'database') {
							// 动态取值
							this._startDataDebug();
							if (databaseConfig.sourceType === 'database' || !databaseConfig.sourceType) {
								this.getDynamic(databaseConfig);
							} else if (databaseConfig.sourceType === 'interface') {
								this.getInterface(databaseConfig);
							}
						}
					}
				}
			}
		};
		const reRenderComponentView = {
			[this.element.id]: ({ componentId }) => {
				// 触发刷新
				if (this.swiperConfig.valueType === 'fixed') return;
				if (!this.paramsConfig.find((ele) => ele.componentId === componentId)) return;
				this.resetSwiper();
			}
		};
		eventBus.$on('reRenderComponentView', reRenderComponentView[this.element.id]);
		eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
	},
	methods: {
		scaleHeightSize(num) {
			if (isNaN(+num)) {
				return num;
			}
			// const ratio = window.devicePixelRatio;
			const ratio = 1;
			return (+num * this.scaleHeight * ratio).toFixed(2);
		},
		/**
		 * @desc: 重置轮播图取值配置
		 */
		resetSwiper() {
			if (!this.swiperConfig) {
				return false;
			}
			const { valueType, fixedConfig, databaseConfig } = this.swiperConfig;
			if (valueType === 'fixed') {
				// 固定
				this.formatFixed(fixedConfig);
			} else if (valueType === 'database') {
				// 动态取值
				if (databaseConfig.sourceType === 'database' || !databaseConfig.sourceType) {
					this.getDynamic(databaseConfig);
				} else if (databaseConfig.sourceType === 'interface') {
					this.getInterface(databaseConfig);
				}
			}
		},
		/**
		 * @desc: 固定配置
		 */
		formatFixed(fixedConfig) {
			if (!fixedConfig || !Array.isArray(fixedConfig)) {
				return false;
			}
			this.swiperOptions = fixedConfig || [];
		},
		/**
		 * @desc: 动态取值配置
		 */
		getDynamic(config = {}) {
			const { objectUUID, viewUUID, imgUUID, textUUID, hrefUUID, hrefTypeUUID } = config;
			if (!objectUUID || !viewUUID) {
				this.$message.warning('请确认配置项完整！');
				this._failDataDebug('请求配置错误');
				return;
			}
			const {
				search = [],
				param = {},
				canPost
			} = this.initParams(this.paramsConfig || [], this.isGroup, this.componentData, this.groupComponents);
			if (!canPost) {
				this._failDataDebug('参数必填校验未通过');
				return false;
			}
			this.loading = true;
			const content = {
				__method_name__: 'dataList',
				object_uuid: objectUUID,
				view_uuid: viewUUID,
				...param,
				search
			};
			dataInterface(content)
				.then((res) => {
					let result = null;
					if (Array.isArray(res?.data?.data)) {
						result = res?.data?.data;
					} else {
						result = res?.data?.data?.data || [];
					}
					this.swiperOptions = [];
					result.map((ele) => {
						// 兼容多张图类型
						let img = ele?.[imgUUID];
						if (Array.isArray(img)) {
							if (this.useAllImages) {
								// 将上传内容中的所有图片放入轮播图 @何昌恩 甘肃建投
								img.forEach((elee) => {
									const img = elee?.url || elee?.path || '';
									this.swiperOptions.push({
										img,
										text: ele?.[textUUID],
										href: ele?.[hrefUUID],
										hrefTypeUUID: ele?.[hrefTypeUUID]
									});
								});
							} else {
								img = img?.[0]?.url || img?.[0]?.path || '';
								this.swiperOptions.push({
									img,
									text: ele?.[textUUID],
									href: ele?.[hrefUUID],
									hrefTypeUUID: ele?.[hrefTypeUUID]
								});
							}
						} else {
							this.swiperOptions.push({
								img,
								text: ele?.[textUUID],
								href: ele?.[hrefUUID],
								hrefTypeUUID: ele?.[hrefTypeUUID]
							});
						}
					});
					this.loading = false;
					this._successDataDebug({
						url: '',
						content,
						res
					});
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
					this._errorDataDebug({
						url: '',
						content,
						err
					});
				});
		},
		/**
		 * @description: 获取常规接口数据
		 * @param {*} config
		 * @return {*}
		 */
		getInterface(config = {}) {
			const {
				search = [],
				param = {},
				canPost
			} = this.initParams(this.paramsConfig, this.isGroup, this.subComponentData, this.groupComponents);
			if (!canPost) {
				console.log('无法请求');
				this._failDataDebug('参数必填校验未通过');
				return false;
			}
			this.loading = true;
			const { url, imgUUID, textUUID, hrefUUID, hrefTypeUUID } = config;
			const content = {
				search,
				...param
			};
			dataInterface(content, `/api${url}`)
				.then((res) => {
					let result = null;
					if (Array.isArray(res?.data?.data)) {
						result = res?.data?.data;
					} else {
						result = res?.data?.data?.data || [];
					}
					this.swiperOptions = result.map((ele) => {
						// 兼容多张图类型
						let img = ele?.[imgUUID];
						if (Array.isArray(img)) {
							img = img?.[0]?.url || img?.[0]?.path || '';
						}
						return {
							img,
							text: ele?.[textUUID],
							href: ele?.[hrefUUID],
							hrefTypeUUID: ele?.[hrefTypeUUID]
						};
					});
					this.loading = false;
					this._successDataDebug({
						url: '',
						content,
						res
					});
				})
				.catch((err) => {
					this.loading = false;
					console.log(err);
					this._errorDataDebug({
						url: '',
						content,
						err
					});
				});
		},
		/**
		 * @desc: 点击
		 * @param {Object} item
		 */
		onItem(item) {
			this.element.resolveData = item;
			// 暴露数据
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData: item
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element,
				resolveData: item
			});
			const eventConfig = this.element.actionConfig.rowClick;
			const { eventList = [] } = eventConfig;
			if (Array.isArray(eventList)) {
				eventList.forEach((el) => {
					const { pattern, eventList = [], specialEventList = [] } = el;
					if (pattern === undefined) {
						this.doActionItem(el, {});
						return;
					}
					const result = pattern === 'special' ? specialEventList : eventList;
					result.forEach((ele) => {
						this.doActionItem(ele, {});
					});
				});
			}
			// 跳转
			if (!this.statusConfig.clickLink) return;
			const { href, hrefType } = item;
			const { path } = this.$route;
			if (path === '/modify-page' || path === '/page-view') {
				this.$message.info(`跳转${href}---方式${hrefType}`);
				return;
			}
			if (hrefType === 'link') {
				// window.open(href);
				openUrl(href, href);
			} else if (hrefType === 'route') {
				this.$router.push({
					path: href
				});
			}
		},
		async doActionItem(ele, output) {
			const { frontOperation = [] } = ele;
			let canNext = true;
			if (frontOperation && frontOperation.length) {
				// 触发预处理事件、行为
				window.$EditorDebug.startFrontOperation(this.element);
				canNext = await doFrontOperation(frontOperation, {
					isGroup: this.isGroup,
					componentList: this.subComponentData,
					componentData: this.subComponentData,
					groupComponents: this.groupComponents
				});
				window.$EditorDebug.resultFrontOperation(canNext, this.element);
			}
			if (!canNext) {
				this.$message.error(this.$t('components.OperationFile.193459-12'));
				return false;
			}
			if (ele.key === 'click') {
				// 跳转页面
				if (ele.actionType === 'jumpPage') {
					if (ele.linkType === 'projectPage') {
						const query = {};
						ele.queryList.forEach((queryItem) => {
							let component = getComponentById(this.subComponentData, queryItem.componentId);
							if (!component && this.isGroup && this.groupComponents.length) {
								component = getComponentById(this.groupComponents, queryItem.componentId);
							}
							this.$set(query, queryItem.key, component.resolveData[queryItem.feild]);
						});
						this.$router.push({
							path: ele.pageId,
							query
						});
						// 基座设置
						// if (ele.routePath) {
						// 	this.$router.replace({
						// 		path: ele.routePath,
						// 		query
						// 	});
						// }
						return;
					} else if (ele.linkType === 'outPage') {
						// window.open(ele.linkUrl);
						openUrl(ele.linkUrl, ele.linkUrl);
					} else if (ele.linkType === 'custom') {
						const customLink = getLinkByTemplate(ele.linkTemplate, output);
						openUrl(customLink, customLink);
						// window.open(customLink);
					}
				} else if (ele.actionType === 'eeAction') {
					// 触发后端事件
					// console.log(ele, '0000001111111111', output);
					this.doEEAction(ele, output);
					// 搜集参数
				} else {
					// 页面事件
					ele.effects.forEach((effect) => {
						this.$store.commit('triggerEvents', {
							config: {
								...ele,
								...effect
							},
							element: this.element,
							EDITOR_pageUUID: this.EDITOR_pageUUID
						});
					});
				}
			}
			// 组件行为
			const { behaviors = [] } = ele;
			behaviors.forEach((behavior) => {
				this.$store.commit('triggerEvents', {
					config: {
						behavior,
						isBehavior: true
					},
					element: this.element,
					EDITOR_pageUUID: this.EDITOR_pageUUID
				});
			});
		},
		/**
		 * @description: 后端回调
		 * @param {*} btnConfig
		 * @param {*} rowData
		 * @return {*}
		 */
		doEEAction(btnConfig, rowData = {}) {
			if (btnConfig && btnConfig.actionType === 'eeAction') {
				window.$EditorDebug.startEEAction(this.element);
				const { objectUUID, viewUUID, eventName } = btnConfig;
				if (!objectUUID || !viewUUID || !eventName) {
					this.$message.error('请正确配置事件!');
					window.$EditorDebug.failEEAction(this.element);
					return false;
				}
				const idUUID = this.getIdUUID();
				const data_id = rowData?.[idUUID];
				if (!data_id) {
					this.$message.error('事件未配置触发对象!');
					window.$EditorDebug.failEEAction(this.element);
					return false;
				}
				dataInterface({
					__method_name__: 'customEventCall',
					object_uuid: btnConfig.objectUUID,
					view_uuid: btnConfig.viewUUID,
					data_id,
					event: btnConfig.eventName
				})
					.then((res) => {
						if (res.status === 200 && res.data.code === 200) {
							this.$message.success('操作成功!');
							window.$EditorDebug.successEEAction(this.element, btnConfig.eventName);
							doEEActionHandle(res.data?.__adds__);
						}
					})
					.catch((err) => {
						console.log(err, '00000后端事件错误');
						window.$EditorDebug.errorEEAction(this.element, btnConfig.eventName);
					});
			}
		},
		/**
		 * @description: 获取ID uuid
		 * @return {*}
		 */
		getIdUUID() {
			let idUUID = this.statusConfig.idUUID || '';
			if (!idUUID) {
				for (let key in this.metadata) {
					if (this.metadata[key] === 'id') {
						idUUID = key;
					}
				}
			}
			return idUUID;
		}
	},
	beforeDestroy() {
		if (!this.EDITOR_pageUUID) {
			// EDITOR_loopResolve 为循环容器特有
			removeEventBus(['databaseTrigger', 'reRenderComponentView'], this.element.id);
		}
	}
};
</script>
