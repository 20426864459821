/*
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-08-10 15:31:30
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-06-30 16:42:29
 * @FilePath: \dataview-next\src\custom-component\component-config\model.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const modelGis = {
	type: 'model',
	title: 'GIS组件',
	show: true,
  key: 3,
	order: 8,
	componentList: [
		{
			component: 'ViewerGIS',
			type: 'ModelGIS',
			name: '模型',
			icon: 'icongis-gismx',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: true,
			},
		},
		{
			component: 'ViewerGISToolbar',
			type: 'container',
			name: '工具栏',
			icon: 'iconxitongshezhi',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: 45,
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
		{
			component: 'ViewerGISModule',
			type: 'container',
			name: '功能容器',
			icon: 'iconbim-gnrq-1',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
        {
            component: 'ViewerGISEditor',
            type: 'container',
            name: '编辑容器',
            icon: 'iconshejibiangeng',
            isLock: false, // 是否锁定
            needCommonStyle: true,
            propValue: '',
            span: 24,
            statusConfig: {
                displayShow: true,
                isShow: true // 是否显示
            },
            style: {
                height: '100%',
                width: '',
                rotate: 0,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0
            },
            behaviors: [
            ],
            actionConfig:{
                needAction: false,
                width: '',
            },
        },
		{
			component: 'ViewerGISLayer',
			type: 'container',
			name: '图层树',
			icon: 'icongis_tuceng',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
		{
			component: 'ViewerGISModelTree',
			type: 'container',
			name: '模型树',
			icon: 'iconbim-mxs-1',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
		{
			component: 'ViewerGISViewPoint',
			type: 'container',
			name: '视点列表',
			icon: 'iconbim-sdlb-1',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
		{
			component: 'ViewerGISMarker',
			type: 'container',
			name: '标记列表',
			icon: 'iconbim-bjlb',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
		{
			component: 'ViewerGISPlot',
			type: 'container',
			name: '标绘列表',
			icon: 'iconbim-bhlb',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
		{
			component: 'ViewerGISKml',
			type: 'container',
			name: 'Kml列表',
			icon: 'iconbim-kmllb',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
		{
			component: 'ViewerGISMeasurePoint',
			type: 'container',
			name: '测点面板',
			icon: 'iconbim-cdmb',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
		{
			component: 'ViewerGISHelper',
			type: 'container',
			name: 'GIS助手',
			icon: 'icona-bangzhuyiwen',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: 45,
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		}

  ]
};

export default modelGis;
