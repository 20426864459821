<!--
 * @Description: 月份选择器
 * @Author: luocheng
 * @Date: 2022-08-01 17:03:54
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-04 14:26:56
-->
<template>
	<div class="common-month common-date" v-if="toMonthObj">
		<CalendarHeader :headerType="'year'" :year="year" @setQuickDate="getQuickDate"></CalendarHeader>
		<article class="content">
			<ul class="date-list" v-if="showDateList && showDateList.length">
				<li
					class="date-item month-item date-item-size"
					v-for="(item, index) in showDateList"
					:key="index"
					:class="{
						'is-today': item.isTargetMonth,
						'is-active': currentDateObj && currentDateObj.year === item.year && +currentDateObj.month === +item.month
					}"
					@click="onMonth(item)"
				>
					<!-- 基础类型 -->
					<div class="base-item" v-if="calendarType === 'base'">
						{{ item.isTargetMonth ? '本月' : item.month }}
					</div>
					<!-- 符合类型 -->
					<div
						class="with-data-item"
						v-else-if="calendarType === 'withDatabase' || calendarType === 'justTotal' || calendarType === 'showDot'"
					>
						<p class="date-text">
							{{ item.isTargetMonth ? '本月' : item.month }}
						</p>
						<p class="count-text">
							<span
								class="finish-count"
								v-if="calendarType !== 'showDot'"
								:class="{
									'just-total': calendarType === 'justTotal',
									'null-count': !+item.finishCount
								}"
								>{{ item.finishCount }}</span
							>
							<template v-if="calendarType === 'withDatabase'">
								<span
									class="separate"
									:class="{
										'null-count': !+item.totalCount && !+item.finishCount
									}"
									>/</span
								>
								<span
									class="total-count"
									:class="{
										'null-count': !+item.totalCount
									}"
									>{{ item.totalCount }}</span
								>
							</template>
							<template v-if="calendarType === 'showDot'">
								<div
									class="dot"
									v-if="+item.finishCount > 0 ? haveData : noData"
									:style="{
										background: +item.finishCount > 0 ? haveDataColor : noDataColor
									}"
								></div>
							</template>
						</p>
					</div>
				</li>
			</ul>
			<el-empty v-else description="暂无数据"></el-empty>
		</article>
	</div>
</template>

<script>
import CalendarHeader from './CalendarHeader';
import mixin from './mixin';

export default {
	name: 'CommonMonth',
	mixins: [mixin],
	components: {
		CalendarHeader
	},
	data() {
		return {
			year: '',
			dateList: [],
			toMonthObj: null,
			// 当前
			currentDateObj: null
		};
	},
	created() {
		this.initDate(true);
		this.currentDateObj = this.toMonthObj;
	},
	methods: {
		/**
		 * @desc: 是否初始化日期
		 * @param {Boolean} isInit
		 */
		initDate(isInit = false) {
			this.dateList = []; // 清空原始数据
			const date = isInit ? new Date() : new Date(`${this.year}/${this.month}/1`);
			if (isInit) {
				this.year = date.getFullYear();
				this.month = this.addZero(date.getMonth() + 1);
				this.toMonthObj = {
					isTargetMonth: true,
					year: new Date().getFullYear(),
					month: new Date().getMonth() + 1,
					date: new Date().getDate(),
					totalCount: 0,
					finishCount: 0,
					lessCount: 0,
					dateString: `${new Date().getFullYear()}-${this.addZero(new Date().getMonth() + 1)}`,
					result: [
						`${this.year}-${this.month}-01 00:00:00`,
						`${this.year}-${this.month}-${this.addZero(this.getMonthDayCount(this.year, +this.month))} 23:59:59`
					]
				};
				this.onMonth(this.toMonthObj);
			}
			for (let i = 0; i < 12; i++) {
				const month = i + 1;
				this.dateList.push({
					isTargetMonth: this.toMonthObj.month === month && this.toMonthObj.year === this.year,
					year: this.year,
					month,
					totalCount: 0,
					finishCount: 0,
					lessCount: 0,
					dateString: `${this.year}-${this.addZero(month)}`,
					result: [
						`${this.year}-${this.addZero(month)}-01 00:00:00`,
						`${this.year}-${this.addZero(month)}-${this.addZero(this.getMonthDayCount(this.year, month))} 23:59:59`
					]
				});
			}
		},
		/**
		 * @desc: 获取快捷日期
		 * @param {Object} dateObj
		 */
		getQuickDate(dateObj) {
			this.year = dateObj.year;
			this.initDate();
		},
		/**
		 * @desc: 月份
		 */
		onMonth(item) {
			this.currentDateObj = item;
			const { dateString, year, month } = item;
			this.$emit('setDate', { date: item.result, dateString, year, month });
		}
	}
};
</script>

<style lang="less" scoped>
@import './common.less';
.common-month {
	.date-item {
		width: 25% !important;
	}
}
</style>
