<!--
    @name: widget-cascader
    @description：widget-cascader
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show">{{ cascaderLabel }}</div>
    <el-cascader
      v-else
      popper-class="cascader-list"
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :options="element.config.options"
      :placeholder="$getLocalizedLabel({'zhLabel': element.config.zhPlaceholder, 'enLabel': element?.config.enPlaceholder})"
      :clearable="element.config.clearable"
      :filterable="element.config.filterable"
    >
    </el-cascader>
  </div>
</template>

<script>
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {Cascader} from "element-ui";

export default {
  name: "WidgetCascader",
  components: {
    'el-cascader': Cascader
  },
  props: ['element', 'disabled', 'displayData'],
  mixins: [formItemMinix],
  data() {
    return {}
  },
  computed: {
    cascaderLabel() {
      const value = this.element.value;
      const treeData = this.element.config.options
      let labelData;
      value instanceof Array ? labelData = [] : labelData = '';
      function getTree(data) {
        for (let item of data) {
          if (value instanceof Array && value.includes(item.id)) {
            labelData.push(item.label)
          } else if (value == item.id) {
            labelData = item.label
          }
          if (item.children && item.children.length > 0) {
            getTree(item.children)
          }
        }
      }
      getTree(treeData);
      if (labelData) {
        return labelData.join(' / ')
      } else {
        return '-'
      }
    }
  },
  created() {
    this.loadingDynamicData(this.element);
  },
  methods: {},
}
</script>

<style lang="less">
.cascader-list {
  .el-cascader-menu__list{
    max-height: 300px;
  }
}
</style>
