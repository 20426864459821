<!--
 * @Description: 走马灯/swiper
 * @Author: luocheng
 * @Date: 2021-09-28 15:09:27
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-04 17:13:40
-->
<template>
	<div class="common-swiper-box">
		<el-carousel
			v-if="computedSwipeOptions.length && carouselShow"
			class="common-swiper"
			v-loading="loading"
			:height="isNaN(statusConfig.height) ? statusConfig.height : statusConfig.height + 'px'"
			:interval="statusConfig.interval || 3000"
			:arrow="statusConfig.arrow"
			:initial-index="statusConfig.initialIndex || 0"
			:trigger="statusConfig.trigger"
			:autoplay="statusConfig.autoplay"
			:indicator-position="statusConfig.indicatorPosition"
			:type="statusConfig.type"
			:loop="statusConfig.loop"
			:direction="statusConfig.direction || 'horizontal'"
			@change="onChange"
		>
			<el-carousel-item v-for="(item, index) in computedSwipeOptions" :key="index">
				<template v-if="Array.isArray(item)">
					<div class="swiper-wrap">
						<div class="swiper-item" v-for="(el, sIndex) in item" :key="sIndex">
							<img
								v-if="!statusConfig.preview"
								:src="el.img || defaultImg"
								:alt="el.text || ''"
								@click="onItem(el, index)"
							/>
							<el-image
								v-else
								style="height: 100%"
								:src="el.img || defaultImg"
								:preview-src-list="[el.img || defaultImg]"
							>
							</el-image>
							<p class="desc" v-if="statusConfig.showText && (el.text || el.enText)" @click="onItem(el, index)">
								{{ getDocsText(el) }}
							</p>
						</div>
					</div>
				</template>
				<template v-else>
					<img :src="item.img || defaultImg" :alt="item.text || ''" @click="onItem(item, index)" />
					<p class="desc" v-if="statusConfig.showText && (item.text || item.enText)" @click="onItem(item, index)">
						{{ getDocsText(item) }}
					</p>
				</template>
			</el-carousel-item>
		</el-carousel>
		<el-empty v-else></el-empty>
		<div v-if="statusConfig.numberIndicator && computedSwipeOptions.length" class="number-indicator">
			<span>{{ initialIndex }}/{{ computedSwipeOptions.length }}</span>
		</div>
	</div>
</template>

<script>
import { Carousel, CarouselItem } from 'element-ui';
import { dataInterface } from '@/apis/data';
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import { Image, Empty } from 'element-ui';
import eventBus from '@/plugins/eventBus';
import { removeEventBus, openUrl } from '@/utils/tools';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';

export default {
	name: 'CommonSwiper',
	mixins: [CustomComponentMixin, databaseTriggerDebug],
	components: {
		'el-carousel': Carousel,
		'el-carousel-item': CarouselItem,
		'el-image': Image,
		'el-empty': Empty
	},
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		scaleWidth: {
			type: Number,
			default: 1
		},
		scaleHeight: {
			type: Number,
			default: 1
		}
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			loading: false,
			carouselShow: true,
			swiperOptions: [],
			defaultImg: require('@/assets/images/defaultImage.png'),
			initialIndex: 0,
			timer: null
		};
	},
	computed: {
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		// 参数配置
		paramsConfig() {
			return this.element?.paramsConfig || [];
		},
		swiperConfig() {
			return this.element?.swiperConfig;
		},
		carouselHeight() {
			const { height = '100%' } = this.statusConfig;
			return isNaN(height) ? height : `${this.scaleHeightSize(height)}px`;
		},
		// 渲染所需的轮播图数据
		computedSwipeOptions() {
			const data = this.swiperOptions;
			let limit = Number(this.statusConfig.limit || 1) || 1;
			if (limit === 1) {
				return data;
			}
			// 进行拆分
			const RES = [];
			let temp = [];
			let current = 0;
			for (let i = 0; i < data.length; i++) {
				const index = Math.floor(i / limit);
				if (index !== current) {
					RES.push([...temp]);
					current = index;
					temp = [];
				}
				temp.push(data[i]);
				if (index === current && i === data.length - 1) {
					RES.push([...temp]);
				}
			}
			return RES;
		},
		useAllImages() {
			return !!this?.statusConfig?.useAllImages;
		}
	},
	watch: {
		swiperConfig: {
			handler() {
				this.swiperOptions = [];
				this.initInitialIndex();
				this.resetSwiper();
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		window.addEventListener('resize', () => {
			this.carouselShow = false;
			this.$nextTick(() => {
				this.carouselShow = true;
			});
		});
		this.initInitialIndex();
		const databaseTrigger = {
			[this.element.id]: (data) => {
				const { valueType, databaseConfig } = this.swiperConfig;
				if (valueType === 'fixed') return;
				for (let i = 0; i < this.paramsConfig.length; i++) {
					const { originType, componentId } = this.paramsConfig[i];
					if (originType === 'component' && componentId === data.componentId) {
						// this.getDynamic(databaseConfig);
						if (valueType === 'database') {
							this._startDataDebug();
							// 动态取值
							if (databaseConfig.sourceType === 'database' || !databaseConfig.sourceType) {
								this.getDynamic(databaseConfig);
							} else if (databaseConfig.sourceType === 'interface') {
								this.getInterface(databaseConfig);
							}
						}
					}
				}
			}
		};
		eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
	},
	methods: {
		initInitialIndex() {
			let num = this.statusConfig.initialIndex || 0;
			this.initialIndex = num + 1;
		},
		/**
		 * @description: 幻灯片切换时触发
		 * @param {*} index 激活的幻灯片的索引
		 * @return {*}
		 */
		onChange(index) {
			this.initialIndex = index + 1;
		},
		/**
		 * @description: 缩放高度尺寸
		 * @param {Number} num
		 * @return {Number} 缩放后尺寸（两位小数）
		 */
		scaleHeightSize(num) {
			if (isNaN(+num)) {
				return num;
			}
			// const ratio = window.devicePixelRatio;
			const ratio = 1;
			return (+num * this.scaleHeight * ratio).toFixed(2);
		},
		/**
		 * @desc: 重置轮播图取值配置
		 */
		resetSwiper() {
			if (!this.swiperConfig) {
				return false;
			}
			const { valueType, fixedConfig, databaseConfig } = this.swiperConfig;
			if (valueType === 'fixed') {
				// 固定
				this.formatFixed(fixedConfig);
			} else if (valueType === 'database') {
				// 动态取值
				if (databaseConfig.sourceType === 'database' || !databaseConfig.sourceType) {
					this.getDynamic(databaseConfig);
				} else if (databaseConfig.sourceType === 'interface') {
					this.getInterface(databaseConfig);
				}
			}
		},
		/**
		 * @desc: 固定配置
		 */
		formatFixed(fixedConfig) {
			if (!fixedConfig || !Array.isArray(fixedConfig)) {
				return false;
			}
			this.swiperOptions = fixedConfig || [];
		},
		/**
		 * @desc: 动态取值配置
		 */
		getDynamic(config = {}) {
			const { objectUUID, viewUUID, imgUUID, textUUID, hrefUUID, hrefTypeUUID } = config;
			if (!objectUUID || !viewUUID) {
				this.$message.warning('请确认配置项完整！');
				this._failDataDebug('请求配置错误');
				return;
			}
			const {
				search = [],
				param = {},
				canPost
			} = this.initParams(
				this.paramsConfig || [],
				this.isGroup,
				this.componentData,
				this.groupComponents,
				this.EDITOR_pageUUID
			);
			if (!canPost) {
				this._failDataDebug('参数必填校验未通过');
				return false;
			}
			this.loading = true;
			const content = {
				__method_name__: 'dataList',
				object_uuid: objectUUID,
				view_uuid: viewUUID,
				...param,
				search
			};
			dataInterface(content)
				.then((res) => {
					let result = null;
					if (Array.isArray(res?.data?.data)) {
						result = res?.data?.data;
					} else {
						result = res?.data?.data?.data || [];
					}
					this.swiperOptions = [];
					result.map((ele) => {
						// 兼容多张图类型
						let img = ele?.[imgUUID];
						if (Array.isArray(img)) {
							if (this.useAllImages) {
								// 将上传内容中的所有图片放入轮播图 @何昌恩 甘肃建投
								img.forEach((elee) => {
									const img = elee?.url || elee?.path || '';
									this.swiperOptions.push({
										img,
										text: ele?.[textUUID],
										href: ele?.[hrefUUID],
										hrefTypeUUID: ele?.[hrefTypeUUID]
									});
								});
							} else {
								img = img?.[0]?.url || img?.[0]?.path || '';
								this.swiperOptions.push({
									img,
									text: ele?.[textUUID],
									href: ele?.[hrefUUID],
									hrefTypeUUID: ele?.[hrefTypeUUID]
								});
							}
						} else {
							this.swiperOptions.push({
								img,
								text: ele?.[textUUID],
								href: ele?.[hrefUUID],
								hrefTypeUUID: ele?.[hrefTypeUUID]
							});
						}
					});
					this.loading = false;
					this._successDataDebug({
						url: '',
						content,
						res
					});
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
					this._successDataDebug({
						url: '',
						content,
						err
					});
				});
		},
		/**
		 * @description: 获取常规接口数据
		 * @param {*} config
		 * @return {*}
		 */
		getInterface(config = {}) {
			const {
				search = [],
				param = {},
				canPost
			} = this.initParams(
				this.paramsConfig,
				this.isGroup,
				this.subComponentData,
				this.groupComponents,
				this.EDITOR_pageUUID
			);
			if (!canPost) {
				console.log('无法请求');
				this._failDataDebug('参数必填校验未通过');
				return false;
			}
			this.loading = true;
			const { url, imgUUID, textUUID, hrefUUID, hrefTypeUUID } = config;
			const content = {
				search,
				...param
			};
			dataInterface(content, `/api${url}`)
				.then((res) => {
					let result = null;
					if (Array.isArray(res?.data?.data)) {
						result = res?.data?.data;
					} else {
						result = res?.data?.data?.data || [];
					}
					this.swiperOptions = result.map((ele) => {
						// 兼容多张图类型
						let img = ele?.[imgUUID];
						if (Array.isArray(img)) {
							img = img?.[0]?.url || img?.[0]?.path || '';
						}
						return {
							img,
							text: ele?.[textUUID],
							href: ele?.[hrefUUID],
							hrefTypeUUID: ele?.[hrefTypeUUID]
						};
					});
					this.loading = false;
					this._successDataDebug({
						url: `/api${url}`,
						content,
						res
					});
				})
				.catch((err) => {
					this.loading = false;
					console.log(err);
					this._errorDataDebug({
						url: `/api${url}`,
						content,
						err
					});
				});
		},
		/**
		 * @desc: 点击
		 * @param {Object} item
		 * @param {Number} index
		 */
		onItem(item, index) {
			if (!this.statusConfig.clickLink) return;
			console.log(index, '----index0000-----');
			const { href, hrefType } = item;
			const { path } = this.$route;
			if (path === '/modify-page' || path === '/page-view') {
				this.$message.info(`跳转${href}---方式${hrefType}`);
				return;
			}
			if (hrefType === 'link') {
				openUrl(href, href);
				// window.open(href);
			} else if (hrefType === 'route') {
				this.$router.push({
					path: href
				});
			}
		},
		/**
		 * @description: 获取描述文本
		 * @param {*} item
		 * @return {*}
		 */
		getDocsText(item) {
			return (item.enText && this.$i18n.locale === 'en' ? item.enText : item.text) || '';
		}
	},
	beforeDestroy() {
		if (!this.EDITOR_pageUUID) {
			// EDITOR_loopResolve 为循环容器特有
			removeEventBus(['databaseTrigger'], this.element.id);
		}
	}
};
</script>

<style lang="less" scoped>
.common-swiper-box {
	width: 100%;
	height: 100%;
	position: relative;
	.number-indicator {
		width: 45px;
		height: 22px;
		padding: 2px 12px 2px 12px;
		line-height: 22px;
		border-radius: 24px;
		background: #161c1fa3;
		// opacity: 0.64;
		position: absolute;
		right: 16px;
		top: 12px;
		span {
			font-family: PingFang SC;
			font-size: 10px;
			font-weight: 500;
			line-height: 18px;
			letter-spacing: 0.5px;
			text-align: left;
			color: #fff;
		}
	}
}
.common-swiper {
	width: 100%;
	overflow: hidden;
	background: transparent;
	.el-carousel__item {
		background: transparent;
		// TIPS 单项时 z-index 不影响使用
		&.is-active {
			z-index: 0 !important;
		}
		// 多项时将z-index 覆盖为2
		&.is-in-stage.is-active {
			z-index: 2 !important;
		}
		img {
			display: block;
			margin: 0 auto;
			height: 100%;
			object-fit: cover;
		}
		.desc {
			position: absolute;
			bottom: 0;
			left: 0;
			height: 20px;
			width: 100%;
			background: rgba(0, 0, 0, 0.6);
			font-size: 14px;
			line-height: 20px;
			box-sizing: border-box;
			padding: 0 10px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: left;
		}
		.swiper-wrap {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-content: center;
			.swiper-item {
				flex-grow: 1;
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				padding: 4px;
				box-sizing: border-box;
				img {
					display: block;
					margin: 0 auto;
					height: 100%;
					object-fit: cover;
				}
				.desc {
					position: absolute;
					bottom: 0;
					left: 0;
					height: 20px;
					width: 100%;
					background: rgba(0, 0, 0, 0.6);
					font-size: 14px;
					line-height: 20px;
					box-sizing: border-box;
					padding: 0 10px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-align: left;
				}
			}
		}
	}
}
</style>
