<!--
    @name: StepProp
    @description：StepProp
    @author: ZengWei
    @date: 2022-08-27 11:26
-->
<template>
	<div class="step-prop">
		<el-form
			ref="form"
			:model="flowStep"
			:label-width="{ en: '150px' }[$i18n.locale] || '80px'"
			label-position="left"
			size="small"
		>
			<el-collapse v-model="activeNames">
				<el-collapse-item name="basic">
					<template #title>
						<span class="collapse-title">{{ $t('cell-panel.StepProp.033670-0') }}</span>
					</template>
					<el-form-item :label="$t('cell-panel.StepProp.033670-1')">
						<el-input v-model="flowStep.name" @change="onChange" :disabled="disabled"></el-input>
					</el-form-item>
					<el-form-item :label="$t('cell-panel.StepProp.033670-2')">
						<el-switch class="switch-fr" v-model="flowStep.selectStep" :disabled="disabled" @change="onChange">
						</el-switch>
					</el-form-item>
					<el-form-item :label="$t('cell-panel.StepProp.033670-3')">
						<el-switch class="switch-fr" v-model="flowStep.turnTo" @change="onChange" :disabled="disabled"></el-switch>
					</el-form-item>
					<!--          <el-form-item label="流程撤回">
            <el-switch
              class="switch-fr"
              v-model="flowStep.revoke"
              @change="onChange"
              :disabled="disabled"></el-switch>
          </el-form-item>
          <el-form-item label="退回类型">
            <el-radio-group
              v-model="flowStep.backType"
              size="small"
              style="float: right"
              :disabled="disabled"
              @change="onChange"
            >
              <el-radio-button :label="1">退回发起人</el-radio-button>
              <el-radio-button :label="2">退回上一步</el-radio-button>
              <el-radio-button :label="3">选择退回</el-radio-button>
            </el-radio-group>
          </el-form-item>-->
				</el-collapse-item>
				<el-collapse-item name="check">
					<template #title>
						<span class="collapse-title">{{ $t('cell-panel.StepProp.033670-4') }}</span>
					</template>
					<el-form-item label="" label-width="0px">
						<el-radio-group v-model="flowStep.userType" @change="onUserType" :disabled="disabled">
							<el-radio :label="1">{{ $t('cell-panel.StepProp.033670-5') }}</el-radio>
							<el-radio :label="2">{{ $t('cell-panel.StepProp.033670-6') }}</el-radio>
							<!--<el-radio :label="3">主管（负责人）</el-radio>-->
							<el-radio :label="5">{{ $t('cell-panel.StepProp.033670-7') }}</el-radio>
							<el-radio :label="6">{{ $t('cell-panel.StepProp.033670-8') }}</el-radio>
							<el-radio :label="7">{{ $t('cell-panel.StepProp.033670-9') }}</el-radio>
							<el-radio :label="8">{{ $t('cell-panel.StepProp.033670-10') }}</el-radio>
							<el-radio :label="9">{{ $t('cell-panel.StepProp.033670-11') }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item
						:label="$t('cell-panel.StepProp.033670-12')"
						v-if="[1, 2, 6, 7].indexOf(flowStep.userType) > -1"
					>
						<el-radio-group v-model="flowStep.userFrom" size="small" :disabled="disabled" @change="onUserScope">
							<el-radio-button :label="1">{{ $t('cell-panel.StepProp.033670-13') }}</el-radio-button>
							<el-radio-button :label="3">{{ $t('cell-panel.StepProp.033670-14') }}</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item v-if="flowStep.userType === 1" :label="$t('cell-panel.StepProp.033670-15')">
						<UserPicker
							:key="'check' + flowStep.userArch"
							:show-choose-button="true"
							:flowArchiType="flowStep.userFrom == 3 ? 6 : 2"
							:flowArchiId="[archiId]"
							:show-list="flowStep.targetUser"
							@on-select-change="onSelectUser"
						>
						</UserPicker>
					</el-form-item>
					<el-form-item v-if="flowStep.userType === 5" :label="$t('cell-panel.StepProp.033670-16')">
						<DepartPicker
							:show-choose-button="true"
							:show-list="flowStep.userSection"
							@on-select-change="onSelectDepart"
						>
						</DepartPicker>
					</el-form-item>
					<el-form-item v-if="flowStep.userType === 6" :label="$t('cell-panel.StepProp.033670-17')">
						<el-select
							multiple
							v-model="flowStep.userJob"
							@change="onChange"
							:disabled="disabled"
							:placeholder="$t('cell-panel.StepProp.033670-18')"
							class="full-width"
						>
							<el-option v-for="item in jobList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="[1, 5, 6].includes(flowStep.userType)" :label="$t('cell-panel.StepProp.033670-19')">
						<el-switch v-model="flowStep.userScope" :disabled="disabled" @change="onChange"></el-switch>
						<span style="margin-left: 15px">{{ $t('cell-panel.StepProp.033670-20') }}</span>
					</el-form-item>
				</el-collapse-item>
				<el-collapse-item name="formCtrl">
					<template #title>
						<span class="collapse-title">{{ $t('cell-panel.StepProp.033670-21') }}</span>
					</template>
					<template v-for="(item, index) in formItemList">
						<el-form-item
							:label="$getLocalizedLabel({ zhLabel: item.label, enLabel: item.enLabel })"
							label-width="165px"
							:key="index"
						>
							<el-radio-group v-model="item.type" size="small" :disabled="disabled" @change="onChange">
								<el-radio-button :label="1">{{ $t('cell-panel.StepProp.033670-22') }}</el-radio-button>
								<el-radio-button :label="2">{{ $t('cell-panel.StepProp.033670-23') }}</el-radio-button>
								<el-radio-button :label="3">{{ $t('cell-panel.StepProp.033670-24') }}</el-radio-button>
							</el-radio-group>
						</el-form-item>
					</template>
				</el-collapse-item>
				<el-collapse-item name="coSign">
					<template #title>
						<span class="collapse-title">{{ $t('cell-panel.StepProp.033670-25') }}</span>
					</template>
					<el-form-item label="" label-width="4px">
						<el-radio-group v-model="flowStep.signType" @change="onChange" :disabled="disabled">
							<el-radio :label="1" style="margin-right: 15px">{{ $t('cell-panel.StepProp.033670-26') }}</el-radio>
							<el-radio :label="2">{{ $t('cell-panel.StepProp.033670-27') }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item v-if="flowStep.signType === 2" :label="$t('cell-panel.StepProp.033670-28')">
						<el-radio-group v-model="flowStep.signRate" size="small" :disabled="disabled" @change="onChange">
							<el-radio-button :label="1">{{ $t('cell-panel.StepProp.033670-29') }}</el-radio-button>
							<el-radio-button :label="2">{{ $t('cell-panel.StepProp.033670-30') }}</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<div style="font-size: 14px; color: #606266">{{}}</div>
					<el-form-item :label="$t('cell-panel.StepProp.033670-54')">
						<el-switch class="switch-fr" v-model="flowStep.isPlusSign"></el-switch>
					</el-form-item>
					<el-form-item label="" label-width="4px" v-if="flowStep.isPlusSign">
						<el-radio-group v-model="flowStep.plusSignType" @change="onChange" :disabled="disabled">
							<el-radio :label="1" style="margin-right: 15px">{{ $t('cell-panel.StepProp.033670-56') }}</el-radio>
							<el-radio :label="2" disabled>{{ $t('cell-panel.StepProp.033670-55') }}</el-radio>
							<el-radio :label="3" disabled>{{ $t('cell-panel.StepProp.033670-57') }}</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-collapse-item>
				<el-collapse-item name="btnList">
					<template #title>
						<span class="collapse-title">{{ $t('cell-panel.StepProp.033670-31') }}</span>
					</template>
					<div class="form-table">
						<template v-for="(item, index) in flowStep.btnList">
							<div :key="index">
								<div class="clearfix self-define" v-if="!disabled">
									<span>{{ index > 9 ? index : '0' + (index + 1) }}</span>
									<span v-if="item.system === 0" class="float-r" @click="delBtn(index)">
										<i class="el-icon-delete"></i>
									</span>
									<span class="float-r" @click="upGo(index)">
										<i class="el-icon-top"></i>
									</span>
									<span class="float-r" @click="downGo(index)">
										<i class="el-icon-bottom"></i>
									</span>
								</div>
								<el-form-item :label="$t('cell-panel.StepProp.033670-32')">
									<el-select v-model="item.status" class="full-width" @change="onChange" :disabled="disabled">
										<el-option :label="$t('cell-panel.StepProp.033670-33')" :value="-1">{{
											$t('cell-panel.StepProp.033670-33')
										}}</el-option>
										<el-option :label="$t('cell-panel.StepProp.033670-34')" :value="1">{{
											$t('cell-panel.StepProp.033670-34')
										}}</el-option>
										<el-option :label="$t('cell-panel.StepProp.033670-35')" :value="2">{{
											$t('cell-panel.StepProp.033670-35')
										}}</el-option>
										<el-option :label="$t('cell-panel.StepProp.033670-36')" :value="6">{{
											$t('cell-panel.StepProp.033670-36')
										}}</el-option>
										<el-option :label="$t('cell-panel.StepProp.033670-37')" :value="7">{{
											$t('cell-panel.StepProp.033670-37')
										}}</el-option>
										<el-option :label="$t('cell-panel.StepProp.033670-38')" :value="9">{{
											$t('cell-panel.StepProp.033670-38')
										}}</el-option>
										<el-option :label="$t('cell-panel.StepProp.033670-39')" :value="3">{{
											$t('cell-panel.StepProp.033670-39')
										}}</el-option>
										<el-option :label="$t('cell-panel.StepProp.033670-40')" :value="4">{{
											$t('cell-panel.StepProp.033670-40')
										}}</el-option>
									</el-select>
								</el-form-item>
								<el-form-item :label="$t('cell-panel.StepProp.033670-41')">
									<el-input v-model="item.btn" @change="onChange" :disabled="disabled"></el-input>
								</el-form-item>
								<el-form-item :label="$t('cell-panel.StepProp.033670-42')">
									<el-radio-group v-model="item.show" @change="onChange" :disabled="disabled">
										<el-radio :label="true">{{ $t('cell-panel.StepProp.033670-43') }}</el-radio>
										<el-radio :label="false">{{ $t('cell-panel.StepProp.033670-24') }}</el-radio>
									</el-radio-group>
								</el-form-item>
							</div>
						</template>
						<el-button
							@click="addBtn"
							size="small"
							style="width: 100%; margin: 15px 0"
							icon="el-icon-plus"
							:disabled="disabled"
							>{{ $t('cell-panel.StepProp.033670-44') }}</el-button
						>
					</div>
				</el-collapse-item>
				<el-collapse-item name="suggest">
					<template #title>
						<span class="collapse-title">{{ $t('cell-panel.StepProp.033670-45') }}</span>
					</template>
					<div class="form-table">
						<el-form-item :label="$t('cell-panel.StepProp.033670-46')">
							<el-switch
								class="switch-fr"
								@change="onChange"
								:disabled="disabled"
								v-model="flowStep.attachShow"
							></el-switch>
						</el-form-item>
						<el-form-item :label="$t('cell-panel.StepProp.033670-47')" v-if="flowStep.attachShow">
							<el-switch
								class="switch-fr"
								@change="onChange"
								:disabled="disabled"
								v-model="flowStep.attachRequired"
							></el-switch>
						</el-form-item>
						<el-form-item :label="$t('cell-panel.StepProp.033670-48')">
							<el-switch
								class="switch-fr"
								@change="onChange"
								:disabled="disabled"
								v-model="flowStep.suggestShow"
							></el-switch>
						</el-form-item>
						<el-form-item :label="$t('cell-panel.StepProp.033670-49')" v-if="flowStep.suggestShow">
							<el-switch
								class="switch-fr"
								@change="onChange"
								:disabled="disabled"
								v-model="flowStep.suggestRequired"
							></el-switch>
						</el-form-item>
						<template v-for="(item, index) in flowStep.extendFields.fields">
							<div :key="index" style="margin-top: 10px">
								<el-form-item :label="$t('cell-panel.StepProp.033670-50')">
									{{ $getLocalizedLabel(getFieldLabel(item)) }}
								</el-form-item>
								<el-form-item :label="$t('cell-panel.StepProp.033670-51')">
									{{ item.__config__.tagIcon }}
								</el-form-item>
							</div>
						</template>
						<el-button
							size="small"
							@click="addExtendDesign"
							style="width: 100%; margin: 15px 0"
							icon="el-icon-plus"
							:disabled="disabled"
						>
							{{ $t('cell-panel.StepProp.033670-52') }}
						</el-button>
					</div>
				</el-collapse-item>
			</el-collapse>
		</el-form>
	</div>
</template>

<script>
import { Collapse, CollapseItem, RadioGroup, RadioButton, Radio } from 'element-ui';
import UserPicker from '@/custom-component/form/formParser/selectUser';
import DepartPicker from '@/custom-component/form/formParser/flowEngine/components/selectDepartment';
import { dataInterface } from '@/apis/data';

export default {
	name: 'StepProp',
	components: {
		UserPicker,
		DepartPicker,
		'el-collapse': Collapse,
		'el-collapse-item': CollapseItem,
		'el-radio-group': RadioGroup,
		'el-radio-button': RadioButton,
		'el-radio': Radio
	},
	props: {
		curCellProp: {
			type: Object,
			default: () => {}
		},
		formItem: {
			type: Array,
			default: () => []
		},
		userData: {
			type: Array,
			default: () => []
		},
		formDesign: {
			type: Object,
			default: () => {}
		},
		disabled: {
			type: Boolean,
			default: false
		},
		archiId: {
			type: String,
			default: ''
		},
		parentArchiId: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			flowStep: {
				name: this.$t('cell-panel.StepProp.033670-53'),
				userType: 1,
				userFrom: 1,
				userArch: this.archiId,
				userScope: false, // 二次选择范围
				userSection: [], // 部门
				userJob: [], // 岗位
				targetUser: [],
				selectStep: false,
				backType: 1,
				turnTo: false,
				revoke: false,
				signType: 1,
				signRate: 1,
				isPlusSign: false,
				plusSignType: 1, // 加签
				attachShow: true,
				attachRequired: false,
				suggestShow: true,
				suggestRequired: false,
				extendFields: {},
				btnList: [],
				editFields: []
			},
			formItemList: [],
			jobList: [],
			activeNames: 'basic'
		};
	},
	watch: {
		formDesign: {
			handler(val) {
				this.flowStep.extendFields = val;
				this.onChange();
			},
			deep: true
		},
		formItem: {
			handler() {
				const formItem = [];
				for (const item of this.formItem) {
					let eleItem = {
						label: item.label,
						enLabel: item.enLabel,
						formId: item.formId,
						field: item.field,
						type: 2
					};
					if (this.curCellProp.editFields) {
						eleItem.type = this.curCellProp.editFields[item.formId] || 2;
					}
					// if(item.open) formItem.push(eleItem)
					formItem.push(eleItem);
				}
				this.formItemList = formItem;
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.initProp();
	},
	methods: {
		initProp() {
			this.flowStep = this.curCellProp;
			this.flowStep.userArch = this.flowStep.userFrom === 3 ? this.parentArchiId : this.archiId;
			if (this.flowStep.userType === 6) {
				this.getArchRole(); //请求岗位接口
			}
		},
		onSelectDepart(departData) {
			let department = [];
			for (let item of departData) {
				let depart = {
					id: item.id,
					name: item.name,
					type: item.section_type
				};
				department.push(depart);
			}
			this.flowStep.userSection = department;
			this.onChange();
		},
		onSelectUser(userData) {
			let users = [];
			for (let item of userData) {
				let user = {
					id: item.user_id,
					name: item.name,
					user_table: item.user_table,
					section_id: item.section_id,
					user_id: item.user_id
				};
				users.push(user);
			}
			this.flowStep.targetUser = users;
			this.onChange();
		},
		onUserType() {
			this.flowStep.userArch = this.flowStep.userFrom === 3 ? this.parentArchiId : this.archiId;
			switch (this.flowStep.userType) {
				case 1:
					this.flowStep.userSection = [];
					this.flowStep.userJob = [];
					break;
				case 2:
					this.flowStep.targetUser = [];
					this.flowStep.userSection = [];
					this.flowStep.userJob = [];
					this.flowStep.userScope = false;
					break;
				case 5:
					this.flowStep.targetUser = [];
					this.flowStep.userFrom = 1;
					this.flowStep.userJob = [];
					this.flowStep.userScope = false;
					break;
				case 6:
					this.flowStep.targetUser = [];
					this.flowStep.userSection = [];
					this.flowStep.userFrom = 1;
					this.flowStep.userScope = false;
					break;
				case 7:
					this.flowStep.targetUser = [];
					this.flowStep.userSection = [];
					this.flowStep.userJob = [];
					this.flowStep.userScope = false;
					break;
				case 8:
				case 9:
					this.flowStep.targetUser = [];
					this.flowStep.userSection = [];
					this.flowStep.userJob = [];
					this.flowStep.userFrom = 1;
					this.flowStep.userScope = false;
					break;
			}
			this.onUserScope();
		},
		getArchRole() {
			let queryData;
			const archiInfo = this.$GetTargetArchi();
			let viewUuid = 'view6172842c7f669';
			let archiId = archiInfo.archiId;

			if (this.flowStep.userFrom === 1) {
				if (archiInfo.archiType === 'company') {
					viewUuid = 'view61728398b0390';
					queryData = {
						object_uuid: 'object6170c260890d9',
						view_uuid: viewUuid,
						company_id: archiId,
						transcode: 0,
						__method_name__: 'dataList'
					};
				} else {
					queryData = {
						object_uuid: 'object6170c260890d9',
						view_uuid: viewUuid,
						project_id: archiId,
						transcode: 0,
						__method_name__: 'dataList'
					};
				}
			} else {
				if (archiInfo.archiType === 'company') {
					if (archiInfo.data.parent_id === 0) {
						this.jobList = [];
						return;
					} else {
						archiId = archiInfo.data.parent_id;
						queryData = {
							object_uuid: 'object6170c260890d9',
							view_uuid: viewUuid,
							company_id: archiId,
							transcode: 0,
							__method_name__: 'dataList'
						};
					}
				} else {
					if (archiInfo.data.parent_id === 0) {
						viewUuid = 'view61728398b0390';
						archiId = archiInfo.data.company_id;
						queryData = {
							object_uuid: 'object6170c260890d9',
							view_uuid: viewUuid,
							company_id: archiId,
							transcode: 0,
							__method_name__: 'dataList'
						};
					} else {
						viewUuid = 'view6172842c7f669';
						archiId = archiInfo.data.parent_id;
						queryData = {
							object_uuid: 'object6170c260890d9',
							view_uuid: viewUuid,
							project_id: archiId,
							transcode: 0,
							__method_name__: 'dataList'
						};
					}
				}
			}

			dataInterface(queryData).then((res) => {
				if (res.data.code === 200) {
					this.jobList = res.data.data;
				}
			});
		},
		onUserScope() {
			if (this.flowStep.userType === 6) {
				this.getArchRole(); //请求岗位接口
			}
			if (this.flowStep.userFrom === 3) {
				this.flowStep.userArch = this.parentArchiId;
			} else {
				this.flowStep.userArch = this.archiId;
			}
			this.onChange();
		},
		addBtn() {
			let item = {
				btn: '',
				status: 1,
				show: true,
				system: 0
			};
			this.flowStep.btnList.push(item);
			this.onChange();
		},
		delBtn(index) {
			this.flowStep.btnList.splice(index, 1);
			this.onChange();
		},
		upGo(index) {
			let fieldData = this.flowStep.btnList;
			if (index !== 0) {
				fieldData[index] = fieldData.splice(index - 1, 1, fieldData[index])[0];
			} else {
				fieldData.push(fieldData.shift());
			}
			this.flowStep.btnList = fieldData;
			this.onChange();
		},
		downGo(index) {
			let fieldData = this.flowStep.btnList;
			if (index !== fieldData.length - 1) {
				fieldData[index] = fieldData.splice(index + 1, 1, fieldData[index])[0];
			} else {
				fieldData.unshift(fieldData.splice(index, 1)[0]);
			}
			this.flowStep.btnList = fieldData;
			this.onChange();
		},
		addExtendDesign() {
			this.$emit('on-form', this.curCellProp.extendFields);
		},
		onChange() {
			const fields = {};
			for (let item of this.formItemList) {
				fields[item.formId] = item.type;
			}
			this.flowStep.editFields = fields;
			this.$emit('on-change', this.flowStep);
		},
		getFieldLabel(item) {
			return { zhLabel: item.__config__.label, enLabel: item?.__config__.enLabel };
		}
	}
};
</script>

<style lang="less" scoped>
.step-prop {
	text-align: initial;

	.collapse-title {
		text-indent: 15px;
	}

	.switch-fr {
		float: right;
		margin-top: 8px;
	}

	.full-width {
		width: 100%;
	}

	.float-r {
		float: right;
		padding: 0 1px;
		cursor: pointer;
	}
	.self-define {
		margin: 8px 0;
		text-indent: 2px;
	}

	:deep(.el-radio) {
		margin-top: 8px;
		margin-bottom: 5px;
	}

	:deep(.el-collapse-item__content) {
		padding-bottom: 0;
	}

	:deep(.el-collapse-item__header) {
		position: relative;

		&:before {
			position: absolute;
			top: 16px;
			width: 3px;
			height: 14px;
			margin-right: 10px;
			background-color: #007fe0 !important;
			content: '';
		}
	}

	:deep(.form-table) {
		.el-form-item {
			border: 1px solid #eaebed;
			display: flex;
			margin-bottom: -1px !important;
			/*margin-bottom: -1px !important;*/

			.el-form-item__label {
				border-right: 1px solid #eaebed;
				background: #f8f8f9;
				padding: 6px 10px;
			}

			.el-form-item__content {
				margin-left: 0 !important;
				flex: 1;
				padding: 6px 10px;
				overflow: hidden;
				clear: both;
				/*margin-bottom: 1px;*/

				.el-select,
				.el-cascader {
					width: 100%;
				}

				.self-type {
					.el-radio {
						margin-right: 16px;
					}
				}
			}
		}
	}
}
</style>
