<!--
 * @Author: qinmengyuan
 * @Date: 2024-09-19 16:06:12
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-10-24 09:39:15
 * @FilePath: \dataview-next\src\custom-component\form\newParser\FormItem\widget-time.vue
-->
<!--
    @name: widget-time
    @description：widget-time
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <div
    :class="{
      'time-container': true,
      custom:
        element.config.__slot__?.prepend || element.config.__slot__?.append,
    }"
    :style="{
      background: (disabled || element.config.disabled) && '#F2F4F7',
      borderRadius: '4px',
    }"
  >
    <div
      class="prepend"
      v-if="element.config.__slot__?.prepend"
      :style="{
        border:
          disabled || element.config.disabled ? 'none' : '1px solid #dcdfe6',
        padding: disabled || element.config.disabled ? '0 0 0 12px' : '0 20px',
      }"
    >
      {{ element.config.__slot__?.prepend }}
    </div>
    <div
      v-if="disabled || element.config.disabled"
      class="input-show"
      :style="{
        padding: disabled || element.config.disabled ? '5px 0' : '5px 12px',
        flex: disabled || element.config.disabled ? 'unset' : 1,
        width: (disabled || element.config.disabled) && 'auto',
        paddingLeft: !element.config.__slot__?.prepend ? '12px' : '0',
        paddingRight: !element.config.__slot__?.append ? '12px' : '0',
      }"
    >
      {{ element.value ? element.value : '-' }}
    </div>

    <el-time-picker
      v-else
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :type="element.config.type"
      :format="element.config.format"
      :value-format="element.config['value-format']"
      :placeholder="
        $getLocalizedLabel({
          zhLabel: element.config.zhPlaceholder,
          enLabel: element?.config.enPlaceholder,
        })
      "
    >
    </el-time-picker>
    <div
      class="append"
      v-if="element.config.__slot__?.append"
      :style="{
        border:
          disabled || element.config.disabled ? 'none' : '1px solid #dcdfe6',
        padding: disabled || element.config.disabled ? '0 12px 0 0' : '0 20px',
      }"
    >
      {{ element.config.__slot__?.append }}
    </div>
  </div>
</template>

<script>
import { TimePicker } from 'element-ui';
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';

export default {
  name: 'WidgetTime',
  components: {
    'el-time-picker': TimePicker,
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.time-container {
  display: flex;
  align-items: center;
  .input-show {
    flex: 1;
  }
  .el-date-editor {
    width: 100%;
  }
  .prepend {
    background: #f5f7fa;
    padding: 0 20px;
    border: 1px solid #dcdfe6;
    border-radius: 4px 0 0 4px;
    box-sizing: border-box;
    height: 36px;
  }
  .append {
    background: #f5f7fa;
    padding: 0 20px;
    border: 1px solid #dcdfe6;
    border-radius: 0 4px 4px 0;
    box-sizing: border-box;
    height: 36px;
  }
}
.custom {
  .el-date-editor {
    flex: 1;
  }
  :deep(.el-input__inner) {
    border-radius: 0 !important;
  }
  .input-show {
    border-radius: 0;
  }
}
</style>
