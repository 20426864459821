var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"common-tabs",style:({
		'--tabs-fontsize': _vm.fontSize
	})},[_c('el-tabs',{class:[_vm.statusConfig.type],attrs:{"type":_vm.statusConfig.type,"tab-position":_vm.statusConfig.tabPosition || 'top'},on:{"tab-click":_vm.onTabClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(_vm.tabOrigin === 'fixed')?_vm._l((_vm.tabConfig),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":_vm.$getLocalizedLabel({ zhLabel: item?.label, enLabel: item?.enLabel }),"name":item.type},scopedSlots:_vm._u([(_vm.useSolt)?{key:"label",fn:function(){return [_c('span',{style:({ color: _vm.activeName === item.type ? _vm.activeColor : _vm.defaultColor })},[_vm._v(" "+_vm._s(_vm.$getLocalizedLabel({ zhLabel: item?.label, enLabel: item?.enLabel }))+" ")])]},proxy:true}:null],null,true)})}):_vm._e(),(_vm.tabOrigin === 'dictionary')?_vm._l((_vm.dictionaryList),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":item.value,"name":item.code},scopedSlots:_vm._u([(_vm.useSolt)?{key:"label",fn:function(){return [_c('span',{style:({ color: _vm.activeName === item.code ? _vm.activeColor : _vm.defaultColor })},[_vm._v(" "+_vm._s(item.value)+" ")])]},proxy:true}:null],null,true)})}):_vm._e(),(_vm.tabOrigin === 'dynamic')?_vm._l((_vm.dynamicTabs),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":item[_vm.dynamicConfig.labelKey],"name":item[_vm.dynamicConfig.valueKey].toString()},scopedSlots:_vm._u([(_vm.useSolt)?{key:"label",fn:function(){return [_c('span',{style:({
							color: _vm.activeName === item[_vm.dynamicConfig.valueKey].toString() ? _vm.activeColor : _vm.defaultColor
						})},[_vm._v(" "+_vm._s(item[_vm.dynamicConfig.labelKey])+" ")])]},proxy:true}:null],null,true)})}):_vm._e(),(_vm.tabOrigin === 'interface')?_vm._l((_vm.interfaceTabs),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":item[_vm.interfaceConfig.labelKey].toString(),"name":item[_vm.interfaceConfig.valueKey].toString()},scopedSlots:_vm._u([(_vm.useSolt)?{key:"label",fn:function(){return [_c('span',{style:({
							color: _vm.activeName === item[_vm.interfaceConfig.valueKey].toString() ? _vm.activeColor : _vm.defaultColor
						})},[_vm._v(" "+_vm._s(item[_vm.interfaceConfig.labelKey])+" ")])]},proxy:true}:null],null,true)})}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }