<!--
    @name: widget-carCard
    @description：widget-carCard
    @author: ZengWei
    @date: 2024-04-26 10:42
-->
<template>
  <div class="widget-car-card">
    <div class="car-card-ksa" v-if="element.config.__config__.carRegion === 'KSA'">
      <div class="car-tip">
        <span>Numbers</span>
        <span style="margin-left: 175px">Letters</span>
      </div>
      <div class="car-card">
        <template v-for="(n,i) in ksaNumber">
          <el-popover ref="popover" trigger="click" :key="i">
            <div class="car-card-code">
              <template v-if="i < 4">
                <div v-for="(item,ind) in alphabetNum" :key="ind" :style="{'margin-bottom': ind === alphabetNum.length - 1 ? '0' : '10px'}">
                  <el-button class="car-card-btn" v-for="(nn,ii) in item" :key="ii" size="small" @click="cnClick(nn, n, i)">{{nn}}</el-button>
                </div>
              </template>
              <template v-else>
                <div v-for="(item,ind) in alphabetStr" :key="ind" :style="{'margin-bottom': ind === alphabetStr.length - 1 ? '0' : '10px'}">
                  <el-button class="car-card-btn" v-for="(nn,ii) in item" :key="ii" size="small" @click="cnClick(nn, n, i)">{{nn}}</el-button>
                </div>
              </template>
            </div>
            <el-input ref="card" class="car-number" v-model="n.code" slot="reference" maxlength="1" minlength="1"></el-input>
          </el-popover>
          <span :key="i+10" v-if="i === separate" class="point">·</span>
        </template>
      </div>
    </div>
    <div class="car-card" v-else>
      <el-popover ref="popover" trigger="click">
        <div class="car-card-first">
          <div v-for="(item,ind) in province" :key="ind" :style="{'margin-bottom': ind === province.length - 1 ? '0' : '10px'}">
            <el-button class="car-card-btn" v-for="(nn,ii) in item" :key="ii" size="small" @click="cnClickFirst(nn)">{{nn}}</el-button>
          </div>
        </div>
        <el-input class="car-number" v-model="cnFirst" slot="reference"></el-input>
      </el-popover>
      <template v-for="(n,i) in cnNumber" >
        <el-popover ref="popover" trigger="click" :key="i">
          <div class="car-card-code">
            <div v-for="(item,ind) in alphabet" :key="ind" :style="{'margin-bottom': ind === alphabet.length - 1 ? '0' : '10px'}">
              <el-button class="car-card-btn" v-for="(nn,ii) in item" :key="ii" size="small" @click="cnClick(nn, n)">{{nn}}</el-button>
            </div>
          </div>
          <el-input class="car-number" v-model="n.code" slot="reference"></el-input>
        </el-popover>
        <span :key="i+10" v-if="i === separate" class="point">·</span>
      </template>
    </div>
  </div>
</template>

<script>
import {Popover} from "element-ui";

export default {
  name: "WidgetCarCard",
  components: {
    'el-popover': Popover
  },
  props: ['element', 'disabled'],
  data() {
    return {
      separate: 0,
      province: [
        ['京', '湘', '津', '鄂', '沪', '粤', '渝', '琼'],
        ['冀', '川', '晋', '贵', '辽', '云', '吉', '陕'],
        ['黑', '甘', '苏', '青', '浙', '皖', '藏', '闽'],
        ['蒙', '赣', '桂', '鲁', '宁', '豫', '新']
      ],
      alphabet: [
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
        [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K'],
        [ 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U'],
        [ 'V', 'W', 'X', 'Y', 'Z' ],
      ],
      alphabetNum: [
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
      ],
      alphabetStr: [
        [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K'],
        [ 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U'],
        [ 'V', 'W', 'X', 'Y', 'Z' ],
      ],
      ksaNumber: [
        {code: ''},
        {code: ''},
        {code: ''},
        {code: ''},
        {code: ''},
        {code: ''},
        {code: ''},
      ],
      cnFirst: '',
      cnNumber: [
        {code: ''},
        {code: ''},
        {code: ''},
        {code: ''},
        {code: ''},
        {code: ''},
        {code: ''},
      ],
    }
  },
  created() {
    const codes = this.element.value.split('')
    if(this.element.config.__config__.carRegion === 'KSA'){
      this.separate = 3
      if(codes.length){
        for (let i=0; i < this.ksaNumber.length; i++) {
          this.ksaNumber[i].code = codes[i]
        }
      }
    } else {
      this.separate = 0
      if(codes.length){
        this.cnFirst = codes[0]
        for (let i=0; i < this.cnNumber.length; i++) {
          this.cnNumber[i].code = codes[i+1]
        }
      }
    }
  },
  methods: {
    cnClickFirst(val){
      this.cnFirst = val
      this.onChange()
    },
    cnClick(val, item, i){
      item.code = val
      this.onChange()
      if(i < this.$refs.card.length - 1){
        const inputRef = this.$refs.card[i+1]
        const popoverCurRef = this.$refs.popover[i]
        const popoverRef = this.$refs.popover[i+1]
        inputRef.focus()
        popoverCurRef.doClose()
        popoverRef.doShow()
      }
    },
    onChange(){
      if(this.element.config.__config__.carRegion === 'KSA'){
        const code = Array.from(this.ksaNumber,t=>t.code)
        this.element.value = code.join('')
      } else {
        const code = Array.from(this.cnNumber,t=>t.code)
        this.element.value = this.cnFirst + code.join('')
      }
    }
  },
}
</script>

<style lang="less">
.widget-car-card{
  .el-input__inner {
    padding-left: 12px !important;
    padding-right: 0 !important;
  }
  .car-tip{
    display: flex;
  }
  .car-card{
    display: flex;
    .car-number{
      width: 36px;
      margin-right: 15px;
    }
    .point{
      margin-right: 15px;
      font-size: 45px;
      line-height: 30px;
    }
  }
  .car-card-code{
    width: 491px;
  }
  .car-card-first{
    width: 390px;
  }
}
.car-card-btn{
  width: 40px;
}
</style>
