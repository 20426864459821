<!--
 * @Author: Shiltin
 * @Date: 2021-10-20 17:52:57
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-06 19:44:15
 * @Description:
-->
<template>
	<div style="height: 100%; width: 100%" class="select-user-main">
		<div style="height: 100%; width: 100%" v-if="!inpage">
			<el-select
				class="new-select-style-user"
				value-key="id"
				v-model="seatValue"
				collapse-tags
				:class="isConsole ? 'console-user-style' : ''"
				style="display: block"
				:value="seatValue"
				popper-class="select-popper"
				multiple
				@visible-change="visibleChange"
			>
				<template #prefix>
					<div
						v-if="showValue && !showChooseButton"
						class="show-value"
						:style="disabled && !isConsole ? 'background:#F5F7FA;color:#c0c4cc' : ''"
					>
						<span class="label-name">{{ showValue }}</span>
						<span style="width: 40px"
							>{{ $t('components.cascader.396254-15') }}{{ selectUserList.length
							}}{{ $t('components.cascader.396254-16') }}</span
						>
					</div>
					<div
						v-if="!showValue && !showChooseButton"
						class="show-value"
						style="cursor: pointer; color: #c0c4cc"
						:style="disabled && !isConsole ? 'background:#F5F7FA;color:#c0c4cc' : ''"
					>
						{{ element?.config?.placeholder ?? $t('components.cascader.396254-0') }}
					</div>
					<div v-if="showChooseButton" class="choose-button">
						<span>{{ $t('components.cascader.396254-1') }}</span>
						<i class="iconfont iconkuaisuxinzeng" style="font-size: 18px"></i>
					</div>
				</template>
				<template #empty>
					<div class="pover">
						<div v-loading="showTree" class="pover-content">
							<div class="content-left">
								<div class="content-left-search">
									<el-input v-model="searchValue" placeholder="请输入人员名字" prefix-icon="el-icon-search"> </el-input>
								</div>
								<el-tabs v-model="activeName" class="demo-tabs" @tab-click="tabHandleClick" ref="tabs">
									<el-tab-pane label="全部" name="1"></el-tab-pane>
									<el-tab-pane label="公司" name="2"></el-tab-pane>
									<el-tab-pane label="项目" name="3"></el-tab-pane>
								</el-tabs>
								<div class="content-left-tree scroller">
									<el-tree
										v-if="dataList?.length !== 0"
										ref="treeRef"
										:data="dataList"
										:render-after-expand="lazy"
										:accordion="lazy ? true : ''"
										:default-checked-keys="showTreeKeys"
										:default-expanded-keys="lazy ? expandKeys : []"
										:default-expand-all="!lazy ? defaultExpand : false"
										:filter-node-method="filterNode"
										node-key="idKey"
										:props="{
											label: 'name',
											children: 'children',
											disabled: () => {
												return disabled;
											}
										}"
										:show-checkbox="isCheckType"
										@check="handleCheckChange"
										@node-click="handleNodeClick"
									>
										<template #default="{ node }">
											<div class="custom-tree-node">
												<el-tooltip
													class="item"
													:content="node.label"
													effect="dark"
													placement="top-start"
													:open-delay="1000"
												>
													<div class="tree-item">
														<div
															:style="{
																color: getTypeInfo(node) ? getTypeInfo(node).color : '',
																fontWeight: 600,
																display: 'flex',
																alignItems: 'center',
																marginRight: '4px'
															}"
														>
															<span
																v-if="
																	getTypeInfo(node) &&
																	getTypeInfo(node).name !== $t('components.cascader.396254-3') &&
																	getTypeInfo(node).name !== $t('components.cascader.396254-4')
																"
															>
																{{ getTypeInfo(node) ? getTypeInfo(node).name : '' }}
															</span>
															<i
																v-if="
																	getTypeInfo(node) && getTypeInfo(node).name === $t('components.cascader.396254-3')
																"
																class="iconfont iconwenjianjia"
															/>
															<img
																v-if="
																	getTypeInfo(node) && getTypeInfo(node).name === $t('components.cascader.396254-4')
																"
																:src="
																	node.data.avatar ??
																	'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/496e62f1dfefde252945bbc3328ac33c.png'
																"
																style="width: 16px; height: 16px"
															/>
														</div>
														<span class="label">{{ node.label }}</span>
													</div>
												</el-tooltip>
											</div>
										</template>
									</el-tree>
									<div v-else class="empty">
										<img
											class="left-user"
											src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/7cc09838241433b2f528985664410949.png"
											alt="empty"
										/>
									</div>
								</div>
							</div>
							<div class="content-right">
								<div class="content-right-top">
									<span> {{ $t('components.cascader.396254-5') }}{{ selectUserList.length }}） </span>
									<span style="float: right; color: #0076ff; cursor: pointer" @click="clearChoose(1)" v-if="!disabled">
										{{ $t('components.cascader.396254-6') }}
									</span>
								</div>
								<div class="content-right-choose scroller">
									<template v-if="selectUserList.length !== 0">
										<div v-for="(item, index) in selectUserList" :key="index" class="content-show">
											<div class="content-show-img">
												<img
													v-if="item.wechat_avatar || item.avatar"
													:src="item.avatar ? item.avatar : item.wechat_avatar"
												/>
												<div v-else class="content-show-seat" />
											</div>
											<div class="content-show-name">
												<el-tooltip class="item" effect="dark" placement="top-start" :open-delay="1000">
													<template #content>
														{{
															item.name +
															'(' +
															(item.company_name ? item.company_name : item.project_name) +
															(item.external_name ? '/' + item.external_name : '') +
															'/' +
															item.section_name +
															')'
														}}
													</template>
													<p>{{ item.name }}({{ item.section_name }})</p>
												</el-tooltip>
												<p>
													{{ item.company_name ? item.company_name : item.project_name }}
													{{ item.external_name ? '/' + item.external_name : '' }}
												</p>
											</div>
											<div class="content-show-delete" v-if="!disabled">
												<i
													class="iconfont iconwangpan-qingchu1x"
													style="color: #a9adb8"
													@click.stop="deleteClick(item)"
												/>
											</div>
										</div>
									</template>
									<div v-else class="empty">
										<img
											class="right-user"
											src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/84ed12890c802e4c09bf380d9f286507.png"
											alt="empty"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</el-select>
			<div v-if="showChooseButton">
				<div
					v-for="(item, index) in selectUserList"
					:key="index"
					class="content-show content-show-new"
					style="justify-content: flex-start"
				>
					<div style="width: 20px; height: 20px; margin: 4px 8px 0 0">
						<img
							v-if="item.wechat_avatar || item.avatar"
							:src="item.avatar ? item.avatar : item.wechat_avatar"
							style="width: 100%; height: 100%"
						/>
						<div v-else style="width: 100%; height: 100%; background: #eee; border-radius: 4px; overflow: hidden" />
					</div>
					<div class="content-show-name" style="flex: 1">
						<el-tooltip
							class="item"
							:content="item.name + '(' + getTitle(item) + ')'"
							effect="dark"
							placement="top-start"
							:open-delay="1000"
						>
							<p>{{ item.name }}</p>
						</el-tooltip>
					</div>
					<div class="content-show-delete-new">
						<i
							style="font-size: 12px; cursor: pointer"
							class="iconfont iconbimgis_guanbi"
							@click.stop="deleteClick(item)"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- 回显 -->
		<div v-if="inpage" class="pover" style="width: 100%; height: 100%">
			<!-- <div class="show-value" style="height: 30px; line-height: 30px">
        <span>{{ showValue }}</span>
      </div> -->
			<div v-loading="showTree" class="pover-content">
				<div class="content-left">
					<div class="content-left-search">
						<el-input v-model="searchValue" :placeholder="$t('components.cascader.396254-2')" />
					</div>
					<div class="content-left-tree scroller">
						<el-tree
							v-if="dataList.length !== 0"
							ref="treeRef"
							:data="dataList"
							:default-checked-keys="showTreeKeys"
							:default-expand-all="defaultExpand"
							:filter-node-method="filterNode"
							node-key="idKey"
							:props="{
								label: 'name',
								children: 'children'
							}"
							:show-checkbox="isCheckType"
							@check="handleCheckChange"
							@node-click="handleNodeClick"
						>
							<template #default="{ node }">
								<span class="custom-tree-node">
									<el-tooltip class="item" :content="node.label" effect="dark" placement="top-start" :open-delay="1000">
										<span>
											<span :style="{ color: getTypeInfo(node) ? getTypeInfo(node).color : '', fontWeight: 600 }">
												<span v-if="getTypeInfo(node) && getTypeInfo(node).name !== $t('components.cascader.396254-3')">
													{{ getTypeInfo(node) ? getTypeInfo(node).name : '' }}
												</span>
												<i
													v-if="getTypeInfo(node) && getTypeInfo(node).name === $t('components.cascader.396254-3')"
													class="iconfont iconwenjianjia"
												/>
											</span>
											{{ node.label }}
										</span>
									</el-tooltip>
								</span>
							</template>
						</el-tree>
						<div v-else class="empty">
							<img
								class="left-user"
								src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/7cc09838241433b2f528985664410949.png"
								alt="empty"
							/>
						</div>
					</div>
				</div>
				<div class="content-right">
					<div class="content-right-top">
						<span>
							{{ $t('components.cascader.396254-7') }}
							<span class="content-right-top-number">
								{{ selectUserList.length }}
							</span>
							{{ $t('components.cascader.396254-4') }}
						</span>
						<span style="float: right; color: #0076ff; cursor: pointer" v-if="!disabled" @click="clearChoose(1)">
							{{ $t('components.cascader.396254-6') }}
						</span>
					</div>
					<div class="content-right-choose scroller">
						<template v-if="selectUserList.length !== 0">
							<div v-for="(item, index) in selectUserList" :key="index" class="content-show">
								<div class="content-show-img">
									<img v-if="item.wechat_avatar || item.avatar" :src="item.avatar ? item.avatar : item.wechat_avatar" />
									<div v-else class="content-show-seat" />
								</div>
								<div class="content-show-name">
									<el-tooltip class="item" effect="dark" placement="top-start" :open-delay="1000">
										<template #content>
											{{
												item.name +
												'(' +
												(item.company_name ? item.company_name : item.project_name) +
												(item.external_name ? '/' + item.external_name : '') +
												'/' +
												item.section_name +
												')'
											}}
										</template>
										<p>{{ item.name }}({{ item.section_name }})</p>
									</el-tooltip>
									<p>
										{{ item.company_name ? item.company_name : item.project_name }}
										{{ item.external_name ? '/' + item.external_name : '' }}
									</p>
								</div>
								<div class="content-show-delete" v-if="!disabled">
									<i class="iconfont iconwangpan-qingchu1x" style="color: #a9adb8" @click.stop="deleteClick(item)" />
								</div>
							</div>
						</template>
						<div v-else class="empty">
							<img
								class="right-user"
								src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/84ed12890c802e4c09bf380d9f286507.png"
								alt="empty"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Tooltip, Tree, Input, Tabs, TabPane } from 'element-ui';

export default {
	name: '',
	components: {
		'el-tooltip': Tooltip,
		'el-tree': Tree,
		'el-input': Input,
		'el-tabs': Tabs,
		'el-tab-pane': TabPane
	},
	props: {
		dataInfo: {
			type: Array,
			default: () => []
		},
		showList: {
			type: Array,
			default: () => []
		},
		inpage: {
			type: Boolean,
			default: false
		},
		// cascaderCheck 级联单选
		// cascaderCheckbox 级联多选
		isCheckType: {
			type: Boolean,
			default: true
		},
		positionType: {
			type: String,
			default: 'bottom-start'
		},
		/* 弹出框宽度 */
		width: {
			type: Number,
			default: 450
		},
		/* 弹出框最大高度 */
		maxHeight: {
			type: Number,
			default: 300
		},
		/* 默认展开 */
		defaultExpand: {
			type: Boolean,
			default: true
		},
		/* 展示内容 */
		showTree: {
			type: Boolean,
			default: true
		},
		/* 禁用选择 */
		disabled: {
			type: Boolean,
			default: false
		},
		/* 展示按钮的选择人员 */
		showChooseButton: {
			type: Boolean,
			default: false
		},
		/* 懒加载树节点 */
		lazy: {
			type: Boolean,
			default: false
		},
		// 中控台的样式
		isConsole: {
			type: Boolean,
			default: false
		},
		element: {
			type: Object,
			default: () => {}
		},
		tableClearVisible: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			seatValue: '',
			treeRef: null,
			showTreeKeys: [],
			selectUserList: [],
			searchValue: '',
			firsetIn: false,
			dataList: [],
			expandKeys: [], // 懒加载状态的回显人员展开的key
			activeName: '1'
		};
	},
	computed: {
		/* 计算当前选择的人员回显 */
		showValue() {
			let str = '';
			this.selectUserList.forEach((item) => {
				if (!str) {
					str += item.name;
				} else {
					str += ',' + item.name;
				}
			});
			return str;
		}
	},
	watch: {
		searchValue: {
			handler(val) {
				this.$refs.treeRef.filter(val);
			}
		},
		dataInfo: {
			handler(val) {
				this.dataList = val;
				this.showTreeInfo(this.showList, val);
			},
			deep: true
		},
		showList: {
			handler(val) {
				this.showTreeInfo(val);
			},
			deep: true
		}
	},
	methods: {
		tabHandleClick() {
			this.$emit('tab-change', this.activeName);
		},

		/* 当前选择的nodekey数组 */
		handleCheckChange() {
			/* 级联单选 */
			if (!this.isCheckType) {
				this.returnShowTreeInfo(this.showTreeKeys);
				return;
			}
			// const treeRef = ref(this.treeRef)
			const keys = this.$refs.treeRef.getCheckedKeys();
			let arr = [];
			if (keys && keys.length) {
				const loopTree = (array) => {
					array.forEach((element) => {
						if (keys.indexOf(element.idKey) !== -1 && element.user_id && element.section_id) {
							arr.push(+element.idKey);
						}
						if (element.children && element.children.length) {
							loopTree(element.children);
						}
					});
				};
				loopTree(this.dataList);
				this.showTreeKeys = arr;
			} else {
				this.showTreeKeys = [];
			}
			this.returnShowTreeInfo(arr);
		},
		/* 级联单选 */
		handleNodeClick(data) {
			if (this.isCheckType || this.disabled) {
				return;
			}
			if (data.nodeKey) {
				this.showTreeKeys = [];
				this.showTreeKeys = [data.idKey];
			}
			this.returnShowTreeInfo(this.showTreeKeys);
		},
		/* 当前选择的人员数组数据（item包含所有信息） */
		returnShowTreeInfo(list) {
			const selectArr = [];
			list.forEach((element) => {
				const getKey = (array) => {
					array.forEach((itemy) => {
						if (itemy.children && itemy.children.length && itemy.children[0].section_id && itemy.children[0].nodeKey) {
							itemy.children.forEach((item) => {
								if (item.idKey === element) {
									selectArr.push(item);
								}
							});
						} else if (itemy.children && itemy.children.length) {
							getKey(itemy.children);
						}
					});
				};
				getKey(this.dataList);
			});
			this.selectUserList = selectArr;
			this.$emit('on-select-change', selectArr);
		},
		/* 传入回显的showList 获取勾选数组  进行默认回显 */
		showTreeInfo(list) {
			if (!list || list.length === 0 || !this.dataList || this.dataList.length === 0) {
				// 表格控件使用人员组建需求，外部传入空回回显数据，需要清空组件内选中回显
				if (this.dataInfo?.length && !list?.length && this.tableClearVisible) {
					this.clearChoose();
				}
				return;
			}
			/* 判断回显数组与当前选择的数据对比 */
			if (
				list &&
				list.length &&
				this.selectUserList &&
				this.selectUserList.length &&
				this.selectUserList.length === list.length
			) {
				let num = 0;
				for (let index = 0; index < list.length; index++) {
					const element = list[index];
					for (let ind = 0; ind < this.selectUserList.length; ind++) {
						const item = this.selectUserList[ind];
						if (element.user_id === item.user_id && element.section_id === item.section_id) {
							num += 1;
						}
					}
				}
				if (num === list.length) {
					return;
				}
			}
			if (this.$refs.treeRef) {
				this.$refs.treeRef?.setCheckedKeys([]);
			}
			let showArr = [];
			let expandKeys = [];
			list.forEach((element) => {
				let singleKeys = [];
				const showTree = (arr) => {
					for (var i = 0; i < arr.length; i++) {
						if (arr[i].user_id === element.user_id && arr[i].section_id === element.section_id) {
							showArr.push(arr[i]);
							singleKeys = arr[i].fatherKeys;
							break;
						} else if (arr[i].children && arr[i].children.length) {
							arr[i].children.forEach((element) => {
								if (!arr[i].fatherKeys || arr[i].fatherKeys.length === 0) {
									element.fatherKeys = [arr[i].idKey];
								} else {
									let array = JSON.parse(JSON.stringify(arr[i].fatherKeys));
									array.push(arr[i].idKey);
									element.fatherKeys = array;
								}
							});
							showTree(arr[i].children);
						}
					}
				};
				showTree(this.dataList);
				expandKeys = [...new Set([...expandKeys, ...new Set(singleKeys)])];
			});
			this.expandKeys = [...new Set(expandKeys)];
			const unique = (arr, attrName) => {
				const res = new Map();
				return arr.filter((a) => !res.has(a[attrName]) && res.set(a[attrName], 1));
			};
			showArr = unique(showArr, 'user_id');
			const arr = [];
			showArr.forEach((element) => {
				arr.push(element.idKey);
			});
			this.showTreeKeys = arr;
			this.returnShowTreeInfo(this.showTreeKeys);
		},
		/* 删除单个勾选 */
		deleteClick(data) {
			for (let index = 0; index < this.showTreeKeys.length; index++) {
				if (this.showTreeKeys[index] === data.idKey) {
					this.showTreeKeys.splice(index, 1);
				}
			}
			this.returnShowTreeInfo(this.showTreeKeys);
			// const treeRef = ref(this.treeRef)
			this.$refs.treeRef?.setCheckedKeys(this.showTreeKeys);
		},
		/* 清空所有勾选 */
		clearChoose() {
			if (!this.isCheckType) {
				// const treeRef = ref(this.treeRef)
				this.$refs.treeRef.setCurrentKey(null);
				this.showTreeKeys = [];
				this.returnShowTreeInfo(this.showTreeKeys);
				this.selectUserList = [];
				this.$emit('on-select-change', this.selectUserList);
				return;
			}
			// const treeRef = ref(this.treeRef)
			this.$refs.treeRef?.setCheckedKeys([]);
			this.selectUserList = [];
			this.$emit('on-select-change', this.selectUserList);
		},
		/* tree组件的过滤规则 */
		filterNode(value, data) {
			if (!value) return true;
			return !!(data.name && data.name.indexOf(value) !== -1);
		},
		getTitle(item) {
			return (item.external_name ? item.external_name + '/' : '') + item.section_name;
		},
		/* 根据架构级别返回显示不同的颜色和类型文字 */
		getTypeInfo(data) {
			const info = data.data;
			if (info.archi_table === 'org_projects') {
				return {
					name: this.$t('components.cascader.396254-9'),
					color: '#2BA5F5'
				};
			} else if (info.archi_table === 'org_companys') {
				return {
					name: this.$t('components.cascader.396254-8'),
					color: '#A56EFF'
				};
			} else if (info.archi_table === 'org_sections') {
				return {
					name: this.$t('components.cascader.396254-10'),
					color: '#26A049'
				};
			} else if (info.archi_table === 'org_external_companys') {
				return {
					name: this.$t('components.cascader.396254-11'),
					color: '#FFCB00'
				};
			} else {
				return {
					name: this.$t('components.cascader.396254-4'),
					color: '#26A049'
				};
			}
		},
		visibleChange(bol) {
			if (!bol) {
				this.$emit('on-select-change', this.selectUserList, true);
			}
		}
	},
	mounted() {
		this.dataList = this.dataInfo;
		this.showTreeInfo(this.showList);
		// tabs下划线初始进来不展示bug
		this.$nextTick(() => {
			if (this.$refs.tabs) {
				this.$refs.tabs.$el.querySelector('.el-tabs__active-bar').style.width = '57px';
			}
		});
	}
};
</script>
<style lang="less">
/* 修改select组件样式 */
.select-popper {
	z-index: 11010 !important;
	min-width: 640px !important;
	width: 640px !important;
}
.new-select-style-user {
	width: 100%;
	height: 36px;
	.el-input__suffix {
		display: none !important;
	}
	.el-input__inner {
		display: none !important;
	}
	.el-input__prefix {
		width: 100% !important;
		display: inherit !important;
		height: 100% !important;
		position: inherit !important;
		padding: 0 !important;
		overflow: hidden;
		box-sizing: border-box !important;
		text-align: left;
		left: 0;
		.el-input__prefix-inner {
			width: 100%;
			div {
				width: 100%;
			}
		}
	}
	.el-input--suffix {
		width: 100% !important;
		display: inherit !important;
		height: 100% !important;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		position: inherit !important;
		box-sizing: border-box !important;
	}
}
</style>
<style lang="less" scoped>
:deep(.el-tree-node__content:hover) {
	border-radius: 6px;
	background: #e1e4ec;
}
.custom-tree-node {
	overflow: hidden;
	width: 100%;
	.tree-item {
		display: flex;
		width: 100%;
		.label {
			width: 100%;
			overflow: hidden;
			color: #181b22;
			text-overflow: ellipsis;
			font-family: 'PingFang SC';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0.5px;
		}
	}
}
.show-value {
	width: 100%;
	line-height: 32px;
	color: #606266;
	border: 1px solid #dcdfe6;
	border-radius: 2px;
	box-sizing: border-box;
	padding: 0 16px;
	height: 100%;
	text-align: left;
	// height: 32px;
	display: flex;
	justify-content: space-between;
	.label-name {
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
.show-value-input {
	height: 32px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.choose-button-box {
	box-sizing: border-box;
	// padding: 10px 0;
	overflow: hidden;
}
.choose-button {
	width: 100%;
	height: 32px;
	background-color: #e8f0fe;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 0 10px;
	border-radius: 4px;
	color: #121619;
	line-height: 32px;
	font-weight: 550;
	// margin: 10px 0;
	cursor: pointer;
}
.content-show-new {
	width: 100%;
	height: 28px;
	line-height: 28px;
	display: flex;
	justify-content: space-between;
	padding: 0 12px 0 10px;
	box-sizing: border-box;
	.content-show-name {
		display: flex;
	}
	.content-show-img {
		width: 20px;
		height: 20px;
		margin: 4px 8px 0px 0px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.content-show-delete-new {
		width: 14px;
		height: 14px;
		line-height: 14px;
		margin-top: 7px;
		border-radius: 50%;
		overflow: hidden;
		text-align: center;
		// background-color: #C1C7CD;
		color: #fff;
	}
}
.empty {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	.left-user {
		width: 100%;
		object-fit: contain;
	}
	.right-user {
		width: 130px;
		height: 106px;
		object-fit: contain;
	}
}
.pover {
	width: 640px;
	height: 560px;
	font-size: 14px;
	.pover-content {
		width: 100%;
		height: 100%;
		display: flex;
		overflow-y: auto;
		.content-left {
			width: 50%;
			height: 100%;
			background: #f2f5fa;
			display: flex;
			flex-direction: column;
			:deep(.el-tree) {
				background: #f2f5fa;
			}
			:deep(.el-tree__empty-block) {
				background: #f2f5fa;
			}
			.content-left-search {
				height: 50px;
				padding: 16px 16px 0 16px;
				box-sizing: border-box;
				:deep(.el-input) {
					height: 32px;
					border-radius: 6px;
				}
				:deep(.el-input--medium .el-input__inner) {
					height: 32px;
					line-height: 32px;
				}
			}
			:deep(.el-tabs) {
				width: 100%;
				height: 56px;
				padding: 8px 16px;
				box-sizing: border-box;
				.el-tabs__nav-scroll {
					display: flex;
					flex-direction: column;
					align-items: center;
					.el-tabs__nav {
						width: 90%;
						display: flex;
						justify-content: space-around;
						.el-tabs__item {
							color: var(--text-on-surface-tertiary, #707786);
							text-align: center;
							width: 65px;
							box-sizing: content-box;
							/* web/cn/heading/heading-01 */
							font-family: 'PingFang SC';
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							letter-spacing: 0.5px;
						}
						.is-active {
							color: var(--text-on-surface-primary, #181b22);
						}
						.el-tabs__active-bar {
							background-color: #387ffc;
						}
					}
				}
			}
			.content-left-tree {
				padding: 6px 12px;
				flex: 1;
				overflow-y: auto;
				overflow-x: hidden;
			}
			:deep(.content-left-tree) {
				.el-checkbox__original {
					display: none;
				}
			}
		}
		.content-right {
			width: 50%;
			height: 100%;
			display: flex;
			flex-direction: column;
			.content-right-top {
				height: 50px;
				line-height: 40px;
				padding: 0 10px;
				box-sizing: border-box;
				.content-right-top-number {
					color: #0076ff;
				}
			}
			.content-right-choose {
				flex: 1;
				padding: 6px 12px;
				overflow-y: auto;
				.content-show {
					width: 100%;
					height: 50px;
					display: flex;
					.content-show-img {
						width: 50px;
						height: 50px;
						box-sizing: border-box;
						padding: 6px;
						.content-show-seat {
							background: #eee;
							width: 100%;
							height: 100%;
							border-radius: 4px;
							overflow: hidden;
						}
						img {
							width: 100%;
							height: 100%;
							border-radius: 4px;
							overflow: hidden;
						}
					}

					.content-show-name {
						flex: 1;
						height: 50px;
						overflow: hidden;
						display: flex;
						flex-direction: column;
						justify-content: center;

						p {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: var(--text-on-surface-primary, #181b22);
							font-family: 'PingFang SC';
							font-size: 14px;
							font-style: normal;
							letter-spacing: 0.5px;
						}
						p:nth-child(1) {
							line-height: 22px;
						}
						p:nth-child(2) {
							color: #a4acbd;
							font-family: 'PingFang SC';
							font-size: 10px;
							font-style: normal;
							line-height: 16px;
						}
					}
					.content-show-delete {
						width: 10%;
						height: 50px;
						line-height: 50px;
						text-align: center;
					}
				}
			}
		}
	}
}
</style>
