<template>
	<div id="parent-app-main" v-loading="loading" v-if="isVisible">
		<router-view></router-view>
		<!-- BIM中控台 -->
		<template v-if="showBIMConsole">
			<BIMConsole v-show="currentMenu && currentMenu.menu_path === '/entry?type=bimConsole'"></BIMConsole>
		</template>
		<!-- 自定义表单新增弹框 -->
		<FormAddParser
			v-if="showFormAdd"
			@close="closeFormAdd"
			:object_uuid="objectUuid"
			:form_uuid="formUuid"
		></FormAddParser>
	</div>
</template>

<script>
import { getRenderData } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import VersionThreeMessage from '@/utils/VersionThreeMessage.js';
import BIMConsole from '@/views/bim_gis/BIMConsole';
import { mapState } from 'vuex';
import { getProjectEnv, setToken, getQueryValue, getToken, setPermissionObj } from '@/utils/tools';
import FormAddParser from '@/views/formAddParser/index';
import actions from './libs/action';
import { dataInterface } from '@/apis/data';
import { getIconRequest } from '@/apis/data/index.js';
import { approximateColor1ToColor2ByPercent } from '@/utils/style';
import systemCompatibleMixin from '@/plugins/systemCompatibleMixin';
import { routes } from '@/router/baseConfig.js';
import { getLangMapData, CacheDB } from '@/utils/indexDB';

export default {
	name: 'App',
	mixins: [systemCompatibleMixin],
	components: {
		BIMConsole,
		FormAddParser
	},
	data() {
		return {
			showFormAdd: false, //子应用触发自定义表单新增
			formUuid: '',
			objectUuid: '',
			isVisible: false,
			// 加载
			loading: false,
			// 用户信息
			userInfo: {},
			// 加载中控台
			showBIMConsole: false,
			isAppInit: true
		};
	},
	computed: {
		...mapState(['currentMenu', 'appNavHistoryList'])
	},
	watch: {
		$route() {
			console.log(this.$route, '----1231231----');
			// 退出登录后架构ID消失 不会重新获取
			let APPID = localStorage.getItem('VUE_APP_BASE_ID');
			if (!APPID) {
				localStorage.setItem('VUE_APP_BASE_ID', process.env.VUE_APP_BASE_ID);
			}
			const { path, query } = this.$route || {};

			console.log(query, 'query');
			if (+query?._isTransparent === 1) {
				this.$nextTick(() => {
					const root = document.querySelector(':root');
					root.style.setProperty('--systemBackground', 'transparent');
				});
			} else {
				this.$nextTick(() => {
					const root = document.querySelector(':root');
					root.style.setProperty('--systemBackground', '#fff');
				});
			}
			if (!this.$GetToken() || !this.$GetTargetArchi()) {
				this.showBIMConsole = false;
				return;
			}
			if (path === '/entry' && query?.type === 'bimConsole') {
				this.showBIMConsole = true;
				// 兼容刷新情况
				this.$store.commit('setCurrentMenu', {
					level: 2,
					menuData: {
						menu_path: '/entry?type=bimConsole'
					}
				});
			} else {
				const currentApp = sessionStorage.getItem('currentApp');
				const queryResult = this.getQueryPath(this.$route.path);
				const result = this.isTargetApp(
					queryResult.path,
					JSON.parse(currentApp)?.app_orign_data || [],
					queryResult.isParam
				);
				// TODO.个人中心板块不切换应用
				if (!result && !['/pedestal-user-center'].includes(this.$route.path)) {
					sessionStorage.removeItem('currentApp');
					this.doCurrentApp(2);
				}
			}
		}
	},
	async created() {
		//动态添加图标js文件及css
		this.loadIconInfo();
		// 移除部分缓存数据，避免直接刷新页面的相关问题
		sessionStorage.removeItem('currentFormUUID');
		// localStorage.setItem('VUE_APP_BASE_ID', process.env.VUE_APP_BASE_ID);
		// 测试代码
		/* eslint-disable */
		// setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVSUQiOiIxMzg4MzA5NDQzMiIsIlBsYXRmb3JtIjoiV2ViIiwiZXhwIjoyMDAzNjIxMzAzLCJuYmYiOjE2ODgwMDIxMDMsImlhdCI6MTY4ODAwMjEwM30.5YD_vYKHUdETmumL46r9OvB-jEmK18SsxetczPALXM8');
		// 测试结束
		// 获取系统配置
		await this.getSystemConfig();
		// 预处理带_key_的路由用于固定跳转且不能切换到其他路由
		const isLimit = await this.doLimitRoute();
		if (isLimit) return false;
		const reg = new RegExp('(^|&)token=([^&]*)(&|$)', 'i');
		const queryToken = window.location.search.substr(1).match(reg);
		const systemType = getQueryValue('systemType') || -1;
		if (queryToken?.length) {
			if (+systemType !== -1) {
				// 外部系统跳入需要进行token交换并准备去前置系统数据（目前是3.0 ）
				const transformResult = await this.transformOutToken();
				console.log(transformResult, '---外部系统跳入需要进行token交换并准备去前置系统数据（目前是3.0系统来源');
				await this.onToggleArchi(transformResult.archi_id);
			} else {
				//带长效token把token写入cookie
				setToken(queryToken[2]);
			}
			// 根据token获取架构信息
			await this.getInfoByToken();
		} else {
			// 验证token是否有效
			const token = this.$GetToken();
			if (token) {
				const needReLogin = sessionStorage.getItem('needReLogin');
				if (needReLogin) {
					this.isVisible = true;
					sessionStorage.removeItem('needReLogin');
					this.$router.replace({
						name: 'Login'
					});
				}
			}
		}
		// return false;
		// 添加app_id, 便于微应用里访问

		this.$store.commit('setProjectEnvironment', getProjectEnv());
		// 接收3.0引入页面推送过来的消息
		window.addEventListener('message', this.msgHandler, false);

		if (localStorage.getItem('userInfo')) {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
		}
		const appList = sessionStorage.getItem('appList');

		if (!appList || appList === '[]') {
			this.getApps();
		} else {
			this.doCurrentApp(3);
			this.$store.commit('setAppList', JSON.parse(appList));
			const list = JSON.parse(appList);
			for (let i = 0; i < list.length; i++) {
				const appOriginData = list[i]?.app_orign_data || [];
				const pathList = appOriginData[0]?.children_list || [];
				if (appOriginData.length) {
					const item = JSON.parse(JSON.stringify(appOriginData[0]));
					delete item.children_list;
					pathList.push({
						...item
					});
					this.configRoutes(pathList, true, 'MyHome');
				}
			}
			// 当前应用
			const currentApp = sessionStorage.getItem('currentApp');
			if (currentApp) {
				this.$store.commit('setCurrentApp', JSON.parse(currentApp));
				const queryResult = this.getQueryPath(this.$route.path);
				this.isTargetApp(queryResult.path, JSON.parse(currentApp)?.app_orign_data || [], queryResult.isParam);
			} else {
				// 根据当前路由获取
				const path = this.$route.path;
				const excludes = ['/login', '/second-screen', '/404'];
				if (!excludes.includes(path)) {
					const app = this.getCurrentApp(path, list);
					this.$store.commit('setCurrentApp', app);
				}
			}
			// 当前项目首页
			const appIndexMenu = sessionStorage.getItem('appIndexMenu');
			if (appIndexMenu) {
				this.$store.commit('setAppIndexMenu', JSON.parse(appIndexMenu));
			}
			// 历史记录
			const appNavHistoryList = sessionStorage.getItem('appNavHistoryList');
			if (appNavHistoryList) {
				this.$store.commit('setAppNavHistory', {
					origin: 1,
					history: JSON.parse(appNavHistoryList)
				});
			}
		}
		// 当前架构
		const targetArchi = this.$GetTargetArchi();
		if (!targetArchi) {
			sessionStorage.setItem('targetArchiInit', 1);
		}
		this.$store.commit('setTargetArchi', { targetArchi, a: 1 });
		this.isVisible = true;
	},
	mounted() {
		eventBus.$on('updateRoute', () => {
			this.isVisible = false;
			this.getApps();
			this.getSystemConfig();
		});
		document.onclick = () => {
			this.$store.commit('hideCustomMenu');
		};
		// 注册一个观察者函数
		// state 是变更后的状态，prevState 是变更前的状态
		actions.onGlobalStateChange((state) => {
			// console.log("主应用观察者，改变前的值", prevState)
			// console.log('主应用观察者，改变后的值', state);
			if (state.msg && !state.lang && !state.theme) {
				this.formUuid = state.msg === 'quality' ? 'form62ba5fff13713' : 'form62b133fb4f5f1';
				this.objectUuid = state.msg === 'quality' ? 'object6268b4c9c083f' : 'object6268bc6f7e8b6';
				this.showFormAdd = true;
			}
		});

		// 获取语言包
		this.getLangMapList();
	},
	methods: {
		/**
		 * @description: 动态在header里添加图标文件
		 * @return {*}
		 */
		loadIconInfo() {
			getIconRequest().then((res) => {
				if (res.data.code === 200 && res.data.data?.data?.length) {
					for (let i = 0; i < res.data.data.data.length; i++) {
						let link = document.createElement('link');
						let script = document.createElement('script');
						link.setAttribute('rel', 'stylesheet');
						link.setAttribute('type', 'text/css');
						link.setAttribute('href', res.data.data.data[i].css_file);
						script.setAttribute('type', 'text/javascript');
						script.setAttribute('src', res.data.data.data[i].js_file);
						let heads = document.getElementsByTagName('head');
						if (heads.length) {
							heads[0].appendChild(link);
							heads[0].appendChild(script);
						} else {
							document.documentElement.appendChild(link);
							document.documentElement.appendChild(script);
						}
					}
				}
			});
		},
		/**
		 * @description: 根据token获取系统信息、用户信息、token等
		 * @return {*}
		 */
		async getInfoByToken() {
			return new Promise((resolve) => {
				dataInterface({
					__method_name__: 'globalFunctionCall',
					typeName: 'LoginFuncClass',
					type: 'value',
					funcName: 'UserInfoByToken',
					token: getToken(),
					transcode: 0
				})
					.then((res) => {
						const { userRoles = '', userInfo = {}, targetArchi = {} } = res?.data?.data || {};
						localStorage.setItem('userRoles', userRoles || '');
						const targetArchiInfo = {
							...targetArchi,
							archiId: targetArchi.archi_id,
							archiType: targetArchi.data_id.indexOf('companys') > -1 ? 'company' : 'project',
							id: targetArchi.data_id,
							company_id: targetArchi.data.company_id || '',
							modelApproval: ['https://bim.xwjgpt.com/'].includes(process.env.VUE_APP_V3_HOST) ? true : false
						};
						this.$store.commit('setTargetArchi', {
							targetArchi: targetArchiInfo,
							a: 2
						});
						let roleNames = [];
						if (Array.isArray(userInfo?.roles) && userInfo?.roles.length) {
							userInfo?.roles.forEach((ele) => {
								if (ele?.name) {
									roleNames.push(ele.name);
								}
							});
						}
						localStorage.setItem(
							'userInfo',
							JSON.stringify({
								...userInfo,
								roleName: roleNames.join()
							})
						);
						resolve(true);
					})
					.catch((err) => {
						console.log(err, '---操作错误');
						resolve(false);
					});
			});
		},
		/**
		 * @description: 校验token
		 * @return {*}
		 */
		validToken(token) {
			return new Promise((resolve, reject) =>
				dataInterface(
					{
						token,
						refresh: 1
					},
					`${process.env.VUE_APP_BASE_URL}api/v4/user/refreshToken`
				)
					.then((res) => {
						if (res.data.code !== 200) {
							setToken(res.data?.data);
							resolve(false);
							return;
						}
						resolve(true);
					})
					.catch((err) => {
						console.log(err);
						this.initEnd = true;
						this.$message.error('请重新登录!');
						this.$router.replace({
							name: 'Login'
						});
					})
			);
		},
		/**
		 * @desc: 限制路由处理
		 */
		doLimitRoute() {
			return new Promise((resolve) => {
				try {
					const href = window.location.href;
					if (!href.includes('?_key_=') && !sessionStorage.getItem('isLimit')) {
						resolve(false);
						return;
					}
					resolve(true);
					// 进入信息获取和重定向
					this.$loading();
					dataInterface({
						__method_name__: 'dataList',
						object_uuid: 'object63edfb115c715',
						view_uuid: 'view63ee01386cf81',
						search: [
							{
								field_uuid: 'field63f4753259f98',
								ruleType: 'eq',
								value: getQueryValue('_key_') || '-1'
								// value: 'ab9d5f3eb2fc5854d453024277015971' // 测试
							}
						],
						transcode: 0,
						page: 1,
						size: 15
					})
						.then(async (res) => {
							let info = null;
							if (Object.prototype.toString.call(res?.data?.data) === '[object Array]') {
								info = res?.data?.data?.[0];
							} else {
								info = res?.data?.data?.data?.[0];
							}
							// console.log(info, '---', getQueryValue)
							if (!info) {
								this.$loading().close();
								this.$message.error('链接已过期!');
								this.$router.replace({
									name: 'Login'
								});
								return false;
							}
							const { token, route, url } = info;
							if (!token || !route) {
								this.$loading().close();
								this.$message.error('链接错误!');
								this.$router.replace({
									name: 'Login'
								});
								return false;
							}
							// 设置token
							setToken(token);
							await this.getInfoByToken();
							// 跳转路由
							const { pathname } = window.location;
							if (pathname === route) {
								this.$loading().close();
								this.isVisible = true;
								return false;
							}
							setTimeout(() => {
								sessionStorage.setItem(
									'limitConfig',
									JSON.stringify({
										...info,
										justRoute: route,
										fePath: url
									})
								);
								this.$loading().close();
								this.isVisible = true;
								this.$router.push(url);
								// 测试
								// this.$router.push(`/dataview/show?pageUUID=page63e469e09acfa&token&_key_=${getQueryValue('_key_')}`)
							}, 50);
						})
						.catch((err) => {
							console.log(err);
						});
				} catch (e) {
					resolve(true);
				}
			});
		},
		/**
		 * @desc: 获取用于查询的路由
		 * @return 可能是params
		 */
		getQueryPath(path) {
			let queryPath = path;
			const pathArr = path.split('/');
			if (pathArr.length && !isNaN(+pathArr[pathArr.length - 1])) {
				pathArr.splice(pathArr.length - 1);
				queryPath = pathArr.join('/');
				return {
					path: queryPath,
					isParam: true
				};
			}
			return {
				path,
				isParam: false
			};
		},
		/**
		 * @desc: 关闭表单
		 */
		closeFormAdd() {
			actions.setGlobalState({
				msg: `close`
			});
			this.showFormAdd = false;
		},
		/**
		 * @desc: 设置当前跳转的应用(feature)
		 */
		/**
		 * @description: 设置当前跳转的应用(feature)
		 * @param {Number} from 来源-测试用
		 */
		doCurrentApp() {
			// console.log(from, '--设置当前跳转的应用--from----')
			let app = null;
			try {
				app = sessionStorage.getItem('currentApp');
				let n = 0;
				const interval = setInterval(() => {
					if (app || n > 100) {
						clearInterval(interval);
						if (app) {
							app = JSON.parse(app);
						} else {
							const path = this.$route.path;
							const appList = JSON.parse(sessionStorage.getItem('appList'));
							if (appList) {
								app = this.getCurrentApp(path, appList);
							}
							if (!app) {
								app = appList?.[0] || null;
							}
						}
						if (app) {
							this.setCurrent(app, 1);
						}
					} else {
						app = sessionStorage.getItem('currentApp');
					}
					n++;
				}, 10);
			} catch (err) {
				console.log(err, '----000---');
				app = JSON.parse(sessionStorage.getItem('appList'))?.[0];
				if (app) {
					this.setCurrent(app, 2);
				}
			}
		},
		/**
		 * @description: 设置当前应用及后续操作
		 * @param {Object} app 应用对象
		 * @param {Number} origin 来源/测试用
		 */
		setCurrent(app) {
			// console.log(app, '----app---xxxxxxxxx--', origin);
			// 保存当前的应用
			this.$store.commit('setCurrentApp', app);
			const appData = app?.app_orign_data;
			if (!appData || !appData.length) {
				this.$message.error('应用配置错误，请联系管理员 !');
				this.$router.replace({
					name: 'Login'
				});
				return;
			}
			const indexObj = this.getIndexPath(appData);
			if (!indexObj) {
				// this.$message.error('应用暂未配置有效首页1!');
				return;
			}
			this.$store.commit('setAppIndexMenu', indexObj);
			if (this.isAppInit && this.appNavHistoryList?.length) {
				this.isAppInit = false;
				return;
			}
			this.$store.commit('setAppNavHistory', {
				origin: 2,
				history: [indexObj]
			});
		},
		/**
		 * @desc: 获取应用首页(feature)
		 * @param {Array} list route list
		 */
		getIndexPath(list) {
			for (let i = 0; i < list.length; i++) {
				const { is_index, is_hide, children_list, menu_type } = list[i];
				if (is_index && !is_hide && (+menu_type === 0 || +menu_type === 4)) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getIndexPath(children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 预加载表格iframe
		 */
		preloadLucky() {
			let sssIframe = document.createElement('iframe');
			sssIframe.src = 'https://sss.bimcc.com';
			sssIframe.id = 'sssIframe';
			sssIframe.style.display = 'block';
			sssIframe.style.border = 'none';
			sssIframe.style.position = 'absolute';
			sssIframe.style.top = '-10000px';
			sssIframe.style.right = '-10000px';
			sssIframe.style.zIndex = -1;
			sssIframe.width = '1000px';
			sssIframe.height = '600px';
			document.body.appendChild(sssIframe);
			// sss服务加载状态
			window.sssLoaded = false;
			sssIframe.onload = () => {
				setTimeout(() => {
					window.sssLoaded = true;
				}, 0);
				sssIframe.contentWindow.postMessage(
					{
						event: 'spreadsheetInit',
						options: {
							baseUrl: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'https://dev.bimcc.net/',
							metadataToken: process.env.VUE_APP_METATOKEN
								? process.env.VUE_APP_METATOKEN
								: '4fb0b638-2c43-4d7e-8acf-f1a7abdee58easdfasdfqwerqwer',
							bimcctoken: this.$GetToken()
						}
					},
					'*'
				);
			};
		},
		/**
		 * @desc: 首页待办点击之后的详情
		 */
		msgHandler(e) {
			new VersionThreeMessage(e).bind(this).handler();
		},
		/**
		 * @desc: 根据路由获取当前所在应用
		 * @param {String} path 路径
		 * @param {Array} appList 应用列表
		 */
		getCurrentApp(path, appList = []) {
			// console.log(path, '----app-----path----', appList);
			if (!path || !Array.isArray(appList)) return null;
			const queryResult = this.getQueryPath(path);
			for (let i = 0; i < appList.length; i++) {
				const isTarget = this.isTargetApp(
					queryResult.path,
					appList[i]?.app_orign_data?.[0]?.children_list || [],
					queryResult.isParam
				);
				// console.log(appList[i].name, '-----isTarget----', isTarget)
				if (isTarget) {
					return appList[i];
				}
			}
			return null;
		},
		/**
		 * @desc: 判断是否为当前应用且保存相关内容
		 * @param {String} path 路径
		 * @param {Array} children 菜单列表
		 */
		isTargetApp(path, children, isParam) {
			// const target = children.find((ele) => ele.menu_path === path);
			const target = children.find((ele) => ele.menu_path === path);
			// console.log(path, children, '----children---', children, '2222-----', target)
			if (target) {
				this.$store.commit('setCurrentMenu', {
					level: target.level,
					menuData: target
				});
				return target;
			}
			for (let i = 0, len = children.length; i < len; i++) {
				if (children[i].menu_path?.split('?')?.[0] === path) {
					return children[i];
				}
				// param 路由传值
				if (isParam && children[i].menu_path.includes(path)) {
					return children[i];
				}
				if (children[i].children_list) {
					// console.log(children[i].name, '---(children[i].name--', children[i].children_list)
					const result = this.isTargetApp(path, children[i].children_list, isParam);
					// console.log(result, '-----11111111111----------------')
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取应用列表
		 */
		getApps() {
			this.loading = true;
			getRenderData({
				__method_name__: 'dataList',
				object_uuid: 'object62331adb0640c',
				view_uuid: 'view6233e631e57c2',
				search: [],
				transcode: 0,
				project_id: +process.env.VUE_APP_BASE_ID // 项目ID
			})
				.then((res) => {
					this.loading = false;
					if (res && res.status === 200 && res.data && res.data.data) {
						let appList = res.data.data || [];
						appList = appList.map((ele) => {
							return {
								...ele,
								auth_key: ele?.app_orign_data?.[0]?.auth_key || ''
							};
						});
						this.$store.commit('setAppList', appList);
						if (res.data.data) {
							sessionStorage.setItem('appList', JSON.stringify(res.data.data));
						}
						this.doCurrentApp(1);
						let routeList = [];
						for (let i = 0; i < appList.length; i++) {
							const appOriginData = appList[i]?.app_orign_data || [];
							const pathList = appOriginData[0]?.children_list || [];
							if (appOriginData.length) {
								const item = JSON.parse(JSON.stringify(appOriginData[0]));
								delete item.children_list;
								pathList.push({
									...item
								});
								const result = this.setConfigRoutes(pathList, true, 'MyHome');
								routeList = routeList.concat(result);
							}
						}
						routeList = routeList.filter((item) => item.path);
						const parent = routes.find((ele) => ele.name === 'MyHome');
						routes.splice(1, 1, {
							...parent,
							children: parent.children.concat(routeList)
						});
						this.$router.addRoutes(routes);
					}
					this.$nextTick(() => {
						this.isVisible = true;
					});
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
					this.$nextTick(() => {
						this.isVisible = true;
					});
					this.$message.error('系统初始化失败，请重试!');
				});
		},
		/**
		 * @desc: 应用版本配置路由
		 * @param {Array} arr
		 * @param {Boolean} isChild
		 * @param {String} parentName
		 */
		configRoutes(arr, isChild, parentName) {
			if (!arr || !Array.isArray(arr)) return;
			arr
				.filter((el) => el.menu_path)
				.forEach((ele) => {
					const { menu_path, menu_type, name, permissions, is_screen } = ele;
					// 权限
					const routeName = `${menu_path}${new Date().getTime()}`;
					// 类型 0 配置页面，1 跳转外链，2 功能组 ，3(微应用暂时弃用)， 4 跳转路由
					if (+menu_type !== 1) {
						if (isChild) {
							this.$router.addRoute(parentName, {
								...ele,
								path: menu_path?.split('?')?.[0],
								name: routeName, // 暂时将path作为name
								meta: {
									title: name,
									isScreen: is_screen,
									auth: permissions
								},
								component: () => import(/* webpackChunkName: "index" */ '@/views/Index')
							});
						} else {
							this.$router.addRoute({
								...ele,
								path: menu_path?.split('?')?.[0],
								name: routeName, // 暂时将path作为name
								meta: {
									title: name,
									isScreen: is_screen,
									auth: permissions
								},
								component: () => import(/* webpackChunkName: "index" */ '@/views/Index')
							});
						}
					}
					// 子路由
					if (+menu_type === 2 && ele.children_list && Array.isArray(ele.children_list) && ele.children_list.length) {
						this.configRoutes(ele.children_list, true, routeName);
					}
				});
		},
		/**
		 * @description: 设置配置的路由列表
		 */
		setConfigRoutes(arr, isChild, parentName) {
			let result = [];
			if (!arr || !Array.isArray(arr)) return result;
			arr
				.filter((item) => item.menu_path)
				.forEach((ele) => {
					const { menu_path, menu_type, name, permissions, is_screen } = ele;
					// 权限
					const routeName = `${menu_path}${new Date().getTime()}`;
					// 类型 0 配置页面，1 跳转外链，2 功能组 ，3(微应用暂时弃用)， 4 跳转路由
					if (+menu_type !== 1) {
						const item = {
							...ele,
							path: menu_path,
							name: routeName, // 暂时将path作为name
							meta: {
								title: name,
								isScreen: is_screen,
								auth: permissions
							},
							component: () => import(/* webpackChunkName: "index" */ '@/views/Index')
						};
						result.push(item);
					}
					// 子路由
					if (+menu_type === 2 && ele.children_list && Array.isArray(ele.children_list) && ele.children_list.length) {
						// this.configRoutes(ele.children_list, true, routeName);
						const children = this.setConfigRoutes(ele.children_list, true, routeName);
						result = result.concat(children);
					}
				});
			return result;
		},
		/**
		 * @desc: 配置系统配置
		 */
		getSystemConfig() {
			return new Promise((resolve, reject) => {
				dataInterface({
					__method_name__: 'dataInfo',
					object_uuid: 'object616639b6a1ebb',
					data_id: +process.env.VUE_APP_BASE_ID, // 项目ID
					transcode: 0
				})
					.then((res) => {
						const result = res?.data?.data;
						// 设置语言
						const lang = localStorage.getItem('preferred_lang') || result?.platform_language || 'en';
						localStorage.setItem('preferred_lang', lang);
						this.$i18n.locale = lang;
						this.$store.commit('setSystemConfig', result);
						document.title = result?.sortName || result?.name || 'BIM+项目建管平台';
						// 设置系统颜色，新版本主题
						localStorage.setItem('VUE_APP_BASE_ID', result?.id || process.env.VUE_APP_BASE_ID);
						this.setRootVars(result);
						this.appendIcon(result?.icon_config);
						this.changeIco(result?.sortIco);
						resolve(true);
					})
					.catch((err) => {
						console.log(err);
						reject(false);
					});
			});
		},

		/**
		 * @description: 获取语言列表 通过更新时间增量处理
		 * @return {*}
		 */
		async getLangMapList() {
			// 存入indexDB
			const latestData = await this.getLatestData();
			const params = !latestData ? [] : { update_time: latestData.data.updated_at };
			dataInterface(params, '/api/language/list').then(async (res) => {
				if (res.data && res.data.code === 200 && res.data.data) {
					const dbObj = new CacheDB('i18n_lang_map', 'langMapList');
					await dbObj.init();
					res.data.data.forEach(async (item) => {
						await dbObj.addData(item.chinese, item, 'put');
					});
				}
			});
		},
		/**
		 * @description: 获取最新翻译列表
		 * @return {*}
		 */
		getLatestData() {
			return getLangMapData().then((res) => {
				window.langMapList = res;
				return res.reduce((accumulator, current) => {
					if (!accumulator || new Date(current.data.updated_at) > new Date(accumulator.data.updated_at)) {
						return current;
					} else {
						return accumulator;
					}
				}, null);
			});
		},
		changeIco(sortIco = '') {
			if (!sortIco) return;
			var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
			link.type = 'image/x-icon';
			link.rel = 'shortcut icon';
			link.href = sortIco; //icon图标
			document.getElementsByTagName('head')[0].appendChild(link);
		},
		/**
		 * 将颜色值HEX格式转换为rgb的格式
		 * @param {hex} hex 需要转换的rgb字符串
		 * @return {string}  ;
		 */
		hexToRgb(hex) {
			let str = hex.replace('#', '');
			if (str.length % 3) {
				return 'hex格式不正确！';
			}
			//获取截取的字符长度
			let count = str.length / 3;
			//根据字符串的长度判断是否需要 进行幂次方
			let power = 6 / str.length;
			let r = parseInt('0x' + str.substring(0 * count, 1 * count)) ** power;
			let g = parseInt('0x' + str.substring(1 * count, 2 * count)) ** power;
			let b = parseInt('0x' + str.substring(2 * count)) ** power;
			return `${r}, ${g}, ${b}`;
		},
		/**
		 * @desc: 新主题设置css变量
		 * @param {Object} theme_append_info 主题配置
		 */
		setRootVars(systemConfig = {}) {
			const { theme_append_info = {} } = systemConfig;
			if (![3, 4].includes(+systemConfig?.theme_type)) return;
			this.$nextTick(() => {
				const root = document.querySelector(':root');
				let {
					themeColor = '#337EFF',
					hoverColor = '#F4F6FA',
					listIconColor = 'linear-gradient(180deg, #5ADFFA 0%, #459CFF 100%)'
				} = theme_append_info;
				if (!themeColor) {
					themeColor = '#337EFF';
				}
				//类似于雅砻江主题
				if (systemConfig?.theme_type === 4) {
					themeColor = '#E46445';
					hoverColor = '#FEE9E4';
					listIconColor = 'linear-gradient(90deg, #F8F2EF 0%, #F6E6E3 99.4%)';
				}
				root.style.setProperty('--themeColorRgb', this.hexToRgb(themeColor));
				root.style.setProperty('--themeColor', themeColor);
				root.style.setProperty('--themeThreeColor', themeColor);
				root.style.setProperty('--menuHoverColor', hoverColor);
				root.style.setProperty('--listIconLinearGradient', listIconColor);
				root.style.setProperty('--rowHoverColor', hoverColor);
				// element 变量
				root.style.setProperty('--el-color-primary', themeColor);
				root.style.setProperty(
					'--el-color-primary-light-1',
					approximateColor1ToColor2ByPercent('#ffffff', themeColor, 0.1)
				);
				root.style.setProperty(
					'--el-color-primary-light-2',
					approximateColor1ToColor2ByPercent('#ffffff', themeColor, 0.2)
				);
				root.style.setProperty(
					'--el-color-primary-light-3',
					approximateColor1ToColor2ByPercent('#ffffff', themeColor, 0.3)
				);
				root.style.setProperty(
					'--el-color-primary-light-4',
					approximateColor1ToColor2ByPercent('#ffffff', themeColor, 0.4)
				);
				root.style.setProperty(
					'--el-color-primary-light-5',
					approximateColor1ToColor2ByPercent('#ffffff', themeColor, 0.5)
				);
				root.style.setProperty(
					'--el-color-primary-light-6',
					approximateColor1ToColor2ByPercent('#ffffff', themeColor, 0.6)
				);
				root.style.setProperty(
					'--el-color-primary-light-7',
					approximateColor1ToColor2ByPercent('#ffffff', themeColor, 0.7)
				);
				root.style.setProperty(
					'--el-color-primary-light-8',
					approximateColor1ToColor2ByPercent('#ffffff', themeColor, 0.8)
				);
				root.style.setProperty(
					'--el-color-primary-light-9',
					approximateColor1ToColor2ByPercent('#ffffff', themeColor, 0.9)
				);
				// --el-color-primary-light-1: #53a8ff;
				// --el-color-primary-light-2: #66b1ff;
				// --el-color-primary-light-3: #79bbff;
				// --el-color-primary-light-4: #8cc5ff;
				// --el-color-primary-light-5: #a0cfff;
				// --el-color-primary-light-6: #b3d8ff;
				// --el-color-primary-light-7: #c6e2ff;
				// --el-color-primary-light-8: #d9ecff;
				// --el-color-primary-light-9: #ecf5ff;
			});
		},
		/**
		 * @desc: 配置icon链接
		 * @param {Object} configList
		 */
		appendIcon(configList = []) {
			if (!configList || !Array.isArray(configList)) return;
			configList.forEach((config) => {
				const { icon_js_url, icon_css_url } = config;
				const styleDom = document.createElement('link');
				styleDom.rel = 'stylesheet';
				styleDom.type = 'text/css';
				styleDom.href = icon_css_url;
				const scriptDom = document.createElement('script');
				scriptDom.src = icon_js_url;
				document.head.appendChild(styleDom);
				document.head.appendChild(scriptDom);
			});
		},
		/**
		 * @desc: 获取组织架构的信息（请求架构列表的信息有问题，需要用此函数请求架构数据）
		 */
		onToggleArchi(id) {
			return new Promise((resolve, reject) => {
				dataInterface({
					__method_name__: 'globalFunctionCall',
					typeName: 'LoginFuncClass',
					type: 'behavior',
					funcName: 'ToggleArchBehavior',
					payload: {
						__external_company_type: localStorage.getItem('userType'),
						archi_id: id,
						archi_table: id.indexOf('companys') > -1 ? 'org_companys' : 'org_projects'
					}
				})
					.then((res) => {
						if (!res || res.status !== 200) {
							resolve(false);
							return false;
						}
						const targetArchi = {
							...res.data.data,
							archiId: res.data.data.archi_id,
							archiType: id.indexOf('companys') > -1 ? 'company' : 'project',
							id: res.data.data.data_id,
							company_id: res.data.data.data.company_id || '',
							modelApproval: ['https://bim.xwjgpt.com/'].includes(process.env.VUE_APP_V3_HOST) ? true : false
						};
						this.$store.commit('setTargetArchi', { targetArchi, a: 4 });
						window.localStorage.setItem('currentProjectInfo', JSON.stringify(targetArchi));
						localStorage.setItem('userRoles', res.data.data.permissions || '');
						setPermissionObj(res.data.data.permissions || '');
						if (res?.data?.data?.userInfo) {
							const userInfo = res?.data?.data?.userInfo || {};
							let roleNames = [];
							if (Array.isArray(userInfo?.roles) && userInfo?.roles.length) {
								userInfo?.roles.forEach((ele) => {
									if (ele?.name) {
										roleNames.push(ele.name);
									}
								});
							}
							localStorage.setItem(
								'userInfo',
								JSON.stringify({
									...userInfo,
									roleName: roleNames.join()
								})
							);
						}
						resolve(true);
					})
					.catch((err) => {
						reject(false);
						console.log(err);
					});
			});
		}
	},
	beforeDestroy() {
		eventBus.$off('updateRoute');
		window.removeEventListener('message', this.msgHandler, false);
		sessionStorage.removeItem('currentFormUUID');
		sessionStorage.removeItem('needReLogin');
	}
};
</script>

<style lang="less">
#parent-app-main {
	height: 100%;
	width: 100%;
	// min-width: 860px;
	// overflow: hidden;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// text-align: center;
	color: #2c3e50;
	overflow: auto;
	background: var(--systemBackground);
}
.gantt-error,
.dhtmlx-error {
	display: none !important;
}
.tox-tinymce-aux {
	z-index: 99999 !important;
}
@font-face {
	font-family: YouSheBiaoTiHei;
	src: url(//static.bimcc.com/fonts/YouSheBiaoTiHei.ttf);
}
.el-empty__image {
	width: 80px !important;
	height: 80px;
}
.el-select-dropdown__item.selected {
	font-weight: 500 !important;
}
/* 替换空状态图片 */
.config-chart-container,
.normalpage-container {
	.el-empty__image {
		svg {
			display: none;
		}
		background-image: url(assets/images/empty-light.png);
		background-size: contain;
		background-repeat: no-repeat;
	}
}
.screen-container,
.chart-container-dialog {
	.el-loading-mask {
		background: rgba(0, 0, 0, 0.9);
		.el-loading-spinner .path {
			stroke: #fff;
		}
	}
	.el-empty__image {
		svg {
			display: none;
		}
		background-image: url(assets/images/empty-dark.png);
		background-size: contain;
		background-repeat: no-repeat;
	}
	.el-empty__description p {
		color: #fff;
	}
}
// 大屏图表弹窗样式
.chart-container-dialog {
	background-color: rgba(255, 255, 255, 0.3) !important;
	backdrop-filter: blur(12px);
}
.dialog-location {
	width: 280px;
	height: 70px;
	border-radius: 8px;
	background: rgba(0, 23, 32, 0.6);
	.el-dialog__header {
		display: none;
	}
	.el-dialog__body {
		color: #fff;
		padding: 0;
		i {
			margin-right: 8px;
			position: relative;
			top: 1px;
		}
		.bind-btn {
			color: #00b7ff;
		}
	}
}
.el-loading-spinner .path {
	stroke: var(--themeColor) !important;
}

//解决fixed设置后，表格无法对齐，滚动条被覆盖
.table-container .el-table__fixed,
.el-table__fixed-right {
	height: calc(100% - 8px) !important;
}
.table-container .el-table__fixed-body-wrapper {
	height: 100% !important;
}

.table-container .el-table__fixed-body-wrapper .el-table__body {
	/*滚动条高度*/
	padding-bottom: 40px !important;
}
.table-container .el-table {
	th.gutter,
	colgroup.gutter {
		width: 6px !important; //此处的宽度值，对应你自定义滚动条的宽度即可
	}
}
// 关键css代码
.table-container .el-table__header colgroup col[name='gutter'] {
	display: table-cell !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background-color: var(--themeColor) !important;
	border-color: var(--themeColor) !important;
}
</style>
