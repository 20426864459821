/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-05-13 14:19:18
 * @FilePath: \dataview-viewer-test\src\custom-component\netDisk\lang\en.js
 */
export default {
  "components.SelectFiles.193459-0": "Please enter file name to search",
  "components.SelectFiles.193459-1": "Selected",
  "components.SelectFiles.193459-2": "Items",
  "components.SelectFiles.193459-3": "Clear",
  "components.SelectFiles.193459-4": "Please select files",
  "components.SelectFiles.193459-5": "This is an outer file",
  "components.SelectFiles.193459-6": "Level 1-1",
  "components.SelectFiles.193459-7": "Level 2-1",
  "components.SelectFiles.193459-8": "Level 3-1-1",
  "components.SelectFiles.193459-9": "Level 3-1-2",
  "components.SelectFiles.193459-10": "Level 2-2",
  "components.SelectFiles.193459-11": "Level 2-2-1",
  "components.SelectFiles.193459-12": "Level 2-2-2",
  "components.SelectFiles.193459-13": "Level 3-2",
  "components.SelectFiles.193459-14": "Level 3-2-1",
  "components.SelectFiles.193459-15": "Level 3-2-2",
  "components.SelectFiles.193459-16": "Level 1-2",
  "netDisk.mixin.193458-0": "Operation failed!",
  "netDisk.mixin.193458-1": "Event configuration error!",
  "netDisk.mixin.193458-2": "Operation successful!",
  "netDisk.mixin.193458-3": "Event trigger object not configured!",
  "netDisk.mixin.193458-4": "Backend event error 00000",
  "netDisk.mixin.193458-5": "The queried component ID does not exist! Please check the component ID: {0}",
  "components.Rename.193459-0": "Please enter name",
  "components.Rename.193459-1": "Cancel",
  "components.Rename.193459-2": "Confirm",
  "components.Rename.193459-3": "New Folder",
  "components.Rename.193459-4": "Rename",
  "components.OperationFile.193459-0": "File to",
  "components.OperationFile.193459-1": "Folder Name",
  "components.OperationFile.193459-2": "File Name (Press Enter to Create)",
  "components.OperationFile.193459-3": "Press Enter to Create",
  "components.OperationFile.193459-4": "Create Folder",
  "components.OperationFile.193459-5": "Cancel",
  "components.OperationFile.193459-6": "Move",
  "components.OperationFile.193459-7": "Copy",
  "components.OperationFile.193459-8": "Action",
  "components.OperationFile.193459-9": "Failed to fetch data!",
  "components.OperationFile.193459-10": "Failed to fetch file!",
  "components.OperationFile.193459-11": "Are you sure you want to {0} to folder {1}?",
  "components.OperationFile.193459-12": "Operation failed!",
  "components.OperationFile.193459-13": "Operation successful!",
  "components.OperationFile.193459-14": "Operation failed!",
  "components.OperationFile.193459-15": "Already at the top level!",
  "components.TableView.193459-0": "File Name",
  "components.TableView.193459-1": "Selected",
  "components.TableView.193459-2": "Items",
  "components.TableView.193459-3": "Download",
  "components.TableView.193459-4": "Move",
  "components.TableView.193459-5": "Copy",
  "components.TableView.193459-6": "Delete",
  "components.TableView.193459-7": "Select All",
  "components.TableView.193459-8": "Creation Time",
  "components.TableView.193459-9": "Size",
  "components.TableView.193459-10": "Creator",
  "components.TableView.193459-11": "Action",
  "components.TableView.193459-12": "Rename",
  "components.TableView.193459-13": "Replace",
  "components.TableView.193459-14": "Please ensure the names are consistent",
  "components.TableView.193459-15": "This action will replace the file, it cannot be undone. Are you sure you want to continue?",
  "components.TableView.193459-16": "Prompt",
  "components.TableView.193459-17": "Confirm",
  "components.TableView.193459-18": "Cancel",
  "components.TableView.193459-19": "An error occurred, please try again!",
  "components.TableView.193459-20": "File address error!",
  "components.TableView.193459-21": "Are you sure you want to delete the selected files, folders, and all sub-items?",
  "components.TableView.193459-22": "Are you sure you want to delete 【{0}】?",
  "components.TableView.193459-23": "Operation failed!",
  "components.TableView.193459-24": "Operation successful!",
  "components.TableView.193459-25": "Please select at least one file or folder!",
  "components.TableView.193459-26": "Configuration error!",
  "components.TableView.193459-27": "File downloading...",
  "components.TableView.193459-28": "Download error, please contact the administrator!",
  "components.TableView.193459-29": "Download successful!",
  "components.TableView.193459-30": "Download failed!",
  "components.FolderView.193460-0": "Select All",
  "components.FolderView.193460-1": "Selected",
  "components.FolderView.193460-2": "Items",
  "components.FolderView.193460-3": "Download",
  "components.FolderView.193460-4": "Move",
  "components.FolderView.193460-5": "Copy",
  "components.FolderView.193460-6": "Delete",
  "components.FolderView.193460-7": "No files available",
  "components.FolderView.193460-8": "Rename",
  "components.FolderView.193460-9": "Replace",
  "components.FolderView.193460-10": "Please ensure the names are consistent",
  "components.FolderView.193460-11": "This action will replace the file, it cannot be undone. Are you sure you want to continue?",
  "components.FolderView.193460-12": "Prompt",
  "components.FolderView.193460-13": "Confirm",
  "components.FolderView.193460-14": "Cancel",
  "components.FolderView.193460-15": "Operation failed!",
  "components.FolderView.193460-16": "Operation successful!",
  "components.FolderView.193460-17": "An error occurred, please try again!",
  "components.FolderView.193460-18": "File address error!",
  "components.FolderView.193460-19": "Are you sure you want to delete the selected files, folders, and all sub-items?",
  "components.FolderView.193460-20": "Are you sure you want to delete 【{0}】?",
  "components.FolderView.193460-21": "Please select at least one file or folder!",
  "components.FolderView.193460-22": "Configuration error!",
  "components.FolderView.193460-23": "File downloading...",
  "components.FolderView.193460-24": "Download error, please contact the administrator!",
  "components.FolderView.193460-25": "Download successful!",
  "components.FolderView.193460-26": "Download failed!",
  "components.Header.193459-0": "Upload File",
  "components.Header.193459-1": "Folder",
  "components.Header.193459-2": "Link",
  "components.Header.193459-3": "Placeholder",
  "components.Header.193459-4": "Search File Name",
  "components.Header.193459-5": "Search",
  "components.Header.193459-6": "List Mode",
  "components.Header.193459-7": "Thumbnail Mode",
  "components.Header.193459-8": "All",
  "components.Header.193459-9": "Documents",
  "components.Header.193459-10": "Images",
  "components.Header.193459-11": "Videos",
  "components.Header.193459-12": "Models",
  "components.Header.193459-13": "Other",
  "components.Header.193459-14": "Failed to fetch files!",
  "components.Header.193459-15": "Operation failed!",
  "components.Header.193459-16": "Operation successful!",
  "components.CreatePlaceholder.193460-0": "Please enter name",
  "components.CreatePlaceholder.193460-1": "Cancel",
  "components.CreatePlaceholder.193460-2": "Confirm",
  "components.CreatePlaceholder.193460-3": "Add Placeholder",
  "components.CreatePlaceholder.193460-4": "Rename",
  "netDisk.CommonNetDisk.193460-0": "Failed to fetch data!",
  "netDisk.CommonNetDisk.193460-1": "Failed to fetch file!",
  "netDisk.CommonNetDisk.193460-2": "Failed to open!",
  "netDisk.CommonNetDisk.193460-3": "Unable to open this file format!",
  "netDisk.CommonNetDisk.193460-4": "DWG file conversion in progress, please try again later!",
  "netDisk.CommonNetDisk.193460-5": "Please try again later!",
  "components.ActionMenu.193460-0": "Download",
  "components.ActionMenu.193460-1": "Share",
  "components.ActionMenu.193460-2": "Move",
  "components.ActionMenu.193460-3": "Rename",
  "components.ActionMenu.193460-4": "Edit",
  "components.ActionMenu.193460-5": "Replace",
  "components.ActionMenu.193460-6": "Delete",
  "components.LinkModel.193459-0": "Name",
  "components.LinkModel.193459-1": "Please enter name",
  "components.LinkModel.193459-2": "Address",
  "components.LinkModel.193459-3": "Cancel",
  "components.LinkModel.193459-4": "Confirm",
  "components.LinkModel.193459-5": "Cannot be empty",
  "components.LinkModel.193459-6": "Add Link",
  "components.LinkModel.193459-7": "Edit Link",
  "components.LinkModel.193459-8": "Please enter address",
  "components.FileUpload.716311-0": "Drag and drop to upload",
  "components.FileUpload.716311-1": "or",
  "components.FileUpload.716311-2": "Click to upload",
  "components.FileUpload.716311-3": "File Upload List",
  "components.FileUpload.716311-4": "Upload successful!",
  "components.FileUpload.716311-5": "Upload File",
  "components.FileUpload.716311-6": "An error occurred, please try again!",
  "components.PlusFile.648379-0": "Add",
  "components.PlusFile.648379-1": "Quality Control",
  "components.PlusFile.648379-2": "Cancel",
  "components.PlusFile.648379-3": "Confirm"

}