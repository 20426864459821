/*
 * @Author: qinmengyuan
 * @Date: 2024-08-27 10:02:55
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-06 19:07:37
 * @FilePath: \dataview-viewer-test\src\views\todo\lang\zh.js
 */
export default {
	'leftContent.LeftContent.146055-0': '待办事项',
	'leftContent.LeftContent.146055-1': '项',
	'leftContent.LeftContent.146055-2': '搜索关键词',
	'leftContent.LeftContent.146055-3': '已读',
	'leftContent.LeftContent.146055-4': '未读',
	'leftContent.LeftContent.146055-5': '暂无待办',
	'leftContent.LeftContent.146055-6': '共',
	'leftContent.LeftContent.146055-7': '条数据',
	'leftContent.LeftContent.146055-8': '列表',
	'leftContent.LeftContent.146055-9': '日',
	'leftContent.LeftContent.146055-10': '周',
	'leftContent.LeftContent.146055-11': '月',
	'leftContent.LeftContent.146055-12': '序号', // No.
	'leftContent.LeftContent.146055-13': '审批编号',
	'leftContent.LeftContent.146055-14': '所属项目',
	'leftContent.LeftContent.146055-15': '功能名称',
	'leftContent.LeftContent.146055-16': '通知内容',
	'leftContent.LeftContent.146055-17': '上一步处理人',
	'leftContent.LeftContent.146055-18': '抄送人',
	'leftContent.LeftContent.146055-19': '当前处理人',
	'leftContent.LeftContent.146055-20': '流程状态',
	'leftContent.LeftContent.146055-21': '截止日期', // main.Header.362249-9
	'leftContent.LeftContent.146055-22': '逾期情况',
	'leftContent.LeftContent.146055-23': '抄送日期',
	'leftContent.LeftContent.146055-24': '阅读状态',
	'leftContent.LeftContent.146055-25': '处理日期',
	'leftContent.LeftContent.146055-26': '您已完成所有审核！',
	'leftContent.LeftContent.146055-27': '今天{0}',
	'leftContent.LeftContent.146055-28': '昨天{0}',
	'leftContent.LeftContent.146055-29': '明天{0}',
	'leftContent.LeftContent.146055-30': '逾期{0}天',
	'leftContent.LeftContent.146055-31': '天',
	'leftContent.LeftContent.146055-32': '待处理',
	'leftContent.LeftContent.146055-33': '我发起的',
	'leftContent.LeftContent.146055-34': '抄送我的',
	'leftContent.LeftContent.146055-35': '已处理',
	'leftContent.FlowFinishPop.107297-0': '{name}的{object}流程',
	'leftContent.FlowFinishPop.107297-1': '流程',
	'leftContent.FlowFinishPop.107297-2': '提交成功',
	'leftContent.FlowFinishPop.107297-3': 's 后自动关闭弹窗，也可点击空白处关闭。',
	'leftContent.FlowFinishPop.107297-4': '关闭弹窗',
	'leftContent.FlowFinishPop.107297-5': '处理下一条数据',
	'leftContent.FilterModal.857271-0': '审批编号',
	'leftContent.FilterModal.857271-1': '所属项目',
	'leftContent.FilterModal.857271-2': '功能名称',
	'leftContent.FilterModal.857271-3': '通知内容',
	'leftContent.FilterModal.857271-4': '处理人',
	'leftContent.FilterModal.857271-5': '流程状态',
	'leftContent.FilterModal.857271-6': '开始日期',
	'leftContent.FilterModal.857271-7': '选择开始日期',
	'leftContent.FilterModal.857271-8': '结束日期',
	'leftContent.FilterModal.857271-9': '选择结束日期',
	'leftContent.FilterModal.857271-10': '查询',
	'leftContent.FilterModal.857271-11': '重置',
	'leftContent.FilterModal.857271-12': '退回',
	'leftContent.FilterModal.857271-13': '发起',
	'leftContent.FilterModal.857271-14': '审核中',
	'leftContent.FilterModal.857271-15': '完成',
	'leftContent.FilterModal.857271-16': '拒绝',
	'leftContent.FilterModal.857271-17': '作废',
	'components.alertItem.007190-0': '[系统]',
	'components.alertItem.007190-1': '项目：',
	'components.alertItem.007190-2': '公告',
	'components.alertItem.007190-3': '公报',
	'components.alertItem.007190-4': '决议',
	'components.alertItem.007190-5': '决定',
	'alertNotify.alertDetailPop.951902-0': '预警通知详情',
	'alertNotify.alertDetailPop.951902-1': '操作成功',
	'alertNotify.alertDetailPop.951902-2': '预警处理',
	'alertNotify.alertNotifications.762766-0': '预警通知',
	'alertNotify.alertNotifications.762766-1': '暂无预警',
	'alertNotify.alertNotifyPop.295431-0': '预警通知',
	'alertNotify.alertNotifyPop.295431-1': '搜索',
	'alertNotify.alertNotifyPop.295431-2': '全部已读',
	'alertNotify.alertNotifyPop.295431-3': '预警处理',
	'alertNotify.alertNotifyPop.295431-4': '已无未读数据',
	'shortCutMenu.shortcutMenu.823110-0': '快捷菜单',
	'shortCutMenu.shortcutMenu.823110-1': '暂无配置',
	'shortCutMenu.ShortcutMenuPop.233974-0': '设置',
	'shortCutMenu.ShortcutMenuPop.233974-1': '搜索菜单名称',
	'shortCutMenu.ShortcutMenuPop.233974-2': '暂无数据',
	'shortCutMenu.ShortcutMenuPop.233974-3': '快捷菜单',
	'shortCutMenu.ShortcutMenuPop.233974-4': '长按',
	'shortCutMenu.ShortcutMenuPop.233974-5': '拖动调整顺序',
	'shortCutMenu.ShortcutMenuPop.233974-6': '取消',
	'shortCutMenu.ShortcutMenuPop.233974-7': '确定',
	'systemNotice.systemDetailNotifyPop.053512-0': '系统公告',
	'systemNotice.systemDetailNotifyPop.053512-1': '回复公文',
	'systemNotice.systemDetailNotifyPop.053512-2': '回复对象',
	'systemNotice.systemDetailNotifyPop.053512-3': '内容',
	'systemNotice.systemDetailNotifyPop.053512-4': '请输入',
	'systemNotice.systemDetailNotifyPop.053512-5': '图片',
	'systemNotice.systemDetailNotifyPop.053512-6': '附件',
	'systemNotice.systemDetailNotifyPop.053512-7': '取消',
	'systemNotice.systemDetailNotifyPop.053512-8': '确定',
	'systemNotice.systemDetailNotifyPop.053512-9': '操作成功',
	'systemNotice.systemDetailNotifyPop.053512-10': '请输入内容',
	'systemNotice.systemDetailNotifyPop.053512-11': '请选择人员',
	'systemNotice.systemNotice.666307-0': '系统公告',
	'systemNotice.systemNotice.666307-1': '暂无公告',
	'systemNotice.systemNoticePop.286370-0': '系统公告',
	'systemNotice.systemNoticePop.286370-1': '通知公告',
	'systemNotice.systemNoticePop.286370-2': '搜索',
	'systemNotice.systemNoticePop.286370-3': '暂无数据',
	'systemNotice.systemNoticePop.286370-4': '通知',
	'systemNotice.systemNoticePop.286370-5': '系统',
	'systemNotice.systemNoticePop.286370-6': '全部已读',
	'systemNotice.systemNoticePop.286370-7': '回复公文',
	'systemNotice.systemNoticePop.286370-8': '回复对象',
	'systemNotice.systemNoticePop.286370-9': '内容',
	'systemNotice.systemNoticePop.286370-10': '请输入',
	'systemNotice.systemNoticePop.286370-11': '图片',
	'systemNotice.systemNoticePop.286370-12': '附件',
	'systemNotice.systemNoticePop.286370-13': '取消',
	'systemNotice.systemNoticePop.286370-14': '确定',
	'systemNotice.systemNoticePop.286370-15': '请输入内容',
	'systemNotice.systemNoticePop.286370-16': '请选择人员',
	'systemNotice.systemNoticePop.286370-17': '设置通知公告全部已读',
	'systemNotice.systemNoticePop.286370-18': '系统公告设置全部已读',
	'systemNotice.systemNoticePop.286370-19': '已无未读数据',
	'systemNotice.systemNoticePop.286370-20': '操作成功',
	'systemNotice.systemNoticePop.286370-21': '公告',
	'systemNotice.systemNoticePop.286370-22': '公报',
	'systemNotice.systemNoticePop.286370-23': '意见',
	'systemNotice.systemNoticePop.286370-24': '命令',
	'systemNotice.systemNoticePop.286370-25': '决定',
	'systemNotice.systemNoticePop.286370-26': '决议',
	'systemNotice.systemNoticePop.286370-27': '发布日期：',
	'components.popContentList.202539-5': '项目：',
	'components.popContentList.202539-6': '暂无数据',
	'components.popContentList.202539-7': '操作成功',
	'components.popContentMain.005741-0': '项目：',
	'components.popContentMain.005741-1': '发布人：',
	'components.popContentMain.005741-2': '发布时间：',
	'components.popContentMain.005741-3': '暂无数据',
	'components.popContentSider.441255-0': '设为已读',
	'components.popContentSider.441255-1': '我已阅读',
	'components.popContentSider.441255-2': '点击处理',
	'components.popContentSider.441255-3': '我已处理',
	'components.popContentSider.441255-4': '点击回复',
	'components.popContentSider.441255-5': '我已回复',
	'calendar.CalendarComponent.189440-0': '年',
	'calendar.CalendarComponent.189440-1': '月',
	'calendar.CalendarComponent.189440-2': '回到今天',
	'calendar.CalendarComponent.189440-3': '今天',
	'calendar.CalendarComponent.189440-4': '{0}日',
	'calendar.CalendarComponent.189440-5': '回到本周',
	'calendar.CalendarComponent.189440-6': '第{0}周',
	'calendar.CalendarComponent.189440-7': '回到本月',
	'calendar.CalendarComponent.189440-8': '{0}月',
	'calendar.CalendarComponent.189440-9': '今',
	'calendar.CalendarComponent.189440-10': '本',
	'calendar.CalendarComponent.189440-11': '周',
	'calendar.CalendarComponent.189440-12': '今日',
	'calendar.CalendarComponent.189440-13': '一',
	'calendar.CalendarComponent.189440-14': '二',
	'calendar.CalendarComponent.189440-15': '三',
	'calendar.CalendarComponent.189440-16': '四',
	'calendar.CalendarComponent.189440-17': '五',
	'calendar.CalendarComponent.189440-18': '六',
	'calendar.CalendarComponent.189440-19': '日',
	'calendar.CalendarComponent.189440-20': '周一',
	'calendar.CalendarComponent.189440-21': '周二',
	'calendar.CalendarComponent.189440-22': '周三',
	'calendar.CalendarComponent.189440-23': '周四',
	'calendar.CalendarComponent.189440-24': '周五',
	'calendar.CalendarComponent.189440-25': '周六',
	'calendar.CalendarComponent.189440-26': '周日'
};
