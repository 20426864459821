/*
 * @Description: 图表
 * @Author: luocheng
 * @Date: 2021-10-15 14:52:56
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-08-08 16:51:58
 */
// 图表
const charts = {
  type: 'charts',
  title: '图表组件',
  show: true,
  key: 2,
	order: 5,
  componentList: [
    {
      component: 'CommonChart',
      type: 'charts',
      name: '图表盒子',
      icon: 'icontubiao-tbhz',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
      },
      style: {
        minHeight: 0,
        height: '100%',
        width: '',
        rotate: 0,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16
      },
      chartConfig: {
        chartUUID: '', // 图表分析组件
      },
      paramsConfig: [], // 筛选参数
      actionConfig: []
    },
    {
      component: 'SimpleChart',
      type: 'container',
      name: '简单图表盒子',
      icon: 'icontubiao-tbhz',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
      },
      style: {
        minHeight: 0,
        height: '100%',
        width: '100%',
        rotate: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0
      },
      paramsConfig: [], // 筛选参数
      actionConfig: [],
      chartConfig: {
        style: {}
      }
    }
  ]
};

export default charts;