<!--
 * @Description: 基础容器
 * @Author: luocheng
 * @Date: 2021-08-18 13:48:12
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-10-29 16:29:44
-->
<template>
	<div
		class="common-container"
		:ref="element.id"
		:data-id="element.id"
		v-loading="statusConfig?.userLoading ? loading : false"
	>
		<ComponentBox
			v-for="child in element.children"
			:isPreview="isPreview"
			:key="child.id"
			:containerData="renderData"
			:fullData="fullData"
			@click.native.stop="onClickBox(child, 'click')"
			@dblclick.native.stop="onDblClick(child, 'dblclick')"
			@mouseleave.native.stop="onClickBox(child, 'mouseleave')"
			@mouseenter.native.stop="onClickBox(child, 'mouseenter')"
			:element="child"
			:pageId="pageId"
			:isGroup="isGroup"
			:groupComponents="groupComponents"
			:defaultTableSelectData="defaultTableSelectData"
			:componentList="componentList"
			:mapData="mapData"
		>
		</ComponentBox>
		<!-- 默认应当导出pdf 当 任存在问题，先默认为pdf -->
		<Spreadsheet
			v-if="showSheet"
			:type="'export'"
			:excelUuid="exportConfig.exportTemplate"
			:objectUuid="exportConfig.interfaceUrl || exportConfig.objectUUID"
			:viewUuid="exportConfig.viewUUID"
			:dataIds="'all'"
			:exportType="exportConfig.exportTypeSheet || 'pdf'"
			@exportSuccess="onExportSuccess"
			@exportError="onExportError"
			:extraParams="exportParam"
		></Spreadsheet>
	</div>
</template>

<script>
import { dataInterface, removeNullSearch } from '@/apis/data/index';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import {
	getComponentById,
	getQueryValue,
	initParams,
	doEEActionHandle,
	doFrontOperation,
	getLinkByTemplate,
	judgingEventLogic,
	getStatusVal,
	generateQuery,
	openUrl
} from '@/utils/tools';
import Spreadsheet from '@/custom-component/common/Entry';
import mixin from './mixins';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';

export default {
	name: 'CommonContainer',
	mixins: [mixin, databaseTriggerDebug],
	props: {
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: false
		},
		// 弹窗页面配置的ID
		pageId: {
			type: Number,
			required: false
		},
		// 循环映射值
		mapData: {
			type: Object,
			required: false,
			default: () => {}
		}
	},
	components: {
		Spreadsheet
	},
	data() {
		return {
			// 渲染数据
			renderData: [],
			// 完全渲染数据未格式化
			fullData: null,
			loading: false,
			// 导出配置
			exportConfig: {},
			// 导出
			showSheet: false,
			exportParam: {},
			// 定时请求
			intervalObj: null,
			intervalTimes: 0,
			// 事件timer
			timer: null
		};
	},
	inject: ['EDITOR_pageUUID'],
	computed: {
		...mapState(['componentData', 'signComponentData', '_PageCustomStatus', '_APPCustomStatus']),
		// 值来源
		valueOrigin() {
			return this.element && this.element.valueOrign;
		}
	},
	created() {
		this.doInterface();
		// 定时器
		this.setInterval();
	},
	watch: {
		valueOrigin(newV) {
			// 清空值
			// 数据源
			if (newV === 'database') {
				const { objectData, viewData } = this.element.database;
				if (objectData && viewData) {
					this.getRenderData(this.element?.database || {});
				}
			}
		}
	},
	mounted() {
		// 配置关联参数的容器才需要监听
		const databaseTrigger = {
			[this.element.id]: (data) => {
				// 测试代码
				// if (this.element.name.indexOf('统计-基础容器') > -1) {
				// 	console.log(data, '----参数变化触发请求', this.element.name, this.element);
				// }
				// 测试结束
				// if (this.element.id !== data.componentId) return;
				// 兼容树容器search，自我调用问题
				if (data.originComponent === this.element.id) return;
				let { paramsConfig, requestType } = this.element.database;
				if (!Array.isArray(paramsConfig)) return;
				// console.log(data, '--配置关联参数的容器才需要监听--');
				// 是否当前组件响应
				let isTarget = false;
				let configItem = null;
				let params = {};
				for (let i = 0; i < paramsConfig.length; i++) {
					if (
						(paramsConfig[i].componentId === data.componentId && this.isSameAction(requestType, data.action)) ||
						(data.componentId.indexOf('CommonTree') > -1 && data.parentId == this.element.id && data.isSearch) ||
						(data.componentId.indexOf('CommonForm') > -1 && data.isSearch && paramsConfig[i]?.key === 'search')
					) {
						isTarget = true;
						configItem = paramsConfig[i];
						params = {
							...params,
							...this.getParams(configItem, data)
						};
					}
				}

				if (!isTarget && !data.isInit && !data.isUpdate) return;
				this._startDataDebug();
				const {
					search = [],
					param = {},
					canPost
				} = initParams(
					this.element?.database?.paramsConfig || [],
					this.isGroup,
					this.subComponentData,
					this.groupComponents
				);
				if (!canPost) {
					this._failDataDebug('参数必填校验未通过');
					return;
				}
				if (this.element?.database?.mapping === 'interface') {
					this.getDataByUrl(this.element?.database, param, search, data.isInit);
				} else {
					this.getRenderData(this.element.database, param, search, data.isInit);
				}
			}
		};
		// 导出
		const exportData = {
			[this.element.id]: (exportConfig) => {
				if (!exportConfig || exportConfig.componentId !== this.element.id) return;
				this.exportConfig = exportConfig;
				this.doExport(exportConfig);
			}
		};
		// 行为
		const doComponentBehavior = {
			[this.element.id]: (config) => {
				const { component, list = [] } = config;
				if (this.element.id !== component) return false;
				list.forEach((ele) => {
					const { behaviors, params } = ele;
					const { param = {}, canPost } = initParams(params, false, this.subComponentData, [], this.EDITOR_pageUUID);
					if (canPost) {
						// 调用行为方法
						behaviors.forEach((funName) => {
							try {
								window.$EditorDebug.startBehavior(
									this.element,
									// containers.componentList.find(ele => ele.component === 'CommonContainer'),
									{},
									funName,
									param
								);
								eval(this[funName])(param);
							} catch (err) {
								console.log(err);
							}
						});
					}
				});
			}
		};
		const reRenderComponentView = {
			[this.element.id]: ({ componentId }) => {
				// 触发刷新
				if (componentId !== this.element?.database?.containerKey) return;
				this.refreshData();
			}
		};
		eventBus.$on('reRenderComponentView', reRenderComponentView[this.element.id]);
		eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
		eventBus.$on('exportData', exportData[this.element.id]);
		eventBus.$on('doComponentBehavior', doComponentBehavior[this.element.id]);
	},
	methods: {
		// 行为开始
		/**
		 * @description: 改变背景颜色
		 * @param {Object} param
		 */
		changeBackground(param) {
			if (!param) return;
			const domRef = this.$refs?.[this.element.id];
			if (!domRef) return;
			const { color } = param;
			if (color) {
				domRef.style.backgroundColor = color;
			}
		},
		/**
		 * @description: 改变字体
		 * @param {Object} param
		 */
		changeFont(param) {
			if (!param) return;
			const domRef = this.$refs?.[this.element.id];
			if (!domRef) return;
			const { color, fontSize, fontWeight } = param;
			if (color) {
				domRef.style.color = color;
			}
			if (fontSize) {
				domRef.style.fontSize = isNaN(+fontSize) ? fontSize : `${fontSize}px`;
			}
			if (fontWeight) {
				domRef.style.fontWeight = fontWeight;
			}
		},
		/**
		 * @description: 刷新数据
		 */
		refreshData() {
			this.doInterface(true);
		},
		// 行为结束
		/**
		 * @description: 执行请求
		 */
		doInterface(isRefresh = false) {
			if (!this.interfaceFinished) return;
			this._initDataDebug();
			this.interfaceFinished = false;
			const { objectData, viewData, mapping } = this.element?.database;
			if (mapping === 'interface') {
				if (!this.element?.database?.interfaceConfig) return;
				const { url, method } = this.element.database?.interfaceConfig || {};
				if (!url || !method) {
					return;
				}
				// 接口请求
				const {
					search = [],
					param = {},
					canPost
				} = initParams(
					this.element?.database?.paramsConfig || [],
					this.isGroup,
					this.subComponentData,
					this.groupComponents,
					this.EDITOR_pageUUID
				);
				if (!canPost) {
					this._failDataDebug('参数必填校验未通过');
					return;
				}
				this.getDataByUrl(this.element?.database, param, search, false, isRefresh);
			} else {
				// 数仓相关
				if (objectData && viewData) {
					const {
						search = [],
						param = {},
						canPost
					} = initParams(
						this.element?.database?.paramsConfig || [],
						this.isGroup,
						this.subComponentData,
						this.groupComponents,
						this.EDITOR_pageUUID
					);
					if (canPost) {
						this.getRenderData(this.element.database, param, search, false, isRefresh);
					}
				} else if (this.element.database?.requestType === 'moreAction') {
					const { statisticalConfig } = this.element.database;
					if (statisticalConfig && statisticalConfig.length) {
						this.getRenderData(this.element.database, {}, [], false, isRefresh);
					}
				}
			}
		},
		//标记点击写入标记容器内标记暴露值resolveId
		setTemplateId(ele) {
			const idArr = ele.id.split('-');
			let pageId = `${idArr[idArr.length - 2]}-${idArr[idArr.length - 1]}`;
			const findData = this.signComponentData.filter((v) => v.id.includes(pageId));
			if (findData.length) {
				const findDataIdArr = findData[0].id.split('-');
				let signTemplate = getComponentById(this.componentData, `${findDataIdArr[0]}-${findDataIdArr[1]}`);
				if (signTemplate) {
					this.$store.commit('modifyComponent', {
						component: {
							...signTemplate,
							resolveId: findDataIdArr[3]
						},
						containerId: null,
						isModify: true
					});
					// 清除resolveId,暂时采用定时器
					setTimeout(() => {
						this.$store.commit('modifyComponent', {
							component: {
								...signTemplate,
								resolveId: ''
							},
							containerId: null,
							isModify: true
						});
					}, 2000);
				}
			}
		},
		/**
		 * @desc: 触发点击事件
		 * @param {Object} item 点击的组件(可能需要兼容)
		 * 触发方式实质就是在改变指定的组件状态
		 * 目前覆盖点击事件
		 */
		async onClickBox(item, eventType) {
			window.$EditorDebug.startEvent(item, eventType);
			if (!item?.events?.[eventType]) return;
			if (item.id.includes('-page')) {
				eventBus.$emit('signResolve', this.element);
				this.setTemplateId(item);
			}
			eventBus.$emit('EDITOR_loopResolve', item.loopData);
			const excludesEvents = ['CommonDateButton'];
			if (excludesEvents.includes(item.component)) {
				return false;
			}
			if (!item.events || Array.isArray(item.events)) return;
			if (this.timer) {
				clearTimeout(this.timer);
			}
			const click = item.events?.[eventType] || {};
			if (item.events?.dblclick) {
				this.timer = setTimeout(() => {
					this.triggerEvent(item, click, eventType);
				}, 300);
			} else {
				this.triggerEvent(item, click, eventType);
			}
		},
		/**
		 * @description: 双击
		 * @param {Object} item 点击的组件
		 */
		onDblClick(item, eventType) {
			window.$EditorDebug.startEvent(item, 'dblclick');
			if (!item?.events?.[eventType]) return;
			eventBus.$emit('EDITOR_loopResolve', item.loopData);
			const excludesEvents = ['CommonDateButton'];
			if (excludesEvents.includes(item.component)) {
				return false;
			}
			if (!item.events || Array.isArray(item.events)) return;
			const dblclick = item.events.dblclick || {};
			if (this.timer) {
				clearTimeout(this.timer);
			}
			this.triggerEvent(item, dblclick, 'dblclick');
		},
		/**
		 * @description: 触发事件
		 */
		async triggerEvent(item, click, eventType = 'click') {
			const { specialEventList = [], pattern, frontOperation = [] } = click || {};
			let canNext = true;
			if (frontOperation && frontOperation.length) {
				// 触发预处理事件、行为
				window.$EditorDebug.startFrontOperation(item);
				canNext = await doFrontOperation(frontOperation, {
					isGroup: this.isGroup,
					componentList: this.subComponentData,
					componentData: this.componentData,
					groupComponents: this.groupComponents
				});
				window.$EditorDebug.resultFrontOperation(canNext, item);
			}
			if (!canNext) {
				console.log(this.$t('components.OperationFile.193459-12'));
				this.$message.error(this.$t('components.OperationFile.193459-12'));
				return false;
			}
			if (!pattern) {
				// 普通事件
				this.triggerEventItem(click, item, eventType);
			} else if (pattern === 'special') {
				specialEventList.forEach((ele) => {
					const { actionType } = ele;
					if (actionType === 'pageStatus') {
						this.updatePageStatus(ele.stateMachine || []);
					} else if (actionType === 'pageAction') {
						this.triggerEventItem(ele, item, eventType);
					} else if (actionType === 'componentBehavior') {
						//触发组件行为
						setTimeout(() => {
							this.triggerEventItem(ele, item, eventType);
						}, 0);
					} else if (actionType === 'eeAction') {
						this.triggerEventItem(ele, item, eventType);
					}
				});
			}
		},
		/**
		 * @description: 触发全局loading
		 */
		triggerGlobalLoading(isClose) {
			eventBus.$emit('dataviewGlobalLoading', {
				loading: isClose
			});
		},
		/**
		 * @description: 触发单个事件
		 * @param {Object} ele 当前数据对象
		 * @param {Object} item 当前数据对象
		 * @param {String} eventType 操作类型
		 * @return {void}
		 */
		triggerEventItem(ele, item, eventType = 'click') {
			const {
				actionType,
				effects = [],
				behaviors,
				linkType,
				queryList = [],
				linkUrl = '',
				pageId,
				linkTemplate,
				stateMachine
			} = ele || {};
			if (actionType === 'eeAction') {
				// 触发后端事件
				window.$EditorDebug.startEEAction(item);
				const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } = ele;
				if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
					this.$message.error('事件配置错误！');
					window.$EditorDebug.failEEAction(item);
					return false;
				}
				this.loading = true;
				if (this.statusConfig.globalLoading) {
					this.triggerGlobalLoading(true);
				}
				const sourceParams = this.getBindParams(item);
				// 蓝图
				if (eeType === 'blueprint') {
					dataInterface(sourceParams, `/api${interfaceUrl}`)
						.then((res) => {
							if (res.status === 200 && res.data.code === 200) {
								this.$message.success('操作成功！');
								window.$EditorDebug.successEEAction(item, `/api${interfaceUrl}`);
								doEEActionHandle(res.data?.__adds__);
							}
							this.loading = false;
							if (this.statusConfig.globalLoading) {
								this.triggerGlobalLoading(false);
							}
						})
						.catch(() => {
							window.$EditorDebug.errorEEAction(item, `/api${interfaceUrl}`);
							this.loading = false;
							if (this.statusConfig.globalLoading) {
								this.triggerGlobalLoading(false);
							}
						});
					return true;
				}
				// 数仓
				const data_id = sourceParams?.data_id;
				dataInterface({
					__method_name__: 'customEventCall',
					object_uuid: objectUUID,
					view_uuid: viewUUID,
					...sourceParams,
					data_id, // 参数配置
					event: eventName
				})
					.then((res) => {
						if (res.status === 200 && res.data.code === 200) {
							this.$message.success('操作成功！');
							window.$EditorDebug.successEEAction(item, eventName);
							doEEActionHandle(res.data?.__adds__);
						}
						this.loading = false;
						if (this.statusConfig.globalLoading) {
							this.triggerGlobalLoading(false);
						}
					})
					.catch(() => {
						this.loading = false;
						if (this.statusConfig.globalLoading) {
							this.triggerGlobalLoading(false);
						}
						window.$EditorDebug.errorEEAction(item, eventName);
					});
				return true;
			} else if (actionType === 'jumpPage') {
				const query = {};
				queryList.forEach((queryItem) => {
					let component = getComponentById(this.componentData, queryItem.componentId);
					this.$set(query, queryItem.key, component?.resolveData?.[queryItem.feild]);
				});
				const queryStr = generateQuery(query);
				if (linkType === 'projectPage') {
					window.$EditorDebug.jumpPage(1, pageId, query);
					this.$router.push({
						path: pageId,
						query
					});
					return;
				} else if (linkType === 'outPage') {
					if (window.EDITORUseDebug) {
						window.$EditorDebug.jumpPage(2, linkUrl);
					}
					openUrl(linkUrl, linkUrl);
				} else if (linkType === 'custom') {
					// if (!this.mapData?.renderData) return;
					const customLink = getLinkByTemplate(linkTemplate, this.mapData?.renderData || {});
					window.$EditorDebug.jumpPage(3, `${customLink}${queryStr}`);
					openUrl(`${customLink}${queryStr}`, queryStr);
				}
			} else if (actionType === 'export') {
				// 导出
				window.$EditorDebug.baseEvent(`<p>开始执行<i>全局导出事件</i></p>`);
				this.onExport(item?.events?.[eventType]);
			} else if (actionType === 'pageStatus') {
				// 修改页面状态
				this.updatePageStatus(stateMachine || [], item);
			} else {
				// 页面事件
				effects.forEach((effect) => {
					this.$store.commit('triggerEvents', {
						config: {
							...ele,
							...effect
						},
						element: this.element,
						EDITOR_pageUUID: this.EDITOR_pageUUID
					});
				});
			}
			// 行为
			if (behaviors && Array.isArray(behaviors)) {
				if (behaviors.length === 1) {
					const { component, list } = behaviors[0];
					if (!component) {
						return;
					}
					if (list.length === 1) {
						const behaviorsList = list[0].behaviors;
						if (!behaviorsList.length) {
							return;
						}
					}
				}
				behaviors.forEach((behavior) => {
					if (behavior.component) {
						this.$store.commit('triggerEvents', {
							config: {
								behavior,
								isBehavior: true
							},
							element: item,
							EDITOR_pageUUID: this.EDITOR_pageUUID
						});
					}
				});
			}
		},
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr = [], element) {
			window.$EditorDebug.startPageStatus(element);
			let componentData = this.sContainer ? this.originComponentData : this.componentData;
			arr.forEach((ele) => {
				if (!judgingEventLogic(ele.logics, componentData)) {
					window.$EditorDebug.failPageStatus(element, ele.code);
					return;
				}
				const { value } = getStatusVal(ele, componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			});
		},
		/**
		 * @desc: 触发导出事件
		 */
		onExport(config) {
			if (!config) return false;
			eventBus.$emit('exportData', {
				...config,
				componentId: config.exportTarget,
				action: 'export'
			});
		},
		/**
		 * @description: 根据接口获取数据
		 */
		getDataByUrl(database, param = {}, search = [], isInit = false, isRefresh) {
			if (!database) return;
			const { interfaceConfig } = database;
			if (!interfaceConfig) return;
			//  method = 'POST', action 暂未启用
			const { url } = interfaceConfig;
			// 注入的参数
			let outParams = {};
			if (this.element.database.userOutParams) {
				outParams = sessionStorage.getItem(
					`dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
				);
				outParams = outParams ? JSON.parse(outParams) : {};
			}
			// if (!url.indexOf('http')) {
			//   // url = `${window.origin}${url}`;
			//   // 测试
			//   url = `https://dwh_api.bimcc.net${url}`;
			// }
			let requestUrl = `${url}`;
			if (url && url.indexOf('http') === -1) {
				requestUrl = `/api${url}`;
			}
			dataInterface(
				{
					search,
					...param,
					...outParams
				},
				requestUrl
			)
				.then((res) => {
					this.formatResData(res, database, isInit, outParams, isRefresh);
					this.loading = false;
					if (this.statusConfig.globalLoading) {
						this.triggerGlobalLoading(false);
					}
					this.interfaceFinished = true;
					this._successDataDebug({
						url: requestUrl,
						content: {
							search,
							...param,
							...outParams
						},
						res
					});
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
					if (this.statusConfig.globalLoading) {
						this.triggerGlobalLoading(false);
					}
					this.interfaceFinished = true;
					this._successDataDebug({
						url: requestUrl,
						content: {
							search,
							...param,
							...outParams
						},
						err
					});
				});
		},
		/**
		 * @desc: 获取数据渲染时候的数据
		 */
		getRenderData(database, params, search = [], isInit = false, isRefresh) {
			this.loading = true;
			if (this.statusConfig.globalLoading) {
				this.triggerGlobalLoading(true);
			}
			if (database && database.requestType === 'moreAction') {
				// 数据统计逻辑
				this.getStaticsData(database, isInit);
				this.loading = false;
				if (this.statusConfig.globalLoading) {
					this.triggerGlobalLoading(false);
				}
				this.interfaceFinished = true;
				return;
			}
			// 注入的参数
			let outParams = {};
			if (this.element.database.userOutParams) {
				outParams = sessionStorage.getItem(
					`dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
				);
				outParams = outParams ? JSON.parse(outParams) : {};
			}
			// 请求方式  dataList dataInfo updateData createData deleteData relationList moreAction
			if (!database.objectData) {
				this.loading = false;
				if (this.statusConfig.globalLoading) {
					this.triggerGlobalLoading(false);
				}
				this.interfaceFinished = true;
				return;
			}
			if (!database.viewData && !database.relationData) {
				this.loading = false;
				if (this.statusConfig.globalLoading) {
					this.triggerGlobalLoading(false);
				}
				this.interfaceFinished = true;
				return;
			}
			let __method_name__ = database.requestType;
			const mapping = database.mapping;
			let paramObj = null;
			if (mapping === 'object') {
				paramObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					search,
					...params,
					...outParams
				};
			} else if (mapping === 'relation') {
				// 关联关系请求方式，后端方面基本已废弃（复杂度）
				__method_name__ = 'relationList';
				paramObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					search,
					...params,
					...outParams
				};
			}
			//标记取值详情ID
			if (__method_name__ === 'dataInfo' && (this.element.id.includes('-view') || this.element.id.includes('-page'))) {
				const arr = this.element.id.split('-');
				const idLastIndex = arr.findIndex((v) => v.includes('view') || v.includes('page'));
				if (idLastIndex !== arr.length - 1) {
					paramObj.data_id = arr[idLastIndex + 1];
				}
			}
			//标记取值详情ID结束
			dataInterface(paramObj)
				.then((res) => {
					this.formatResData(res, database, false, {}, isRefresh);
					this.loading = false;
					if (this.statusConfig.globalLoading) {
						this.triggerGlobalLoading(false);
					}
					this.interfaceFinished = true;
					this._successDataDebug({
						url: '',
						content: paramObj,
						res
					});
				})
				.catch((err) => {
					console.log(err, '-----err');
					this.loading = false;
					if (this.statusConfig.globalLoading) {
						this.triggerGlobalLoading(false);
					}
					this.interfaceFinished = true;
					this._successDataDebug({
						url: '',
						content: paramObj,
						err
					});
				});
		},
		/**
		 * @description: 处理容器数据 处理请求数据
		 * @param {Object/null} res
		 */
		formatResData(res, database, isInit, outParams, isRefresh) {
			if (!res || Object.prototype.toString.call(res) !== '[object Object]') return;
			if (res.status !== 200) return;
			const { mapping = '', requestType = '' } = database;
			let metadata = {};
			if (['dataList', 'relationList'].includes(requestType)) {
				// 列表
				this.renderData = this.getListFromRes(res) || [];
			} else if (requestType === 'dataInfo') {
				// 详情
				this.renderData = res.data.data || {};
			}
			if (mapping === 'interface') {
				this.renderData = res.data.data || {};
			}
			this.element.containerData = this.renderData;
			// 暴露单一对象
			if (this.statusConfig?.resolveObject && Array.isArray(this.renderData)) {
				this.element.resolveData = this.renderData?.[0];
			} else {
				this.element.resolveData = this.renderData;
			}
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element,
				resolveData: this.element.resolveData
			});
			setTimeout(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					originComponent: this.element.id,
					isSearch: true,
					action: 'any',
					output: this.renderData
				});
			}, 0);
			// 完全数据
			this.fullData = res.data.data || {};
			const fieldList = [];
			if (mapping === 'interface') {
				let dataObj = null;
				if (Array.isArray(this.renderData)) {
					dataObj = this.renderData[0];
				} else {
					dataObj = this.renderData;
				}
				if (Object.prototype.toString.call(dataObj) === '[object Object]') {
					for (let key in dataObj) {
						metadata[key] = key;
					}
				}
			} else {
				// 数仓直接使用  metadata
				metadata = res.data?.metadata || {};
			}
			// 保存容器数据
			for (let key in metadata) {
				fieldList.push({
					name: metadata[key],
					uuid: key
				});
			}
			if (this.element.id.includes('-page') && this.element.pageUUid) {
				//标记的数据修改
				const idArr = this.element.id.split('-');
				const searchId = `${idArr[0]}-${idArr[1]}`;
				const component = getComponentById(this.componentData, searchId);
				if (component) {
					this.$store.commit('modifyComponent', {
						component: {
							...component,
							containerData: this.renderData,
							fullData: this.fullData,
							metadata,
							database: {
								...this.element.database,
								fieldList
							}
						},
						containerId: null,
						isModify: true,
						pageUUID: this.element.id.includes('-page') ? '' : this.EDITOR_pageUUID,
						type: 1
					});
				}
			}
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					containerData: this.renderData,
					fullData: this.fullData,
					metadata,
					database: {
						...this.element.database,
						fieldList
					}
				},
				containerId: null,
				isModify: true,
				pageUUID: this.element.id.includes('-page') ? '' : this.EDITOR_pageUUID,
				type: 1
			});
			if (isRefresh) {
				console.log('需要去刷新对应绑定值------');
				eventBus.$emit('reRenderComponentView', {
					componentId: this.element.id
				});
			}
			// 编辑器情况
			if (
				isInit &&
				this.element.database.userOutParams &&
				outParams &&
				this.$route.path === '/modify-page' &&
				!this.pageId
			) {
				// 详情页面需要初始化
				this.$store.commit('initDetailsPage', {
					targetContainer: this.element,
					fullData: this.fullData,
					containerData: this.renderData,
					metadata
				});
			}
		},
		/**
		 * @desc: 获取统计结果
		 * @param {array} database 数据仓库配置
		 * @param {boolean} isInit 是否为初始化
		 */
		getStaticsData(database, isInit = false) {
			const { statisticalConfig } = database;
			const {
				search = [],
				param = {},
				canPost
			} = initParams(
				this.element?.database?.paramsConfig || [],
				this.isGroup,
				this.subComponentData,
				this.groupComponents,
				this.EDITOR_pageUUID
			);
			if (!canPost) {
				this._failDataDebug('参数必填校验未通过');
				return;
			}
			if (!Array.isArray(statisticalConfig)) {
				this.$message.error('获取统计数据失败！');
				this._failDataDebug('获取统计数据失败！');
				return;
			}
			const data = {};
			const metadata = {};
			const fieldList = [];
			statisticalConfig.forEach((ele) => {
				const paramResult = removeNullSearch({
					__method_name__: 'globalFunctionCall',
					typeName: 'PublicFunc',
					type: 'value',
					funcName: 'ViewAggregate',
					payload: {
						field_uuid: ele.field_uuid,
						view_uuid: ele.view_uuid,
						aggregate: ele.aggregate,
						need_search: !!ele.need_search,
						is_group: !!ele.is_group
					},
					search,
					...param
				});
				this.$set(data, ele.key, paramResult);
				this.$set(metadata, ele.key, ele.key);
				fieldList.push({
					name: ele.key,
					uuid: ele.key
				});
			});
			dataInterface({
				__method_name__: 'moreAction',
				data
			})
				.then((res) => {
					if (res.status === 200) {
						this.renderData = res?.data?.data || {};
						// 完全数据
						this.fullData = this.renderData || {};
						this.element.resolveData = this.renderData;
						this.$store.commit('updatePageCustomStatus', {
							origin: this.element,
							resolveData: this.renderData
						});
						this.element.resolveData = this.renderData || {};
						// 保存容器数据
						this.$store.commit('modifyComponent', {
							component: {
								...this.element,
								containerData: this.renderData,
								fullData: this.fullData,
								metadata: isInit ? metadata : this.element.metadata,
								database: {
									...this.element.database,
									fieldList
								}
							},
							containerId: null,
							isModify: true,
							pageUUID: this.EDITOR_pageUUID
						});
					}
				})
				.catch((err) => {
					console.log(err, '----统计失败！！！！');
				});
		},
		/**
		 * @desc: 获取请求参数
		 * @param {Array} config 请求参数配置
		 */
		getParams(config, data) {
			if (!config || !data?.output) return {};
			let result = {};
			for (let key in data.output) {
				if (key === config.paramKey) {
					result[config.key] = data.output[key];
				}
			}
			return result;
		},
		/**
		 * @desc: 根据请求返回获取列表结构
		 * @param {Object} res 请求返回的数据
		 */
		getListFromRes(res) {
			if (Array.isArray(res?.data)) {
				return res.data;
			}
			if (typeof res === 'object' && res.data) {
				return this.getListFromRes(res.data);
			}
			return [];
		},
		/**
		 * @desc: 判断前后端操作标识是否已知
		 * @param {String} beAction 后端标识
		 * @param {String} feAction 前端标识
		 * @return {Boolean}
		 */
		isSameAction(beAction, feAction) {
			if (feAction === 'any') return true;
			const feToBe = {
				list: 'dataList',
				add: 'createData',
				detail: 'dataInfo',
				modify: 'updateDate',
				delete: 'deleteData'
			};
			return beAction === feAction || beAction === feToBe[feAction];
		},
		/**
		 * @desc: 后端事件获取绑定参数
		 * @param {Object} comp 组件数据
		 * @return {Object}
		 */
		getBindParams(comp) {
			if (!comp) {
				window.$EditorDebug.descEEActionParam(1);
				return {};
			}
			window.$EditorDebug.descEEActionParam(2);
			const sourceConfig = comp.sourceConfig || [];
			const sourceParams = {};
			for (let i = 0; i < sourceConfig.length; i++) {
				const {
					componentId,
					field,
					key,
					originType = '',
					urlParamKey = '',
					systemKey = '',
					systemCode = '',
					fixedValue = '',
					statusCode = ''
				} = sourceConfig[i];
				if (originType === 'url' && urlParamKey) {
					// 从url获取参数
					const result = getQueryValue(urlParamKey);
					this.$set(sourceParams, key, result);
				} else if (originType === 'system') {
					// 系统参数
					try {
						let obj = sessionStorage.getItem(systemKey);
						if (!obj) {
							obj = localStorage.getItem(systemKey);
						}
						if (!obj) break;
						const result = JSON.parse(obj);
						if (result && Object.prototype.toString.call(result) === '[object Object]') {
							const queryVal = result[systemCode];
							this.$set(sourceParams, key, queryVal);
						}
					} catch (err) {
						console.log(err, '99999999');
					}
				} else if (originType === 'fixed') {
					// 固定值
					this.$set(sourceParams, key, fixedValue);
				} else if (originType === 'pageStatus') {
					// 页面状态
					const statusCodeValue =
						this._PageCustomStatus[statusCode] === undefined
							? this._APPCustomStatus[statusCode] || null
							: this._PageCustomStatus[statusCode];
					this.$set(sourceParams, key, statusCodeValue || '');
				} else if (componentId && field && key) {
					// 普通从组件获取
					let sourceComponent = getComponentById(this.subComponentData, componentId);
					if (!sourceComponent && this.isGroup && this.groupComponents.length) {
						sourceComponent = getComponentById(this.groupComponents, componentId);
					}
					const componentName = componentId.toString().split('-')[0];
					if (
						field === 'DATAVIEWSELECT' &&
						['CommonTree', 'CommonTableContainer', 'CommonLoopContainer'].includes(componentName)
					) {
						this.$set(sourceParams, key, sourceComponent?.resolveData || []);
					} else {
						if (Array.isArray(sourceComponent?.resolveData)) {
							const result = sourceComponent?.resolveData.map((item) => item?.[field] || '');
							this.$set(sourceParams, key, result);
						} else {
							const result = sourceComponent?.resolveData?.[field] || '';
							this.$set(sourceParams, key, result);
						}
					}
				}
			}
			window.$EditorDebug.successEEActionParam(sourceParams, comp.sourceConfig);
			return sourceParams;
		},
		/**
		 * @desc: 导出
		 * @param {Object} config 配置
		 */
		async doExport(config) {
			window.$EditorDebug.startExport(this.element);
			// 参数
			if (config?.params) {
				const { param = {} } = initParams(
					config.params,
					this.isGroup,
					this.subComponentData,
					this.groupComponents,
					this.EDITOR_pageUUID
				);
				this.exportParam = param;
			}
			// console.log("导出中--------");
			// this.showSheet = true;
			// this.$loading({
			// 	text: '导出中....'
			// });
			// const { exportType = 'all' } = config;
			// let idUUID = this.statusConfig.idUUID || '';
			// if (!idUUID) {
			// 	for (let key in this.metadata) {
			// 		if (this.metadata[key] === 'id') {
			// 			idUUID = key;
			// 		}
			// 	}
			// }
			// if (!idUUID) {
			// 	loading.close();
			// 	this.$message.error('导出失败！数据验证错误。');
			// 	return;
			// }
			/* eslint-disable */
			// exportType 导出配置 all 全部， targetPage 本页，checked 当前选中
			// 最终导出数据
			// 导出全部
			// this.exportViewParams = queryData.param || {};
			// this.exportViewSearch = queryData.search;
			// // console.log(this.sheetFormData, '---11', exportType)
			// if (!this.sheetFormData) {
			// 	loading.close();
			// 	this.$message.error('导出配置错误！');
			// 	return false;
			// }
			// this.showSheet = true;
		},
		/**
		 * @desc: 导出成功
		 */
		onExportSuccess() {
			this.$loading().close();
			this.showSheet = false;
			this.$message.success('导出成功！');
			this.exportParam = {};
			window.$EditorDebug.successExport();
		},
		/**
		 * @desc: 导出错误
		 * @param {String} msg 错误信息
		 */
		onExportError(msg) {
			this.$loading().close();
			this.showSheet = false;
			this.$message.error(msg || '导出失败！');
			this.exportParam = {};
			window.$EditorDebug.errorExport();
		}
	}
};
</script>

<style lang="less" scoped>
.common-container {
	height: 100%;
	width: 100%;
}
</style>
