<!--
 * @Author: hw
 * @Date: 2023-10-20 16:33:25
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-09 11:57:00
 * @FilePath: \dataview-next\src\custom-component\form\newParser\FormItem\widget-textarea.vue
-->
<!--
    @name: widget-textarea
    @description：widget-textarea
    @author: ZengWei
    @date: 2022-03-25 09:28
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show">{{ element.value ? element.value : '-' }}</div>
    <el-input
      v-else
      v-model="element.value"
      :rows="3"
      type="textarea"
      :placeholder="$getLocalizedLabel({'zhLabel': element.config.zhPlaceholder, 'enLabel': element?.config.enPlaceholder})"
      :maxlength="element.config.maxlength"
      :disabled="disabled || element.config.disabled"
      :show-word-limit="showWordLimit"
    ></el-input>
  </div>
</template>

<script>
import {Input} from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetTextarea",
  components: {
    'el-input': Input
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  computed: {
    showWordLimit() {
      if (this.element.config.maxlength) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {},
}
</script>

<style lang="less" scoped>
</style>
