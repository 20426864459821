/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-06 17:00:02
 * @FilePath: \dataview-viewer-test\src\custom-component\netDisk\lang\zh.js
 */

export default {
  "components.SelectFiles.193459-0": "请输入文件名查询",
  "components.SelectFiles.193459-1": "已选",
  "components.SelectFiles.193459-2": "项",
  "components.SelectFiles.193459-3": "清空",
  "components.SelectFiles.193459-4": "请选择文件",
  "components.SelectFiles.193459-5": "这是外层文件",
  "components.SelectFiles.193459-6": "一级 1",
  "components.SelectFiles.193459-7": "二级 1-1",
  "components.SelectFiles.193459-8": "三级 1-1-1",
  "components.SelectFiles.193459-9": "三级 1-1-2",
  "components.SelectFiles.193459-10": "一级 2",
  "components.SelectFiles.193459-11": "二级 2-1",
  "components.SelectFiles.193459-12": "二级 2-2",
  "components.SelectFiles.193459-13": "一级 3",
  "components.SelectFiles.193459-14": "二级 3-1",
  "components.SelectFiles.193459-15": "二级 3-2",
  "components.SelectFiles.193459-16": "一级 4",
  "netDisk.mixin.193458-0": "操作失败！",
  "netDisk.mixin.193458-1": "事件配置错误！",
  "netDisk.mixin.193458-2": "操作成功！",
  "netDisk.mixin.193458-3": "事件未配置触发对象!",
  "netDisk.mixin.193458-4": "00000后端事件错误",
  "netDisk.mixin.193458-5": "查询的组件id不存在! 请检测组件ID:{0}",
  "components.Rename.193459-0": "请输入名称",
  "components.Rename.193459-1": "取 消",
  "components.Rename.193459-2": "确 定",
  "components.Rename.193459-3": "新增文件夹",
  "components.Rename.193459-4": "重命名",
  "components.OperationFile.193459-0": "文件至",
  "components.OperationFile.193459-1": "文件夹名",
  "components.OperationFile.193459-2": "文件名(回车创建)",
  "components.OperationFile.193459-3": "回车创建",
  "components.OperationFile.193459-4": "创建文件夹",
  "components.OperationFile.193459-5": "取 消",
  "components.OperationFile.193459-6": "移动",
  "components.OperationFile.193459-7": "复 制",
  "components.OperationFile.193459-8": "操作",
  "components.OperationFile.193459-9": "获取数据失败!",
  "components.OperationFile.193459-10": "获取文件失败！",
  "components.OperationFile.193459-11": "是否确认{0}至文件夹{1}？",
  "components.OperationFile.193459-12": "操作失败!",
  "components.OperationFile.193459-13": "操作成功！",
  "components.OperationFile.193459-14": "操作失败！",
  "components.OperationFile.193459-15": "已到最顶级！",
  "components.TableView.193459-0": "文件名",
  "components.TableView.193459-1": "已选",
  "components.TableView.193459-2": "项",
  "components.TableView.193459-3": "下载",
  "components.TableView.193459-4": "移动",
  "components.TableView.193459-5": "复制",
  "components.TableView.193459-6": "删除",
  "components.TableView.193459-7": "全选",
  "components.TableView.193459-8": "创建时间",
  "components.TableView.193459-9": "大小",
  "components.TableView.193459-10": "创建人",
  "components.TableView.193459-11": "操作",
  "components.TableView.193459-12": "重命名",
  "components.TableView.193459-13": "替换",
  "components.TableView.193459-14": "请确保名称一致",
  "components.TableView.193459-15": "此操作将替换文件， 不可撤销, 是否继续?",
  "components.TableView.193459-16": "提示",
  "components.TableView.193459-17": "确定",
  "components.TableView.193459-18": "取消",
  "components.TableView.193459-19": "出错了，请重试!",
  "components.TableView.193459-20": "文件地址错误!",
  "components.TableView.193459-21": "是否确认删除选中文件、文件夹及所有下级?",
  "components.TableView.193459-22": "是否确认删除【{0}】?",
  "components.TableView.193459-23": "操作失败！",
  "components.TableView.193459-24": "操作成功！",
  "components.TableView.193459-25": "请选择至少一个文件或文件夹!",
  "components.TableView.193459-26": "配置错误！",
  "components.TableView.193459-27": "文件下载中...",
  "components.TableView.193459-28": "下载错误，请联系管理员！",
  "components.TableView.193459-29": "下载成功！",
  "components.TableView.193459-30": "下载失败！",
  "components.FolderView.193460-0": "全选",
  "components.FolderView.193460-1": "已选",
  "components.FolderView.193460-2": "项",
  "components.FolderView.193460-3": "下载",
  "components.FolderView.193460-4": "移动",
  "components.FolderView.193460-5": "复制",
  "components.FolderView.193460-6": "删除",
  "components.FolderView.193460-7": "暂无文件",
  "components.FolderView.193460-8": "重命名",
  "components.FolderView.193460-9": "替换",
  "components.FolderView.193460-10": "请确保名称一致",
  "components.FolderView.193460-11": "此操作将替换文件， 不可撤销, 是否继续?",
  "components.FolderView.193460-12": "提示",
  "components.FolderView.193460-13": "确定",
  "components.FolderView.193460-14": "取消",
  "components.FolderView.193460-15": "操作失败！",
  "components.FolderView.193460-16": "操作成功！",
  "components.FolderView.193460-17": "出错了，请重试!",
  "components.FolderView.193460-18": "文件地址错误!",
  "components.FolderView.193460-19": "是否确认删除选中文件、文件夹及所有下级?",
  "components.FolderView.193460-20": "是否确认删除【{0}】?",
  "components.FolderView.193460-21": "请选择至少一个文件或文件夹!",
  "components.FolderView.193460-22": "配置错误！",
  "components.FolderView.193460-23": "文件下载中...",
  "components.FolderView.193460-24": "下载错误，请联系管理员！",
  "components.FolderView.193460-25": "下载成功！",
  "components.FolderView.193460-26": "下载失败！",
  "components.Header.193459-0": "上传文件",
  "components.Header.193459-1": "文件夹",
  "components.Header.193459-2": "链接",
  "components.Header.193459-3": "占位符",
  "components.Header.193459-4": "搜索文件名称",
  "components.Header.193459-5": "搜索",
  "components.Header.193459-6": "列表模式",
  "components.Header.193459-7": "缩略模式",
  "components.Header.193459-8": "全部",
  "components.Header.193459-9": "文档",
  "components.Header.193459-10": "图片",
  "components.Header.193459-11": "视频",
  "components.Header.193459-12": "模型",
  "components.Header.193459-13": "其他",
  "components.Header.193459-14": "获取文件失败！",
  "components.Header.193459-15": "操作失败！",
  "components.Header.193459-16": "操作成功！",
  "components.CreatePlaceholder.193460-0": "请输入名称",
  "components.CreatePlaceholder.193460-1": "取 消",
  "components.CreatePlaceholder.193460-2": "确 定",
  "components.CreatePlaceholder.193460-3": "新增占位符",
  "components.CreatePlaceholder.193460-4": "重命名",
  "netDisk.CommonNetDisk.193460-0": "获取数据失败!",
  "netDisk.CommonNetDisk.193460-1": "获取文件失败！",
  "netDisk.CommonNetDisk.193460-2": "打开失败！",
  "netDisk.CommonNetDisk.193460-3": "无法打开此格式的文件！",
  "netDisk.CommonNetDisk.193460-4": "dwg文件转换中,请稍后再试！",
  "netDisk.CommonNetDisk.193460-5": "请稍后再试！",
  "components.ActionMenu.193460-0": "下载",
  "components.ActionMenu.193460-1": "分享",
  "components.ActionMenu.193460-2": "移动",
  "components.ActionMenu.193460-3": "重命名",
  "components.ActionMenu.193460-4": "编辑",
  "components.ActionMenu.193460-5": "替换",
  "components.ActionMenu.193460-6": "删除",
  "components.LinkModel.193459-0": "名称",
  "components.LinkModel.193459-1": "请输入名称",
  "components.LinkModel.193459-2": "地址",
  "components.LinkModel.193459-3": "取 消",
  "components.LinkModel.193459-4": "确 定",
  "components.LinkModel.193459-5": "不能为空",
  "components.LinkModel.193459-6": "新增链接",
  "components.LinkModel.193459-7": "编辑链接",
  "components.LinkModel.193459-8": "请输入地址",
  "components.FileUpload.716311-0": "拖拽上传",
  "components.FileUpload.716311-1": "或",
  "components.FileUpload.716311-2": "点击上传",
  "components.FileUpload.716311-3": "文件上传列表",
  "components.FileUpload.716311-4": "上传成功!",
  "components.FileUpload.716311-5": "上传文件",
  "components.FileUpload.716311-6": "出错了，请重试！",
  "components.PlusFile.648379-0": "添加",
  "components.PlusFile.648379-1": "质量管控",
  "components.PlusFile.648379-2": "取 消",
  "components.PlusFile.648379-3": "确 定"
}


