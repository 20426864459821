<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2023-04-25 17:07:59
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-06 19:31:26
 * @FilePath: \dataview-viewer-test\src\custom-component\flow\flow-designer\src\operation\FlowOperate.vue
 * @Description: 流程审批组件 bate 版本
-->

<template>
	<div
		class="flow-operate-wrap"
		v-if="editData.btn && editData.btn.length !== 0 && editData.btn.filter((t) => t.show).length !== 0"
	>
		<div class="action">
			<span class="expand" @click.stop="handleExpand">
				<i v-if="isExpand" class="iconfont iconjijia_xiangxia" />
				<i v-else class="iconfont iconjijia_xiangshang" />
			</span>
			<div>
				<el-button v-if="!isExpand || !btnRadio" type="primary" class="btn" size="mini" @click="isExpand = !isExpand">
					{{ isExpand ? $t('operation.FlowOperate.468394-0') : $t('operation.FlowOperate.468394-1') }}
				</el-button>
				<el-button
					v-else
					:loading="submitLoading"
					class="btn"
					size="mini"
					:type="btnInfo.type"
					@click.stop="flowOperate"
				>
					{{ btnInfo.text || $t('operation.FlowOperate.468394-2') }}
				</el-button>
			</div>
		</div>
		<transition name="fade">
			<div v-show="isExpand" class="float-operate-wrap">
				<template v-if="isPlusSign">
					<div class="type-label">
						{{ plusSignType === 1 ? '后置加签审批' : '前置加签审批' }}
					</div>
					<div class="operate-content">
						<el-form v-if="plusSignSelf == 3">
							<el-form-item label="加签类型">
								<el-radio-group v-model="plusSignType">
									<el-radio :label="1">后置加签</el-radio>
									<el-radio :label="1">前置加签</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-form>
						<FlowPicker
							:data-id="dataId"
							:module="module"
							:plusSign="true"
							type="next"
							:flowArchiId="flowArchiId"
							:flowParentArchiId="flowParentArchiId"
							:flow-edit="flowEdit"
							@checked-users="dealWithPicker"
							@selected-step="getSelectStep"
						/>
					</div>
				</template>
				<template v-else>
					<div class="label">审批意见</div>
					<div class="type-select-wrap">
						<el-radio-group v-model="btnRadio" @change="btnChange">
							<template v-for="(item, index) in editData.btn">
								<el-radio v-if="item.show" :label="item.status" :key="index">
									{{ item.btn }}
								</el-radio>
							</template>
						</el-radio-group>
					</div>
					<div class="operate-content">
						<el-form v-if="![7, 9].includes(buttonStatus)" ref="baseForm" label-position="top" :model="applyMsg">
							<el-form-item
								v-if="suggestShow"
								style="margin-bottom: 10px; margin-top: 10px"
								prop="remarks"
								:rules="suggestRequired ? suggestRule : []"
							>
								<el-input
									v-model="applyMsg.remarks"
									:placeholder="$t('operation.FlowOperate.468394-4')"
									:rows="3"
									type="textarea"
								/>
							</el-form-item>
							<el-form-item
								v-if="attachShow"
								style="margin-bottom: 10px"
								:label="$t('operation.FlowOperate.468394-5')"
								prop="files"
								:rules="attachRequired ? attachRule : []"
							>
								<fileUpload :files="applyMsg.files" @extra-files="extraFiles" />
							</el-form-item>
						</el-form>
						<div style="padding-top: 10px">
							<PcParser
								ref="otherForm"
								v-if="showOtherForm && ![7, 9].includes(buttonStatus)"
								:form-data="curStepNode.extendFields"
							>
							</PcParser>
						</div>
						<div v-if="buttonStatus === -1">
							<el-form label-position="top">
								<el-form-item :label="$t('operation.FlowOperate.468394-6')">
									<el-radio-group v-model="back_type" size="small">
										<el-radio-button :label="1">{{ $t('operation.FlowOperate.468394-7') }}</el-radio-button>
										<el-radio-button :label="2">{{ $t('operation.FlowOperate.468394-8') }}</el-radio-button>
										<el-radio-button :label="3">{{ $t('operation.FlowOperate.468394-9') }}</el-radio-button>
									</el-radio-group>
								</el-form-item>
								<el-form-item v-if="back_type === 3" :label="$t('operation.FlowOperate.468394-10')">
									<el-select
										v-model="back_step"
										:placeholder="$t('operation.FlowOperate.468394-11')"
										style="width: 100%"
									>
										<el-option
											v-for="(item, index) in editData.doneStep"
											:key="index"
											:value="item.nodeId"
											:label="item.name"
										>
											{{ item.name }}
										</el-option>
									</el-select>
								</el-form-item>
							</el-form>
						</div>
						<div v-if="buttonStatus === 10">
							<el-form label-position="top">
								<el-form-item label="选择加签类型">
									<el-radio-group v-model="addSignType" size="small">
										<el-radio-button :label="1">审批前加签</el-radio-button>
										<el-radio-button :label="2">通过后加签</el-radio-button>
									</el-radio-group>
								</el-form-item>
							</el-form>
						</div>
						<div v-if="buttonStatus > 0 && ![4, 6, 7, 9].includes(buttonStatus)">
							<FlowPicker
								:data-id="dataId"
								:module="module"
								:turn="buttonStatus === 2 ? true : false"
								type="next"
								:flowArchiId="flowArchiId"
								:flowParentArchiId="flowParentArchiId"
								:flow-edit="flowEdit"
								@checked-users="dealWithPicker"
								@selected-step="getSelectStep"
							/>
						</div>
					</div>
				</template>
			</div>
		</transition>
	</div>
</template>

<script>
import fileUpload from '@/components/fileUpload/newFiles/index.vue';
import FlowPicker from '@/custom-component/flow/flow-designer/src/operation/FlowPicker.vue';
import { flowEngine } from '@/apis/data/workflow';
import eventBus from '@/plugins/eventBus';
import { Form, FormItem, Input, Select, Option, RadioGroup, Radio, RadioButton } from 'element-ui';

export default {
	name: 'FlowOperate',
	components: {
		fileUpload,
		FlowPicker,
		'el-form': Form,
		'el-form-item': FormItem,
		'el-input': Input,
		'el-select': Select,
		'el-option': Option,
		'el-radio-group': RadioGroup,
		'el-radio-button': RadioButton,
		'el-radio': Radio
	},
	inject: {
		parser: {
			default: null
		}
	},
	props: {
		editData: {
			type: Object,
			default: () => {
				return {};
			}
		},
		//流程中修改的数据
		flowEdit: {
			type: Object,
			default: () => {
				return {};
			}
		},
		// 跳过表单数据验证
		breakValid: {
			type: Boolean,
			default: false
		},
		openNextFlow: {
			type: Boolean,
			default: false
		},
		//是否是来自新版待办预警
		isWarn: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		btnInfo() {
			const buttonStatus = this.buttonStatus;
			const btns = this.editData.btn;
			if (Array.isArray(btns)) {
				const btnItem = btns.find((el) => el.status === buttonStatus && el.show);
				if (btnItem) {
					return {
						type: btnItem.status === -1 || btnItem.status === 7 ? 'danger' : 'primary',
						text: `确认${btnItem.btn}`
					};
				} else {
					return {};
				}
			}
			return {};
		}
	},
	data() {
		return {
			isExpand: false,
			curStepNode: {},
			buttonStatus: 0,
			back_type: 1,
			back_step: '',
			module: '',
			dataId: '',
			applyMsg: {
				remarks: '',
				files: []
			},
			selectedUsers: [],
			checkPickerNum: 0,
			selectStep: '',
			addSignType: '',

			btnRadio: '',
			submitLoading: false,
			suggestRequired: false,
			suggestShow: false,
			attachShow: false,
			attachRequired: false,
			suggestRule: {
				required: true,
				message: '审批意见不能为空',
				type: 'string',
				trigger: 'blur'
			},
			attachRule: {
				required: true,
				message: '审批附件不能为空',
				type: 'array',
				trigger: 'change'
			},
			showOtherForm: false,
			flowArchiId: '',
			flowParentArchiId: '',
			isPlusSign: false,
			plusSignSelf: false,
			plusSignType: 1
		};
	},
	methods: {
		handleExpand() {
			this.isExpand = !this.isExpand;
		},
		formItemShow(list) {
			this.$emit('input-show', list);
		},
		getSelectStep(nodeId) {
			this.selectStep = nodeId;
		},
		dealWithPicker(selected, total) {
			this.selectedUsers = selected;
			this.checkPickerNum = total;
		},
		extraFiles(files) {
			this.applyMsg.files = files;
		},
		actionEvent(status) {
			this.buttonStatus = status;
		},
		flowOperate() {
			const oldFlow = this.editData?.recover?.pens || null;
			let invalid = false;
			if (this.$refs.baseForm) {
				try {
					this.$refs.baseForm.validate((valid) => {
						if (!valid) {
							throw new Error('表单验证失败');
						}
					});
					if (this.$refs.otherForm) {
						this.$refs.otherForm.validateFormInput();
					}
				} catch (e) {
					invalid = true;
					this.$message.error('审批意见必填项未填报完整');
				}
			}
			if (invalid) return;
			if (
				this.checkPickerNum > 0 &&
				this.buttonStatus !== 4 &&
				this.buttonStatus !== 6 &&
				this.buttonStatus !== 7 &&
				this.buttonStatus !== 9 &&
				this.checkPickerNum !== this.selectedUsers.target_users.length
			) {
				this.$message.error('审批人未选择完整！');
				return false;
			}
			let formData = {
				status: this.buttonStatus,
				module: this.module,
				remarks: this.applyMsg.remarks,
				appendix: this.applyMsg.files
			};
			if (this.$refs.otherForm) {
				const otherData = this.$refs.otherForm.getFormData();
				formData.extend_fields = otherData;
			}
			if (this.buttonStatus === -1) {
				formData.back_type = this.back_type;
				if (this.back_type === 3) formData.back_step = this.back_step;
			}
			if (this.selectedUsers.target_users && this.selectedUsers.target_users.length > 0) {
				formData.target_users = this.selectedUsers.target_users;
			}
			if (this.selectedUsers.send_users && this.selectedUsers.send_users.length > 0) {
				formData.send_users = this.selectedUsers.send_users;
			}
			if (this.selectStep) formData.select_step = this.selectStep;
			if (Object.keys(this.flowEdit).length) formData.editFields = this.flowEdit;
			if (this.submitLoading) {
				return;
			}
			this.submitLoading = true;

			// 解決新版表单与流程操作同级没有provide
			let provideParser = this.parser;
			const formInstance = this.$store.state.formInstance;
			if (this.parser === null && formInstance) {
				provideParser = formInstance;
			}
			// 兼容手写功能流程审批
			if (provideParser) {
				provideParser.formParserValid().then((valid) => {
					if (valid || this.breakValid) {
						if (oldFlow) {
							flowEngine.flowOperate(this.dataId, formData).then((res) => {
								if (res.data.code === 200) {
									this.$message.success(res.data.msg);
								}
								this.$emit('change-end');
								if (this.isWarn) {
									eventBus.$emit('handle-todo-warn-status');
								}
								setTimeout(() => {
									this.submitLoading = false;
								}, 500);
							});
						} else {
							flowEngine.newFlowOperate(this.dataId, formData).then((res) => {
								if (res.data.code === 200) {
									this.$message.success(res.data.msg);
									if (this.openNextFlow) {
										window.parent.postMessage({ ...res.data, event: 'open-next-flow' }, '*');
									} else if (this.isWarn) {
										eventBus.$emit('handle-todo-warn-status');
									}
								}
								this.$emit('change-end');
								setTimeout(() => {
									this.submitLoading = false;
								}, 500);
							});
						}
					} else {
						this.submitLoading = false;
						this.isExpand = !this.isExpand;
						this.$message.error('数据验证失败，请核对必填字段数据');
					}
				});
			} else {
				if (oldFlow) {
					flowEngine.flowOperate(this.dataId, formData).then((res) => {
						if (res.data.code === 200) {
							this.$message.success(res.data.msg);
						}
						this.$emit('change-end');
						setTimeout(() => {
							this.submitLoading = false;
						}, 500);
					});
				} else {
					flowEngine.newFlowOperate(this.dataId, formData).then((res) => {
						if (res.data.code === 200) {
							this.$message.success(res.data.msg);
						}
						this.$emit('change-end');
						setTimeout(() => {
							this.submitLoading = false;
						}, 500);
					});
				}
			}
		},
		/* 操作按钮选择 */
		btnChange(val) {
			if (val === -1) {
				this.checkPickerNum = 0;
			}
			this.actionEvent(val);
		},
		/* 隐藏操作区域 */
		hidOperate() {
			this.$emit('hid-operate');
		}
	},
	created() {
		this.module = this.editData.module;
		console.log(this.editData, '---429--');
		this.dataId = this.editData.id;
		this.flowArchiId = this.editData?.flow_archi_id || '';
		this.flowParentArchiId = this.editData?.flow_parent_archi_id || '';
		if (this.editData?.isPlusSign) {
			this.isPlusSign = this.editData.isPlusSign;
			this.plusSignSelf = this.editData?.plusSignType || 1;
			if (this.plusSignSelf != 3) {
				this.plusSignType = this.plusSignSelf;
			}
			this.btnRadio = 10;
			this.buttonStatus = 10;
		}
		this.curStepNode = this.editData?.curStepNode;
		const extendFields = this.curStepNode?.extendFields || {};
		if (extendFields instanceof Object && extendFields?.fields?.length) {
			this.showOtherForm = true;
		}
		this.attachShow = this.curStepNode?.attachShow;
		this.suggestShow = this.curStepNode?.suggestShow;
		this.attachRequired = this.curStepNode?.attachRequired;
		this.suggestRequired = this.curStepNode?.suggestRequired;
		if (this.curStepNode.nodeType === 'Start') {
			this.attachShow = true;
			this.suggestShow = true;
		}
	}
};
</script>
<style scoped></style>
<style lang="less" scoped>
.flow-operate-wrap {
	text-align: initial;
	flex-shrink: 0;
	//height: 56px;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	.action {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px;
		box-sizing: border-box;
		.expand {
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #f4f6fa;
			border-radius: 50%;
			user-select: none;
		}
		.btn {
			min-width: 122px;
		}
	}
	.float-operate-wrap {
		position: absolute;
		bottom: 48px;
		left: 0;
		width: 100%;
		height: 400px;
		z-index: 999;
		padding: 20px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		background: #fff;
		box-shadow: 0px -4px 12px -1px rgba(12, 29, 61, 0.08), 0px 0px 2px -1px rgba(12, 29, 61, 0.04);
		border-radius: 12px 12px 0px 0px;
		.label {
			flex-shrink: 0;
			font-family: 'PingFang SC';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
			letter-spacing: 0.5px;
			color: #161c1f;
		}
		.type-select-wrap {
			margin: 10px 0;
			flex-shrink: 0;
		}
		.operate-content {
			flex-grow: 1;
			overflow-y: auto;
		}
	}
}
.fade-enter-active {
	animation: bounce-in 0.3s;
}
.fade-leave-active {
	animation: bounce-in 0.2s reverse;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	height: 0;
}
@keyframes bounce-in {
	0% {
		height: 0;
		opacity: 0;
	}
	100% {
		height: 400px;
		opacity: 1;
	}
}
</style>
