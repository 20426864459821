var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'time-container': true,
    custom:
      _vm.element.config.__slot__?.prepend || _vm.element.config.__slot__?.append,
  },style:({
    background: (_vm.disabled || _vm.element.config.disabled) && '#F2F4F7',
    borderRadius: '4px',
  })},[(_vm.element.config.__slot__?.prepend)?_c('div',{staticClass:"prepend",style:({
      border:
        _vm.disabled || _vm.element.config.disabled ? 'none' : '1px solid #dcdfe6',
      padding: _vm.disabled || _vm.element.config.disabled ? '0 0 0 12px' : '0 20px',
    })},[_vm._v(" "+_vm._s(_vm.element.config.__slot__?.prepend)+" ")]):_vm._e(),(_vm.disabled || _vm.element.config.disabled)?_c('div',{staticClass:"input-show",style:({
      padding: _vm.disabled || _vm.element.config.disabled ? '5px 0' : '5px 12px',
      flex: _vm.disabled || _vm.element.config.disabled ? 'unset' : 1,
      width: (_vm.disabled || _vm.element.config.disabled) && 'auto',
      paddingLeft: !_vm.element.config.__slot__?.prepend ? '12px' : '0',
      paddingRight: !_vm.element.config.__slot__?.append ? '12px' : '0',
    })},[_vm._v(" "+_vm._s(_vm.element.value ? _vm.element.value : '-')+" ")]):_c('el-time-picker',{attrs:{"disabled":_vm.disabled || _vm.element.config.disabled,"type":_vm.element.config.type,"format":_vm.element.config.format,"value-format":_vm.element.config['value-format'],"placeholder":_vm.$getLocalizedLabel({
        zhLabel: _vm.element.config.zhPlaceholder,
        enLabel: _vm.element?.config.enPlaceholder,
      })},model:{value:(_vm.element.value),callback:function ($$v) {_vm.$set(_vm.element, "value", $$v)},expression:"element.value"}}),(_vm.element.config.__slot__?.append)?_c('div',{staticClass:"append",style:({
      border:
        _vm.disabled || _vm.element.config.disabled ? 'none' : '1px solid #dcdfe6',
      padding: _vm.disabled || _vm.element.config.disabled ? '0 12px 0 0' : '0 20px',
    })},[_vm._v(" "+_vm._s(_vm.element.config.__slot__?.append)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }