<!--
    @name: widget-color
    @description：widget-color
    @author: ZengWei
    @date: 2022-03-25 09:35
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show" :style="{background: element.value}"></div>
    <el-color-picker
      v-else
      v-model="element.value"
      recommend
    >
    </el-color-picker>
  </div>
</template>

<script>
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {ColorPicker} from "element-ui";

export default {
  name: "WidgetColor",
  components: {
    'el-color-picker':ColorPicker
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="less" scoped>

</style>
