/*
 * @Description: 注入
 * @Author: luocheng
 * @Date: 2022-08-01 17:13:41
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-06 19:35:04
 */
import { Empty } from 'element-ui';
import { dataInterface } from '@/apis/data/index';
import { initParams, removeEventBus } from '@/utils/tools';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';

export default {
	data() {
		return {
			numberList: [],
			timer: null
		};
	},
	watch: {
		dateList(val) {
			if (val.length) {
				// console.log(val, 'val')
				this.$nextTick(() => {
					if (this.statusConfig.calendarType === 'base') return;
					this.getNumbers();
				});
			}
		},
		yearList(val) {
			if (val.length) {
				this.$nextTick(() => {
					if (this.statusConfig.calendarType === 'base') return;
					this.getNumbers(val);
				});
			}
		},
		weekList(val) {
			if (val.length) {
				this.$nextTick(() => {
					if (this.statusConfig.calendarType === 'base') return;
					this.getNumbers();
				});
			}
		},

		'element.countConfig': {
			handler() {
				if (this.statusConfig.calendarType === 'base') return;
				this.getNumbers();
			}
		}
	},
	components: {
		'el-empty': Empty
	},
	props: {
		element: {
			type: Object,
			required: true
		},
		// 是否为分组
		isGroup: {
			type: Boolean
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		},
		// 表格默认选中数据
		defaultTableSelectData: {
			type: Array,
			default: () => []
		},
		componentList: {
			default: null
		},
		type: {
			type: String,
			default: 'date'
		}
	},
	inject: ['EDITOR_pageUUID'],
	computed: {
		...mapState(['componentData']),
		statusConfig() {
			return this.element?.statusConfig;
		},
		// 标志位类型, 周日在后 sundayEnd， 周日在前 sundayStart
		headerFormat() {
			return this.statusConfig?.headerFormat;
		},
		// 类型， 基础类型 base， 符合数据类型(带统计) withDatabase
		calendarType() {
			return this.statusConfig?.calendarType;
		},
		customDataColor() {
			return this.statusConfig?.customDataColor || null;
		},
		showDateList() {
			if (this.type === 'year') {
				return this.computedNumberToDateList(this.yearList);
			} else if (this.type === 'date') {
				return this.computedNumberToDateList(this.dateList);
			} else if (this.type === 'week') {
				const res = this.computedNumberToDateList(this.weekList);
				return res;
			}
		},
		// 计数配置
		countConfig() {
			return this.element.countConfig || {};
		},
		// 是否为旧配置
		isOldConfig() {
			/* eslint-disable-next-line */
			return this.countConfig.hasOwnProperty('object_uuid') && !this.countConfig.hasOwnProperty('finish_view_uuid');
		},
		haveData() {
			return this.statusConfig.haveData;
		},
		noData() {
			return this.statusConfig.noData;
		},
		haveDataColor() {
			return this.statusConfig.haveDataColor;
		},
		noDataColor() {
			return this.statusConfig.noDataColor;
		}
	},
	mounted() {
		const databaseTrigger = {
			[this.element.id]: (config) => {
				if (
					(config.componentId !== this.element.id && this.isTriggerTarget(config)) ||
					this.element.statusConfig?.calendarType === 'customDot'
				) {
					clearTimeout(this.timer);
					this.timer = setTimeout(() => {
						this.initDate();
					}, 200);
				}
			}
		};
		eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
	},
	beforeDestroy() {
		// console.log('销毁', this.element.name, this.EDITOR_pageUUID);
		// @tab组件嵌入页面事件监听问题，暂时注释
		// EDITOR_loopResolve 为循环容器特有
		removeEventBus(['exportData', 'databaseTrigger', 'EDITOR_loopResolve', 'doComponentBehavior'], this.element.id);
	},
	methods: {
		/**
		 * @description: 是否触发当前组件刷新
		 * @param {Object} config 配置
		 * @return {Boolean} 是否当前组件
		 */
		isTriggerTarget(config) {
			if (!this.element?.countConfig) return false;
			const { finishParams = {}, totalParams = {} } = this.element?.countConfig || {};
			const finishParam = finishParams?.paramsConfig || [];
			const totalParam = totalParams?.paramsConfig || [];
			const params = finishParam.concat(totalParam);
			if (!Array.isArray(params) || !params.length) {
				return false;
			}
			return !!params.find((ele) => ele.componentId === config.componentId);
		},
		/**
		 * @desc: 补零
		 * @param {Number/Number} val 补零
		 * @return {String/Number}
		 */
		addZero(val) {
			if (isNaN(+val)) return val;
			if (val >= 0 && val < 10) {
				return `0${+val}`;
			}
			return val;
		},
		/**
		 * @desc: 获取当前月有多少天
		 * @param {Number} year 年
		 * @param {Number} month 月
		 * @return {Number} count 天数
		 */
		getMonthDayCount(year, month) {
			const largeMonth = [1, 3, 5, 7, 8, 10, 12];
			const lessMonth = [4, 6, 9, 11];
			if (largeMonth.includes(month)) {
				return 31;
			}
			if (lessMonth.includes(month)) {
				return 30;
			}
			// 2月 需要判断平闰年
			if ((year % 4 === 0 && year && 100 !== 0) || year % 400 === 0) {
				return 29;
			}
			return 28;
		},
		/**
		 * @description: 生成请求数据
		 * @param{Array} dateList 日期数组
		 * @return {Object} 全局moreAction请求数据
		 */
		generateNumberData(dateList = []) {
			if (dateList.length == 0) {
				if (this.type === 'date') {
					dateList = this.dateList;
				} else if (this.type === 'year') {
					dateList = this.yearList;
				} else if (this.type === 'week') {
					dateList = this.weekList;
				}
			}
			// 处理参数和新版本配置
			let finishParam = {};
			let finishOutParam = {};
			let totalParam = {};
			let totalOutParam = {};
			const finishParamConfig = this.countConfig?.finishParams;
			const totalParamConfig = this.countConfig?.totalParams;
			if (!this.isOldConfig) {
				if (finishParamConfig) {
					finishParam =
						initParams(
							finishParamConfig?.paramsConfig || [],
							this.isGroup,
							this.componentList || this.componentData,
							this.groupComponents,
							this.EDITOR_pageUUID
						)?.param || {};
					// 注入
					if (finishParamConfig.userOutParams) {
						finishOutParam = sessionStorage.getItem(
							`dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
						);
						finishOutParam = finishOutParam ? JSON.parse(finishOutParam) : {};
					}
				}
				if (totalParamConfig) {
					totalParam =
						initParams(
							totalParamConfig?.paramsConfig || [],
							this.isGroup,
							this.componentList || this.componentData,
							this.groupComponents,
							this.EDITOR_pageUUID
						)?.param || {};
					// 注入
					if (totalParamConfig.userOutParams) {
						totalOutParam = sessionStorage.getItem(
							`dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
						);
						totalOutParam = totalOutParam ? JSON.parse(totalOutParam) : {};
					}
				}
			}
			/* eslint-disable */
			let data = [];
			if (dateList.length) {
				dateList.forEach((item) => {
					if (item.type == 'target' || item.type === undefined) {
						let tmp = {
							__method_name__: 'globalFunctionCall',
							funcName: 'ViewAggregate',
							payload: {
								view_uuid: this.isOldConfig ? this.countConfig?.view_uuid : this.countConfig?.total_view_uuid,
								aggregate: this.isOldConfig ? '4' : this.countConfig.total_aggregate,
								_is_cache: false,
								need_search: true
							},
							typeName: 'PublicFunc',
							type: 'value'
						};
						if (this.countConfig?.total_field_uuid) {
							tmp.search = [
								{ field_uuid: this.countConfig?.total_field_uuid, ruleType: 'between', value: item.result }
							];
						} else {
							tmp.search = [{ code: 'created_at', ruleType: 'between', value: item.result }];
						}
						// 总数
						data.push({
							...tmp,
							...totalParam,
							...totalOutParam
						});
						// 未结束
						tmp = JSON.parse(JSON.stringify(tmp));
						tmp = {
							...tmp,
							payload: {
								...tmp.payload,
								view_uuid: this.isOldConfig ? this.countConfig?.view_uuid : this.countConfig?.finish_view_uuid,
								aggregate: this.isOldConfig ? '4' : this.countConfig.finish_aggregate
							},
							__show_pre__: 1
						};
						if (this.countConfig?.finish_field_uuid) {
							tmp.search = [
								{ field_uuid: this.countConfig?.finish_field_uuid, ruleType: 'between', value: item.result }
							];
						} else {
							tmp.search = [{ code: 'created_at', ruleType: 'between', value: item.result }];
						}
						data.push({
							...tmp,
							...finishParam,
							...finishOutParam
						});
					}
				});
			}
			let params = {
				__method_name__: 'moreAction',
				data: data
			};
			return params;
		},
		/**
		 * @description: 获取日历相关的数字数据
		 * @return {Void}
		 */
		getNumbers() {
			if (this.element.calendarType == 'base') {
				return;
			}
			// 没选视图，直接pass
			if (
				(this.isOldConfig && !this.countConfig?.view_uuid) ||
				!this.countConfig?.finish_view_uuid ||
				!this.countConfig?.total_view_uuid
			) {
				return;
			}
			let params = this.generateNumberData();
			dataInterface(params).then((res) => {
				if (res.data.code == 200) {
					this.numberList = res.data.data;
				}
			});
		},
		/**
		 * @description: 将数字归入到日历数据中
		 * @param{Array} dateList 日期数组
		 * @return {Void}
		 */
		computedNumberToDateList(dateList) {
			if (!this.numberList.length || !dateList.length) {
				return dateList;
			}
			const { totalTaking = 'odd', finishTaking = 'even' } = this.statusConfig;
			let index = 0;
			dateList.forEach((item) => {
				if (item.type == 'target' || item.type === undefined) {
					if (totalTaking === 'even') {
						item.totalCount = this.numberList[index];
					}
					if (finishTaking === 'even') {
						item.finishCount = this.numberList[index];
					}
					index++;
					if (totalTaking === 'odd') {
						item.totalCount = this.numberList[index];
					}
					if (finishTaking === 'odd') {
						item.finishCount = this.numberList[index];
					}
					index++;
				}
			});
			return dateList;
		}
	}
};
