<!--
 * @Author: wsp 542278473@qq.com
 * @Date: 2022-11-30 14:17:40
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-06 19:45:36
 * @FilePath: /dataview-viewer-test/src/custom-component/login/components/LoginByInput.vue
 * @Description: 以输入方式登录的组件，包括账号密码和手机验证码登录
-->
<template>
  <div class="login-wrap">
    <div v-if="methodConfig.userQRCodelogin" class="code-model">
      <div class="code" @click.stop="changeModel('code')">
        <svg
          t="1669605775884"
          class="icon"
          viewBox="0 0 1024 1024"
          fill="#409eff"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="76289"
          width="100%"
          height="100%"
        >
          <path
            d="M694.144 349.824l192.192 0L886.336 157.632l-192.192 0L694.144 349.824zM732.544 196.032l115.328 0 0 115.392-115.328 0L732.544 196.032z"
            p-id="76290"
          ></path>
          <path
            d="M153.216 349.824l195.456 0L348.672 157.632 153.216 157.632 153.216 349.824zM192.256 196.032l117.248 0 0 115.392L192.256 311.424 192.256 196.032z"
            p-id="76291"
          ></path>
          <path
            d="M636.416 407.424l96.64 0 0 115.392-96.64 0 0-115.392Z"
            p-id="76292"
          ></path>
          <path
            d="M732.992 522.752l115.392 0 0 115.392-115.392 0 0-115.392Z"
            p-id="76293"
          ></path>
          <path
            d="M191.616 734.208l115.328 0 0 115.392-115.328 0 0-115.392Z"
            p-id="76294"
          ></path>
          <path
            d="M694.144 157.568l192.128 0 0 192.192-192.128 0 0-192.192Z"
            p-id="76295"
          ></path>
          <path
            d="M310.144 638.08 79.488 638.08l0 326.72 326.72 0L406.208 638.08 406.208 522.816 310.144 522.816 310.144 638.08zM348.608 887.936 156.352 887.936l0-192.128 192.256 0L348.608 887.936z"
            p-id="76296"
          ></path>
          <path
            d="M406.272 99.968 79.488 99.968l0 307.52 0 115.328 115.392 0L194.88 407.488l211.392 0L521.6 407.488 521.6 311.36 406.272 311.36 406.272 99.968zM348.608 349.824 156.352 349.824 156.352 157.568l192.256 0L348.608 349.824z"
            p-id="76297"
          ></path>
          <path
            d="M636.992 99.968 521.6 99.968l0 96.064 115.392 0 0 211.456 96 0 0 115.328 115.392 0L848.384 407.488l96.128 0L944.512 99.968 636.992 99.968zM886.72 349.824l-192.128 0L694.592 157.568l192.128 0L886.72 349.824z"
            p-id="76298"
          ></path>
          <path
            d="M521.6 849.536l115.392 0 0 115.264-115.392 0 0-115.264Z"
            p-id="76299"
          ></path>
          <path
            d="M521.6 407.488l115.392 0 0 115.328-115.392 0 0-115.328Z"
            p-id="76300"
          ></path>
          <path
            d="M829.12 638.08 732.992 638.08 732.992 522.816 636.992 522.816 636.992 638.08 521.6 638.08 521.6 734.208 732.992 734.208 732.992 849.536 732.992 964.8 944.512 964.8 944.512 849.536 848.384 849.536 848.384 734.208 944.512 734.208 944.512 638.08 944.512 522.816 848.384 522.816 848.384 638.08Z"
            p-id="76301"
          ></path>
        </svg>
      </div>
      <div class="model"></div>
    </div>
    <div v-if="methodConfig.userQRCodelogin" class="code-tips">
      {{ $t('components.LoginByInput.642272-0') }}
    </div>
    <div class="input-methods">
      <div 
        v-if="methodConfig.usePasswordLogin" 
        class="methods-item" 
        :style="
          loginMethod === 'password'
            ? `
          border-bottom-color: ${styleConfig.activeColor};
          color: ${styleConfig.activeColor};
          font-size: ${styleConfig.tabsFontSize}px;
          font-weight: ${styleConfig.tabsFontWeight}`
            : `
          font-size: ${styleConfig.tabsFontSize}px;
          font-weight: ${styleConfig.tabsFontWeight}`
        "
        :class="loginMethod === 'password' ? 'active' : ''"
        @click="loginMethod = 'password'"
      >
        {{ $t('components.LoginByInput.642272-1') }}
      </div>
      <div
        v-if="methodConfig.useCodeLogin"
        class="methods-item"
        :style="
          loginMethod === 'code'
            ? `
          border-bottom-color: ${styleConfig.activeColor};
          color: ${styleConfig.activeColor};
          margin-left: ${styleConfig.tabsMargin}px;
          font-size: ${styleConfig.tabsFontSize}px;
          font-weight: ${styleConfig.tabsFontWeight}`
            : `
          margin-left: ${styleConfig.tabsMargin}px;
          font-size: ${styleConfig.tabsFontSize}px;
          font-weight: ${styleConfig.tabsFontWeight}`
        "
        :class="loginMethod === 'code' ? 'active' : ''"
        @click="loginMethod = 'code'"
      >
        {{ $t('components.LoginByInput.642272-2') }}
      </div>
    </div>
    <div class="input-main">
      <div class="input-form-wrap">
        <transition name="alternate" mode="out-in">
          <!-- 密码登录表单 -->
          <section
            v-if="loginMethod === 'password'"
            key="password"
            class="login-by-password"
          >
            <el-form
              ref="passwordForm"
              :model="passwordFormData"
              :rules="passwordFormRules"
              :hide-required-asterisk="true"
              :show-message="false"
              size="lager"
            >
              <p class="message-info" :class="`${errorMessageType}-message`">
                <template v-if="showErrorMessage">
                  <i class="el-icon-warning"></i>
                  <span class="message">{{ errorMessage }}</span>
                </template>
                <template v-else>
                  <span>&nbsp;</span>
                </template>
              </p>
              <el-form-item
                prop="name"
                :style="`margin-bottom: ${styleConfig.formItemMargin}px`"
              >
                <el-input
                  :class="`${styleConfig.inputStyle}-input`"
                  v-model="passwordFormData.name"
                  :placeholder="$t('components.LoginByInput.642272-3')"
                >
                  <i
                    slot="prefix"
                    style="fontsize: 16px"
                    class="el-icon-s-custom"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="password" :style="`margin-bottom: ${styleConfig.formItemMargin}px`">
                <el-input :class="`${styleConfig.inputStyle}-input`" v-model="passwordFormData.password" :placeholder="$t('components.LoginByInput.642272-4')" show-password @keyup.enter="handleSubmit">
                  <i slot="prefix" style="fontSize: 16px" class="iconfont iconsuoding"></i>
                </el-input>
              </el-form-item>
            </el-form>
            <el-button :style="{ background: styleConfig.activeColor, color: styleConfig.btnColor }" style="width:100%" size="lager" :loading="loginLoading" @click="handleSubmit">{{ $t('components.LoginByInput.642272-5') }}</el-button>
            <p class="other-tips">
              <!-- 此功能暂时做不了，故暂时隐藏 -->
              <!-- <el-checkbox>记住密码</el-checkbox> -->
              <span/>
              <el-button v-if="methodConfig.useResetPassword" type="text" :style="{ color: styleConfig.activeColor }" @click.stop="changeModel('reset')">{{ $t('components.LoginByInput.642272-6') }}</el-button>
              <span v-else></span>
            </p>
          </section>
          <!-- 短信登录表单 -->
          <section
            v-else-if="loginMethod === 'code'"
            key="message"
            class="login-by-message"
          >
            <el-form
              ref="codeForm"
              :model="codeFormData"
              :rules="codeFormRules"
              :hide-required-asterisk="true"
              :show-message="false"
              size="lager"
            >
              <!-- <p class="message-info error-message">
                &nbsp;
              </p> -->
              <el-form-item prop="phone" :style="`margin-bottom: ${styleConfig.formItemMargin}px`">
                <el-input :class="`${styleConfig.inputStyle}-input`" v-model="codeFormData.phone" class="phone-input" :placeholder="$t('components.LoginByInput.642272-7')">
                  <span slot="prefix">
                    <i class="iconfont iconduanxindenglu"></i>
                    <span class="phone-type">+86</span>
                  </span>
                </el-input>
              </el-form-item>
              <el-form-item prop="code" :style="`margin-bottom: ${styleConfig.formItemMargin}px`">
                <el-input :class="`${styleConfig.inputStyle}-input`" v-model="codeFormData.code" class="code-input" :placeholder="$t('components.LoginByInput.642272-8')">
                  <i slot="prefix" class="iconfont iconyanzhengma"></i>
                  <span slot="suffix">
                    <el-button
                      type="text"
                      :disabled="isGetingCode"
                      :style="{ color: styleConfig.activeColor }"
                      :loading="getCodeLoading"
                      @click="getCode"
                      @keydown.enter="handleSubmit"
                    >
                      <span v-if="!isGetingCode">
                        {{ $t('components.LoginByInput.642272-9') }}
                      </span>
                      <span v-else>
                        {{countdown}}{{ $t('components.LoginByInput.642272-10') }}
                      </span>
                    </el-button>
                  </span>
                </el-input>
              </el-form-item>
            </el-form>
            <el-button :style="{ background: styleConfig.activeColor, color: styleConfig.btnColor }" style="width:100%" size="lager" :loading="loginLoading" @click="handleSubmit">{{ $t('components.LoginByInput.642272-5') }}</el-button>
            <p class="message-info" :class="`${errorMessageType}-message`">
              <template v-if="showErrorMessage">
                <i
                  :class="
                    errorMessageType === 'error'
                      ? 'el-icon-warning'
                      : 'el-icon-success'
                  "
                ></i>
                <span class="message">{{ errorMessage }}</span>
              </template>
              <template v-else>
                <span>&nbsp;</span>
              </template>
            </p>
          </section>
        </transition>
      </div>
      <div class="footer">
        &nbsp;
        <!-- 免费注册功能暂不存在 -->
        <!-- 免费注册 -->
      </div>
    </div>
  </div>
</template>
<script>
import { dataInterface } from "@/apis/data/index";
import { setToken } from "@/utils/tools";
import mixin from "@/custom-component/login/mixin.js";
import JsEncrypt from 'jsencrypt';

export default {
  mixins: [mixin],
  props: {
    methodConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    styleConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(""));
      }
      const phoneReg =
        /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
      if (!phoneReg.test(value)) {
        return callback(new Error(""));
      } else {
        callback();
      }
    };
    return {
      loginMethod: "password",
      passwordFormData: {
        name: "",
        password: "",
      },
      passwordFormRules: {
        name: [{ required: true, message: "", trigger: "blur" }],
        password: [{ required: true, message: "", trigger: "blur" }],
      },
      codeFormData: {
        phone: "",
        code: "",
      },
      codeFormRules: {
        phone: [
          { required: true, message: "", trigger: "blur" },
          { validator: checkPhone, trigger: "change" },
        ],
        code: [{ required: true, message: "", trigger: "blur" }],
      },
      showErrorMessage: false,
      errorMessage: "",
      errorMessageType: "",
      countdown: 0,
      isGetingCode: false,
      getCodeLoading: false,
      loginLoading: false,
    };
  },
  watch: {
    methodConfig: {
      handler(n) {
        if (n && n.usePasswordLogin) {
          this.loginMethod = "password";
        } else if (n && n.useCodeLogin) {
          this.loginMethod = "code";
        } else {
          this.loginMethod = "";
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener("keydown", (e) => {
      if (e?.keyCode === 13 && window.location.href.includes("/login")) {
        this.handleSubmit();
      } 
    });
  },
  methods: {
    handleSubmit() {
      if (this.loginLoading) {
        return;
      }
      this.$refs[`${this.loginMethod}Form`].validate((valid) => {
        if (valid) {
          // 验证成功
          switch (this.loginMethod) {
            case "password": {
              this.doPasswordLogin();
              break;
            }
            case "code": {
              this.doCodeLogin();
              break;
            }
          }
        } else {
          this.errorMessage = this.$t('components.LoginByInput.642272-11')
          this.errorMessageType = 'error'
          this.changeShowErrorMessage()
        }
      });
    },
    // 登录。这里重写了minix中的方法，因为要使用一些状态
    doPasswordLogin() {
      this.handleLogin(this.passwordFormData);
    },
    doCodeLogin() {
      const data = {
        loginType: "phone",
        ...this.codeFormData,
      };
      this.handleLogin(data);
    },
    handleLogin(payload) {
      const loading = this.$loading({
        text: this.$t('components.LoginByInput.642272-12')
      });
      this.loginLoading = true;
      const key = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDAnjv69DQZgDq+S0DqHu8bRF9uwxmJdHuiBqvsZ8dLb2i37+T15JzCfYjRf6rtsXsNZ5MHC5FMeOZnoslrr6iGu6fFdmG8GBp2ZOQln0plFIV/sIxG6KSeXPnYhPYCYaz4Pz5HNh/KJL0VSphV6SEmDc6pl/Vhyhbd85MufUetMQIDAQAB-----END PUBLIC KEY-----';
      const jsonstr = JSON.stringify({payload});
      const Encrypt = new JsEncrypt();
      Encrypt.setPublicKey(key);
      const encryption_str = Encrypt.encrypt(jsonstr);
      
      dataInterface({
        __method_name__: 'globalFunctionCall',
        typeName: 'LoginFuncClass',
        type: 'behavior',
        funcName: 'LoginBehavior',
        // payload: payload,
        encryption_str
      })
        .then(async (res) => {
          if (
            !res ||
            res.status !== 200 ||
            !res.data ||
            res.data.code !== 200 ||
            !res.data.data
          ) {
          setTimeout(() => {
            loading.close();
          }, 400);
          this.errorMessage = res.data.msg || this.$t('components.LoginByInput.642272-13')
          this.errorMessageType = 'error'
          this.changeShowErrorMessage()
          return;
        }
        setTimeout(() => {
          loading.close();
        }, 400);
        this.$message.success(this.$t('components.LoginByInput.642272-14'));
        // 区分页面编辑器和正式环境
        const path = window.location.href;
          if (path.includes("/modify-page?") || path.includes("/page-view?")) {
          this.$nextTick(() => {
              this.$message.info(
                "编辑器环境不做后续操作，请前往dev或线上环境测试效果!"
              );
          });
          return false;
        }
        const userInfo = res.data.data;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          setToken(userInfo.token || "");
        // 首页
        const routeObj = {
          path: process.env.VUE_APP_HOME_PATH,
          query: {
              pageId: "",
              pageUUID: "",
            },
        };
          sessionStorage.setItem("indexPath", JSON.stringify(routeObj));
          localStorage.setItem("updatePwd", userInfo.is_update_pwd);
          const systemConfig = JSON.parse(localStorage.getItem("systemConfig"));
        if (userInfo.is_update_pwd === 0 && systemConfig?.force_password) {
          this.$router.replace({
              name: "PedestalUserCenter",
          });
          } else {
            const oldUrl = window.location.href.split("?");
          if (oldUrl.length > 1) {
            const toggleResult = await this.getCurrentArchi();
            if (!toggleResult) {
                this.$message.error("获取系统基本信息失败！");
              return false;
            }
              const urlString = oldUrl[1].split("url=")?.[1];
            this.$router.replace(decodeURIComponent(urlString));
            } else if (
              !systemConfig?.need_second &&
              systemConfig?.custom_route
            ) {
            // 跳转自定义页面
            // 应用体系
            const toggleResult = await this.getCurrentArchi();
            if (!toggleResult) {
              this.$message.error(this.$t('components.LoginByInput.642272-16'));
              return false;
            }
            // 跳转到指定页面或首页
            this.setCustomConfig(systemConfig?.custom_route);
          } else {
            // 跳转二屏
            this.$router.replace({
                name: "SecondScreen",
            });
          }
        }
        })
        .catch((err) => {
          console.log(err, "----err");
        setTimeout(() => {
          loading.close();
        }, 400);
        this.errorMessage = this.$t('components.LoginByInput.642272-13')
        this.errorMessageType = 'error'
        this.changeShowErrorMessage()
      }).finally(() => {
        setTimeout(() => {
          loading.close();
        }, 400);
          this.loginLoading = false;
        });
    },
    getCode() {
      if (!this.getCodeLoading && !this.isGetingCode) {
        this.$refs[`codeForm`].validateField("phone", (valid) => {
          if (!valid) {
            const phoneReg = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/
            if(!phoneReg.test(this.codeFormData.phone)) {
              this.errorMessage = this.$t('components.LoginByInput.642272-17')
              this.errorMessageType = 'error'
              this.changeShowErrorMessage()
              return
            }
            // 发个请求
            this.getCodeLoading = true;
            dataInterface({
              __method_name__: "globalFunctionCall",
              typeName: "LoginFuncClass",
              type: "behavior",
              funcName: "SendLoginSms",
              payload: {
                phone: this.codeFormData.phone,
              },
            })
              .then((res) => {
              if (res && res.status === 200) {
                this.errorMessage = this.$t('components.LoginByInput.642272-18')
                this.errorMessageType = 'success'
                this.changeShowErrorMessage()
                this.countdown = 60
                this.isGetingCode = true
                this.intervalTimer = setInterval(() => {
                    this.countdown -= 1;
                    if (this.countdown <= 0) {
                      clearInterval(this.intervalTimer);
                      this.isGetingCode = false;
                  }
                }, 1000);
              }
            }).catch((err) => {
              console.error(err)
              this.errorMessage = this.$t('components.LoginByInput.642272-19')
              this.errorMessageType = 'error'
              this.changeShowErrorMessage()
            }).finally(() => {
              this.getCodeLoading = false
            })
          } else {
            this.errorMessage = this.$t('components.LoginByInput.642272-20')
            this.errorMessageType = 'error'
            this.changeShowErrorMessage()
          }
        });
      }
    },
    changeShowErrorMessage() {
      if (this.showErrorMessage && this.timer) {
        clearTimeout(this.timer);
      }
      this.showErrorMessage = true;
      this.timer = setTimeout(() => {
        this.showErrorMessage = false;
        this.timer = null;
      }, 3000);
    },
    changeModel(type) {
      this.$emit("changeModel", type);
    },
  },
  beforeDestroy() {
    // window.removeEventListener('keydown');
  },
};
</script>
<style lang="less" scoped>
.login-wrap {
  width: 100%;
  height: 100%;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  .code-model {
    @seiz: 50px;
    position: absolute;
    right: 4px;
    top: 4px;
    width: @seiz;
    height: @seiz;
    &:hover {
      cursor: pointer;
      .code {
        transform: all 0.3s;
        z-index: 1;
      }
    }
    .code {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .model {
      position: absolute;
      right: 0;
      top: 6px;
      width: 0;
      height: 0;
      box-sizing: border-box;
      border: @seiz / 2 solid transparent;
      border-left: @seiz / 2 solid #fff;
      border-bottom: @seiz / 2 solid #fff;
    }
  }
  .code-tips {
    position: absolute;
    right: 54px;
    padding: 4px 8px;
    background: #e9f3ff;
    border: 1px solid rgba(1, 135, 252, 0.3);
    border-radius: 1px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--el-color-primary);
    &::before{
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      top: calc(50% - 4px);
      right: -4px;
      border: 1px solid rgba(1, 135, 252, 0.3);
      background: #e9f3ff;
      border-left: transparent;
      border-bottom: transparent;
      box-sizing: border-box;
      transform: rotateZ(45deg);
    }
  }
  .input-methods {
    margin-top: 40px;
    display: flex;
    padding: 0 24px;
    box-sizing: border-box;
    .methods-item {
      font-style: normal;
      line-height: 1.5;
    }
    .active {
      font-style: normal;
      border-bottom-width: 2px;
      border-bottom-style: solid;
    }
  }
  .input-main {
    flex-grow: 1;
    height: 10px;
    display: flex;
    flex-direction: column;
    .message-info {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 8px 0;
      box-sizing: border-box;
      .message {
        padding-left: 8px;
      }
    }
    .error-message {
      color: #e2a045;
    }
    .success-message {
      color: #70c241;
    }
    .input-form-wrap {
      flex-grow: 1;
      height: 10px;
      padding: 0 24px;
      display: flex;
      align-items: flex-end;
      box-sizing: border-box;
      .alternate-enter-active,
      .alternate-leave-active {
        transition: all 0.3s ease;
      }
      .alternate-enter, .alternate-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
      }
    }
    .login-by-password {
      width: 100%;
      .other-tips {
        height: 14px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin: 8px 0;
      }
    }
    .login-by-message {
      width: 100%;
      :deep(.phone-input) {
        .el-input__inner {
          padding: 0 15px 0 70px;
        }
        .phone-type {
          margin-left: 8px;
        }
        .iconfont {
          font-size: 16px;
        }
      }
      :deep(.code-input) {
        .el-input__inner {
          padding: 0 90px 0 30px;
        }
      }
    }
    .footer {
      padding: 8px 24px;
      box-sizing: border-box;
      text-align: right;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #a9adb8;
    }
  }
}
</style>
