/**
 * @description 功能组件相关配置
 */

const distActionConfig = {
  use: true,
  useDepend: false,
  dependLogic: [],
  type: 'detail',
  eventList: [],
  permission: [], // 权限
  archiLimit: [], // 架构限制
  paramsConfig: [], // 附加参数
};
// 功能组件小项配置
export const functionComponentsList = [
  {
    component: 'CommonPagination',
    name: '分页组件',
    icon: 'icongonneng-fyzj',
    isLock: false, // 是否锁定
    needCommonStyle: true,
    span: -1,
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      layout: 'prev, pager, next, sizes, total',
      size: 15,
    },
    style: {
      width: '100%',
      height: 'auot',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 50,
      letterSpacing: 0,
      textAlign: 'left',
      color:
        sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
      overflowX: 'hidden',
      overflowY: 'hidden',
      rotate: 0,
    },
    propValue: '/',
    actionConfig: [],
  },
  {
    component: 'CommonSwiper',
    type: 'swiper',
    name: '轮播图',
    icon: 'icongonneng-lbt',
    isLock: false, // 是否锁定
    needCommonStyle: true,
    span: 24,
    style: {
      height: '100%',
      width: '',
      rotate: 0,
      background:
        sessionStorage.getItem('editorType') === 'dataview'
          ? 'transparent'
          : '#fff',
    },
    propValue: '',
    statusConfig: {
      displayShow: true,
      isShow: true,
      height: '',
      initialIndex: 0,
      autoplay: true,
      interval: 3000,
      trigger: 'click',
      indicatorPosition: '',
      numberIndicator: false, // 显示数量指示器
      arrow: 'hover',
      type: '',
      loop: true,
      direction: 'horizontal',
      limit: 1,
      preview: false,
      showText: true,
      clickLink: false,
      useAllImages: false,
    },
    swiperConfig: {
      valueType: 'fixed', // 值类型  fixed 固定值，database 数仓
      fixedConfig: [
        // {
        // 	img: '', // 图片链接
        // 	text: '', // 描述文本
        // 	href: '' // 跳转链接
        //  hrefType: '' // 跳转方式
        // }
      ],
      databaseConfig: {
        objectUUID: '',
        viewUUID: '',
        imgUUID: '', // 图片链接
        textUUID: '', // 描述文本
        hrefUUID: '', // 跳转链接
        hrefTypeUUID: '', // 跳转方式
      },
    },
    paramsConfig: [], // 筛选参数
  },
  {
    component: 'NewCommonSwiper',
    type: 'swiper',
    name: '新轮播图',
    icon: 'icongonneng-lbt',
    isLock: false, // 是否锁定
    needCommonStyle: true,
    span: 24,
    style: {
      height: '100%',
      width: '',
      rotate: 0,
      background:
        sessionStorage.getItem('editorType') === 'dataview'
          ? 'transparent'
          : '#fff',
    },
    propValue: '',
    statusConfig: {
      displayShow: true,
      isShow: true,
      initialIndex: 0,
      autoplay: false,
      interval: 3000,
      trigger: 'click',
      indicatorPosition: '',
      numberIndicator: false, // 显示数量指示器
      arrow: 'hover',
      type: '',
      loop: true,
      direction: 'horizontal',
      switchTime: 1, //切换时间
      objectFit: 'cover', //轮播图片的填充方式
      swiperWidth: '270', // 轮播宽度
      limit: 1,
      preview: false,
      showText: true,
      clickLink: false,
      useAllImages: false,
    },
    swiperConfig: {
      valueType: 'fixed', // 值类型  fixed 固定值，database 数仓
      fixedConfig: [
        // {
        // 	img: '', // 图片链接
        // 	text: '', // 描述文本
        // 	href: '' // 跳转链接
        //  hrefType: '' // 跳转方式
        // }
      ],
      databaseConfig: {
        objectUUID: '',
        viewUUID: '',
        imgUUID: '', // 图片链接
        textUUID: '', // 描述文本
        hrefUUID: '', // 跳转链接
        hrefTypeUUID: '', // 跳转方式
      },
    },
    paramsConfig: [], // 筛选参数
    actionConfig: {
      needAction: true,
      rowClick: {
        use: true,
        useDepend: false,
        dependLogic: [],
        type: 'rowClick',
        name: '轮播点击',
        color: '#409EFF',
        btnType: 'text',
        btnSize: '',
        margin: 5,
        eventList: [],
        permission: [], // 权限
        archiLimit: [], // 架构限制
      },
    },
  },
  {
    component: 'CommonTree',
    type: 'tree',
    name: '树形图',
    icon: 'icongonneng-sxt',
    isLock: false, // 是否锁定
    needCommonStyle: true,
    style: {
      width: 200,
      height: 180,
      rotate: 0,
    },
    propValue: '',
    statusConfig: {
      isShow: true,
      displayShow: true,
      showSearch: true,
      placeholder: '请输入名称',
      enPlaceholder: 'Please enter a name',
      label: '组织名称',
      nodeKey: 'id',
      children: '子集架构',
      idUUID: '',
      disabled: false,
      // isLeaf: false,
      lazy: false,
      emptyText: '暂无数据',
      hideActions: false,
      appendChild: false,
      defaultExpandAll: true,
      showCheckBox: false,
      showCheckAll: false,
      defaultSelectAll: false,
      checkAllText: '全选',
      enCheckAllText: 'Select All',
      defaultSelectFirst: true,
      rowExpend: true,
      rowHoverBG: '',
      rowHoverColor: '',
      rowActiveBG: '',
      checkOnClickNode: true,
      nodeSelectSingle: false,
      resolveFullNode: false,
      resolveCodes: '',
      defaultHideAction: true,
      checkStrictly: false,
      useTooltip: false,
      leafCode: '',
    },
    // 组件定义的自定义属性
    customStatus: {},
    specialStyle: {
      isCollector: false, // 是否设为收集者 flex 1
      flexDirection: 'column', // flex 主轴方向
    },
    actionLimit: {
      permission: [], // 权限
      archiLimit: [], // 架构限制
    },
    // 删除权限
    deleteLimit: {
      permission: [], // 权限
      archiLimit: [], // 架构限制
    },
    actionConfig: [
      {
        key: 'appendChild',
        type: 'appendChild',
        name: '新增子集(特殊)',
        color: '#409EFF',
        outSide: false,
        eventList: [],
        permission: [], // 权限
        archiLimit: [], // 架构限制
      },
      {
        key: 'modify',
        type: 'modify',
        name: '编辑',
        color: '#409EFF',
        outSide: false,
        eventList: [],
        permission: [], // 权限
        archiLimit: [], // 架构限制
      },
      {
        key: 'add',
        type: 'add',
        name: '新增',
        color: '#409EFF',
        outSide: false,
        eventList: [],
        permission: [], // 权限
        archiLimit: [], // 架构限制
      },
      {
        key: 'rowClick',
        type: 'rowClick',
        name: '点击行',
        eventList: [],
        permission: [], // 权限
        archiLimit: [], // 架构限制
      },
      {
        key: 'check',
        type: 'check',
        name: '点击复选框',
        eventList: [],
        permission: [], // 权限
        archiLimit: [], // 架构限制
      },
      {
        key: '$selectedNode',
        type: '$selectedNode',
        name: '选中节点',
        eventList: [],
        permission: [], // 权限
        archiLimit: [], // 架构限制
      },
      {
        key: '$uncheckNode',
        type: '$uncheckNode',
        name: '取消选中',
        eventList: [],
        permission: [], // 权限
        archiLimit: [], // 架构限制
      },
    ],
    // 图标配置
    iconConfig: {
      type: '',
      fixedConfig: {
        preIcon: '',
        actionIcon: '',
      },
      customConfig: {
        preList: [],
        actionList: [],
      },
    },
    behaviors: [
      {
        label: '选中指定行',
        value: 'onSelectItem',
        desc: '参数名为【key】，参数值为【唯一标识】/【id的UUID】对应的UUID的取值',
      },
      {
        label: '改变数据状态',
        value: 'changeDataValue',
        desc: '必须的参数项：1.参数名【rowKey】，参数值为需要改变行的【唯一标识】/【id的UUID】对应的UUID的取值；2.参数名【field】, 参数值为需要改变的字段名（自定义）或UUID；3.参数名【value】, 参数值为新的值',
      },
    ],
  },
  {
    component: "CommonCalendar",
    type: "container",
    name: "日历",
    icon: "icongonneng-rl",
    isLock: false, // 是否锁定
    needCommonStyle: true,
    propValue: '',
    span: -1,
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      type: 'date', // 日历类型
      calendarType: 'base', // 基础日历
      headerFormat: 'sundayEnd', // 日历头部类型
      valueFormat: 'YMDhms', // 值类型
      startYear: 2008, // 开始年份
      yearNum: 20, // 年份展示数量
      totalTaking: 'odd', // 总数默认取偶数
      finishTaking: 'even', // 已完成默认取奇数
      haveData: true,
      noData: true,
      haveDataColor:'rgb(251, 98, 92)',
      noDataColor:'rgb(59, 203, 206)',
      customDataColor:{
        field: '',
        customColorData: [],
      },//自定义状态
    },
    style: {
      height: '',
      width: '100%',
      rotate: 0,
    },
    countConfig: {
      finish_object_uuid: '',
      finish_view_uuid: '',
      finish_field_uuid: '',
      finish_aggregate: '4',
      finishParams: {
        userOutParams: false,
        paramsConfig: [],
      },
      total_object_uuid: '',
      total_view_uuid: '',
      total_field_uuid: '',
      total_aggregate: '4',
      totalParams: {
        userOutParams: false,
        paramsConfig: [],
      },
    },
  },
  {
    component: 'CommonDateFilter',
    type: 'form',
    name: '日期组',
    icon: 'icongonneng-rqz',
    isLock: false, // 是否锁定
    needCommonStyle: true,
    propValue: '',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      valueFormat: 'yyyy-MM-dd',
      monthDay: 31,
      transparent: false,
      selectHeight: 36,
      hideBorder: false,
      hideIcon: false,
      shortcuts: [],
      defaultShortcut: '',
    },
    style: {
      width: '',
      height: '',
      minHeight: 34,
    },
  },
  {
    component: 'CommonBaseDate',
    type: 'form',
    name: '基础日期',
    icon: 'icongonneng-jcrq',
    isLock: false, // 是否锁定
    needCommonStyle: true,
    propValue: '',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      valueFormat: 'yyyy-MM-dd',
      type: 'date', // 类型
      shortcuts: 'targetMonth', // 快捷选择备选项
      transparent: false,
      // selectWidth: 220,
      selectHeight: 36,
      hideIcon: false,
      hideBorder: false,
      toDay: true,
      dayType: '',
      inputDefault: '',
      clearable: false,
    },
    style: {
      width: '',
      height: '',
      minHeight: 34,
    },
  },
  {
    component: 'CommonNetDisk',
    type: 'container',
    name: '网盘',
    icon: 'icongonneng-wp',
    isLock: false, // 是否锁定
    needCommonStyle: true,
    propValue: '',
    span: -1,
    statusConfig: {
      displayShow: true,
      partName: '', // 当前模块名称
      isShow: true, // 是否显示
      newFolder: true, // 新建文件夹
      useCreateLink: false, // 创建链接
      usePlaceholder: false,
      mode: '',
      canUpload: true, // 上传文件
      pushFile: true, // 添加文件(从功能网盘)
      showSearch: true, // 使用搜索
      changeModel: false, // 切换显示模式
      showActions: true, // 显示操作
      showInfoBtn: true, // 显示详情按钮
      showModifyBtn: true, // 显示编辑按钮
      showShareBtn: true, // 显示分享按钮
      showDownloadBtn: true, // 显示下载按钮
      showMoveBtn: true, // 显示移动按钮
      showRemoveBtn: true, // 显示删除按钮
      multiple: false, // 多文件上传
      batchAction: false, // 批量操作
    },
    style: {
      height: '100%',
      width: '100%',
      rotate: 0,
    },
    actionConfig: {
      upload: {
        ...distActionConfig,
        order: 1,
        name: '上传文件',
      },
      create: {
        ...distActionConfig,
        order: 2,
        name: '新增',
      },
      plus: {
        ...distActionConfig,
        order: 3,
        name: '添加文件',
      },
      details: {
        ...distActionConfig,
        order: 4,
        name: '详情',
      },
      download: {
        ...distActionConfig,
        order: 5,
        name: '下载',
      },
      share: {
        ...distActionConfig,
        order: 6,
        name: '分享',
      },
      move: {
        ...distActionConfig,
        order: 7,
        name: '移动',
      },
      rename: {
        ...distActionConfig,
        order: 8,
        name: '重命名',
      },
      delete: {
        ...distActionConfig,
        order: 9,
        name: '删除',
      },
    },
    businessConfig: {
      // 业务自定义配置
      objectUUID: '', // 对象
      defaultView: '', // 默认视图
      detailsView: '', // 详情视图不填则使用默认视图
    },
    operationConfig: {
      fileViewUUID: '', // 文件视图UUID
      withClass: false,
      objectUUID: '',
      viewUUID: '',
      idUUID: '',
      labelUUID: '',
      childrenUUID: '',
      typeKey: '',
    },
  },
  {
    component: 'CommonSelectPerson',
    type: 'selectPerson',
    name: '选择人员',
    icon: 'icongonneng-xzry',
    isLock: false, // 是否锁定
    needCommonStyle: false,
    propValue: '',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      filter: true,
      filterText: '请输入关键字',
      label: 'label',
      key: 'id',
      children: 'children',
    },
    style: {
      rotate: 0,
    },
    actionConfig: [
      {
        key: 'confirm',
        effect: false,
        effectType: '',
        canCustom: false,
        postType: '', // 请求类型
        buttonType: 'primary',
        text: '确认',
        objectUUID: '', // 对象UUID
        viewUUID: '', // 视图UUID
      },
      {
        key: 'cancel',
        effect: false, // 是否有附加作用
        effectType: '', // 附加操作类型 请求类型
        canCustom: false, // 是否可以删除
        buttonType: '',
        text: '取消',
        postType: '', // 请求类型
        objectUUID: '', // 对象UUID
        viewUUID: '', // 视图UUID
      },
    ],
  },
  {
    component: 'CommonFlow',
    type: 'flowEngine',
    name: '旧版流程',
    icon: 'icongonneng-jblc',
    isLock: false, // 是否锁定
    needCommonStyle: true,
    propValue: '',
    span: 24,
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
    },
    style: {
      height: '500',
      width: '',
      rotate: 0,
    },
    flowConfig: {
      configType: '',
      object_uuid: '',
      view_uuid: '',
      form_uuid: '',
      customConfig: {
        // 自定义组件取值来源
        componentId: '',
        object_uuid_field: '',
        view_uuid_field: '',
        _uuid_field: '',
      },
    },
  },
  {
    component: 'NewCommonFlow',
    type: 'flowEngine',
    name: '新版流程',
    icon: 'icongonneng-xblc',
    isLock: false, // 是否锁定
    needCommonStyle: true,
    propValue: '',
    span: 24,
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
    },
    style: {
      height: '500',
      width: '',
      rotate: 0,
    },
    flowConfig: {
      configType: '',
      object_uuid: '',
      view_uuid: '',
      form_uuid: '',
      is_template: false, // 是否模板流程
      objectUUID: '',
      formUUID: '', // 模板表单
      customConfig: {
        // 自定义组件取值来源
        componentId: '',
        object_uuid_field: '',
        view_uuid_field: '',
        _uuid_field: '',
      },
    },
  },
  {
    name: '视频组件',
    component: 'LiveCom',
    type: 'container',
    icon: 'icongonneng-spzj',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      src: '',
      videoType: 'Other',
      screen_sequence: 1,
      showControl: false,
      autoplay: true,
      useInCG: false,
      outerNet: false, //是否外网
    },
    style: {
      mixBlendMode: 'normal',
      width: '100%',
      height: '100%',
    },
  },
  {
    name: '天气',
    component: 'WeatherCom',
    type: 'functionCom',
    icon: 'icongonneng-tqr',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      showWeather: true,
      showAirpm: true,
      weatherMargin: '30px',
      showIcon: true,
    },
    paramsConfig: [], // 筛选参数
  },
  {
    name: '新版天气',
    component: 'WeatherComNew',
    type: 'functionCom',
    icon: 'icongonneng-xbtq',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      showWeather: true,
      showAirpm: true,
      showHumidity: true,
      showWindSpeed: true,
      mainColor: '#fff',
      secondaryColor: '#5CF691',
    },
    paramsConfig: [], // 筛选参数
  },
  {
    name: '天气-日',
    component: 'WeatherDay',
    type: 'functionCom',
    icon: 'icongonneng-tq',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
    },
    style: {
      borderRadius: '4px',
      padding: '6px',
    },
    paramsConfig: [], // 筛选参数
  },
  {
    name: '天气-周',
    component: 'WeatherWeek',
    type: 'functionCom',
    icon: 'icongonneng-tqz',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      itemHide: [], // 隐藏条目
      itemBackground: 'rgba(255, 255, 255, 0.6)',
      showItemHover: false,
      showSpecialBorder: false,
    },
    style: {
      borderRadius: '4px',
      padding: '12px',
    },
    paramsConfig: [], // 筛选参数
  },
  {
    name: '轴列表',
    component: 'AxisList',
    type: 'container',
    icon: 'icongonneng-zlb',
    fieldConfig: [],
    listConfig: {
      axisDirection: 'x',
      itemPosition: 'right',
      title: '轴列表',
      enTitle: 'Axis List',
      itemWidth: 200,
      itemHeight: 100,
      isBaseBg: true, //手动背景色
      centerDot: false, //点居中
      isDashed: false, //虚线轴
      itemBg: '#fff',
      textAlign: 'left',
      itemCol: '#000000',
      topFields: [], //置顶参数
    },
    statusConfig: {
      isShow: true, // 是否显示
      displayShow: true,
    },
    style: {},
  },
  {
    name: '新登录页',
    component: 'CommonLoginNew',
    type: 'functionCom',
    icon: 'icongonneng-xdly',
    statusConfig: {
      isShow: true, // 是否显示
      usePasswordLogin: true,
      useCodeLogin: false,
      useResetPassword: false,
      userQRCodelogin: false,
      tabsMargin: 8,
      tabsFontSize: 16,
      tabsFontWeight: 'normal',
      formItemMargin: 22,
      activeColor: '#409eff',
      btnColor: '#fff',
      inputStyle: 'border',
      inputColor: '#606266',
      placeholderColor: '',
      forgetPasswordMargin: 0,
      forgetPasswordFontSize: 12,
    },
    style: {
      height: 400,
      width: 350,
    },
  },
  {
    name: '语音控制',
    component: 'VoiceAssistant',
    type: 'functionCom',
    icon: 'iconyuyin',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
    },
    paramsConfig: [], // 筛选参数
  },
  {
    name: '云台控件(沙特)',
    component: 'Rocker',
    type: 'functionCom',
    icon: 'icon-qita',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      outerNet: false, //是否外网
    },
    style: {
      height: 400,
      width: 400,
    },
    actionConfig: {},
    // 没有请求，固定meateData
    metadata: {
      event_name: 'event_name',
    },
  },
];
// 初始化组件列表
const initComponentsList = (list) => {
  // 公共配置
  const publicConfig = {
    type: '',
    icon: 'iconyeqian',
    isLock: false, // 是否锁定
    needCommonStyle: true,
    propValue: '',
    span: -1,
    style: {
      height: 400,
      width: '',
      rotate: 0,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    modelConfig: {
      appId: '',
      appSecret: '',
      modelId: {
        type: '',
        value: '',
      },
    },
  };
  // 返回融合后的配置
  return list.map((el) => {
    return Object.assign({}, publicConfig, el);
  });
};
const functionCom = {
  type: 'functionCom',
  title: '功能组件',
  show: true,
  key: 2,
  order: 3,
  componentList: initComponentsList(functionComponentsList),
};
export default functionCom;
