/*
 * @Description: 图表
 * @Author: luocheng
 * @Date: 2021-10-15 14:52:56
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-06-21 15:55:46
 */
// 图表
const decoration = {
  type: 'decoration',
  title: '装饰组件',
  show: true,
  key: 5,
	order: 12,
  componentList: [
    {
      name: '渐变背景',
      component: 'GradientBackground',
      icon: 'iconzhuangshi-jbbj',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        mainColor: '#666',
        secondaryColor: '#aaa',
        direction: ''
      }
    },
    {
      name: '磨砂边框',
      type: 'decoration',
      component: 'BorderBox15',
      icon: 'iconzhuangshi-jbbj',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        backgroundColor: '',
        blur: 6
      },
      style: {
        height: '100%',
        width: '',
        rotate: 0,
      }
    },
  ]
};

export default decoration;