/*
 * @Description: 图表
 * @Author: luocheng
 * @Date: 2021-10-15 14:52:56
 * @LastEditors: asahi
 * @LastEditTime: 2023-11-09 09:54:57
 */
// 图表
const UEComs = {
  type: 'model',
  title: 'UE组件',
  show: true,
  key: 3,
	order: 10,
  componentList: [
    {
      component: 'UEClient',
      type: 'container',
      name: 'UE事件中心',
      icon: 'iconue-sjlt',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true // 是否显示
      },
      style: {
        height: '100%',
        width: '',
        rotate: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0
      },
      behaviors: [
      ],
      actionConfig:{
        needAction: true,
      },
    },
    {
      component: 'UEStreamClient',
      type: 'container',
      name: 'UE推流客户端',
      icon: 'iconue-sjlt',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true // 是否显示
      },
      style: {
        height: '100%',
        width: '',
        rotate: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0
      },
      behaviors: [
      ],
      actionConfig:{
        needAction: true,
      },
    },
    {
      component: 'UEStreamViews',
      type: 'container',
      name: 'UE推流视点',
      icon: 'iconue-sjlt',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true // 是否显示
      },
      style: {
        height: '100%',
        width: '',
        rotate: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0
      },
      behaviors: [
      ],
      actionConfig:{
        needAction: true,
      },
    },
  ]
};

export default UEComs;