<!--
    @name: SendProp
    @description：SendProp
    @author: ZengWei
    @date: 2022-08-27 11:27
-->
<template>
	<div>
		<el-form
			ref="form"
			:model="flowSend"
			:label-width="{ en: '150px' }[$i18n.locale] || '80px'"
			label-position="left"
			size="small"
		>
			<el-collapse v-model="activeNames">
				<el-collapse-item name="basic">
					<template #title>
						<span class="collapse-title">{{ $t('cell-panel.SendProp.975649-0') }}</span>
					</template>
					<el-form-item :label="$t('cell-panel.SendProp.975649-1')">
						<el-input v-model="flowSend.name" @change="onChange" :disabled="disabled"></el-input>
					</el-form-item>
				</el-collapse-item>
				<el-collapse-item name="send">
					<template #title>
						<span class="collapse-title">{{ $t('cell-panel.SendProp.975649-2') }}</span>
					</template>
					<el-form-item label="" label-width="0px">
						<el-radio-group v-model="flowSend.userType" @change="onUserType" :disabled="disabled">
							<el-radio :label="1">{{ $t('cell-panel.SendProp.975649-3') }}</el-radio>
							<el-radio :label="2">{{ $t('cell-panel.SendProp.975649-4') }}</el-radio>
							<!--<el-radio :label="3">主管（负责人）</el-radio>-->
							<el-radio :label="5">{{ $t('cell-panel.SendProp.975649-5') }}</el-radio>
							<el-radio :label="6">{{ $t('cell-panel.SendProp.975649-6') }}</el-radio>
							<el-radio :label="7">{{ $t('cell-panel.SendProp.975649-7') }}</el-radio>
							<el-radio :label="8">{{ $t('cell-panel.SendProp.975649-8') }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item :label="$t('cell-panel.SendProp.975649-9')" v-if="[1, 2, 6, 7].indexOf(flowSend.userType) > -1">
						<el-radio-group v-model="flowSend.userFrom" size="small" :disabled="disabled" @change="onUserScope">
							<el-radio-button :label="1">{{ $t('cell-panel.SendProp.975649-10') }}</el-radio-button>
							<el-radio-button :label="3">{{ $t('cell-panel.SendProp.975649-11') }}</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item v-if="flowSend.userType === 1" :label="$t('cell-panel.SendProp.975649-12')">
						<UserPicker
							:key="'send' + flowSend.userArch"
							:show-choose-button="true"
							:flowArchiType="flowSend.userFrom == 3 ? 6 : 2"
							:flowArchiId="[archiId]"
							:company-id="flowSend.userArch"
							:show-list="flowSend.sendUser"
							@on-select-change="onSelectUser"
						>
						</UserPicker>
					</el-form-item>
					<el-form-item v-if="flowSend.userType === 5" :label="$t('cell-panel.SendProp.975649-13')">
						<DepartPicker
							:show-choose-button="true"
							:show-list="flowSend.userSection"
							@on-select-change="onSelectDepart"
						>
						</DepartPicker>
					</el-form-item>
					<el-form-item v-if="flowSend.userType === 6" :label="$t('cell-panel.SendProp.975649-14')">
						<el-select
							style="width: 100%"
							multiple
							v-model="flowSend.userJob"
							@change="onChange"
							:disabled="disabled"
							:placeholder="$t('cell-panel.SendProp.975649-15')"
							class="full-width"
						>
							<el-option v-for="item in jobList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<!--          <el-form-item v-if="[1,5,6].includes(flowSend.userType)" label="人员范围">
            <el-switch
              v-model="flowSend.userScope"
              :disabled="disabled"
              @change="onChange"
            ></el-switch>
            <span style="margin-left: 15px">开启：已选择人员作为选择范围</span>
          </el-form-item>-->
				</el-collapse-item>
			</el-collapse>
		</el-form>
	</div>
</template>

<script>
// import {RadioButton} from 'element-ui'
import { Collapse, CollapseItem, RadioGroup, Radio } from 'element-ui';
import UserPicker from '@/custom-component/form/formParser/selectUser';
import DepartPicker from '@/custom-component/form/formParser/flowEngine/components/selectDepartment';
import { dataInterface } from '@/apis/data';

export default {
	name: 'SendProp',
	components: {
		UserPicker,
		DepartPicker,
		'el-collapse': Collapse,
		'el-collapse-item': CollapseItem,
		'el-radio-group': RadioGroup,
		'el-radio': Radio
		// 'el-radio-button': RadioButton,
	},
	props: {
		curCellProp: {
			type: Object,
			default: () => {}
		},
		userData: {
			type: Array,
			default: () => []
		},
		disabled: {
			type: Boolean,
			default: false
		},
		archiId: {
			type: String,
			default: ''
		},
		parentArchiId: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			activeNames: 'basic',
			jobList: [],
			flowSend: {
				name: this.$t('cell-panel.SendProp.975649-16'),
				userType: 1,
				userFrom: 1,
				userArch: this.archiId,
				userScope: 1,
				userSection: [],
				userJob: [],
				sendUser: []
			}
		};
	},
	mounted() {
		this.initProp();
	},
	methods: {
		onSelectDepart(departData) {
			let department = [];
			for (let item of departData) {
				let depart = {
					id: item.id,
					name: item.name,
					type: item.type
				};
				department.push(depart);
			}
			this.flowSend.userSection = department;
			this.onChange();
		},
		onSelectUser(userData) {
			let users = [];
			for (let item of userData) {
				let user = {
					id: item.user_id,
					name: item.name,
					user_table: item.user_table,
					section_id: item.section_id,
					user_id: item.user_id
				};
				users.push(user);
			}
			this.flowSend.sendUser = users;
			this.onChange();
		},
		initProp() {
			this.flowSend = this.curCellProp;
			this.flowStep.userArch = this.flowStep.userFrom === 3 ? this.parentArchiId : this.archiId;
			if (this.flowSend.userType === 6) {
				this.getArchRole(); //请求岗位接口
			}
		},
		onUserType() {
			this.onUserScope();
		},
		getArchRole() {
			let queryData;
			const archiInfo = this.$GetTargetArchi();
			if (this.flowSend.userFrom === 1) {
				queryData = {
					object_uuid: 'object6170c260890d9',
					view_uuid: 'view6172842c7f669',
					project_id: archiInfo.archi_id,
					transcode: 0,
					__method_name__: 'dataList'
				};
			} else {
				queryData = {
					object_uuid: 'object6170c260890d9',
					view_uuid: 'view61728398b0390',
					company_id: archiInfo.company_id,
					transcode: 0,
					__method_name__: 'dataList'
				};
			}
			dataInterface(queryData).then((res) => {
				if (res.data.code === 200) {
					this.jobList = res.data.data;
				}
			});
		},
		onUserScope() {
			if (this.flowSend.userType === 6) {
				this.getArchRole(); //请求岗位接口
			}
			if (this.flowStep.userFrom === 3) {
				this.flowStep.userArch = this.parentArchiId;
			} else {
				this.flowStep.userArch = this.archiId;
			}
			this.onChange();
		},
		onChange() {
			this.$emit('on-change', this.flowSend);
		}
	}
};
</script>

<style lang="less" scoped>
.collapse-title {
	text-indent: 15px;
}

:deep(.el-radio) {
	margin-top: 8px;
	margin-bottom: 5px;
}

:deep(.el-collapse-item__content) {
	padding-bottom: 0;
}

:deep(.el-collapse-item__header) {
	position: relative;

	&:before {
		position: absolute;
		top: 16px;
		width: 3px;
		height: 14px;
		margin-right: 10px;
		background-color: var(--themeColor) !important;
		content: '';
	}
}
</style>
