/*
 * @Description: 表单相关
 * @Author: luocheng
 * @Date: 2021-10-15 14:52:31
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-10-18 15:41:45
 */
const form = {
  type: 'form',
  title: '表单组件',
  show: true,
  key: 2,
  order: 4,
  componentList: [
		{
			component: 'CommonDialog',
			type: 'dialog',
			name: '弹窗',
			icon: 'iconbiaodan-tc',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			style: {
				minHeight: '',
				width: '',
				rotate: 0
			},
			propValue: '',
			statusConfig: {
				displayShow: true,
				isShow: true,
				firstType: 'dialog',
				showConfirm: true,
				confirmText: '确认',
				enConfirmText: 'Confirm',
				confirmType: 'primary',
				showCancel: true,
				cancelText: '取消',
				enCancelText: 'Cancel',
				cancelType: '',
				title: '这是标题',
				enTitle: 'This is the title',
				width: '50%',
				fullscreen: false,
				top: '5vh',
				drawerWidth: 400,
				modal: true,
				closeOnClickModal: false,
				closeOnPressEscape: true,
				showClose: true,
				center: false,
				showExcel: false,
				preferredType: 'form',
				useCache: false,
				autoSave: false,
				createWithDetails: false,
				closeConfigCache: false,
				showShareBtn: true,
				showFullScreen:true,
				showChangeMode:false,
			},
			contentConfig: {
				contentType: '', // 内容类型
				configType: '', // 配置类型 '' 普通类型， dynamic 动态配置
				dynamicComponent: '', // 动态组件
				objectUUID: '', // 数据对象
				viewUUID: '', // 视图ID
				pageId: '', // 页面id
				formUUID: '', // 表单对象
				newFlow: false, // 启用新流程
				developPageRoute: '', // 首页页面路由
			},
			actionConfig: [
				{
					key: 'cancel',
					effect: false, // 是否有附加作用
					effectType: '', // 附加操作类型 请求类型
					canCustom: false, // 是否可以删除
					buttonType: '',
					text: '取消',
					postType: '', // 请求类型
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				{
					key: 'confirm',
					effect: false,
					effectType: '',
					canCustom: false,
					postType: '', // 请求类型
					buttonType: 'primary',
					text: '确认',
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			],
			updateRulesConfig: {
				pattern: '',
				rules: [
					// {
					//   componentId: '' // 需要更新的组件
					// }
				]
			},
			formFillConfig: []
		},
		{
			component: 'CommonEmailShow',
			type: 'emailShow',
			name: '邮件显示',
			icon: 'iconbiaodan-tc',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			style: {
				height: '100%',
				width: '100%'
			},
			propValue: '',
			statusConfig: {
				displayShow: true,
				isShow: true
			},
			sourceConfig: [
				{
					key: "form_uuid",
					label: '表单UUID',
					originType: 'url',
					urlParamKey: 'form_uuid',
					componentId: "",
					field: "",
					isRequired: false,
					systemKey: "",
					systemCode: "",
					fixedValue: "",
				},							
				{
					key: "data_id",
					label: '数据ID',
					originType: 'url',
					urlParamKey: 'data_id',
					componentId: "",
					field: "",
					isRequired: false,
					systemKey: "",
					systemCode: "",
					fixedValue: "",
				},
				{
					key: "archi_type",
					label: '模拟架构信息',
					originType: 'url',
					urlParamKey: 'archi_type',
					componentId: "",
					field: "",
					isRequired: false,
					systemKey: "",
					systemCode: "",
					fixedValue: "",
				},
				{
					key: "title",
					label: '标题(非必须)',
					originType: 'url',
					urlParamKey: 'title',
					componentId: "",
					field: "",
					isRequired: false,
					systemKey: "",
					systemCode: "",
					fixedValue: "",
				},
				{
					key: "tag",
					label: '用途位置(预保留)',
					originType: 'url',
					urlParamKey: 'tag',
					componentId: "",
					field: "",
					isRequired: false,
					systemKey: "",
					systemCode: "",
					fixedValue: "",
				}
				// {
				// 	key: "disabled",
				// 	label: '是否禁用(非必须)',
				// 	originType: 'url',
				// 	urlParamKey: 'disabled',
				// 	componentId: "",
				// 	field: "",
				// 	isRequired: false,
				// 	systemKey: "",
				// 	systemCode: "",
				// 	fixedValue: "",
				// },
			]
		},
    {
      component: 'CommonForm',
      type: 'form',
      name: '表单筛选',
      icon: 'iconbiaodan-bdsx',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        showButton: true, // 显示操作按钮
        quickSearch: false, // 快速搜索
      },
      style: {
        minHeight: 40,
        height: '',
        width: '',
        rotate: 0,
        paddingTop: 18,
        paddingBottom: 0,
        paddingLeft: 15,
        paddingRight: 15
      },
      actionConfig: [
        {
          label: '查询',
          type: 'search',
          btnType: 'primary',
          icon: '',
          show: true,
          permission: [], // 权限
          archiLimit: [], // 架构限制
        },
        {
          label: '重置',
          type: 'reset',
          btnType: '',
          icon: '',
          show: true,
          permission: [], // 权限
          archiLimit: [], // 架构限制
        },
        {
          label: '导出',
          type: 'export',
          btnType: '',
          icon: '',
          show: false,
          eventList: [],
          permission: [], // 权限
          archiLimit: [], // 架构限制
        },
        {
          label: '新增',
          type: 'add',
          btnType: '',
          icon: 'el-icon-plus',
          show: false,
          eventList: [],
          permission: [], // 权限
          archiLimit: [], // 架构限制
        }
      ]
    },
		{
      component: 'CommonCanvasTable',
      type: 'container',
      name: 'canvas表格',
      icon: 'iconbiaodan-bdsx',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        showButton: true, // 显示操作按钮
        quickSearch: false, // 快速搜索
      },
      style: {
        minHeight: 40,
        height: '',
        width: '',
        rotate: 0,
        paddingTop: 18,
        paddingBottom: 0,
        paddingLeft: 15,
        paddingRight: 15
      },
      actionConfig: [
        {
          label: '查询',
          type: 'search',
          btnType: 'primary',
          icon: '',
          show: true,
          permission: [], // 权限
          archiLimit: [], // 架构限制
        },
        {
          label: '重置',
          type: 'reset',
          btnType: '',
          icon: '',
          show: true,
          permission: [], // 权限
          archiLimit: [], // 架构限制
        },
        {
          label: '导出',
          type: 'export',
          btnType: '',
          icon: '',
          show: false,
          eventList: [],
          permission: [], // 权限
          archiLimit: [], // 架构限制
        },
        {
          label: '新增',
          type: 'add',
          btnType: '',
          icon: 'el-icon-plus',
          show: false,
          eventList: [],
          permission: [], // 权限
          archiLimit: [], // 架构限制
        }
      ]
    },
		{
			component: 'CommonTableContainer',
			type: 'container',
			name: '表格容器',
			icon: 'iconbiaodan-bgrq',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				idUUID: '', // id uuid
				loading: false,
				stripe: false,
        autoRoll: false,
        rollTime: 50,
				border: false,
				size: '', // medium / small / mini
				rowKey: '',
				fit: true,
				showHeader: true,
				highlightCurrentRow: false,
				rowShowDetails: false,
				emptyText: '暂无数据',
				// 单元格属性
				align: 'center',
				useStyle: false,
				headerAlign: '',
				canSelect: false,
				showOrder: false,
				pagination: true,
				defaultPerPage: 15,
				customPagination: false,
				perList: '15, 30, 50, 100',
				isTreeTable: false,
				children: '下级数据',
				defaultExpandAll: true,
				isNesting: false,
				nestingKey: '子表数据',
				nestingRowKey: '',
				nestingObjectUUID: '',
				useSort: false,
				useFeWBS: false,
				// keepWBS: false,
				orderWidth: 50,
				feTreeKey: '',
				fixColumn: '',
				transparent: false
			},
			propValue: '',
			style: {
				minHeight: '',
				width: '',
				height: 400,
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			actionConfig: {
				needAction: false,
				width: '',
				maxOrder: 6,
				add: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'add',
					name: '新增',
					btnType: 'text',
					color: '#67C23A',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
					order: 1
				},
				detail: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'detail',
					name: '详情',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
					order: 2
				},
				modify: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'modify',
					name: '编辑',
					color: '#E6A23C',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
					order: 3
				},
				export: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'export',
					name: '导出',
					btnType: 'text',
					color: '#E6A23C',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
					order: 4
				},
				delete: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'delete',
					name: '删除',
					btnType: 'text',
					color: '#F56C6C',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
					order: 5
				}
			},
			// 导出配置
			exportConfig: {
				exportWay: '', // 导出方式
				exportType: 'targetRow', // 类型预留
				exportTemplate: '', // 导出模板
				exportRequest: '', // 导出请求
				exportTypeSheet: 'pdf', // 导出类型 [excel | image | pdf]
				objectUUID: '', // 导出对象UUID
				viewUUID: '', // 导出视图UUID
				exportTemplateField: '', // 模板字段
				objectUUIDField: '', // 模板导出对象字段
				viewUUIDField: '', // 模板导出视图字段
			},
			// 选中被用与操作的(需要保持住，因此需要单独字段进行保持)
			cacheData: null,
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'column' // flex 主轴方向
			},
			fieldConfig: [], // 字段配置列表
			nestingFieldConfig: [], // 嵌套表格字段配置
			nestingActionConfig: { // 嵌套表格的操作
				needAction: false,
				width: '',
				add: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'add',
					name: '新增',
					btnType: 'text',
					color: '#67C23A',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				detail: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'detail',
					name: '详情',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				modify: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'modify',
					name: '编辑',
					color: '#E6A23C',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				delete: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'delete',
					name: '删除',
					btnType: 'text',
					color: '#F56C6C',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				export: {
					use: false,
					useDepend: false,
					dependLogic: [],
					type: 'export',
					name: '导出',
					btnType: 'text',
					color: '#E6A23C',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			},
			interfaceInterval: null // 定时刷新配置
		},
    {
      name: '表格列表',
      component: 'TableCom',
      type: 'container',
      icon: 'iconbiaodan-bglb',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        showHeader: true,
        showIndex: false,
        pagination: true,
        headerHieght: '30px',
        headerBackGround: '#fff',
        headerTransparent: true,
        headerColor: '#666',
        headerFontSize: '16px',
				headerFillet: false,
        bodyHieght: '30px',
        bodyBackGround: '#fff',
        bodyTransparent: true,
        bodyColor: '#666',
        bodyFontSize: '16px',
        transparentPagination: false,
        useSort: false,
        userInnerBorder: false,
        borderColor: '',
        borderWidth: 0,
        stripe: false,
        oddRowsBackground: '',
        evenRowsBackground: '',
				orderPointShow: false,
				orderPointColor: '#409eff'
      },
			style: {
				color: '#fff'
			}
    },
    {
      name: '滚动表格列表',
      component: 'ScrollTableCom',
      type: 'container',
      icon: 'iconbiaodan-gdbg',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        showHeader: true,
        showIndex: false,
        loopScroll: true,
        rowNumber: 3,
        fullPageScrolling: false,
        duration: 3,
        headerHieght: '30px',
        headerBackGround: '#fff',
        headerTransparent: true,
        headerColor: '#666',
        headerFontSize: '16px',
				headerFillet: false,
        bodyHieght: '30px',
        bodyBackGround: '#fff',
        bodyTransparent: true,
        bodyColor: '#666',
        bodyFontSize: '16px',
        stripe: false,
        oddRowsBackground: '',
        evenRowsBackground: '',
				orderPointShow: false,
				orderPointColor: '#409eff',
				useTemplate: false
      },
      style: {
        height: 200,
				color: '#fff',
        width:'100%',
      }
    },
		{
			component: 'CommonFormContainer',
			type: 'container',
			name: '表单容器',
			icon: 'iconbiaodan-bdrq',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				showAction: false,
				jumpToEdit: false, // 是否能跳转到编辑 @凌志华
			},
			propValue: '',
			style: {
				width: '',
				height: 400,
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			actionConfig: [
				{
					key: 'cancel',
					effect: false, // 是否有附加作用
					effectType: '', // 附加操作类型 请求类型
					canCustom: false, // 是否可以删除
					buttonType: '',
					text: '取消',
					postType: '', // 请求类型
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
				},
				{
					key: 'confirm',
					effect: false,
					effectType: '',
					canCustom: false,
					postType: '', // 请求类型
					buttonType: 'primary',
					text: '确认',
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
				}
			],
			fieldConfig: [], // 字段配置列表
			contentConfig: {
				contentType: 'outForm', // 内容类型
				configType: '', // 配置类型 '' 普通类型， dynamic 动态配置
				dynamicComponent: '', // 动态组件
				objectUUID: '', // 数据对象
				viewUUID: '', // 视图ID
				pageId: '', // 页面id
				formUUID: '', // 表单对象
				developPageRoute: '', // 首页页面路由
			},
			updateRulesConfig: {
				pattern: '',
				rules: []
			}
		},
		{
			component: 'CommonSheetContainer',
			type: 'container',
			name: 'Excel容器',
			icon: 'iconbiaodan-excle',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				showAction: false
			},
			propValue: '',
			style: {
				width: '',
				height: 400,
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			actionConfig: [
				{
					key: 'cancel',
					effect: false, // 是否有附加作用
					effectType: '', // 附加操作类型 请求类型
					canCustom: false, // 是否可以删除
					buttonType: '',
					text: '取消',
					postType: '', // 请求类型
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
					show: true,
				},
				{
					key: 'confirm',
					effect: false,
					effectType: '',
					canCustom: false,
					postType: '', // 请求类型
					buttonType: 'primary',
					text: '确认',
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
					show: true,
				}
			],
			baseConfig: {
				/**
				 * 解析类型：form - 表单, table - 数据表
				 * 解析类型的作用是用来判断提交方式，form数据提交时走常规接口，table的导入/编辑走固定接口
				 */
				solveType: "",
				/**
				 * 操作类型：add - 新增/导入，edit - 编辑，info - 详情，export - 导出
				 * 直接传入Entry组件
				*/
				type: '',
				enableDialog: false, // 是否启用弹窗
				dialogTitle: "Excel弹窗", // 标题
				excelTemplate: '',
				autoTemplate: false,
				checkOnly: false, // 导入时查重
			},
			databaseList: [],
			dataSource: [
				{
					code: '', // 字段名
					isRequired: false, // 必须
					source: 'component', // 值来源， component 组件，url url参数，system 系统参数
					component: '', // 关联组件
					componentField: '', // 组件取值字段
					urlKey: '', // url参数字段
					systemObject: '', // 系统取值对象
					systemField: '' // 系统取值字段
				}
			],
			behaviors: [
				{
					label: '刷新',
					value: 'refresh'
				}
			],
			updateRulesConfig: {
				pattern: '',
				rules: []
			}
		},
		{
			component: 'CommonConfigPage',
			type: 'configPage',
			name: '配置页',
			icon: 'iconbiaodan-pzy',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true
			},
			style: {
				height: '100%',
				width: '100%',
				rotate: 0,
				overflowY: 'auto'
			},
			actionConfig: {
				needAction: false,
				permission: [], // 权限
				archiLimit: [], // 架构限制
				edit: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'edit',
					name: '编辑',
					btnType: 'text',
					color: '#67C23A',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			}
		},
    {
      component: 'FormOnlyOne',
      type: 'form',
      name: '独立表单',
      icon: 'iconbiaodan-dlbd',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        AutoCreate: true
      },
      style: {
        width: '100%',
        height: "100%"
      },
      contentConfig: {
        objectUUID: '', // 数据对象
        viewUUID: '', // 视图ID
        formUUID: '', // 表单对象
        moreCondition: [], // 查找数据时的额外条件，会自动当成字典在新增时写入
        moreCreateDicts: [], // 自动创建时的附加字典
      },
    },
		{
			component: 'CommonCount',
			type: 'form',
			name: '计数器',
			icon: 'iconshuzi',
			isLock: false,
			needCommonStyle: true,
			propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
				fontSize: 64,
				margin: 12,
				bit: 'auto', // 位数 auto, 不补零  Number 位数需要大于数字长度
				// zeroFill: false, // 补零
				// useBorder: false, // 边框
				borderColor: '#abc8ff',
				borderSize: 1,
				borderMargin: 8,
				borderStyle: 'solid',
				radius: 0,
				stepTime: 0, // 生长动画毫秒数
				stepCount: 5, // 步进数
      },
			countConfig: null,
      style: {
        width: '100%',
        height: 84,
				color: '#4872FB',
      }
		}
  ]
};
export default form;