/* eslint-disable */
import { formatThousandsMoney } from '@/custom-component/form/newParser/scripts/tools';
import i18n from '@/locale/index';

/**
 * @description: 逻辑输入验证
 * @param {*} rule
 * @param {*} value
 * @param {*} cb
 * @return {*}
 */
function logicInputValidator(rule, value, cb) {
	if (!Array.isArray(value) || !value.length) {
		cb(new Error(this.message));
		return;
	}

	// 检验
	const itemHandle = (data) => {
		if (Array.isArray(data)) {
			return data.every((el) => itemHandle(el));
		} else if (data && typeof data === 'object') {
			switch (data.type) {
				case 'logic': {
					return !!data.logicType && itemHandle(data.children);
				}
				case 'condition': {
					return (
						data.leftField &&
						data.condition &&
						data.rightType &&
						((data.rightType === 'field' && data.rightField) ||
							(data.rightType === 'fixed' &&
								data.fixedValue !== '' &&
								data.fixedValue !== undefined &&
								data.fixedValue !== null))
					);
				}
				default: {
					return false;
				}
			}
		}
	};
	if (value) {
		if (itemHandle(value)) {
			cb();
		} else {
			cb(new Error(this.message));
		}
	}
}

const formatElement = (ele, fillMatch = {}, fillData = {}, recoverData = {}) => {
	let itemData; //格式化后的item数据
	let recoverVal = '';
	let type = 'string';
	let trigger = 'blur';
	const existKeys = Object.keys(recoverData);
	switch (ele.__config__.tagIcon) {
		case 'input':
		case 'carCard':
		case 'textarea':
		case 'rich-text':
		case 'money':
		case 'password':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : '';
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__];
			}
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele };
			if (ele.__config__.tagIcon === 'money') {
				// 金融控件千分位格式化
				itemData.formatValue = formatThousandsMoney(itemData.value);
			}
			if (ele.__config__.required) {
				itemData.validate = [{ required: true, message: getFormText(ele.__config__), trigger: 'blur' }];
				if (ele.__config__?.regList && ele.__config__.regList.length) {
					const regList = ele.__config__.regList;
					for (let item of regList) {
						itemData.validate.push({
							pattern: item.pattern,
							message: i18n.locale === 'en' ? item?.enMessage : item?.message,
							trigger: 'blur'
						});
					}
				}
			} else if (ele.__config__?.regList && ele.__config__.regList.length) {
				itemData.validate = [];
				const regList = ele.__config__.regList;
				for (let item of regList) {
					itemData.validate.push({
						pattern: item.pattern,
						message: i18n.locale === 'en' ? item?.enMessage : item?.message,
						trigger: 'blur'
					});
				}
			}
			break;
		case 'number':
		case 'slider':
		case 'rate':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : 0;
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__];
			}
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele };
			trigger = 'change';
			if (ele.__config__.required) {
				itemData.validate = { required: true, message: getFormText(ele.__config__), type: 'number', trigger: 'change' };
			}
			break;
		case 'select':
			if (ele.__config__.dataType !== 'static') {
				ele.__slot__.options = []; // 动态时将静态数据清空
			}
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : '';
			if (ele.multiple) recoverVal = [];
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__] || recoverVal;
				if (recoverData[ele.__vModel__] === 0) {
					recoverVal = 0;
				}
			}
			if (!(recoverVal instanceof Array)) {
				recoverVal += '';
			}
			itemData = {
				value: recoverVal,
				type: ele.__config__.tagIcon,
				field: ele.__vModel__,
				config: ele
			};
			trigger = 'change';
			if (ele.__config__.required) {
				itemData.validate = [
					{
						required: true,
						message: getFormText(ele.__config__),
						type: 'string',
						trigger: 'change',
						transform: (value) => {
							let base;
							if (value) base = '' + value;
							return base;
						}
					}
				];
			}
			if (ele.multiple) {
				itemData.value = recoverVal;
				if (ele.__config__.required) {
					itemData.validate = {
						required: true,
						message: getFormText(ele.__config__),
						type: 'array',
						trigger: 'change'
					};
				}
			}
			break;
		case 'radio':
		case 'color':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue + '' : '';
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__] + '' || recoverVal;
			}
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele };
			if (ele.__config__.required) {
				itemData.validate = {
					required: true,
					type: 'string',
					message: getFormText(ele.__config__),
					trigger: 'change',
					transform: (value) => {
						let base;
						if (value) base = '' + value;
						return base;
					}
				};
			}
			break;
		case 'switch':
			recoverVal = ele.__config__.defaultValue;
			const activeValue = ele['active-value'];
			if (existKeys.includes(ele.__vModel__)) {
				// recoverVal = Boolean(parseInt(recoverData[ele.__vModel__]))
				recoverVal =
					typeof activeValue === typeof recoverData[ele.__vModel__]
						? recoverData[ele.__vModel__]
						: Boolean(parseInt(recoverData[ele.__vModel__]));
			}
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele };
			const selfType = ele['active-value'] !== undefined ? typeof ele['active-value'] : 'boolean';
			if (ele.__config__.required) {
				itemData.validate = {
					required: true,
					message: getFormText(ele.__config__),
					type: selfType,
					trigger: 'change'
				};
			}
			break;
		case 'time':
		case 'date':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : '';
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__];
			}
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele };
			if (ele.__config__.required) {
				itemData.validate = {
					required: true,
					message: getFormText(ele.__config__),
					type: 'string',
					trigger: 'change',
					transform: (value) => {
						let base;
						if (value) base = '' + value;
						return base;
					}
				};
			}
			break;
		case 'cascader':
			if (ele.__config__.dataType !== 'static') {
				ele.options = []; // 动态时将静态数据清空
			}
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : [];
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__];
			}
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: [] };
			if (ele.__config__.required) {
				itemData.validate = { required: true, message: getFormText(ele.__config__), type: 'array', trigger: 'change' };
			}
			break;
		case 'checkbox':
		case 'time-range':
		case 'date-range':
		case 'upload':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : [];
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__] || [];
			}
			if (ele.__config__.tagIcon === 'time-range') {
				if (recoverVal.length === 0) {
					recoverVal = ['00:00', '12:00'];
				}
			}
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: [] };
			if (ele.__config__.required) {
				itemData.validate = { required: true, message: getFormText(ele.__config__), type: 'array', trigger: 'change' };
			}
			break;
		case 'form':
			recoverVal = [];
			ele.__config__.label = ele.__config__.componentName || '此项';
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: [] };
			if (ele.__config__.required) {
				itemData.validate = { required: true, message: getFormText(ele.__config__), type: 'array', trigger: 'blur' };
			}
			break;
		case 'bimList':
			recoverVal = [];
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__] || [];
			}
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: [] };
			if (ele.__config__.required) {
				itemData.validate = { required: true, message: getFormText(ele.__config__), type: 'array', trigger: 'change' };
			}
			break;
		case 'gisList':
			recoverVal = [];
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__] || [];
			}
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: [] };
			if (ele.__config__.required) {
				itemData.validate = { required: true, message: getFormText(ele.__config__), type: 'array', trigger: 'change' };
			}
			break;
		case 'user':
			recoverVal = [];
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__] || [];
			}
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: [] };
			if (ele.__config__.required) {
				itemData.validate = { required: true, message: getFormText(ele.__config__), type: 'array', trigger: 'change' };
			}
			break;
		case 'treeSelect':
			recoverVal = '';
			ele.options = [];
			const isMulti = ele.props.props.multiple;
			if (isMulti) {
				recoverVal = [];
				type = 'array';
			}
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__] || recoverVal;
				if (!(recoverVal instanceof Array)) {
					recoverVal += '';
				}
			}
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: [] };
			if (ele.__config__.required) {
				itemData.validate = { required: true, message: getFormText(ele.__config__), trigger: 'blur' };
			}
			break;
		case 'signature':
		case 'position':
			recoverVal = [];
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__] || [];
			}
			// type = 'array';
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: [] };
			if (ele.__config__.required) {
				itemData.validate = { required: true, message: getFormText(ele.__config__), type: 'array', trigger: 'blur' };
			}
			break;
		case 'task':
			recoverVal = '';
			type = 'string';
			if (existKeys.includes(ele.__vModel__)) {
				recoverVal = recoverData[ele.__vModel__] + '';
			}
			itemData = { value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: [] };
			if (ele.__config__.required) {
				itemData.validate = { required: true, message: getFormText(ele.__config__), type: type, trigger: 'change' };
			}
			break;
		case 'outerForm':
			recoverVal = 0;
			type = 'number';
			itemData = { type: ele.__config__.tagIcon, config: ele };
			if (ele.__vModel__) {
				if (existKeys.includes(ele.__vModel__)) {
					recoverVal = recoverData[ele.__vModel__];
				}
				itemData.field = ele.__vModel__;
				itemData.value = recoverVal;
			}
			if (ele.__config__.required) {
				itemData.validate = { required: true, message: getFormText(ele.__config__), type: type, trigger: 'change' };
			}
			break;
		case 'logic-input':
			itemData = { type: ele.__config__.tagIcon, config: ele };
			if (ele.__vModel__) {
				if (existKeys.includes(ele.__vModel__)) {
					recoverVal = recoverData[ele.__vModel__];
				}
				itemData.field = ele.__vModel__;
				itemData.value = recoverVal;
			}
			if (ele.__config__.required) {
				itemData.validate = {
					required: true,
					message: getFormText(ele.__config__),
					validator: logicInputValidator,
					trigger: 'change'
				};
			}
			break;
		default:
			itemData = { type: ele.__config__.tagIcon, config: ele };
			if (ele.__vModel__) {
				if (existKeys.includes(ele.__vModel__)) {
					recoverVal = recoverData[ele.__vModel__];
				}
				itemData.field = ele.__vModel__;
				itemData.value = recoverVal;
			}
	}

	//表单配置了填充规则的数据匹配 -- 数据自动填充
	const fillMatchKeys = Object.keys(fillMatch);
	const fillDataKeys = Object.keys(fillData);

	if (itemData.type === 'tab' || itemData.type === 'form') {
		// 用于tab子项填充数据
		itemData._fillMatch = fillMatch;
		itemData._fillData = fillData;
	}

	if (itemData.value === null || itemData.value === 'null') itemData.value = '';

	if (fillMatchKeys.length > 0 && fillDataKeys.length > 0) {
		const formId = ele.__config__.formId;
		if (fillMatchKeys.includes(formId)) {
			const matchVal = fillMatch[formId];
			if (fillDataKeys.includes(matchVal)) {
				let value = fillData[matchVal];
				if (typeof value === 'number') {
					value = value + '';
				}
				itemData.value = value;
				//填充数据禁用输入
			}
		}
	}

	return itemData;
};

const getFormText = (ele) => {
	return i18n.locale === 'en' ? (ele.enLabel || ele.label) + ' is required' : ele.label + '为必填项';
};

export default formatElement;
