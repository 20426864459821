<!--
 * @Description: 雷达图
 * @Author: luocheng
 * @Date: 2022-01-13 18:00:01
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2024-07-01 14:28:33
-->
<template>
  <div
    class="chart-radar"
    :style="{
      height: height + 'px'
    }"
    v-loading="getting"
    :element-loading-background="loadingBackColor"
  >
    <ChartContainer
      v-if="option && canShow"
      ref="chartInstance"
      class="chart-bar"
      :option="option"
    ></ChartContainer>
    <el-empty
      v-else
    ></el-empty>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  name: 'ChartRadar',
  mixins: [mixin],
  data() {
    return {
      option: null
    };
  },
  created() {
    this.getOptions();
  },
  watch: {
		'$i18n.locale': {
			handler(n, o) {
				if(n && n !== o) {
					this.getOptions()
				}
			}
		}
	},
  methods: {
    /**
     * @desc: 配置图表
     */
    async setOptions(data) {

      console.log(data)
      const { attributes = {} } = this.chartData;
      const { series = [], names = [] } = data[0];
      if (!series?.length) {
        this.canShow = false;
        return false;
      }
      /**
			 * 国际化 英文翻译
			 */
			if(this.$i18n.locale === 'en') {
				const tasks = [];
        // 需要转换的内容
				if(attributes.showTitle) {
					tasks.push({ target: attributes, key: 'title' });
				}
				// 需要转换的内容
				if(attributes.indicator.length) {
          attributes.indicator.forEach(el => {
            tasks.push({ target: el, key: 'label' });
          })
				}
        if(series.length) {
          series.forEach(el => {
            tasks.push({ target: el, key: 'name' });
          })
        }
				Object.keys(names).forEach((key) => {
          tasks.push({ target: names, key });
				})
				await this.translate2English(tasks);
			}
      const {
        showTitle = true,
        title = '',
        textColor = '',
        titleFontSize = 12,
        titleFontWeight = 400,
        showTooltip = true,
        tooltipFontsize = 14,
        showLegend = true,
        legendTextColor = '#000000',
        legenFontSize = 12,
        colorRange = [],
        legendIcon = 'circle',
        indicator = [],
        legendOrient = 'vertical',
        legendType = 'plain',
        titleTop = 'auto',
        titleBottom = 'auto',
        titleRight = 'auto',
        titleLeft = 'auto',
        left = '10%',
        right = '10%',
        top = 60,
        bottom = 60
      } = attributes;
      // 渲染的数据
      this.option = {
        title: {
          show: showTitle,
          text: title,
          left: 'left',
          y: 'top',
          textStyle: {
            color: textColor,
            fontFamily: '微软雅黑',
            fontSize: this.scaleSize(titleFontSize || 12),
            fontWeight: titleFontWeight || 400,
            left: titleLeft,
            top: titleTop,
            bottom: titleBottom,
            right: titleRight
          }
        },
        grid: {
          left,
          right,
          bottom,
          top,
          containLabel: true
        },
        tooltip: {
          show: showTooltip,
          trigger: 'axis',
          axisPointer: {
            type: 'line'
          },
          textStyle: {
						fontSize: this.scaleSize(tooltipFontsize || 14),
					},
          appendToBody: true
        },
        legend: {
          show: showLegend,
          itemWidth: 10,
          itemHeight: 10,
          data: this.getLegendData(names, legendTextColor),
          icon: legendIcon,
          ...this.getLegendPosition(attributes),
          type: legendType,
          orient: legendOrient,
          textStyle: {
            fontSize: this.scaleSize(legenFontSize)
          }
        },
        color: colorRange || [],
        radar: {
          // shape: 'circle',
          indicator: indicator.map(ele => {
            return {
              name: ele.label,
              max: ele.value
            };
          })
        },
        series: [
          {
            name: title,
            type: 'radar',
            data: series.map(ele => {
              return {
                ...ele,
                value: ele.data
              };
            })
          }
        ]
      };
    },
    /**
     * @desc: 设置图例
     * @param {Array} names 名称
     * @param {String} color 文本颜色
     * @return {*}
     */
    getLegendData(names, color) {
      let s = [];
      if (!names) {
        return [];
      }
      for (let i = 0; i < names.length; i++) {
        s.push({
          name: names[i],
          textStyle: {
            color,
            // fontSize: this.scaleSize(12)
          }
        });
      }
      return s;
    }
  }
};
</script>

<style lang="less" scoped>
.chart-radar {
  height: 100%;
  width: 100%;
  :deep(.el-empty) {
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    .el-empty__image {
      width: 35%;
      max-width: 120px;
      min-width: 50px;
    }
    .el-empty__description {
      margin-top: 15px;
    }
    .page-container p {
      color: #fff;
    }
  }
}
</style>
