/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2024-10-31 16:27:22
 * @FilePath: \dataview-viewer-test\src\custom-component\container\lang\en.js
 */
export default {
  "container.CommonTableContainer.351873-0": "picture",
  "container.CommonTableContainer.351873-1": "link",
  "container.CommonTableContainer.351873-2": "Action",
  "container.CommonTableContainer.351873-3": "Num",
  "container.CommonTableContainer.351873-4": "Sunday",
  "container.CommonTableContainer.351873-5": "Monday",
  "container.CommonTableContainer.351873-6": "Tuesday",
  "container.CommonTableContainer.351873-7": "Wednesday",
  "container.CommonTableContainer.351873-8": "Thursday",
  "container.CommonTableContainer.351873-9": "Friday",
  "container.CommonTableContainer.351873-10": "Saturday",
  "container.CommonTableContainer.351873-11": "Parameter mandatory verification failed",
  "container.CommonTableContainer.351873-12": "Request configuration error",
  "container.CommonTableContainer.351873-13": "Front end sorting failure - Table 1",
  "container.CommonTableContainer.351873-14": "Operation failed!",
  "container.CommonTableContainer.351873-15": "Event triggered--------",
  "container.CommonTableContainer.351873-16": "Operation failed!",
  "container.CommonTableContainer.351873-17": "Please configure the event correctly!",
  "container.CommonTableContainer.351873-18": "Operation successful!",
  "container.CommonTableContainer.351873-19": "Event not configured with trigger object!",
  "container.CommonTableContainer.351873-20": "00000 backend event error",
  "container.CommonTableContainer.351873-21": "Are you sure you want to delete it?",
  "container.CommonTableContainer.351873-22": "prompt",
  "container.CommonTableContainer.351873-23": "confirm",
  "container.CommonTableContainer.351873-24": "cancel",
  "container.CommonTableContainer.351873-25": "year",
  "container.CommonTableContainer.351873-26": "month",
  "container.CommonTableContainer.351873-27": "day",
  "container.CommonTableContainer.351873-28": "Time",
  "container.CommonTableContainer.351873-29": "branch",
  "container.CommonTableContainer.351873-30": "second",
  "container.CommonTableContainer.351873-31": "Week {0}",
  "container.CommonTableContainer.351873-32": "Exporting",
  "container.CommonTableContainer.351873-33": "Export failed! Data validation error.",
  "container.CommonTableContainer.351873-34": "There is currently no data available for export!",
  "container.CommonTableContainer.351873-35": "Please select the data that needs to be exported!",
  "container.CommonTableContainer.351873-36": "Export configuration error!",
  "container.CommonTableContainer.351873-37": "Export configuration error! No exported data exists",
  "container.CommonTableContainer.351873-38": "Export successful!",
  "container.CommonTableContainer.351873-39": "Export failed!",
  "container.CommonTableContainer.351873-40": "Component [{0}] data interface returned with no [id] field. Please check the interface configuration!",
  "container.CommonTableContainer.351873-41": "Component [{0}] has [{1}] returned [id] fields in its data interface. Please check the interface configuration! To ensure accuracy!",
  "container.CommonTableContainer.351873-42": "{0}_ {1}- {2} Meters",
  "container.CommonTableContainer.351873-43": "{0}_ {1} Meters",
  "container.CommonTableContainer.351873-44": "No Data",
  "container.CommonTableContainer.351873-45": "excel Window"
}