<!--
    @name: SwimProps
    @description：SwimProps
    @author: ZengWei
    @date: 2023-06-21 15:24
-->
<template>
	<div class="prop-container">
		<el-tabs type="border-card" v-model="activeTab">
			<el-tab-pane label="泳道配置" name="swim-lane">
				<div class="scrollbar">
					<div class="label-title">泳道设置</div>
					<el-form label-position="left" class="form-table" label-width="80px">
						<template v-for="(item, index) in task.content">
							<div :key="index">
								<div class="self-define">
									<span>{{ index > 9 ? index : '0' + (index + 1) }}</span>
									<span class="float-r" @click="delContent(index)">
										<i class="el-icon-delete"></i>
									</span>
								</div>
								<el-form-item label="名称">
									<el-input :disabled="disabled" v-model="item.name" @change="onChange"></el-input>
								</el-form-item>
								<el-form-item label="比例">
									<el-input :disabled="disabled" v-model="item.ratio" @change="onChange"></el-input>
								</el-form-item>
							</div>
						</template>
						<el-button :disabled="disabled" @click="addContent" style="width: 100%; margin: 15px 0" icon="el-icon-plus"
							>添加泳道</el-button
						>
					</el-form>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { TabPane, Tabs } from 'element-ui';
export default {
	name: 'SwimProps',
	components: {
		'el-tabs': Tabs,
		'el-tab-pane': TabPane
	},
	props: ['currentItem', 'currentNodeId', 'nodeType', 'formNode', 'disabled'],
	data() {
		return {
			activeTab: 'swim-lane',
			task: {
				content: [{ name: '泳道', ratio: 1 }]
			}
		};
	},
	mounted() {
		this.initPropsData();
	},
	watch: {
		currentItem: {
			handler() {
				this.initPropsData();
			},
			deep: true
		}
	},
	methods: {
		initPropsData() {
			this.task.content = this.currentItem?.content || this.task.content;
			this.onChange();
		},
		delContent(index) {
			this.task.content.splice(index, 1);
			this.onChange();
		},
		addContent() {
			let item = { name: '', ratio: 1 };
			this.task.content.push(item);
			this.onChange();
		},
		onChange() {
			this.$emit('on-change', this.task);
		}
	}
};
</script>

<style lang="less" scoped>
.prop-container {
	width: 350px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	background: #ffffff;

	:deep(.el-tabs) {
		height: 100%;
		.el-tabs__content {
			padding: 0;
			height: calc(100% - 40px);
			.el-tab-pane {
				height: 100%;
			}
		}
	}

	.iconc-close {
		position: absolute;
		font-size: 22px;
		top: 5px;
		right: 8px;
		z-index: 10;
		cursor: pointer;
	}
}

.scrollbar {
	overflow-y: auto;
	padding: 15px 15px 0 15px;
	height: calc(100% - 15px);
}

:deep(.el-tabs--border-card) {
	border-left: 1px solid #dcdfe6;
	border-top: none;
	border-right: none;
	border-bottom: none;
}

:deep(.form-table) {
	.el-form-item {
		border: 1px solid #eaebed;
		display: flex;
		margin-bottom: -1px !important;
		/*margin-bottom: -1px !important;*/

		.el-form-item__label {
			border-right: 1px solid #eaebed;
			background: #f8f8f9;
			padding: 6px 10px;
		}

		.el-form-item__content {
			margin-left: 0 !important;
			flex: 1;
			padding: 6px 10px;
			overflow: hidden;
			clear: both;
			/*margin-bottom: 1px;*/

			.el-select,
			.el-cascader {
				width: 100%;
			}

			.self-type {
				.el-radio {
					margin-right: 18px;
				}
			}
		}
	}
}

.label-title {
	border-left: 3px solid var(--themeColor);
	padding: 0 10px;
	line-height: 1;
	margin: 0 0 16px;
	font-weight: 600;
}

.self-define {
	margin: 8px 0;
	text-indent: 2px;
}

.mb-10 {
	margin-bottom: 10px;
}

.float-r {
	float: right;
	padding: 0 1px;
	cursor: pointer;
}
</style>
