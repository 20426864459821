/*
 * @Description: 混入方法
 * @Author: luocheng
 * @Date: 2022-03-07 15:03:01
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-11-27 10:48:15
 */
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data';
import {
	getComponentById,
	getQueryValue,
	doEEActionHandle,
	doFrontOperation,
	getLinkByTemplate,
	openUrl
} from '@/utils/tools';
import eventBus from '@/plugins/eventBus';

export default {
	props: {
		// 是否在组合内
		isGroup: {
			type: Boolean
		},
		// 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
		element: {
			type: Object,
			required: true
		},
		componentList: {
			default: null
		}
	},
	inject: ['EDITOR_pageUUID'],
	computed: {
		...mapState(['componentData', 'subsidiaryComponentData']),
		// 嵌入页面的参数获取
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return (
					this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData ||
					this.componentList ||
					this.componentData ||
					[]
				);
			}
			return this.componentList || this.componentData || [];
		}
	},
	methods: {
		/**
		 * @description: 自定义按钮事件触发和数据更新
		 * @return {*}
		 */
		/**
		 * @description: 自定义按钮事件触发和数据更新
		 * @param {String} action
		 * @param {Object} output
		 */
		doCustomBtn(action, output) {
			const comEvents = this.element?.actionConfig?.[action]?.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.doActionItem(comEvents[i], output);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList;
				result.forEach((ele) => {
					this.doActionItem(ele, output);
				});
			}
			this.$nextTick(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					action,
					output,
					isInit: false,
					noUpdate: true
				});
			});
		},
		/**
		 * @description: 单个事件触发
		 * @param {*} ele
		 * @param {*} output
		 */
		async doActionItem(ele, output) {
			const { frontOperation = [] } = ele;
			let canNext = true;
			if (frontOperation && frontOperation.length) {
				// 触发预处理事件、行为
				window.$EditorDebug.startFrontOperation(this.element);
				canNext = await doFrontOperation(frontOperation, {
					isGroup: this.isGroup,
					componentList: this.subComponentData,
					componentData: this.subComponentData,
					groupComponents: this.groupComponents
				});
				window.$EditorDebug.resultFrontOperation(canNext, this.element);
			}
			if (!canNext) {
				this.$message.error(this.$t('components.OperationFile.193459-12'));
				return false;
			}
			if (ele.key === 'click') {
				// 跳转页面
				if (ele.actionType === 'jumpPage') {
					if (ele.linkType === 'projectPage') {
						const query = {};
						ele.queryList.forEach((queryItem) => {
							let component = getComponentById(this.subComponentData, queryItem.componentId);
							if (!component && this.isGroup && this.groupComponents.length) {
								component = getComponentById(this.groupComponents, queryItem.componentId);
							}
							this.$set(query, queryItem.key, component.resolveData[queryItem.feild]);
						});
						this.$router.push({
							path: ele.pageId,
							query
						});
						// 基座设置
						// if (ele.routePath) {
						// 	this.$router.replace({
						// 		path: ele.routePath,
						// 		query
						// 	});
						// }
						return;
					} else if (ele.linkType === 'outPage') {
						// window.open(ele.linkUrl);
						openUrl(ele.linkUrl, ele.linkUrl);
					} else if (ele.linkType === 'custom') {
						const customLink = getLinkByTemplate(ele.linkTemplate, output);
						openUrl(customLink, customLink);
						// window.open(customLink);
					}
				} else if (ele.actionType === 'eeAction') {
					// 触发后端事件
					// console.log(ele, '0000001111111111', output);
					this.doEEAction(ele, output);
					// 搜集参数
				} else {
					// 页面事件
					ele.effects.forEach((effect) => {
						this.$store.commit('triggerEvents', {
							config: {
								...ele,
								...effect
							},
							element: this.element,
							EDITOR_pageUUID: this.EDITOR_pageUUID
						});
					});
				}
			}
			// 组件行为
			const { behaviors = [] } = ele;
			behaviors.forEach((behavior) => {
				this.$store.commit('triggerEvents', {
					config: {
						behavior,
						isBehavior: true
					},
					element: this.element,
					EDITOR_pageUUID: this.EDITOR_pageUUID
				});
			});
			// 导出事件(只能导出当前行的数据)
			if (ele.actionType === 'export') {
				this.doExport(ele, output);
			}
		},

		/**
		 * @desc: 触发后端事件
		 */
		doEEAction(btnConfig) {
			if (btnConfig && btnConfig.actionType === 'eeAction') {
				window.$EditorDebug.startEEAction(this.element);
				const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } = btnConfig;
				if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
					this.$message.error('事件配置错误！');
					window.$EditorDebug.failEEAction(this.element);
					return false;
				}
				this.loading = true;
				const sourceParams = this.getBindParams(this.element);
				// 蓝图
				if (eeType === 'blueprint') {
					dataInterface(sourceParams, `/api${interfaceUrl}`)
						.then((res) => {
							if (res.status === 200 && res.data.code === 200) {
								this.$message.success('操作成功！');
								window.$EditorDebug.successEEAction(this.element, `/api${interfaceUrl}`);
								doEEActionHandle(res.data?.__adds__);
							}
							this.loading = false;
						})
						.catch(() => {
							this.loading = false;
							window.$EditorDebug.errorEEAction(this.element, `/api${interfaceUrl}`);
						});
					return true;
				}
				const data_id = sourceParams?.id;
				if (!data_id) {
					this.$message.error('事件未配置触发对象!');
					return false;
				}
				dataInterface({
					__method_name__: 'customEventCall',
					object_uuid: objectUUID,
					view_uuid: viewUUID,
					...sourceParams,
					data_id,
					event: eventName
				})
					.then((res) => {
						if (res.status === 200 && res.data.code === 200) {
							this.$message.success('操作成功！');
							window.$EditorDebug.successEEAction(this.element, eventName);
							doEEActionHandle(res.data?.__adds__);
							this.onPage(this.pager.current_page);
						}
						this.loading = false;
					})
					.catch((err) => {
						console.log(err, '00000后端事件错误');
						this.loading = false;
						window.$EditorDebug.errorEEAction(this.element, eventName);
					});
			}
		},
		/**
		 * @desc: 后端事件获取绑定参数
		 * @param {Object} comp 组件数据
		 * @return {Object}
		 */
		getBindParams(comp) {
			if (!comp) {
				window.$EditorDebug.descEEActionParam(1);
				return {};
			}
			window.$EditorDebug.descEEActionParam(2);
			const sourceConfig = comp.sourceConfig || [];
			const sourceParams = {};
			for (let i = 0; i < sourceConfig.length; i++) {
				const {
					componentId,
					field,
					key,
					originType = '',
					urlParamKey = '',
					systemKey = '',
					systemCode = '',
					fixedValue = '',
					statusCode = ''
				} = sourceConfig[i];
				if (originType === 'url' && urlParamKey) {
					// 从url获取参数
					const result = getQueryValue(urlParamKey);
					this.$set(sourceParams, key, result);
				} else if (originType === 'system') {
					// 系统参数
					try {
						let obj = sessionStorage.getItem(systemKey);
						if (!obj) {
							obj = localStorage.getItem(systemKey);
						}
						if (!obj) break;
						const result = JSON.parse(obj);
						if (result && Object.prototype.toString.call(result) === '[object Object]') {
							const queryVal = result[systemCode];
							this.$set(sourceParams, key, queryVal);
						}
					} catch (err) {
						console.log(err, '99999999');
					}
				} else if (originType === 'fixed') {
					// 固定值
					this.$set(sourceParams, key, fixedValue);
				} else if (originType === 'pageStatus') {
					// 页面状态
					const statusCodeValue =
						this._PageCustomStatus[statusCode] === undefined
							? this._APPCustomStatus[statusCode] || null
							: this._PageCustomStatus[statusCode];
					this.$set(sourceParams, key, statusCodeValue || '');
				} else if (componentId && field && key) {
					// 普通从组件获取
					let sourceComponent = getComponentById(this.subComponentData, componentId);
					if (!sourceComponent && this.isGroup && this.groupComponents.length) {
						sourceComponent = getComponentById(this.groupComponents, componentId);
					}
					const componentName = componentId.toString().split('-')[0];
					if (
						field === 'DATAVIEWSELECT' &&
						['CommonTree', 'CommonTableContainer', 'CommonLoopContainer'].includes(componentName)
					) {
						this.$set(sourceParams, key, sourceComponent?.resolveData || []);
					} else {
						const result = sourceComponent?.resolveData?.[field] || '';
						this.$set(sourceParams, key, result);
					}
				}
			}
			window.$EditorDebug.successEEActionParam(sourceParams, comp.sourceConfig);
			return sourceParams;
		},
		/**
		 * @desc: 初始化获取参数
		 */
		initParams() {
			const { paramsConfig } = this.element.database;
			if (!paramsConfig || !paramsConfig.length) return {};
			let search = [];
			const param = {};
			paramsConfig.forEach((ele) => {
				// 暂时归类了param ,特殊的search暂时无法处理？？？
				let originComponent = getComponentById(this.componentData, ele.componentId);
				if (!originComponent && this.isGroup && this.groupComponents.length) {
					originComponent = getComponentById(this.groupComponents, ele.componentId);
				}
				if (ele.key !== 'search') {
					if (ele.sourceType === 'url') {
						// url参数
						this.$set(param, ele.key, getQueryValue(ele.urlParamsKey));
					} else if (originComponent?.resolveData) {
						this.$set(param, ele.key, originComponent?.resolveData[ele.paramKey]);
					}
				} else {
					if (Array.isArray(originComponent?.resolveData)) {
						search = search.concat(originComponent.resolveData);
					}
				}
			});
			return { search, param };
		},
		/**
		 * @desc: 获取操作参数
		 * @param {Array} paramsConfig 配置数组
		 */
		getQuery(paramsConfig = []) {
			// 是否可以请求
			let canPost = true;
			if (!paramsConfig || !paramsConfig.length)
				return {
					canPost
				};
			let search = [];
			const param = {};
			for (let i = 0; i < paramsConfig.length; i++) {
				const {
					paramType,
					code,
					uuid,
					isRequired,
					originType,
					componentId,
					urlKey,
					ruleType,
					componentKey,
					fixedValue
				} = paramsConfig[i];
				let paramsVal = '';
				if (originType === 'url') {
					// url参数
					paramsVal = getQueryValue(urlKey);
					if (isRequired && !paramsVal) {
						canPost = false;
						break;
					}
				} else if (originType === 'component') {
					// 组件参数
					let originComponent = getComponentById(this.componentData, componentId);
					if (!originComponent && this.isGroup && this.groupComponents.length) {
						originComponent = getComponentById(this.groupComponents, componentId);
					}
					if (!originComponent) {
						console.error(`查询的组件id不存在! 请检测组件ID:${componentId}`);
					}
					if (isRequired && (!originComponent || !originComponent.resolveData)) {
						canPost = false;
						break;
					}
					if (componentKey) {
						paramsVal = originComponent?.resolveData?.[componentKey];
					} else {
						paramsVal = originComponent?.resolveData;
					}
				} else if (originType === 'fixed') {
					// 固定值
					paramsVal = fixedValue;
				}
				if (paramType === 'param') {
					// 普通请求参数
					// 写入params
					if (code) {
						this.$set(param, code, paramsVal);
					} else if (uuid) {
						this.$set(param, uuid, paramsVal);
					} else {
						canPost = false;
						// console.log('参数配置出错')
						break;
					}
				} else if (paramType === 'search') {
					if (Array.isArray(paramsVal) && this.validSearch(paramsVal)) {
						search = search.concat(paramsVal);
					} else if (uuid) {
						search.push({
							field_uuid: uuid,
							ruleType,
							value: paramsVal
						});
					} else if (code) {
						search = search.concat({
							code,
							ruleType,
							value: paramsVal
						});
					} else {
						canPost = false;
						break;
					}
				}
			}
			return { search, param, canPost };
		},
		/**
		 * @desc: 是否为合法的search
		 * @param {Array} arr 数组
		 */
		validSearch(arr) {
			if (!Array.isArray(arr) || !arr.length) return true;
			for (let i = 0, len = arr.length; i < len; i++) {
				const item = arr[i];
				if (Object.prototype.toString.call(item) !== '[object Object]') {
					return false;
				}
			}
			return true;
		},
		/**
		 * @description: 组件暴露值
		 * @param {Object/Array} data
		 */
		doResolveData(resolveData) {
			this.element.resolveData = resolveData;
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
		}
	}
};
