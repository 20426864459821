import { render, staticRenderFns } from "./CommonTree.vue?vue&type=template&id=2d9c4e96&scoped=true"
import script from "./CommonTree.vue?vue&type=script&lang=js"
export * from "./CommonTree.vue?vue&type=script&lang=js"
import style0 from "./CommonTree.vue?vue&type=style&index=0&id=2d9c4e96&prod&lang=less&scoped=true"
import style1 from "./CommonTree.vue?vue&type=style&index=1&id=2d9c4e96&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d9c4e96",
  null
  
)

export default component.exports