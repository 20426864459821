<!--
 * @Author: 姚铃 1871883713@qq.com
 * @Date: 2022-11-02 16:43:20
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-05 15:00:08
 * @FilePath: \dataview-next\src\custom-component\form\newParser\FormItem\widget-switch.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
    @name: widget-switch
    @description：widget-switch
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
	<div>
		<div v-if="disabled" class="input-show">
			{{
				element.value === element.config['active-value']
					? $t('FormItem.widget-switch.079870-0')
					: $t('FormItem.widget-switch.079870-1')
			}}
		</div>
		<el-switch
			v-else
			v-model="element.value"
			:disabled="disabled"
			@change="triggerInteractive"
			:active-value="element.config['active-value'] ?? true"
			:inactive-value="element.config['inactive-value'] ?? false"
		></el-switch>
	</div>
</template>

<script>
import { Switch } from 'element-ui';
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';

export default {
	name: 'WidgetSwitch',
	components: {
		'el-switch': Switch
	},
	mixins: [formItemMinix],
	props: ['element', 'disabled', 'displayData'],
	data() {
		return {};
	},
	created() {
		if (this.element.value) {
			this.element.value = this.element.config['active-value'] ?? true;
		} else {
			this.element.value = this.element.config['inactive-value'] ?? false;
		}
		this.triggerInteractive(this.element.value);
	},
	methods: {}
};
</script>

<style scoped></style>
