<!--
    @name: widget-longlat
    @description：widget-longlat
    @author: ZengWei
    @date: 2022-04-24 16:21
-->
<template>
	<div v-if="show" :style="style">
		<BindLatLng
			ref="chooseLngLat"
			@set-data="setData"
			componentType="latlng"
			:modelData="bindInfo"
			:drawType="drawType"
			:config="config"
			:interfaceData="interfaceData"
			:fillData="fillData"
			:searchData="searchData"
			:bodyParams="bodyParams"
		>
			<template v-slot:showType>
				<section class="container" v-if="element.value && element.value != null">
					<section class="detail" v-if="echoContent.length && !list.length">
						<section class="address">
							<div class="address-icon" @click.stop="openModelDrawer('chooseLngLat')" />
						</section>
						<template v-if="echoContent.includes('longlat')">
							<p class="text">经 度：{{ lng || '-' }}</p>
							<p class="text">纬 度：{{ lat || '-' }}</p></template
						>
						<template v-if="echoContent.includes('ab')">
							<p class="text">A 坐标：{{ A || '-' }}</p>
							<p class="text">B 坐标：{{ B || '-' }}</p></template
						>
					</section>
					<section class="detail" v-if="echoContent.length && list.length">
						<section class="address">
							<div class="address-icon" @click.stop="openModelDrawer('chooseLngLat')" />
						</section>
						<div class="detail-item" v-for="(item, index) in list" :key="index">
							<p class="text">地 址：{{ item.location_name || '-' }}</p>
							<template v-if="echoContent.includes('longlat')">
								<p class="text">经 度：{{ item.location_gis?.lng || '-' }}</p>
								<p class="text">纬 度：{{ item.location_gis?.lat || '-' }}</p></template
							>
							<template v-if="echoContent.includes('ab')">
								<p class="text">A 坐标：{{ item.A || '-' }}</p>
								<p class="text">B 坐标：{{ item.B || '-' }}</p></template
							>
						</div>
					</section>
				</section>
				<template v-else>
					<section class="container">
						<section class="detail" v-if="echoContent.length">
							<section class="address">
								<div class="address-icon" @click.stop="openModelDrawer('chooseLngLat')" />
							</section>
							<div class="detail-item" v-for="(item, index) in fillData" :key="index">
								<p class="text">地 址：{{ item.location_name || '-' }}</p>
								<template v-if="echoContent.includes('longlat')">
									<p class="text">经 度：{{ item.location_gis?.lng || '-' }}</p>
									<p class="text">纬 度：{{ item.location_gis?.lat || '-' }}</p></template
								>
								<template v-if="echoContent.includes('ab')">
									<p class="text">A 坐标：{{ item.A || '-' }}</p>
									<p class="text">B 坐标：{{ item.B || '-' }}</p></template
								>
							</div>

							<p class="placeholder" v-if="!fillData.length">标记信息描述</p>
						</section>
					</section>
				</template>
			</template>
		</BindLatLng>
	</div>
</template>

<script>
import BindLatLng from '@/custom-component/bindLatLng/ButtonType.vue';
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';
import eventBus from '@/plugins/eventBus';
import { getSearchData, getBodyParams } from '@/custom-component/form/newParser/scripts/tools';
import { dataInterface } from '@/apis/data/index';

export default {
	name: 'WidgetLonglat',
	components: {
		BindLatLng
	},
	mixins: [formItemMinix],
	props: ['element', 'disabled', 'displayData', 'formData', 'fillMatch', 'editFields'],
	data() {
		return {
			list: [],
			addressStr: '',
			lng: '',
			lat: '',
			bindInfo: [],
			show: true,
			A: '',
			B: '',
			echoContent: [],
			drawType: 0,
			config: null,
			info: {
				fullName: '',
				area: ''
			},
			style: {},
			fillData: [],
			interfaceData: { label: '', value: '', viewUuid: '', objectUuid: '' },
			searchData: [],
			bodyParams: {}
		};
	},
	created() {
		this.config = {
			...this.element.config.__config__,
			disabled: this.element.config.disabled
		};
		// 调用视图接口
		if (this.element.config?.props) {
			const { label, value } = this.element.config.props.props || {};
			this.interfaceData.label = label;
			this.interfaceData.value = value;
		}
		if (this.element.config?.__config__) {
			const { viewUuid, objectUuid } = this.element.config?.__config__;
			this.interfaceData.viewUuid = viewUuid;
			this.interfaceData.objectUuid = objectUuid;
		}
		this.bindInfo = this.element.value || [];
		this.echoContent = this.element.config.__config__.echoContent || [];
		this.drawType = this.element.config.__config__.locateType;
		this.style = this.element.config.style || {};
		// 默认有值则获取器坐标点信息
		if (Object.prototype.toString.call(this.element?.value) === '[object Object]') {
			this.lat = this.element.value.lat;
			this.lng = this.element.value.lng;
		} else if (Object.prototype.toString.call(this.element?.value) === '[object Array]' && this.element?.value.length) {
			const lastIndex = this.element.value.length - 1;
			this.list = this.element.value;
			this.addressStr =
				this.element.value[lastIndex].location_name.split('|')[1] || this.element.value[lastIndex].location_name;
		}
		if (!this.lng || !this.lat) {
			return;
		}
		dataInterface(
			{
				location: `${this.lat},${this.lng}`,
				language: this.$i18n && this.$i18n?.locale === 'en' ? 'en' : 'zh-CN'
			},
			'api/language/baiduMap',
			'post'
		).then((res) => {
			if (res.data.code === 200 && res.data?.data?.result?.addressComponent) {
				this.addressStr = '';
				const data = res.data.data.result.addressComponent;
				let arr = ['nation', 'province', 'city', 'county', 'address', 'poi'];
				if (data.nation && data.nation === this.$t('FormItem.widget-longlat.186372-7')) {
					arr = arr.splice(1, arr.length - 1);
				}
				arr.forEach((item, i) => {
					if (data[item]) {
						if (i === 0) {
							this.addressStr += data[item];
						} else if (data[arr[i - 1]] !== data[item]) {
							this.addressStr += data[item];
						}
					}
				});
			}
		});
		this.initSearch();
	},
	methods: {
		initSearch() {
			const filterOpen = this.element.config.__config__?.openFilter ?? false;
			const filterRule = this.element.config.__config__?.filterRule ?? [];
			if (filterOpen && filterRule.length > 0) {
				this.searchData = getSearchData(filterRule, this.parser.getFormIdValue);
				this.bodyParams = getBodyParams(filterRule, this.parser.getFormIdValue);
			}
		},
		setData(data) {
			const lastIndex = data.length - 1;
			this.element.value = data || [];
			this.$emit('input', data || []);
			if (data?.length) {
				if (data[lastIndex].location_name && data[lastIndex].location_name.includes('|')) {
					this.addressStr = data[lastIndex].location_name.split('|')[1];
				} else {
					this.addressStr = '';
				}
				this.list = data;
				this.show = false;
				this.$nextTick(() => {
					this.bindInfo = this.element.value;
					this.show = true;
				});
			} else if (data === null || !data?.length) {
				this.show = false;
				this.$nextTick(() => {
					this.bindInfo = [];
					this.show = true;
				});
				this.addressStr = '';
				this.lat = '';
				this.lng = '';
				this.A = '';
				this.B = '';
				this.list = [];
			}
		},
		openModelDrawer(name) {
			this.$nextTick(() => {
				this.$refs[name].show = true;
			});
		},
		/**
		 * @desc: 移除
		 */
		onRemove() {
			this.$confirm('是否确认删除已选点?', '提示')
				.then(() => {
					this.setData(null);
				})
				.catch(() => {});
		}
	},
	mounted() {
		const eleConf = this.element.config.__config__;
		const filterRule = eleConf?.filterRule || [];
		const formIdArr = filterRule.filter((item) => item.valueType === 2);
		const compFormId = Array.from(formIdArr, (item) => item.value);
		this.initSearch();
		eventBus.$on('TRIGGER_selectChange', (formId) => {
			if (compFormId.includes(formId)) {
				this.initSearch();
			}
		});
		eventBus.$on('TRIGGER_INPUT_ONCHANGE', (el) => {
			if (compFormId.includes(el.config.__config__.formId)) {
				this.initSearch();
			}
		});
	}
};
</script>

<style lang="less" scoped>
.content-box {
	// display: flex;
	flex-direction: column;
	overflow: hidden;
	.result-box {
		display: flex;
		.lng-lat-yet {
			flex: 1;
			position: relative;
			box-sizing: border-box;
			background: #f5f7fa;
			border-radius: 6px;
			padding: 6px 12px;
			border: 1px solid #f5f7fa;
			overflow: hidden;
			width: 90%;
			&.lng-lat-yet-disabled {
				color: #c0c4cc;
			}
			.item {
				display: flex;
				span {
					font-size: 14px;
					line-height: 20px;
					letter-spacing: 0.2px;
					&.label {
						// width: 72px;
					}
					&.value {
						flex: 1;
					}
				}
			}
		}
		.remove {
			display: flex;
			align-items: center;
			margin-left: 8px;
			i {
				font-size: 18px;
				font-weight: 600;
				color: @dangerColor;
				z-index: 10;
				box-sizing: border-box;
				padding: 0 4px;
			}
		}
	}
}
.container {
	display: flex;
	flex-direction: column;
	gap: 6px;
	.address {
		padding: 5px 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: 1px solid #f0f1f4;
		border-radius: 6px;
		&:hover {
			border: 1px solid #387ffc;
		}
		&-text {
			line-height: 22px;
			color: #181b22;
		}
		&-desc {
			line-height: 22px;
			font-family: PingFang SC;
			color: #a4acbd;
		}
		&-icon {
			width: 16px;
			height: 16px;
			border-radius: 5px;
			background: url(https://openim-1309784708.cos.ap-shanghai.myqcloud.com/da7a609e52944650ac2a3a78a4ea9000.png)
				no-repeat;
			background-size: cover;
			cursor: pointer;
		}
	}
	.detail {
		padding: 5px 12px;
		display: flex;
		flex-direction: column;
		border: 1px solid #f0f1f4;
		border-radius: 6px;
		font-family: PingFang SC;
		min-height: 98px;
		gap: 2px;
		position: relative;
		.address {
			position: absolute;
			right: 12px;
			top: 8px;
			&-icon {
				width: 16px;
				height: 16px;
				border-radius: 5px;
				background: url(https://openim-1309784708.cos.ap-shanghai.myqcloud.com/da7a609e52944650ac2a3a78a4ea9000.png)
					no-repeat;
				background-size: cover;
				cursor: pointer;
			}
		}
		.detail-item {
			border-bottom: 1px solid #f0f1f4;
		}
		.placeholder {
			color: #a4acbd;
			line-height: 22px;
		}
		.text {
			line-height: 28px;
			color: #181b22;
		}
	}
}
</style>
