var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-list"},[(_vm.showHeader)?_c('header',{staticClass:"table-header",class:{ 'table-header-radius': !!_vm.statusConfig.headerFillet }},[(_vm.statusConfig.showIndex)?_c('div',{staticClass:"header-item",style:({
				width: '55px',
				'flex-grow': 0,
				'flex-basis': '55px',
				textAlign: 'center',
				..._vm.headerStyle
			})},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'en' ? 'No.' : '序号')+" ")]):_vm._e(),_vm._l((_vm.fieldForView),function(item,index){return _c('div',{key:item.uuid,staticClass:"header-item",style:(Object.assign(
					{
						width: `${item.width}px`,
						'flex-basis': `${item.width}px`,
						flex: item.width ? '' : '1',
						color: item.color,
						textAlign: item.headerAlign || item.align || 'left',
						..._vm.headerStyle
					},
					_vm.userInnerBorder && (_vm.statusConfig.showIndex || index > 0) ? _vm.cellBorder : {}
				))},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'en' ? item.enLabel : item.label)+" ")])})],2):_vm._e(),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-body"},[(_vm.tableData.length !== 0)?_vm._l((_vm.tableData),function(row,index){return _c('section',{key:index,staticClass:"row",style:(_vm.rowStyle(index))},[(_vm.statusConfig.showIndex)?_c('div',{staticClass:"cell",style:({
						'flex-basis': '55px',
						'flex-grow': 0,
						width: '55px',
						textAlign: 'center',
						..._vm.bodyStyle
					})},[_c('span',[(_vm.statusConfig.orderPointShow)?_c('span',{staticClass:"order-point-icon",style:({ color: _vm.statusConfig.orderPointColor })},[_vm._v("•")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("indexToOrderNum")(index))+" ")])]):_vm._e(),_vm._l((_vm.fieldForView),function(item,subindex){return _c('div',{key:item.uuid,staticClass:"cell",style:(Object.assign(
							{
								width: `${item.width}px`,
								'flex-basis': `${item.width}px`,
								flex: item.width ? '' : '1',
								color: item.color,
								textAlign: item.align || 'left',
								..._vm.bodyStyle
							},
							_vm.userInnerBorder && (_vm.statusConfig.showIndex || subindex > 0) ? _vm.cellBorder : {}
						))},[_c('span',[(item.type === 2)?[(Array.isArray(row[item.uuid]))?_c('el-image',{staticClass:"image",style:({
									height: (item.imgConfig.height || 60) + 'px',
									width: (item.imgConfig.height || 60) + 'px'
								}),attrs:{"src":row[item.uuid] && row[item.uuid].length && row[item.uuid][0].url.toString().indexOf('http') > -1
										? row[item.uuid][0].url
										: _vm.defaultImage,"previewSrcList":[
									row[item.uuid].length ? row[item.uuid][0].url.toString().replace('_thumb', '') : _vm.defaultImage
								],"lazy":"","fit":"cover","alt":"图片"}}):_c('el-image',{staticClass:"image",style:({
									height: (item.imgConfig.height || 60) + 'px',
									width: (item.imgConfig.height || 60) + 'px'
								}),attrs:{"src":row[item.uuid] && row[item.uuid].indexOf('http') > -1 ? row[item.uuid] : _vm.defaultImage,"previewSrcList":[row[item.uuid] ? row[item.uuid].toString().replace('_thumb', '') : _vm.defaultImage],"lazy":"","fit":"cover","alt":"图片"}})]:(item.type === 3 && !_vm.isNull(row[item.uuid]))?_c('span',{style:({
								color: _vm.filterStatus(row[item.uuid], item.statusOptions).color || item.color
							})},[_vm._v(" "+_vm._s(_vm.filterStatus(row[item.uuid], item.statusOptions).label)+" ")]):(item.type === 4 && !_vm.isNull(row[item.uuid]))?[_vm._v(_vm._s(_vm.filterTime(row[item.uuid], item.timeFormat, item.connector)))]:(item.type === 5 && !_vm.isNull(row[item.uuid]))?[_c('div',{staticClass:"rich-text",domProps:{"innerHTML":_vm._s(row[item.uuid])}})]:(item.type === 6 && !_vm.isNull(row[item.uuid]))?[_c('a',{staticClass:"link",attrs:{"href":row[item.uuid],"alt":"链接"}},[_vm._v(" "+_vm._s(row[item.uuid])+" ")])]:(item.type === 7)?[_c('el-switch',{on:{"change":function($event){return _vm.onSwitch(item, row[item.uuid], row)}},model:{value:(row[item.uuid]),callback:function ($$v) {_vm.$set(row, item.uuid, $$v)},expression:"row[item.uuid]"}})]:[_vm._v(_vm._s(row[item.uuid] ?? '/'))]],2)])})],2)}):_c('el-empty')],2),(_vm.statusConfig.pagination)?_c('footer',{staticClass:"paging"},[_c('el-pagination',{class:_vm.statusConfig.transparentPagination ? 'custom-pagination' : '',attrs:{"current-page":+_vm.pager.current_page,"page-sizes":[15, 30, 50, 100],"page-size":+_vm.pager.per_page,"layout":"total, sizes, prev, pager, next, jumper","total":+_vm.pager.total},on:{"size-change":_vm.onSize,"current-change":_vm.onPage}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }