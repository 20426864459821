<!--
 * @Description: 按钮
 * @Author: luocheng
 * @Date: 2021-08-23 11:42:01
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-14 10:58:02
-->
<template>
  <el-tag
	>{{ $i18n.locale === 'en'? element.enPropValue: element.propValue || '' }}</el-tag>
</template>

<script>
import { Tag } from 'element-ui';
import eventBus from '@/plugins/eventBus';
import { initParams } from '@/utils/tools';
import { dataInterface } from '@/apis/data/index';
import { mapState } from 'vuex';
export default {
  name: 'TagMenu',
  components: {
    'el-tag': Tag
  },
  props: {
    // 组件对象
    element: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
		...mapState(['componentData']),
    // 组件配置
    statusConfig() {
      return this.element ? this.element.statusConfig || {} : {};
    }
  },
	methods: {
		//同步进度计划模型
		onSyncPlanModel(data){
			let obj = {
				"__method_name__": "globalFunctionCall",
				"typeName": "PublicFunc",
				"type": "behavior",
				"funcName": "SpecialPurposeBehavior",
				"payload": {
						"function_name":"TaskToModelComponent"        
				},
				"schedule_id":data.schedule_id
			}
			dataInterface(obj).then((res) => {
				if(res.data.code === 200){
					console.log(res,'同步计划模型成功！')
				}
			}).catch(err => {
				console.log(err, '-----');
			});
		},
    handleEval(fn) {
      const Fn = Function; 
      return new Fn('return ' + fn)();
    }
	},
	mounted() {
		//监听行为
		eventBus.$on('doComponentBehavior', config => {
			const { component, list = [] } = config;
			let componentName = component.split('-')[0]
			if (!this.element.id.includes(componentName)) return false;
			list.forEach(ele => {
				const { behaviors, params } = ele;
				const { param = {}, canPost } = initParams(params, false, this.componentData, []);
				if (canPost) {
					// 调用行为方法
					behaviors.forEach(funName => {
						try {
              this.handleEval(this[funName])(param);
						} catch (err) {
							console.log(err);
						}
					});
				}
			});
		})
	},
	unmounted() {
		eventBus.$off('doComponentBehavior');
	}
}
</script>
<style lang="less" scoped>
.el-tag{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
</style>