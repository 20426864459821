<!--
    @name: widget-select
    @description：widget-select
    @author: ZengWei
    @date: 2022-03-25 09:30
-->
<template>
	<div
		:class="{
			'select-container': true,
			custom: element.config.__slot__.prepend || element.config.__slot__.append
		}"
	>
		<div
			class="prepend"
			v-if="element.config.__slot__.prepend"
			:style="{
				border: disabled || element.config.disabled ? 'none' : '1px solid #dcdfe6',
				padding: disabled || element.config.disabled ? '0 0 0 12px' : '0 20px'
			}"
		>
			{{ element.config.__slot__.prepend }}
		</div>
		<div class="select-show-content" v-if="disabled || element.config.disabled">
			<div
				class="input-show"
				:style="{
					padding: disabled || element.config.disabled ? '5px 0' : '5px 12px',
					paddingLeft: !element.config.__slot__?.prepend ? '12px' : '0',
					paddingRight: !element.config.__slot__?.append ? '12px' : '0'
				}"
			>
				{{ selectLabel }}
			</div>
			<el-button
				type="primary"
				v-if="element.config.__config__.outerViewer"
				:disabled="selectLabel === '-'"
				@click="openOuterViewer"
				>查看
			</el-button>
		</div>
		<div v-else class="select-input" style="position: relative; flex: 1">
			<div :class="{ 'outer-viewer': element.config.__config__.outerViewer }">
				<el-select
					ref="selectRef"
					v-model="element.value"
					:disabled="disabled || element.config.disabled"
					:placeholder="getPlaceholder(element.config)"
					:clearable="element.config.clearable"
					:multiple="element.config.multiple"
					:filterable="element.config.filterable"
					collapse-tags
					@focus="dynamicFilter"
					:loading="loading"
				>
					<template v-for="(val, key) in element.config.__slot__.options">
						<template v-if="getLabel(val).length > optionTextNum">
							<el-tooltip :key="key" effect="dark" :content="getLabel(val)" placement="top">
								<el-option :value="optionValue(val.value)" :label="getLabel(val)" :key="key">
									{{ getLabel(val).substr(0, optionTextNum) + '...' }}
								</el-option>
							</el-tooltip>
						</template>
						<template v-else>
							<el-option :value="optionValue(val.value)" :label="getLabel(val)" :key="key">
								{{ getLabel(val) }}
							</el-option>
						</template>
					</template>
				</el-select>
			</div>
			<el-button
				type="primary"
				v-if="element.config.__config__.outerViewer"
				style="position: absolute; top: 0; right: 0"
				@click="openOuterViewer"
				>查看
			</el-button>
		</div>
		<div
			class="append"
			v-if="element.config.__slot__.append"
			:style="{
				border: disabled || element.config.disabled ? 'none' : '1px solid #dcdfe6',
				padding: disabled || element.config.disabled ? '0 12px 0 0' : '0 20px'
			}"
		>
			{{ element.config.__slot__.append }}
		</div>
		<el-dialog
			top="5vh"
			:custom-class="'common-dialog'"
			title="外部内容查看"
			:visible.sync="outerViewerVisible"
			:width="outerViewerWidth"
			append-to-body
		>
			<div v-loading="outerViewerLoading" style="height: calc(100vh - 200px)">
				<iframe ref="iframe" :src="outerRouter" style="width: 100%; height: 100%; border: none"></iframe>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { Select, Dialog, Tooltip } from 'element-ui';
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';
import { getBodyParams, getSearchData, optionData } from '@/custom-component/form/newParser/scripts/tools';
import { formRequest } from '@/apis/data/form';

export default {
	name: 'WidgetSelect',
	components: {
		'el-select': Select,
		'el-dialog': Dialog,
		'el-tooltip': Tooltip
	},
	props: ['element', 'disabled', 'displayData'],
	mixins: [formItemMinix],
	data() {
		return {
			outerViewerVisible: false,
			outerViewerLoading: false,
			outerViewerWidth: '100%',
			outerViewerParam: {},
			outerRouter: '',
			optionTextNum: 20
		};
	},
	watch: {
		'element.value': {
			handler(val) {
				this.triggerInteractive(val);
			}
		}
	},
	computed: {
		selectLabel() {
			let value = this.element.value;
			const multiple = this.element.config.multiple;
			const data = this.element.config.__slot__.options;
			if (value && Array.isArray(data) && data?.length) {
				// 多选
				if (multiple && Array.isArray(value) && value?.length) {
					value = value.map((el) => {
						return el?.toString();
					});
					let labelArr = data.filter((item) => value.includes(item.value.toString()));
					if (labelArr.length) {
						labelArr = labelArr.map((el) => {
							return el?.label;
						});
						return labelArr.join(' ; ');
					} else {
						return '-';
					}
				} else {
					let label = data.find((item) => item?.value?.toString() === value.toString())?.label;
					if (label) {
						return label;
					} else {
						return '-';
					}
				}
			} else {
				return '-';
			}
		}
	},
	created() {
		const dynamic = this.element.config.__config__.dataType;
		if (dynamic === 'static') {
			this.triggerInteractive(this.element.value);
		} else {
			this.initDynamicData();
		}
	},
	mounted() {
		// 根据控件长度，计算最多可以展示多少字符，选项不能超出控件长度 @蒲亚军、蒋冬梅、刘刚、凌志华都提过这个需求
		const selectWidth = this.$refs.selectRef?.$el?.clientWidth;
		if (selectWidth) {
			this.optionTextNum = parseInt(selectWidth / 15);
		}
		window.addEventListener('message', (e) => {
			if (e.data.event === 'homepageDeferenceDialog') {
				this.outerViewerVisible = false;
				this.$emit('refresh-todo');
			}
		});
	},
	methods: {
		/**
		 * @desc: 数据类型统一
		 * @param {*} value
		 */
		optionValue(value) {
			const multiple = this.element.config.multiple;
			if (!multiple) {
				this.element.value = this.element.value.toString();
			}
			return '' + value;
		},
		initDynamicData() {
			const filterRule = this.element.config.__config__.filterRule ?? [];
			const objectUuid = this.element.config.__config__.objectUuid;
			const viewUuid = this.element.config.__config__.viewUuid;
			const dynamic = this.element.config.__config__.dataType;
			if (this.element.type === 'select' && dynamic === 'static') {
				return false; //select 静态数据
			}
			let url = '/api/mapi',
				params;
			let searchData = getSearchData(filterRule, this.parser.getFormIdValue);
			params = {
				__method_name__: 'dataList',
				object_uuid: objectUuid,
				view_uuid: viewUuid,
				search: searchData,
				size: 1000,
				__now_archi_type: this.parser.nowArchiType
			};
			let bodyParams = getBodyParams(filterRule, this.parser.getFormIdValue);
			if (Object.keys(bodyParams).length) {
				params = Object.assign(params, bodyParams);
			}
			if (searchData.length) params.search = searchData;
			if (objectUuid && viewUuid) {
				formRequest('post', url, params).then((res) => {
					let respData;
					respData = res.data.data;
					if (res.data.data.data) {
						respData = res.data.data.data;
					}
					const tag = this.element.config.__config__.tagIcon;
					const propLabel = this.element.config.props.props.label;
					const propEnLabel = this.element.config.props.props.enLabel;
					const propValue = this.element.config.props.props.value;
					const options = optionData(respData, tag, propLabel, propValue);

					// 字典翻译
					if (this.$i18n.locale === 'en') {
						options.map((item) => {
							this.$set(item, 'enLabel', item?.allField[propEnLabel]);
						});
					}

					if (options) {
						this.element.config.__slot__.options = options;
					}
					this.triggerInteractive(this.element.value, 'auto');
					this.loading = false;
				});
			}
		},
		dynamicFilter() {
			const childFilter = this.element.config.__config__?.childFilter || false;
			const filterRule = this.element.config.__config__?.filterRule || [];
			if (filterRule.length && !childFilter) {
				this.loading = true;
				this.initDynamicData();
			}
		},
		openOuterViewer() {
			this.outerViewerVisible = true;
			let route = this.element.config.__config__.outerRouter;
			const width = this.element.config.__config__.windowWidth;
			if (width) this.outerViewerWidth = width;
			const obj = this.outerViewerParam;
			const keys = Object.keys(obj);
			const regex = /\{(.+?)\}/g;
			const arr = route.match(regex);
			if (arr !== null) {
				for (let item of arr) {
					let param = item.replace('{', '').replace('}', '');
					if (keys.includes(param)) {
						route = route.replace(item, obj[param]);
					}
				}
			}
			this.outerRouter = route;
			this.outerViewerLoading = true;
			this.$nextTick(() => {
				this.$refs.iframe.onload = () => {
					this.outerViewerLoading = false;
				};
			});
		},
		getLabel(val) {
			return this.$getLocalizedLabel({
				zhLabel: val.label,
				enLabel: val.enLabel
			}).toString();
		},
		getPlaceholder(item) {
			return this.$i18n.locale === 'en' ? item.enPlaceholder : item.zhPlaceholder;
		}
	}
};
</script>

<style lang="less" scoped>
:deep(.common-dialog) {
	.el-dialog__body {
		padding: 0;
	}
}

.dialog-main {
	padding: 0 15px;
	height: calc(100vh - 200px);
	overflow-y: auto;
}
.select-show-content {
	display: flex;
	.select-show {
		width: 100%;
		padding: 5px 12px;
		box-sizing: border-box;
		background: #f2f4f7;
		border-radius: 4px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		color: #2a2f3d;
		line-height: 22px;
		cursor: default;
	}
}
.select-container {
	display: flex;
	align-items: center;
	.select-show-content {
		flex: 1;
	}
	.input-show {
		flex: 1;
	}
	.select-input {
		:deep(.el-input__inner) {
			border: 1px solid #dcdfe6 !important;
		}
	}
	.prepend {
		background: #f5f7fa;
		padding: 0 20px;
		border: 1px solid #dcdfe6;
		border-radius: 4px 0 0 4px;
		box-sizing: border-box;
		height: 36px;
	}
	.append {
		background: #f5f7fa;
		padding: 0 20px;
		border: 1px solid #dcdfe6;
		border-radius: 0 4px 4px 0;
		box-sizing: border-box;
		height: 36px;
	}
}
.custom {
	.input-show {
		border-radius: 0;
	}
	.select-input {
		flex: 1;
		:deep(.el-input__inner) {
			border-radius: 0;
		}
	}
}
</style>
