<!--
 * @Description: 折线图
 * @Author: luocheng
 * @Date: 2022-01-07 09:22:05
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2024-06-28 14:11:46
-->
<template>
	<div
		class="chart-line"
		:style="{
			height: height + 'px'
		}"
		v-loading="getting"
		:element-loading-background="loadingBackColor"
	>
		<ChartContainer
			v-if="option && canShow"
			ref="chartInstance"
			class="chart-bar"
			:option="option"
		></ChartContainer>
		<el-empty
			v-else
		></el-empty>
	</div>
</template>

<script>
import mixin from './mixin';

export default {
	name: 'ChartLine',
	mixins: [mixin],
	data() {
		return {
			statisticalData: null,
			option: null
		};
	},
	created() {
		this.getOptions();
	},
	watch: {
		'$i18n.locale': {
			handler(n, o) {
				if(n && n !== o) {
					this.getOptions()
				}
			}
		}
	},
	methods: {
		/**
		 * @desc: 设置配置项
		 * @param {*} data
		 * @return {*}
		 */
		async setOptions(data) {
			if (!data || !data.length) return false;
			const { xData = [], series = [], names = [] } = data[0];
			if (!xData?.length) {
				this.canShow = false;
				return false;
			}
			const { attributes = {} } = this.chartData;
			/**
			 * 国际化 英文翻译
			 */
			if(this?.$i18n?.locale === 'en') {
				const tasks = [];
				// 需要转换的内容
				if(attributes.showTitle) {
					tasks.push({ target: attributes, key: 'title' });
				}
				if(attributes.xAxisText) {
					tasks.push({ target: attributes, key: 'xAxisText' });
				}
				if(attributes.yAxisText) {
					tasks.push({ target: attributes, key: 'yAxisText' });
				}
				names.forEach((el, index) => {
					tasks.push({ target: names, key: index });
				})
				Object.keys(xData).forEach((key) => {
					tasks.push({ target: xData, key });
				})
				series.forEach((el) => {
					tasks.push({ target: el, key: 'name' });
				})
				attributes?.markLines?.forEach((el) => {
					tasks.push({ target: el, key: 'labelName' });
				})
				await this.translate2English(tasks);
			}
			const {
				showTitle = true,
				title = '',
				textColor = '',
				titleFontSize = 12,
				titleFontWeight = 400,
				showTooltip = true,
				tooltipFontsize = 14,
				showLegend = true,
				legendTextColor = '#000000',
				legenFontSize = 12,
				smooth = false,
				xAxisText = '',
				yAxisText = '',
				colorRange = [],
				left = '10%',
				right = '10%',
				top = 60,
				bottom = 60,
				legendIcon = 'circle',
				showDataZoom = false,
				dataZoomBottom = 'auto',
				dataZoomStart = 0,
				dataZoomEnd = 100,
				legendOrient = 'vertical',
				legendType = 'plain',
				titleTop = 'auto',
				titleBottom = 'auto',
				titleRight = 'auto',
				titleLeft = 'auto',
				axisTextColor = '#ffffff',
				axisFontSize = '14px',
				useArea = false,
				userStack = false,
				useGradient = false,
				gradientRange = [],
				minInterval = 0,
				labelRotate = 0,
        labelHideLength = 0,
				dataZoomBackGround ='rgba(47,69,84,0)',
				dataZoomFill = 'rgba(167,183,204,0.4)',
				areaStyleColor = '#d2dbee',
				areaStyleOpacity=  0.2,
				areaStyleShadowColor = '',
				selectedDataAreaColor ='#8fb0f7',
				selectedDataAreaOpacity = 0.2,
				selectedDataAreaShadowColor = '',
				dateZoomHeight = 30,
				moveHandleColor = '#D2DBEE',
				markLines = [],
				showLineLabel = false,
				lineLabelFontsize = 12,
				hiddenXAxis = false,
				hiddenYAxis = false
			} = attributes;
			this.option = {
				title: {
					show: showTitle,
					text: title,
					left: 'left',
					y: 'top',
					textStyle: {
						color: textColor,
						fontFamily: '微软雅黑',
						fontSize: this.scaleSize(titleFontSize || 12),
						fontWeight: titleFontWeight || 400,
						left: titleLeft,
						top: titleTop,
						bottom: titleBottom,
						right: titleRight
					}
				},
				tooltip: {
					show: showTooltip,
					trigger: 'axis',
					axisPointer: {
						type: 'line'
					},
					textStyle: {
						fontSize: this.scaleSize(tooltipFontsize || 14),
					},
					appendToBody: true
				},
				legend: {
					show: showLegend,
					itemWidth: 10,
					itemHeight: 10,
					data: this.getLegendData(names, legendTextColor),
					icon: legendIcon,
					...this.getLegendPosition(attributes),
					type: legendType,
					orient: legendOrient,
					textStyle: {
						fontSize: this.scaleSize(legenFontSize)
					}
				},
				color: colorRange || [],
				grid: {
					left,
					right,
					bottom,
					top,
					containLabel: true
				},
				xAxis: {
					show: !hiddenXAxis,
					type: 'category',
					name: xAxisText,
					data: xData || [],
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: true // 隐藏坐标轴
					},
					axisLabel: {
						textStyle: {
							color: axisTextColor,
							fontSize: this.scaleSize(axisFontSize)
						},
						rotate: labelRotate,
            formatter: function (params){
              if(labelHideLength){
                if( params.length > labelHideLength )
                  return params.substring(0, labelHideLength) + '...';
                else
                  return params;
              }else {
                return params;
              }
            },
						margin: 16 // 文案与坐标轴间距
					}
				},
				dataZoom: [
					{
						type: 'slider',
						show: showDataZoom,
						backgroundColor: dataZoomBackGround,
						fillerColor: dataZoomFill,
						dataBackground: {
							areaStyle: {
								color: areaStyleColor,
								opacity: areaStyleOpacity,
								shadowColor: areaStyleShadowColor
							}
						},
						selectedDataBackground: {
								areaStyle: {
									color: selectedDataAreaColor,
									opacity:selectedDataAreaOpacity
								},
								shadowColor: selectedDataAreaShadowColor
						},
						start: dataZoomStart || 0,
						end: dataZoomEnd || 100,
						bottom: isNaN(dataZoomBottom) ? dataZoomBottom : Number(dataZoomBottom),
						height: dateZoomHeight,
						moveHandleStyle: {
							color: moveHandleColor
						}
					}
				],
				yAxis: {
					show: !hiddenYAxis,
					type: 'value',
					name: yAxisText,
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: true // 隐藏坐标轴
					},
					axisLabel: {
						textStyle: {
							color: axisTextColor,
							fontSize: this.scaleSize(axisFontSize)
						},
						margin: 16 // 文案与坐标轴间距
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: 'rgba(194, 197, 204, 0.1)'
						}
					}
				},
				series: series.map((ele, i) => {
					// return {
					// 	name: ele.name,
					// 	type: 'line',
					// 	symbol: smooth ? 'none' : '', //去掉折线图中的节点
					// 	smooth, //true 为平滑曲线，false为直线
					// 	data: ele.data
					// };
					const data = {
						name: ele.name,
						type: 'line',
						symbol: smooth ? 'none' : '', //去掉折线图中的节点
						smooth, //true 为平滑曲线，false为直线
						data: ele.data,
						label: {
							show: showLineLabel,
							position: 'top',
							fontSize: this.scaleSize(lineLabelFontsize || 12),
							color: useGradient ? textColor : 'inherit', // 设置跟随为视觉映射得到的颜色，如系列色
						},
					}
					if(Array.isArray(markLines) && markLines.length) {
						const markLineData = []
						markLines.forEach(item => {
							const { labelName = '默认标题', lineColor = '', type = '', fixedValOne = '', fixedValTow = '', attachmentVal = '' } = item
							const obj = {
								name: labelName,
								label: {
									formatter: '{b}-{c}',
									position: 'insideStart'
								}
							}
							if(type === 'attachment' && !isNaN(attachmentVal) && i === 0) {
								obj.yAxis = attachmentVal
							} else if (type === 'fixed' && !isNaN(fixedValOne) && !isNaN(fixedValTow) && i === 0) {
								const startObj = {
									name: 'start: ' + labelName,
									coord: [0, Number(fixedValOne)],
									label: {
										formatter: labelName,
										position: 'insideStart'
									}
								}
								const endObj = {
									name: 'end: ' + labelName,
									coord: [ele.data.length - 1, Number(fixedValTow)]
								}
								if(lineColor) {
									startObj.lineStyle = {
										color: lineColor
									}
									endObj.lineStyle = {
										color: lineColor
									}
								}
								markLineData.push([startObj, endObj])
							} else if(type === 'average') {
								const sum = ele.data.reduce((a, b) =>{
									const av = a.value ?? a
									const bv = b.value ?? b
									return Number(av) + Number(bv)
								}, 0)
								const val = sum / ele.data.length
								obj.yAxis = val
							}
							if(lineColor) {
								obj.lineStyle = {
									color: lineColor
								}
							}
							if(type !== 'fixed' && type !== 'attachment') {
								markLineData.push(obj)
							}
							if(type === 'attachment' && i === 0) {
								markLineData.push(obj)
							}
						})
						if(markLineData.length) {
							data.markLine = {
								data: markLineData
							}
						}
					}
					if(this.indialog) {
						data.lineStyle = {
							width: 4
						};
						data.symbolSize = 8;
					}
					if(userStack || useArea) {
						// 使用堆叠
						if(userStack) {
							data.stack = 'Total'
						}

						const areaStyle = {
							opacity: 0.8
						}
						if(useGradient) {
							areaStyle.color = {
								type: 'linear',
								x: 0.5,
								y: 1,
								x2: 0.5,
								y2: 0,
								colorStops: Array.isArray(gradientRange[i % gradientRange.length]) ?
								gradientRange[i % gradientRange.length].map((el, index) => {
									if(index === 0) {
										return ({
											offset: 0,
											color: el
										})
									}
									return ({
										offset: ( 1 / gradientRange[i % gradientRange.length].length) * (index + 1),
										color: el
									})
								}) : [],
								global: false // 缺省为 false
							}
						}
						data.areaStyle = areaStyle
					}
					return data
				})
			};
			if(!isNaN(Number(minInterval))) {
				const number = Number(minInterval)
				if(number > 0) {
					this.option.yAxis.minInterval = number
				}
			}
		},
		/**
		 * @desc: 设置图例
		 * @param {Array} names 名称
		 * @param {String} color 文本颜色
		 * @return {*}
		 */
		getLegendData(names, color) {
			let s = [];
			if (!names) {
				return [];
			}
			for (let i = 0; i < names.length; i++) {
				s.push({
					name: names[i],
					textStyle: {
						color,
						// fontSize: this.scaleSize(12)
					}
				});
			}
			return s;
		}
	}
};
</script>

<style lang="less" scoped>
.chart-line {
	height: 100%;
	width: 100%;
	:deep(.el-empty){
		padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
		.el-empty__image{
			width: 35%;
			max-width: 120px;
			min-width: 50px;
		}
		.el-empty__description{
			margin-top: 15px;
		}
		.page-container p{
			color: #fff;
		}
	}
}
</style>