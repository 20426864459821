/*
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-08-10 15:31:30
 * @LastEditors: asahi
 * @LastEditTime: 2024-07-23 14:26:28
 * @FilePath: \dataview-next\src\custom-component\component-config\newModel.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const modelConsole = {
	type: 'model',
	title: 'BIM组件',
	show: true,
  key: 3,
	order: 7,
	componentList: [
		{
			component: 'ViewerBIM',
			type: 'container',
			name: '模型',
			icon: 'iconbim-bimmx',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: true,
			},
		},
		{
			component: 'viewerBIMModule',
			type: 'container',
			name: '功能容器',
			icon: 'iconbim-gnrq',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
		{
			component: 'DunGou',
			type: 'container',
			name: '盾构',
			icon: 'icon-moxing',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
		{
			component: 'viewerBIMModuleViewState',
			type: 'container',
			name: '视点列表',
			icon: 'iconbim-sdlb',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
		{
			component: 'viewerBIMModuleModelTree',
			type: 'container',
			name: '模型树',
			icon: 'iconbim-mxs',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: false,
				width: '',
			},
		},
  ]
};

export default modelConsole;
