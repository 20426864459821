/*
 * @Description: 容器组件
 * @Author: luocheng
 * @Date: 2021-10-15 14:50:51
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-01-12 17:54:39
 */
// 容器
import { uniqid } from "@/utils/tools";

const containers = {
	type: 'container',
	title: '容器组件',
	show: true,
	key: 1,
	order: 2,
	componentList: [
		{
			component: 'CommonContainer',
			type: 'container',
			name: '基础容器',
			icon: 'iconbuju-jcrq',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				resolveObject: false,
				userLoading: false,
				globalLoading: false
			},
			propValue: '',
			style: {
				minHeight: '',
				width: '',
				height: 400,
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'flex-start',
				alignContent: 'flex-start',
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'column', // flex 主轴方向
				flexWrap: false, // 是否换行
			},
			behaviors: [
				{
					label: '修改背景颜色',
					value: 'changeBackground',
					desc: '修改文本背景, 目前支持颜色，参数字段color: 字体颜色'
				},
				{
					label: '修改字体',
					value: 'changeFont',
					desc: '修改文本字体，参数字段color: 字体颜色， fontSize: 字体大小，fontWeight: 字重（字体粗细数字或字符）'
				},
				{
					label: '刷新数据',
					value: 'refreshData',
					desc: '刷新数据'
				}
			],
			interfaceInterval: null // 定时刷新配置
		},
		{
			component: 'CommonLoopContainer',
			type: 'container',
			name: '循环容器',
			icon: 'iconbuju-xhrq',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				isShow: true, // 是否显示
				idUUID: '',
				useCheck: false, // 选择框
				resolveFullNode: false,
				defaultCheck: true,
				resolveCodes: ''
			},
			propValue: '',
			style: {
				minHeight: '',
				maxHeight: '',
				width: '',
				height: '100%',
				display: 'flex',
				overflowX: 'hidden',
				overflowY: 'auto',
			},
			// 用于直接渲染的数据
			containerData: null,
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'row', // flex 主轴方向
				flexWrap: true, // 是否换行
				justifyContent: 'flex-start'
			},
			loopConfig: { // 循环配置
				cardId: '',
				mapTable: [] // 值映射表
			},
			behaviors: [
				{
					label: '切换复选框显隐',
					value: '_toggleCheckBox',
					desc: '切换复选框显隐。参数字段 isShow, 0 隐藏，1 显示。'
				},
				{
					label: '清除已选项',
					value: '_clearCheckList',
					desc: '清除当前已选项，目前无需参数'
				}
			]
		},
		{
			component: 'CommonScreenContainer',
			type: 'screenContainer',
			name: '大屏容器',
			icon: 'iconbuju-dprq',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			propValue: '',
			style: {
				width: 500,
				height: 400,
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'column', // flex 主轴方向
				flexWrap: false, // 是否换行
			}
		},
		{
			component: 'CommonGridContainer',
			type: 'container',
			name: '拖拽容器',
			icon: 'iconbuju-tzrq',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				panelName: 'Dashboard名称',
				isShow: true, // 是否显示
				toolbar: true,
				autoSize: true,
				// responsive: true,
				preventCollision: false,
			},
			propValue: '',
			style: {
				minHeight: '',
				width: '',
				height: '100%',
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'flex-start',
				alignContent: 'flex-start',
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			layoutData: [
				{ id: uniqid(), name: '', bgColor: '', "x": 0, "y": 0, "w": 3, "h": 6, "i": "0" },
				{ id: uniqid(), name: '', bgColor: '', "x": 3, "y": 0, "w": 3, "h": 6, "i": "1" },
				{ id: uniqid(), name: '', bgColor: '', "x": 6, "y": 0, "w": 3, "h": 6, "i": "2" },
				{ id: uniqid(), name: '', bgColor: '', "x": 9, "y": 0, "w": 3, "h": 6, "i": "3" },
				{ id: uniqid(), name: '', bgColor: '', "x": 0, "y": 6, "w": 3, "h": 6, "i": "4" },
				{ id: uniqid(), name: '', bgColor: '', "x": 3, "y": 6, "w": 3, "h": 6, "i": "5" },
				{ id: uniqid(), name: '', bgColor: '', "x": 6, "y": 6, "w": 3, "h": 6, "i": "6" },
				{ id: uniqid(), name: '', bgColor: '', "x": 9, "y": 6, "w": 3, "h": 6, "i": "7" },
				{ id: uniqid(), name: '', bgColor: '', "x": 0, "y": 12, "w": 3, "h": 6, "i": "8" },
				{ id: uniqid(), name: '', bgColor: '', "x": 3, "y": 12, "w": 3, "h": 6, "i": "9" },
				{ id: uniqid(), name: '', bgColor: '', "x": 6, "y": 12, "w": 3, "h": 6, "i": "10" },
				{ id: uniqid(), name: '', bgColor: '', "x": 9, "y": 12, "w": 3, "h": 6, "i": "11" },
			],
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'row', // flex 主轴方向
				flexWrap: false, // 是否换行
			}
		},
		{
			component: 'CommonPageContainer',
			type: 'container',
			name: '页面容器',
			icon: 'iconbuju-ymrq',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				showAction: false
			},
			propValue: '',
			style: {
				width: '',
				height: 400,
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			actionConfig: [
			],
			fieldConfig: [], // 字段配置列表
			contentConfig: {
				contentType: 'configPage', // 内容类型
				configType: '', // 配置类型 '' 普通类型， dynamic 动态配置
				dynamicComponent: '', // 动态组件
				objectUUID: '', // 数据对象
				viewUUID: '', // 视图ID
				pageId: '', // 页面id
				formUUID: '', // 表单对象
				developPageRoute: '', // 首页页面路由
			}
		},
		{
			name: '内嵌页面',
			component: 'IframeCom',
			type: 'functionCom',
			span: 24,
			icon: 'iconbuju-nqym',
			style: {
				width: '100%',
				height: 400,
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				isPreview: false,
				addParamToSrc: false, // 是否追加参数至src
				src: 'http://www.baidu.com'
			}
		},
		{
			name: '路由容器',
			component: 'RouterCom',
			type: 'functionCom',
			icon: 'iconbuju-lyrq',
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			}
		},
		{
			component: 'CommonSheetContainer',
			type: 'container',
			name: 'Excel容器',
			icon: 'icon-excelrongqi',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				showAction: false
			},
			propValue: '',
			style: {
				width: '',
				height: 400,
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
			// 用于直接渲染的数据
			containerData: null,
			actionConfig: [
				{
					key: 'cancel',
					effect: false, // 是否有附加作用
					effectType: '', // 附加操作类型 请求类型
					canCustom: false, // 是否可以删除
					buttonType: '',
					text: '取消',
					postType: '', // 请求类型
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
					show: true,
				},
				{
					key: 'confirm',
					effect: false,
					effectType: '',
					canCustom: false,
					postType: '', // 请求类型
					buttonType: 'primary',
					text: '确认',
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
					show: true,
				}
			],
			baseConfig: {
				/**
				 * 渲染模式：preview - 预览, edit - 编辑
				 * 渲染模式是为了bimcc-spreadsheet与外部交互所使用的新模式
				 * 此模式与solveType的区别在于会动态改变，甚至去修改solveType或其他属性
				 */
				renderMode: 'edit',
				/**
				 * 解析类型：form - 表单, table - 数据表
				 * 解析类型的作用是用来判断提交方式，form数据提交时走常规接口，table的导入/编辑走固定接口
				 */
				solveType: "",
				/**
				 * 操作类型：add - 新增/导入，edit - 编辑，info - 详情，export - 导出
				 * 直接传入Entry组件
				*/
				type: '',
				enableDialog: false, // 是否启用弹窗
				dialogTitle: "Excel弹窗", // 标题
				excelTemplate: '',
				autoTemplate: false,
				checkOnly: false, // 导入时查重
			},
			databaseList: [],
			dataSource: [
				{
					code: '', // 字段名
					isRequired: false, // 必须
					source: 'component', // 值来源， component 组件，url url参数，system 系统参数
					component: '', // 关联组件
					componentField: '', // 组件取值字段
					urlKey: '', // url参数字段
					systemObject: '', // 系统取值对象
					systemField: '' // 系统取值字段
				}
			],
			behaviors: [
				{
					label: '刷新',
					value: 'refresh'
				},
				{
					label: '切换模式',
					value: 'toggleMode',
					desc: '字段名: mode; 字段值: preview - 预览, edit - 编辑',
				},
				{
					label: '保存',
					value: 'triggerSubmit'
				},
			],
			updateRulesConfig: {
				pattern: '',
				rules: []
			}
		},
	]
};
export default containers;
