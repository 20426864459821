<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2024-08-09 10:39:26
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2024-08-30 14:44:29
 * @FilePath: \dataview-viewer-test\src\custom-component\form\formParser\selectUser\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: Shiltin
 * @Date: 2021-10-20 17:52:57
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2024-08-09 10:57:07
 * @Description:
-->
<template>
  <div
    style="height: 100%"
  >
    <cascader
      :data-info="userData"
      :default-expand="defaultExpand"
      :disabled="disabled"
      :tableClearVisible="tableClearVisible"
      :show-choose-button="showChooseButton"
      :inpage="inpage"
      :element="element"
      :is-check-type="isCheckType"
      :max-height="maxHeight"
      :position-type="positionType"
      :show-list="showList"
      :show-tree="showTree"
      :is-console="isConsole"
      :width="width"
      :lazy="lazy"
      @on-select-change="onSelectChange"
      @tab-change="tabChange"
    />
  </div>
</template>
<script>
import cascader from './components/cascader';
// import { getValue } from "@/api/workflow";
import { getUserList } from './js';
import { getUserSelect, getUserListDepartId } from '@/apis/data/monitor';
export default {
  name: '',
  components: { cascader },
  props: {
    /* 流程组件内（inpage）页面盒子内使用示例
   <select-user
      :inpage="true"
      :not-form="true"
      :is-itself="true" //当前架构人员  不选下级
      :depart-id="210" //指定部门id
      @on-select-change="onSelectChange"
    />
   */
    /**
     * @desc 流程状态调用人员组件时候传输的流程架构ID
     */
    flowArchiId: {
      type: Array,
      default: () => {
        [];
      },
    },
    /**
     * @desc 流程架构范围
     */
    flowArchiType: {
      type: Number,
    },

    dataInfo: {
      type: Object,
      default: () => {},
    },
    /* 回显传入的选中的值[
          { user_id: 1, section_id: 1 },
          { user_id: 14, section_id: 1 },
        ], */
    showList: {
      type: Array,
      default: () => [],
    },
    /* 指定公司id */
    companyId: {
      type: Number,
      default: 0,
    },
    /* 指定部门id */
    departId: {
      type: Array,
      default: () => [],
    },
    /* 指定项目id */
    projectId: {
      type: Number,
      default: 0,
    },
    /* 只请求架构本身的人员  不包括下级单位 */
    isItself: {
      type: Boolean,
      default: false,
    },
    /* 回显传入的选中的值 */
    inpage: {
      type: Boolean,
      default: false,
    },
    // false 级联单选
    // true 级联多选
    isCheckType: {
      type: Boolean,
      default: true,
    },
    /* 点击选择窗口位置 */
    positionType: {
      type: String,
      default: 'bottom-start',
    },
    /* 弹出框宽度 */
    width: {
      type: Number,
      default: 450,
    },
    /* 弹出框最大高度 */
    maxHeight: {
      type: Number,
      default: 300,
    },
    /* 弹出框宽度 */
    notForm: {
      type: Boolean,
      default: false,
    },
    /* 最大的显示人的长度(form表单默认100%会撑开，不能超出隐藏，form表单下使用需要设置宽度数值) */
    maxShowWidth: {
      type: Number,
      default: 428,
    },
    /* 默认展开 */
    defaultExpand: {
      type: Boolean,
      default: true,
    },
    pickerType: {
      type: String,
      default: 'target',
    },
    pickerNode: {
      type: String,
      default: '',
    },
    /* 禁用选择 */
    disabled: {
      type: Boolean,
      default: false,
    },
    /* 展示按钮的选择人员 */
    showChooseButton: {
      type: Boolean,
      default: false,
    },
    /* 懒加载 */
    lazy: {
      type: Boolean,
      default: false,
    },
    //中控台引入
    isConsole: {
      type: Boolean,
      default: false,
    },
    roleId: {
      type: Array,
      default: () => [],
    },
    element: {
      type: Object,
      default: () => {},
    },
    visibleEmit: {
      type: Boolean,
      default: false,
    },
    tableClearVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userInfo: {},
      archiInfo: {},
      userData: null,
      showTree: true,
      tabId: 1,
      userListData: '',
    };
  },
  watch: {
    dataInfo: {
      handler() {
        if (Object.keys(this.dataInfo).length) {
          const obj = JSON.parse(JSON.stringify(this.dataInfo));
          this.handleDataInfo(obj);
        }
      },
      deep: true,
    },
  },
  methods: {
    tabChange(id) {
      this.tabId = id;
      this.selectUserData();
    },
    selectUserData() {
      this.showTree = true;
      let arr;
      if (+this.tabId === 3) {
        arr = getUserList(
          this.userListData.projects,
          this.tabId,
          'section',
          this.roleId
        );
      } else if (+this.tabId === 2) {
        arr = getUserList(
          this.userListData.companys,
          this.tabId,
          'section',
          this.roleId
        );
      } else {
        arr = getUserList(
          this.userListData.tree,
          this.tabId,
          'section',
          this.roleId
        );
      }
      let key = 1;
      const nodeKey = ($list) => {
        $list.forEach((element) => {
          if (element.section_id && element.user_id) {
            element.nodeKey = key;
            key += 1;
          } else if (element.children && element.children.length) {
            nodeKey(element.children);
          }
        });
      };
      if (arr && arr.length) {
        nodeKey(arr);
      }
      this.setId(arr);
      this.userData = arr;
      this.showTree = false;
    },
    onSelectChange(arr, bol) {
      const pickerNode = this.pickerNode;
      const pickerType = this.pickerType;
      const params = { pickerNode, pickerType };
      if ((!this.visibleEmit && !bol) || (this.visibleEmit && bol)) {
        this.$emit('on-select-change', arr, params);
      }
    },
    handleDataInfo(obj) {
      if (obj.type === 'section') {
        this.companySectionEdit(obj.list);
      } else if (obj.type === 'project') {
        const arr = getUserList(obj.list, 'project', 'section', this.roleId);
        let key = 1;
        const nodeKey = ($list) => {
          if (!$list || $list.length <= 0) {
            return;
          }
          $list.forEach((element) => {
            if (element.section_id && element.user_id) {
              element.nodeKey = key;
              key += 1;
            } else if (element.children && element.children.length) {
              nodeKey(element.children);
            }
          });
        };
        if (arr && arr.length) {
          this.setId(arr);
          nodeKey(arr);
          this.userData = JSON.parse(JSON.stringify(arr));
        } else {
          this.userData = [];
        }
        this.showTree = false;
      } else if (obj.type === 'company') {
        const arr = getUserList(obj.list, 'company', 'section', this.roleId);
        let key = 1;
        const nodeKey = ($list) => {
          if (!$list || $list.length <= 0) {
            return;
          }
          $list.forEach((element) => {
            if (element.section_id && element.user_id) {
              element.nodeKey = key;
              key += 1;
            } else if (element.children && element.children.length) {
              nodeKey(element.children);
            }
          });
        };
        if (arr && arr.length) {
          this.setId(arr);
          nodeKey(arr);
          this.userData = JSON.parse(JSON.stringify(arr));
        } else {
          this.userData = [];
        }
        this.showTree = false;
      }
    },
    companySectionEdit(data) {
      let arr = data;
      if (arr.length) {
        arr.forEach((item) => {
          if (this.roleId?.length && item.users?.length) {
            for (let index = 0; index < item.users.length; index++) {
              const element = item.users[index];
              let isRole = false;
              if (element?.roles?.length) {
                element.roles.forEach((item) => {
                  let arr = this.roleId.map((ite) => {
                    return +ite;
                  });
                  if (arr.indexOf(+item.roleID) > -1) {
                    isRole = true;
                  }
                });
              }
              if (!isRole) {
                item.users.splice(index, 1);
                index--;
              }
            }
          }
          item.children = item.users;
        });
        arr = arr.filter((item) => item.children.length > 0);
        let key = 1;
        if (arr.length) {
          arr.forEach((element) => {
            element.children.forEach((itemz) => {
              itemz.nodeKey = key;
              itemz.departmentName = element.name;
              key += 1;
            });
          });
          this.setId(arr);
          this.userData = JSON.parse(JSON.stringify(arr));
          this.showTree = false;
        }
      }
    },
    projectSectionEdit(data) {
      const arr = [];
      let key = 1;
      data.forEach((element) => {
        const project_name = element.name;
        const project_id = element.id;
        const sections = [];
        element.sections.forEach((item) => {
          if (item.users && item.users.length) {
            item.children = item.users;
            item.children.forEach((user) => {
              user.nodeKey = key;
              key += 1;
              user.project_name = project_name;
              user.project_id = project_id;
            });
            if (item.children && item.children.length) {
              sections.push(item);
            }
          }
        });
        element.children = sections;
        if (element.sections && element.sections.length) {
          arr.push(element);
        }
      });
      this.setId(arr);
      this.userData = JSON.parse(JSON.stringify(arr));
      this.showTree = false;
    },
    /* 前端定义唯一key */
    setId(arr) {
      let key = 1;
      const loopIdList = (arr) => {
        arr.forEach((element) => {
          element.idKey = key;
          key += 1;
          if (element.children && element.children.length) {
            loopIdList(element.children);
          }
        });
      };
      if (!arr || arr.length === 0) {
        return;
      } else {
        loopIdList(arr);
      }
    },
    getList() {
      this.showTree = true;
      let archiId = this.archiInfo.id; // 当前登录的架构id
      let params = {
        type: this.element?.config?.__config__.archiType,
      };
      if (this.element?.config.__config__?.filterRule?.length) {
        let rule = this.element.config.__config__.filterRule;
        rule.forEach((item) => {
          if (item.value) {
            if (item.value === 'departId') {
              params[item.code] = JSON.parse(item.value);
            } else {
              params[item.code] = item.value;
            }
          }
        });
      }
      // 如果有选择部门 优先使用部门id
      if (params.departId) {
        getUserListDepartId({ section: params.departId }).then((res) => {
          if (res.data.code === 200) {
            this.userListData = JSON.parse(JSON.stringify(res.data.data));
            this.selectUserData();
          }
        });
        return;
      }
      //  如果有传参数据优先使用传参数据（流程传参）
      if (this.flowArchiType) {
        params.type = this.flowArchiType;
      }
      if (
        this.flowArchiId &&
        this.flowArchiId?.filter((item) => item)?.length
      ) {
        params.archi_types = this.flowArchiId;
      }

      // 如果没有选择架构使用当前架构
      if (!params.archi_types) {
        params.archi_types = archiId;
      }
      getUserSelect(params).then((res) => {
        if (res.data.code === 200) {
          this.userListData = JSON.parse(JSON.stringify(res.data.data));
          this.selectUserData();
        }
      });
    },
  },
  created() {
    this.userInfo = this.$GetUserInfo();
    this.archiInfo = this.$GetTargetArchi();
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="less" scoped></style>
