<!--
    @name: widget-steps
    @description：widget-steps
    @author: ZengWei
    @date: 2022-03-25 09:41
-->
<template>
  <div>
    {{ initTrigger(element) }}
    <div class="step-bar">
      <div style="flex: 1">
        <el-steps :active="element.config.__config__.activeName" simple>
          <el-step
            v-for="(step,stepInd) in element.config.__steps__"
            :key="stepInd"
          >
            <template slot="title">
              <span style="cursor: pointer;" @click="toggleStep(element,stepInd)">{{ $getLocalizedLabel({'zhLabel': step.label, 'enLabel': step.enLabel}) }}</span>
            </template>
            <!--<template slot="icon"><i :class="'iconfont '+step.icon"></i></template>-->
          </el-step>
        </el-steps>
      </div>
      <div style="width: 90px;">
        <el-button type="primary" @click="nextStep(element)">{{ $i18n.locale === 'en' ? 'Next' : '下一步' }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {Steps,Step} from "element-ui"
export default {
  name: "WidgetSteps",
  components: {
    'el-steps': Steps,
    'el-step': Step,
  },
  props: ['element', 'disabled'],
  data() {
    return {}
  },
  computed: {
    initTrigger() {
      return function (element) {
        let value = element.value;
        if (element.type === 'steps') {
          value = element.config.__config__.activeName;
        }
        this.triggerEventCtrl(element, value)
      };
    },
  },
  methods: {
    toggleStep(item,stepInd){
      let allow = item.config.__config__.allowChange
      if(allow) {
        item.config.__config__.activeName = stepInd
      }
    },
    nextStep(item){
      let stepInd = item.config.__config__.activeName
      if(stepInd < item.config.__steps__.length-1){
        item.config.__config__.activeName++
      }
    },
    // 初始触发交互事件（step,radio,select）默认值
    triggerEventCtrl(element,val){
      const interact = element.config.__config__.interact;
      const effect = interact.effect;
      const condition = interact.condition;
      if (effect === 'showhide') {
        let trueData = condition.filter(item => {
          if(typeof item.condV === 'string' && item.condV.indexOf(',')){
            let arr = item.condV.split(',');
            return arr.includes(val) || arr.includes(val.toString())
          }
          return item.condV == val
        })
        let compList = Array.from(condition, item => item.comp).flat();
        let compShowHide = {};
        for (let item of compList){
          compShowHide[item] = false;
        }
        if (trueData.length) {
          for (let eleItem of trueData){
            let compShow = eleItem.comp;
            for (let item of compShow){
              compShowHide[item] = true;
            }
          }
        }
        // this.triggerActive([compShowHide])
      }
    }
  },
}
</script>

<style scoped>
.step-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f5f7fa;
}
</style>
