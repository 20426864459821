<template>
	<div class="table-list" v-loading="loading" @mouseenter="handleMouseenter" @mouseleave="handleMouseout">
    <header v-if="showHeader" :class="{ 'table-header-radius': !!statusConfig.headerFillet }" class="table-header">
			<div class="header-item"
				v-if="statusConfig.showIndex"
				:style="{
					width: '55px',
					'flex-grow': 0,
					'flex-basis': '55px',
					textAlign: 'center',
					...headerStyle
				}"
			>
				{{ $i18n.locale === 'en'? 'No.': '序号' }}
			</div>
        <div v-for="(item) in fieldForView" class="header-item"
					:style="{
						width: `${item.width}px`,
						'flex-basis': `${item.width}px`,
						'flex': item.width ? '': '1',
						textAlign: item.headerAlign || (item.align || 'left'),
						...headerStyle
					}"
          :key="item.uuid">
					{{ $i18n.locale === 'en'? item.enLabel: item.label }}
        </div>
    </header>
    <div class="table-body">
      <template v-if="tableData.length !== 0">
				<div 
					v-for="(row, index) in activeRow"
					:key="index" 
					:style="{
						height: statusConfig.bodyHieght,
					}" 
					class="row-com"
				>
					<transition appear name="scroll-list-anmiate">
						<section
							v-if="useTemplate"
							class="row"
							:key="row.key"
						>
							<ComponentBox
								:isPreview="true"
								:containerData="row"
								:fullData="row"
								:mapData="{
									loopConfig: loopConfig,
									renderData: row,
								}"
								:element="getLoopChildren(index, row)"
								:groupComponents="groupComponents"
								:componentList="componentList"
							>
							</ComponentBox>
						</section>
						<section v-else class="row"
							:style="rowStyle(row.index)" 
							:key="row.key + '-1'"
						>
							<div class="cell"
								v-if="statusConfig.showIndex"
								:style="{
									'flex-basis': '55px',
									'flex-grow': 0,
									width: '55px',
									textAlign: 'center',
									...bodyStyle
								}"
							>
								<span>
									<span v-if="statusConfig.orderPointShow" :style="{ color: statusConfig.orderPointColor }" class="order-point-icon">•</span>
									{{ row.index | indexToOrderNum }}
								</span>
							</div>
							<div v-for="item in fieldForView" class="cell"
								:style="{
									width: `${item.width}px`,
									'flex-basis': `${item.width}px`,
									'flex': item.width ? '': '1',
									color: item.color,
									textAlign: item.align || 'left',
									...bodyStyle
								}"
								:key="item.uuid"
							>
								<span class="cell-span" :key="row[item.uuid]">
									<!-- 图片 -->
									<template v-if="item.type === 2">
										<el-image
											v-if="Array.isArray(row[item.uuid])"
											:src="row[item.uuid] && row[item.uuid].length && row[item.uuid][0].url.toString().indexOf('http') > -1 ? row[item.uuid][0].url : defaultImage"
											:previewSrcList="[row[item.uuid].length ? (row[item.uuid][0].url.toString().replace('_thumb', '')) : defaultImage ]"
											lazy
											fit="cover"
											alt="图片"
											class="image"
											:style="{
												height: (item.imgConfig.height || 60) + 'px',
												width: (item.imgConfig.height || 60) + 'px',
											}"
										></el-image>
										<!-- 链接 -->
										<el-image
											v-else
											:src="row[item.uuid] && row[item.uuid].indexOf('http') > -1 ? row[item.uuid] : defaultImage"
											:previewSrcList="[row[item.uuid] ? (row[item.uuid].toString().replace('_thumb', '')) : defaultImage ]"
											lazy
											fit="cover"
											alt="图片"
											class="image"
											:style="{
												height: (item.imgConfig.height || 60) + 'px',
												width: (item.imgConfig.height || 60) + 'px',
											}"
										></el-image>
									</template>
									<!-- 状态 -->
									<span
										v-else-if="item.type === 3 && !isNull(row[item.uuid])"
										:style="{
											color:
												filterStatus(row[item.uuid], item.statusOptions)
													.color || item.color,
										}"
									>
										{{
											filterStatus(row[item.uuid], item.statusOptions)
												.label
										}}
									</span>
									<!-- 时间 -->
									<template v-else-if="item.type === 4 && !isNull(row[item.uuid])">{{ filterTime(row[item.uuid], item.timeFormat, item.connector) }}</template>
									<!-- 富文本 -->
									<template v-else-if="item.type === 5 && !isNull(row[item.uuid])">
										<div
											class="rich-text"
											v-html="row[item.uuid]"
										></div>
									</template>
									<!-- 富文本 -->
									<template v-else-if="item.type === 6 && !isNull(row[item.uuid])">
										<a
											:href="row[item.uuid]"
											class="link"
											alt="链接"
										>
											{{
												row[item.uuid]
											}}
										</a>
									</template>
									<!-- switch 开关 -->
									<template v-else-if="item.type === 7">
										<el-switch
											v-model="row[item.uuid]"
											@change="onSwitch(item, row[item.uuid], row)"
										></el-switch>
									</template>
									<!-- 常规显示 -->
									<template v-else>{{ row[item.uuid] ?? '/' }}</template>
								</span>
							</div>
						</section>
					</transition>
				</div>
      </template>
      <el-empty v-else></el-empty>
    </div>
		<div class="full" @click.stop="dialogVisible = true">
			<i :style="`color: ${headerStyle.color}`" class="iconfont icondaping-quanping" />
		</div>
		<el-dialog
			:visible.sync="dialogVisible"
			title=""
			:modal="false"
			:append-to-body="true"
			custom-class="chart-container-dialog"
			width="60%"
		>
			<div
				v-if="dialogVisible"
				:style="`color:${element.style.color}`"
				class="table-list"
				v-loading="loading"
				@mouseenter="handleMouseenter"
				@mouseleave="handleMouseout"
			>
				<header v-if="showHeader" :class="{ 'table-header-radius': !!statusConfig.headerFillet }" class="table-header">
					<div class="header-item"
						v-if="statusConfig.showIndex"
						:style="{
							width: '55px',
							'flex-grow': 0,
							'flex-basis': '55px',
							textAlign: 'center',
							...headerStyle
						}"
					>
						序号
					</div>
						<div v-for="(item) in fieldForView" class="header-item"
							:style="{
								width: `${item.width}px`,
								'flex-basis': `${item.width}px`,
								'flex': item.width ? '': '1',
								textAlign: item.headerAlign || (item.align || 'left'),
								...headerStyle
							}"
							:key="item.uuid">
							{{ item.label }}
						</div>
				</header>
				<div class="table-body">
					<template v-if="tableData.length !== 0">
						<div 
							v-for="(row, index) in activeRow"
							:key="index" 
							:style="{
								height: statusConfig.bodyHieght,
							}" 
							class="row-com"
						>
							<transition appear name="scroll-list-anmiate">
								<section
									v-if="useTemplate"
									class="row"
									:key="row.key"
								>
									<ComponentBox
										:isPreview="true"
										:containerData="row"
										:fullData="row"
										:mapData="{
											loopConfig: loopConfig,
											renderData: row,
										}"
										:element="getLoopChildren(index, row)"
										:groupComponents="groupComponents"
										:componentList="componentList"
									>
									</ComponentBox>
								</section>
								<section v-else class="row"
									:style="rowStyle(row.index)" 
									:key="row.key + '-1'"
								>
									<div class="cell"
										v-if="statusConfig.showIndex"
										:style="{
											'flex-basis': '55px',
											'flex-grow': 0,
											width: '55px',
											textAlign: 'center',
											...bodyStyle
										}"
									>
										<span>
											<span v-if="statusConfig.orderPointShow" :style="{ color: statusConfig.orderPointColor }" class="order-point-icon">•</span>
											{{ row.index | indexToOrderNum }}
										</span>
									</div>
									<div v-for="item in fieldForView" class="cell"
										:style="{
											width: `${item.width}px`,
											'flex-basis': `${item.width}px`,
											'flex': item.width ? '': '1',
											color: item.color,
											textAlign: item.align || 'left',
											...bodyStyle
										}"
										:key="item.uuid"
									>
										<span class="cell-span" :key="row[item.uuid]">
											<!-- 图片 -->
											<template v-if="item.type === 2">
												<el-image
													v-if="Array.isArray(row[item.uuid])"
													:src="row[item.uuid] && row[item.uuid].length && row[item.uuid][0].url.toString().indexOf('http') > -1 ? row[item.uuid][0].url : defaultImage"
													:previewSrcList="[row[item.uuid].length ? (row[item.uuid][0].url.toString().replace('_thumb', '')) : defaultImage ]"
													lazy
													fit="cover"
													alt="图片"
													class="image"
													:style="{
														height: (item.imgConfig.height || 60) + 'px',
														width: (item.imgConfig.height || 60) + 'px',
													}"
												></el-image>
												<!-- 链接 -->
												<el-image
													v-else
													:src="row[item.uuid] && row[item.uuid].indexOf('http') > -1 ? row[item.uuid] : defaultImage"
													:previewSrcList="[row[item.uuid] ? (row[item.uuid].toString().replace('_thumb', '')) : defaultImage ]"
													lazy
													fit="cover"
													alt="图片"
													class="image"
													:style="{
														height: (item.imgConfig.height || 60) + 'px',
														width: (item.imgConfig.height || 60) + 'px',
													}"
												></el-image>
											</template>
											<!-- 状态 -->
											<span
												v-else-if="item.type === 3 && !isNull(row[item.uuid])"
												:style="{
													color:
														filterStatus(row[item.uuid], item.statusOptions)
															.color || item.color,
												}"
											>
												{{
													filterStatus(row[item.uuid], item.statusOptions)
														.label
												}}
											</span>
											<!-- 时间 -->
											<template v-else-if="item.type === 4 && !isNull(row[item.uuid])">{{ filterTime(row[item.uuid], item.timeFormat, item.connector) }}</template>
											<!-- 富文本 -->
											<template v-else-if="item.type === 5 && !isNull(row[item.uuid])">
												<div
													class="rich-text"
													v-html="row[item.uuid]"
												></div>
											</template>
											<!-- 富文本 -->
											<template v-else-if="item.type === 6 && !isNull(row[item.uuid])">
												<a
													:href="row[item.uuid]"
													class="link"
													alt="链接"
												>
													{{
														row[item.uuid]
													}}
												</a>
											</template>
											<!-- switch 开关 -->
											<template v-else-if="item.type === 7">
												<el-switch
													v-model="row[item.uuid]"
													@change="onSwitch(item, row[item.uuid], row)"
												></el-switch>
											</template>
											<!-- 常规显示 -->
											<template v-else>{{ row[item.uuid] ?? '/' }}</template>
										</span>
									</div>
								</section>
							</transition>
						</div>
					</template>
					<el-empty v-else></el-empty>
				</div>
			</div>
    </el-dialog>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { $, getComponentById, initParams, removeEventBus } from '@/utils/tools';
import { Empty, Image, Switch, Dialog } from 'element-ui';
import { mapState } from 'vuex';
import { judgingArchiAPermission } from '@/utils/tools';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';
/**
 * @description 表格列表
 */
export default {
	name: 'CommonTableContainer',
	mixins: [databaseTriggerDebug],
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
	},
	inject: ['EDITOR_pageUUID'],
	components: {
		'el-image': Image,
		'el-switch': Switch,
		'el-empty': Empty,
		'el-dialog': Dialog
	},
	data() {
		return {
			// 当前组件唯一Key
			boxId: `table-container-${new Date().getTime()}`,
			// 分页
			pager: {
				current_page: 1,
				per_page: 3,
				total: 0
			},
      activePage: 0,
			// 表格数据
			tableData: [],
			// 表格高度
			height: 250,
			// 配置数据
			metadata: [],
			loading: false,
			// 缓存的搜索条件
			search: [],
			// 周几
			weekDays: ['周天', '周一', '周二', '周三', '周四', '周五', '周六'],
			// 默认图片
			defaultImage: require('@/assets/images/defaultImage.png'),
			param: {},
			// 树形图
			treeProps: {},
			rowKey: '',
			// 表格弹窗
			showSheet: false,
			// 表格数据
			sheetFormData: null,
			exportConfig: null,
			// 操作类型：add - 新增，edit - 编辑，info - 详情，export - 导出
			spreadsheetType: 'export',
			exportViewParams: {},
			exportViewSearch: [],
			// 使用后端返回配置导出的导出配置
			exportWay: '', // 导出配置 eeConfig 后端配置， '' 自定义
			eeExportTemplate: '',
			eeObjectUUID: '',
			eeViewUUID: '',
			fieldForView: [],
			// 请求是否完成
			interfaceFinished: true,
			dialogVisible: false
		};
	},
	created() {
		if (this.database && !this.hasComponentParam()) {
			this._initDataDebug();
			const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents, this.EDITOR_pageUUID);
			if (!canPost) {
        this._failDataDebug('参数必填校验未通过');
				return;
			}
			this.getList(this.database, search, param);
		}
		this.metadata = this.element.metadata || [];
		// 定时器
		this.setInterval();
	},
	computed: {
		...mapState(['componentData', 'globalInterval', 'subsidiaryComponentData']),
		// 取值组件列表
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
		},
		// 当前活动行
    activeRow() {
			// 如果循环滚动
			if (this.statusConfig.loopScroll) {
				let activePage = this.activePage % (this.tableData.length - 1);
				if (this.tableData.length - 1 === 0) {
					activePage = 0;
				}
				const data = [];
				let temp = this.linkedList[activePage];
				// 更新key才能触发动画
				temp.key = (Math.random() * 10000) + '' + temp.index;
				data.push(temp)
				for (let i = 1; i < Number(this.statusConfig.rowNumber); i++) {
					const index = (activePage + i )% this.tableData.length
					const result = this.linkedList[index];
					result.key = `key${Math.floor((Math.random() * 1000000))}`;
					data.push(result);
				}
				// for (let i = 0; i < Number(this.statusConfig.rowNumber) - 1; i++) {
				// 	const result = {
				// 		...temp.next
				// 	};
				// 	result.key = `key${Math.floor((Math.random() * 1000000))}`
				// 	data.push(result);
				// }
				return data;
			} else {
				if(this.activePage > this.tableData.length - 1) {
					this.setActivePage(0)
				}
				let activePage = this.activePage % (this.tableData.length);
				if (this.tableData.length - Number(this.statusConfig.rowNumber) <= 0) {
					activePage = 0;
				}
				let data = this.linkedList.slice(activePage , activePage + Number(this.statusConfig.rowNumber))
				data = data.map((el) => {
					// 更新key才能触发动画;
					el.key = `key${Math.floor((Math.random() * 1000000))}`
					return el;
				})
				return data;
			}
    },
		// 表格链表
		linkedList () {
			const data = [];
			let temp = null;
			let header = null;
			this.tableData.map((el, index) => {
				let result = el;
				result.index = index;
				if(!temp) {
					header = result;
				} else {
					// TO DO,  error.  
					temp.next = {
						...result
					};
					result.pre = {
						...temp
					};
				}
				if (index === this.tableData.length - 1) {
					header.pre = {
						...result
					};
					result.next = {
						...header
					};
				}
				temp = result;
				data.push(result);
			})
			return data;
		},
		// 数据仓库配置
		database() {
			return this.element && this.element.database;
		},
		// 操作类型
		actionConfig() {
			return this.element && this.element.actionConfig;
		},
		// 子表操作配置
		nestingActionConfig() {
			return this.element && this.element.nestingActionConfig;
		},
		// 配置
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		// 字段配置
		fieldConfig() {
			const configs = this.element && this.element.fieldConfig;
			if (!configs) return [];
			if (!configs && !configs.length) return this.metadata || [];
			const result = [];
			configs.forEach((ele) => {
				if (ele && ele.show) {
					result.push(ele);
				}
			});
			return result;
		},
		// 字表字段配置
		nestingFieldConfig() {
			const configs = this.element && this.element.nestingFieldConfig;
			if (!configs) return [];
			if (!configs && !configs.length) return this.metadata || [];
			const result = [];
			configs.forEach((ele) => {
				if (ele && ele.show) {
					result.push(ele);
				}
			});
			return result;
		},
		showHeader() {
			return this.statusConfig.showHeader;
		},
		headerStyle () {
			return {
				'line-height': this.statusConfig.headerHieght,
				color: this.statusConfig.headerColor,
				background: this.statusConfig.headerTransparent ? 'transparent' : this.statusConfig.headerBackGround,
				fontSize:this.statusConfig.headerFontSize,
			};
		},
		bodyStyle () {
			return {
				'line-height': 1,
				height: this.statusConfig.bodyHieght,
				background: this.statusConfig.bodyTransparent ? 'transparent' : this.statusConfig.bodyBackGround,
				fontSize:this.statusConfig.bodyFontSize,
			};
		},
		oddRowsStyle() {
			const oddRowsBackground = this.statusConfig?.oddRowsBackground;
			if(oddRowsBackground) {
				return { background: oddRowsBackground };
			}
			return {};
		},
		evenRowsStyle() {
			const evenRowsBackground = this.statusConfig?.evenRowsBackground;
			if(evenRowsBackground) {
				return { background: evenRowsBackground };
			}
			return {};
		},
		rowStyle() {
			return (index) => {
				const useStripe = this.statusConfig?.stripe;
				const heightStyle = { height: this.statusConfig?.bodyHieght };
				if (useStripe) {
					let realIndex = index + 1;
					if(realIndex % 2 === 1) {
						return {
							...heightStyle,
							...this.oddRowsStyle
						};
					} else if (realIndex % 2 === 0) {
						return {
							...heightStyle,
							...this.evenRowsStyle
						};
					}
				} else {
					return heightStyle;
				}
			}
		},
    // 请求定时器，若存在全局则以全局为准，若无全局则取当前组件自定义
    interfaceInterval() {
      if (this.globalInterval && this.globalInterval.useInterval) {
        return this.globalInterval;
      }
      return this.element?.interfaceInterval;
    },
		fullPageScrolling() {
			return this.statusConfig.fullPageScrolling
		},
		useTemplate() {
			// 是否使用模板
			return this.statusConfig.useTemplate;
		},
		/**
     * @desc: 循环容器配置
     */
		loopConfig() {
      return this.element?.loopConfig;
    },
		idUUID() {
			return '' 
		}
	},
	watch: {
		// 为了触发配置时候字段排序更新
		fieldConfig: {
			handler() {
				this.fieldForView = [];
				setTimeout(() => {
					this.fieldForView = this.fieldConfig || [];
				}, 0);
			},
			deep: true,
			immediate: true
		}
	},
	filters: {
		/**
   * @description: index 补 0 序号
   * @param {*} v
   * @return {*}
   */		
		indexToOrderNum(v) {
			if(isNaN(v)) {
				return v
			} else {
				const orderNum = Number(v) + 1;
				if(orderNum < 10) {
					return `0${orderNum}`
				} else {
					return orderNum
				}
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			const box = $(`#${this.boxId}`);
			if (box && box.offsetHeight) {
				this.height = box.offsetHeight - 42;
			}
			this.initTimer(true);
		});
		// 监听请求
		// 配置关联参数的容器才需要监听
		const databaseTrigger = {
			[this.element.id]: (data) => {
				if (data.parentId && data.parentId !== this.element.id) return false;
				// 配置时候触发请求
				if (data.componentId === this.element.id && data.isInit) {
					this._startDataDebug();
					const { search = [], param = {}, canPost } = initParams(
						this.element?.database?.paramsConfig || [],
						this.isGroup,
						this.subComponentData,
						this.groupComponents,
						this.EDITOR_pageUUID
					);
					// console.log("配置时候触发请求-------", canPost, search, param);
					if (!canPost) {
						this._failDataDebug('参数必填校验未通过');
						return;
					}

					this.getList(this.element.database, search, param);
					return;
				}

				// 点击操作时候不更新数据
				if (data.noUpdate) return;
				const { paramsConfig } = this.element.database;
				if (!paramsConfig || !paramsConfig.length) return;
				let isTarget = false;
				paramsConfig.forEach((ele) => {
					if (ele.componentId === data.componentId) {
						isTarget = true;
					}
				});
				if (!isTarget && !data.isUpdate) return;
				this._startDataDebug();
				// 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
				let { search = [], param = {}, canPost } = initParams(
					this.element?.database?.paramsConfig || [],
					this.isGroup,
					this.subComponentData,
					this.groupComponents,
					this.EDITOR_pageUUID
				);
				// console.log("以下步骤是为了避免有多个来源的search需要进行differ-------", canPost, search, param);
				if (!canPost) {
					this._failDataDebug('参数必填校验未通过');
					return;
				}
				this.param = param;
				this.getList(this.element.database, search, param);
			}
		}
		eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
	},
	methods: {
  /**
   * @description: 鼠标进入暂停动画
   * @return {*}
   */		
		handleMouseenter() {
			this.suspend = true;
		},
  /**
   * @description: 鼠标移出继续动画
   * @return {*}
   */		
		handleMouseout() {
			this.suspend = false;
		},
		/**
		 * @desc: 判断是否为逻辑空(空对象，空数组等)
		 * @param {Number} val
		 */
		isNull(val) {
			if (val === 0) return false;
			if (val === '00000-00-00 00:00:00') return true;
			if (Array.isArray(val) && !val.length) return true;
			if (Object.prototype.toString.call(val) === '[object Object]' && JSON.stringify(val) === '{}') return true;
			if (!val) return true;
			return false;
		},
		setActivePage(v) {
			this.activePage = v
		},
		initTimer(flag) {
			const timerHandle = () => {
				this.times += 1
				if (this.times >= (this.statusConfig.duration || 3) * 60) {
					this.times = 0;
					// 如果没有暂停
					if(!this.suspend) {
						if(this.fullPageScrolling) {
							this.activePage += Number(this.statusConfig.rowNumber)
						} else {
							this.activePage += 1
						}
					}
				}
				requestAnimationFrame(timerHandle)
			}
			if (flag) {
				this.times = 0
				this.timer = requestAnimationFrame(timerHandle)
			} else if (this.timer) {
				cancelAnimationFrame(this.timer)
			}
		},
		// 判断架构及权限
		judgingArchiAPermission,
		/**
		 * @desc: 根据配置逻辑判断操作是否显示
		 * @param {Object} actionConfig
		 */
		logicAction(actionConfig, row) {
			if (!actionConfig.useDepend || !actionConfig || !actionConfig.dependLogic ||!actionConfig.dependLogic.length) {
				return true;
			}
			const dependLogic = actionConfig.dependLogic;
			const logicList = [];
			for (let i = 0, len = dependLogic.length; i < len; i++) {
				const { field, fieldAttr, fieldLogic, itemLogic, value, valueType } =
					dependLogic[i];
				let logicValue = null;
				if (fieldAttr === 'length') {
					logicValue = row[field].length;
				} else {
					logicValue = row[field];
				}
				// 当前项判断条件
				// 字段关系 大于>  小于 < 等于 === 大于等于>=  小于等于 <= 不等于 !=
				let itemBoolean = true;
				if (fieldLogic === '===') {
					if (valueType === 'string') {
						itemBoolean = logicValue === value;
					} else if (valueType === 'number') {
						itemBoolean = +logicValue === +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() === value;
					}
				} else if (fieldLogic === '!=') {
					if (valueType === 'string') {
						itemBoolean = logicValue !== value;
					} else if (valueType === 'number') {
						itemBoolean = logicValue !== +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() !== value;
					}
				} else if (fieldLogic === '>') {
					if (valueType === 'number') {
						itemBoolean = logicValue > +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() > value;
					}
				} else if (fieldLogic === '<') {
					if (valueType === 'number') {
						itemBoolean = logicValue < +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() < value;
					}
				} else if (fieldLogic === '<=') {
					if (valueType === 'number') {
						itemBoolean = logicValue <= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() <= value;
					}
				} else if (fieldLogic === '>=') {
					if (valueType === 'number') {
						itemBoolean = logicValue >= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() >= value;
					}
				} else if (fieldLogic === 'like') {
					if (valueType === 'string') {
						itemBoolean = logicValue.toString()?.includes(value.toString());
					}
				} else if (fieldLogic === 'whereIn') {
					// 包含
					try {
						let valArr = [];
						if (Array.isArray(logicValue)) {
							valArr = logicValue;
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						} else if (logicValue.toString()?.startsWith('[') && logicValue.toString()?.endsWith(']')) {
							valArr = JSON.parse(logicValue);
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						} else if (value.toString()?.startsWith('[') && value.toString()?.endsWith(']')) {
							valArr = JSON.parse(value);
							itemBoolean = valArr.includes(logicValue) || valArr.includes(+logicValue);
						} else {
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						}
					} catch (err) {
						console.log(err);
					}
				}
				logicList.push({ itemLogic, itemBoolean });
			}
			if (logicList.length === 1) {
				return logicList[0].itemBoolean;
			}
			// 循环逻辑关系
			let prevLogicBoolean = null;
			for (let j = 1; j < logicList.length; j++) {
				const prev = logicList[j - 1];
				const prevItemLogic = prev.itemLogic;
				if (prevItemLogic === 'and') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
					}
					if (!prevLogicBoolean) {
						return false;
					}
				} else if (prevItemLogic === 'or') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean
					}
					if (!prevLogicBoolean) {
						return false;
					}
				}
			}
			return true;
		},
		/**
		 * @desc: 判断是否存在依赖其他组件的取值
		 */
		hasComponentParam() {
			if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
				return false;
			}
			for (let i = 0; i < this.database.paramsConfig.length; i++) {
				const { componentId = '', key = '', sourceType = '' } = this.database.paramsConfig[i];
				if ((key !== 'search' || !componentId.includes('CommonForm')) && sourceType !== 'url') {
					// componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
					// 补充不同架构问题
					if (!componentId.includes('CommonTableContainer') && this.paramsSameArchi(componentId)) {
						return true;
					}
				}
			}
			return false;
		},
		/**
		 * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
		 * @param {String} componentId
		 * @return {Boolean}
		 */
		paramsSameArchi(componentId) {
			let comp = getComponentById(this.subComponentData, componentId);
			if (!comp && this.isGroup && this.groupComponents.length) {
				comp = getComponentById(this.groupComponents, componentId);
			}
			if (!comp) return false;
			const targetArchi = this.$GetTargetArchi('archiType');
			if (comp && comp.archiLimit && comp.archiLimit.length && comp.archiLimit.includes(targetArchi)) {
				return true;
			}
			return false;
		},
		/**
		 * @desc: 初始化获取参数
		 */
		/**
		 * @desc: 获取行key
		 */
		getRowKey(row) {
			return row[this.rowKey];
		},

		// 订阅 WebSocket 事件
		// subscribeWebSocketEvents(database) {
		// 	// 不使用 wsEventName，而是直接监听 'message' 事件
		// 	this.webSocketConnection.addEventListener('message', (event) => {
		// 		const data = JSON.parse(event.data);
		// 		// 更新表格数据
		// 		console.log("表格数据")
		// 	});
		// },

		/**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {}) {
			this.search = Array.isArray(search) ? search : [];
			this.tableData = [];
			if (!this.validDatabase(database)) {
				this.interfaceFinished = true;
				this._failDataDebug('请求配置错误');
				return;
			}
			// 注入的参数
			let outParams = {};
			if (this.element.database.userOutParams) {
				outParams = sessionStorage.getItem(
					`dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
				);
				outParams = outParams ? JSON.parse(outParams) : {};
			}
			this.loading = true;
			const paramsObj = {
				...params,
				...outParams,
				page: this.pager.current_page || 1,
				size: this.statusConfig.pagination ? this.pager.per_page || 15 : 15,
				search
			};
			// 配置
			let __method_name__ = 'dataList';
			const mapping = database.mapping;

			console.log(mapping, 'mapping');

			//const mapping = "websocket";
			let configObj = null;
			let url = '/api/mapi';
			if (mapping === 'interface') {
				configObj = {
					...paramsObj
				}
				if (database?.interfaceConfig?.url?.indexOf('http') === -1) {
					url = `/api${database?.interfaceConfig?.url}`;
				} else {
					url = database?.interfaceConfig?.url
				}
			} else if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					...paramsObj
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj
				};
			} else if (mapping === 'websocket') {
				// 初始化websocket
				this.initWebSocketConnection(database, mapping, __method_name__);

				return;
			}
			// 将使用的字段添加到请求用于后端请求优化(隐式使用的字段分析问题？暂时注释)
			// configObj = {
			// 	...configObj,
			// 	__page_uuid__ : this.getPageUUID(), // 页面UUID
			// 	__component_id__: this.element?.id, // 组件ID
			// 	__selects__: this.getTableFields() // 使用的数组
			// }

			// 获取表格数据
			dataInterface(configObj, url)
				.then((res) => {
					console.log(res, 'res');
					if (res && res.status === 200) {
						let tableData = [];
						if (mapping === 'interface' || ['dataList', 'relationList'].includes(__method_name__)) {
							// 列表数据
							tableData = this.getListFromRes(res, true) || [];
						}
					// 字段列表
					if (!this.metadata || !this.metadata.length) {
						let metadata = {};
						if (mapping === 'interface') {
							// 接口
							if (Array.isArray(tableData)) {
								if (Object.prototype.toString.call(tableData?.[0]) === '[object Object]') {
									for (let key in tableData?.[0]) {
										metadata[key] = key;
									}
								} 
							}
						} else {
							metadata = res.data.metadata;
						}
						this.metadata = metadata;
					}
					// 树形表格
					if (this.statusConfig.isTreeTable) {
						let children = '';
						for (let key in this.metadata) {
							if (this.metadata[key] === this.statusConfig.children) {
								children = key;
							}
						this.rowKey = this.getIdUUID();
						}
						tableData = this.formatTreeData(tableData, children);
						this.treeProps = {
							children: 'children',
							hasChildren: 'hasChildren'
						};
					} else if (this.statusConfig.useFeWBS) {
						// 非树形WBS
						tableData = this.formatTreeData(tableData, '');
					}
					// 非树形表格
					if (!this.statusConfig.isTreeTable && this.statusConfig.feTreeKey) {
						const { feTreeKey } = this.statusConfig;
						this.rowKey = this.getIdUUID();
						this.treeProps = {
							children: 'children',
							hasChildren: 'hasChildren'
						}
						tableData = this.listToTree(tableData, feTreeKey, this.getIdUUID());
						// console.log(this.rowKey, '---123123----', this.treeProps);
					}
					// 嵌套表格
					if (this.statusConfig.isNesting) {
						this.rowKey = '';
						let children = '';
						for (let key in this.metadata) {
							if (this.metadata[key] === this.statusConfig.nestingKey) {
								children = key;
							}
						this.rowKey = this.getIdUUID();
						}
						tableData = tableData.map((dataItem) => {
							return {
								...dataItem,
								children: dataItem[children] || []
							};
						});
						// 字段处理
						for (let i = 0; i < this.nestingFieldConfig.length; i++) {
							const item = this.nestingFieldConfig[i];
							if (item.type === 7) {
								// switch 编辑
								tableData = tableData.map((ele) => {
									const childrenTable = ele[children] || [];
									return {
										...ele,
										[children]: childrenTable.map(child => {
											if (item.switchOptions.type === 'number') {
												// 数字
												child[item.uuid] = +child[item.uuid] === +item.switchOptions.trueValue;
											} else if (item.switchOptions.type === 'boolean') {
												if (child[item.uuid] === 'true') {
													child[item.uuid] = true;
												} else if (child[item.uuid] === 'false') {
													child[item.uuid] = false;
												}
											} else if (item.switchOptions.type === 'string') {
												child[item.uuid] = child[item.uuid] === item.switchOptions.trueValue;
											}
											return {
												...child
											};
										})
									}
								});
							}
						}
					}
					// 排序
					if (this.useSort && this.sortConfigs && this.sortConfigs.length) {
						tableData = this.customSort(tableData);
					}
					// console.log(tableData, '----tableData----');
					this.tableData = tableData;
					if (this.statusConfig?.canSelect) {
						// 获取默认选中
						if (this.defaultTableSelectData) {
							this.getDefaultSelect();
						}
					}
						// 完全数据 暂时保存
						this.fullData = res.data.data;
						// 保存容器数据
						this.$store.commit('modifyComponent', {
							component: {
								...this.element,
								containerData: this.tableData,
								fullData: this.fullData,
								metadata: this.metadata,
								database: {
									...this.element.database,
									fieldList: this.getFieldList(this.metadata)
								}
							},
							containerId: null,
							isModify: true,
							pageUUID: this.EDITOR_pageUUID
						});
					}
					this.interfaceFinished = true;
					this.loading = false;
					this._successDataDebug({
						url,
						content: configObj,
						res
					});
		
			});
		},
		// 初始化 WebSocket 连接
		initWebSocketConnection(database, mapping, __method_name__) {
			const wsUrl = database.websocket.socketUrl;
			const wsParams = { types: '2' };

			this.webSocketConnection = new WebSocket(wsUrl);

			// 处理 WebSocket 连接建立
			this.webSocketConnection.onopen = () => {
				// 发送初始化参数
				this.webSocketConnection.send(JSON.stringify(wsParams));
			};

			// 处理 WebSocket 消息事件
			this.webSocketConnection.onmessage = (event) => {
				const data = JSON.parse(event.data);
				// 更新表格数据
				console.log('Received data:', data, '表格数据');
				// var res = {
				// 	"code":200,
				// 	"data":[
				// 		{
				// 			"id": 13,
				// 			"person_uuid": "5fb8a491-7fe9-c7f0-21c9-0d14a51928bd",
				// 			"serial_number": "1001229",
				// 			"actual_attend_type": "进",
				// 			"attend_time": "2024-01-24 09:22:27",
				// 			"people_name": "ces",
				// 			"work_types": "其他",
				// 			"unit_name": "测试班组",
				// 			"type": 2
				// 		},
				// 		{
				// 			"id": 13,
				// 			"person_uuid": "5fb8a491-7fe9-c7f0-21c9-0d14a51928bd",
				// 			"serial_number": "1001229",
				// 			"actual_attend_type": "进",
				// 			"attend_time": "2024-01-24 09:22:27",
				// 			"people_name": "ces",
				// 			"work_types": "其他",
				// 			"unit_name": "测试班组",
				// 			"type": 2
				// 		},
				// 		{
				// 			"id": 13,
				// 			"person_uuid": "5fb8a491-7fe9-c7f0-21c9-0d14a51928bd",
				// 			"serial_number": "1001229",
				// 			"actual_attend_type": "进",
				// 			"attend_time": "2024-01-24 09:22:27",
				// 			"people_name": "ces",
				// 			"work_types": "其他",
				// 			"unit_name": "测试班组",
				// 			"type": 2
				// 		}

				// 	],
				// 	"metadata":null,
				// 	"msg":"success",
				// 	}
				this.insertTableData(data, mapping, __method_name__);
			};
		},

		insertTableData(res, mapping, __method_name__) {
			let tableData = [];
			if (mapping === 'interface' || ['dataList', 'relationList', 'websocket'].includes(__method_name__)) {
				// 列表数据
				tableData = this.getListFromRes(res, true) || [];
			}

			for (let i = 0; i < this.fieldConfig.length; i++) {
				const item = this.fieldConfig[i];
				if (item.type === 7) {
					// switch 编辑
					tableData = tableData.map((ele) => {
						if (item.switchOptions.type === 'number') {
							// 数字
							ele[item.uuid] = +ele[item.uuid] === +item.switchOptions.trueValue;
						} else if (item.switchOptions.type === 'boolean') {
							if (ele[item.uuid] === 'true') {
								ele[item.uuid] = true;
							} else if (ele[item.uuid] === 'false') {
								ele[item.uuid] = false;
							}
						} else if (item.switchOptions.type === 'string') {
							ele[item.uuid] = ele[item.uuid] === item.switchOptions.trueValue;
						}
						return ele;
					});
				}
			}

			// 字段列表
			if (!this.metadata || !this.metadata.length) {
				let metadata = {};
				if (mapping === 'interface') {
					// 接口
					if (Array.isArray(tableData)) {
						if (Object.prototype.toString.call(tableData?.[0]) === '[object Object]') {
							for (let key in tableData?.[0]) {
								metadata[key] = key;
							}
						}
					}
				} else {
					metadata = res.data.metadata;
				}
				this.metadata = metadata;
			}
			// 树形表格
			if (this.statusConfig.isTreeTable) {
				let children = '';
				for (let key in this.metadata) {
					if (this.metadata[key] === this.statusConfig.children) {
						children = key;
					}
					this.rowKey = this.getIdUUID();
				}
				tableData = this.formatTreeData(tableData, children);
				this.treeProps = {
					children: 'children',
					hasChildren: 'hasChildren'
				};
			} else if (this.statusConfig.useFeWBS) {
				// 非树形WBS
				tableData = this.formatTreeData(tableData, '');
			}
			// 非树形表格
			if (!this.statusConfig.isTreeTable && this.statusConfig.feTreeKey) {
				const { feTreeKey } = this.statusConfig;
				this.rowKey = this.getIdUUID();
				this.treeProps = {
					children: 'children',
					hasChildren: 'hasChildren'
				};
				tableData = this.listToTree(tableData, feTreeKey, this.getIdUUID());
				// console.log(this.rowKey, '---123123----', this.treeProps);
			}
			// 嵌套表格
			if (this.statusConfig.isNesting) {
				this.rowKey = '';
				let children = '';
				for (let key in this.metadata) {
					if (this.metadata[key] === this.statusConfig.nestingKey) {
						children = key;
					}
					this.rowKey = this.getIdUUID();
				}
				tableData = tableData.map((dataItem) => {
					return {
						...dataItem,
						children: dataItem[children] || []
					};
				});
				// 字段处理
				for (let i = 0; i < this.nestingFieldConfig.length; i++) {
					const item = this.nestingFieldConfig[i];
					if (item.type === 7) {
						// switch 编辑
						tableData = tableData.map((ele) => {
							const childrenTable = ele[children] || [];
							return {
								...ele,
								[children]: childrenTable.map((child) => {
									if (item.switchOptions.type === 'number') {
										// 数字
										child[item.uuid] = +child[item.uuid] === +item.switchOptions.trueValue;
									} else if (item.switchOptions.type === 'boolean') {
										if (child[item.uuid] === 'true') {
											child[item.uuid] = true;
										} else if (child[item.uuid] === 'false') {
											child[item.uuid] = false;
										}
									} else if (item.switchOptions.type === 'string') {
										child[item.uuid] = child[item.uuid] === item.switchOptions.trueValue;
									}
									return {
										...child
									};
								})
							};
						});
					}
				}
			}
			// 排序
			if (this.useSort && this.sortConfigs && this.sortConfigs.length) {
				tableData = this.customSort(tableData);
			}
			// console.log(tableData, '----tableData----');
			this.tableData = tableData;
			if (this.statusConfig?.canSelect) {
				// 获取默认选中
				if (this.defaultTableSelectData) {
					this.getDefaultSelect();
				}
			}
			// 完全数据 暂时保存
			this.fullData = res.data.data;
			// 保存容器数据
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					containerData: this.tableData,
					fullData: this.fullData,
					metadata: this.metadata,
					database: {
						...this.element.database,
						fieldList: this.getFieldList(this.metadata)
					}
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});

			this.interfaceFinished = true;
			this.loading = false;
			// this._successDataDebug({
			// 	url,
			// 	content: configObj,
			// 	res
			// });
		},
		/**
		 * @desc: 获取字段列表
		 * @param {Object} obj metadata对象
		 */
		getFieldList(obj) {
			const arr = [];
			if (obj && typeof obj === 'object') {
				for (let key in obj) {
					arr.push({
						name: obj[key],
						uuid: key
					});
				}
			}
			return arr;
		},
		/**
		 * @desc: 切换switch操作状态
		 * @param {Object} item 配置
		 * @param {Boolean} data 当前切换的布尔值
		 * @param {Object} row 当前行
		 * @param {Boolean} isNesting 是否为子表操作
		 */
		onSwitch(item, data, row, isNesting) {
			this.loading = true;
			let idUUID = this.statusConfig.idUUID || '';
			if (!idUUID) {
				for (let key in this.metadata) {
					if (this.metadata[key] === 'id') {
						idUUID = key;
					}
				}
			}
			// 需要将boolean还原为后端允许的值
			let object_uuid = this.element?.database?.objectData?.uuid;
			if (isNesting && this.statusConfig?.nestingObjectUUID) {
				object_uuid = this.statusConfig?.nestingObjectUUID;
			}
			if (!object_uuid) {
				this.loading = false;
				this.$message.error('操作失败！')
				return false;
			}
			const params = {
				__method_name__: 'updateData',
				object_uuid,
				data_id: row[idUUID],
				...row
			};
			const value = data ? item.switchOptions.trueValue : item.switchOptions.falseValue;
			this.$set(params, item.uuid, value);
			dataInterface(params)
				.then(() => {
					this.loading = false;
					this.onPage(this.pager.current_page);
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
		/**
		 * @desc: 选择表格
		 * @param {Array} selectList
		 */
		handleSelectionChange(selectList) {
			this.element.cacheData = selectList || [];
		},
		/**
		 * @desc: 格式化比对收缩内容
		 * @param {Array} search 当前组件中的搜索内容
		 * @param {Array} outputSearch 外部传入的搜索项目
		 */
		formatSearch(search, outputSearch) {
			if (!search.length) return outputSearch;
			let result = search || [];
			for (let i = 0; i < outputSearch.length; i++) {
				const item = outputSearch[i];
				const index = search.findIndex(
					(ele) => ele.field_uuid === item.field_uuid
				);
				if (index === -1) {
					result.push(item);
				} else {
					result.splice(index, 1, item);
				}
			}
			return result;
		},
		/**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			// console.log(database, "database");
			// console.log(!database || typeof database !== 'object', "1");
			// console.log(database.mapping === 'interface' && database.interfaceConfig, "2");
			// console.log(!database.objectData, "3");
			// console.log(!database.viewData && !database.relationData, "4");

			if (!database || typeof database !== 'object') return false;
			if (database.mapping === 'websocket' || (database.mapping === 'interface' && database.interfaceConfig))
				return true;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
		/**
		 * @desc: 根据请求返回获取列表结构
		 * @param {Object} res 请求返回的数据
		 * @param {Boolean} withPager 是否需要重置pager
		 */
		getListFromRes(res, withPager = true) {
			if (res.data && Array.isArray(res.data)) {
				if (withPager) {
					this.pager = res;
				}
				return res.data;
			}
			if (res.data && typeof res === 'object') {
				return this.getListFromRes(res.data, withPager);
			}
			return [];
		},
		/**
		 * @desc: 点击行展开详情操作（@廖总）
		 * @param {Object} row 行数据
		 * @param {Object} column
		 */
		onRowClick(row) {
			if (!this.actionConfig || !this.actionConfig.detail || !this.statusConfig.rowShowDetails) {
				return;
			}
			const { detail } = this.actionConfig;
			if (!detail || !detail.eventList || !detail.eventList.length) return;
			const comEvents = detail.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.onRowClickItem(comEvents[i], row);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					this.onRowClickItem(ele, row);
				});
			}
		},
		onRowClickItem(eventItem, row) {
			const { effects } = eventItem;
			if (effects && effects.length) {
				for (let j = 0; j < effects.length; j++) {
					const effectItem = effects[j];
					const { actions = [], targets = [] } = effectItem;
					if (actions.length && targets.length && actions.includes('show')) {
						targets.forEach((ele) => {
							if (ele.toString().indexOf('CommonDialog-') === 0) {
								this.element.resolveData = row;
								this.$store.commit('updatePageCustomStatus', {
									origin: this.element,
									resolveData: row
								});
								this.$store.commit('triggerEvents', {
									config: {
										...eventItem,
									...effectItem,
									actions: ['hidden']
									},
									element: this.element,
									EDITOR_pageUUID: this.EDITOR_pageUUID
								});
								this.$nextTick(() => {
									this.$store.commit('triggerEvents', {
										config: {
												...eventItem,
										...effectItem,
										},
										element: this.element,
										EDITOR_pageUUID: this.EDITOR_pageUUID
									});
									this.$nextTick(() => {
										const interval = setInterval(() => {
											const drawer = document.querySelector('.common-drawer');
											if (drawer) {
												clearInterval(interval);
												const drawerBox = drawer.parentNode?.parentNode;
												if (drawerBox) {
													drawerBox.classList.add('Editor-drawer__wrapper');
												}
											}
										}, 100);
									});
								});
							}
						});
					}
				}
			}
		},
		/**
		 * @desc: 操作
		 * @param {String} action 操作
		 * @param {Object} output 输出的数据
		 */
		onAction(action, output, e, type, actionConfig) {
			e.preventDefault();
			e.stopPropagation();
			// 是否为删除
			if (action === 'delete') {
				this.doDelete(output);
				return;
			}
			// 为兼容自定义export，多个导出
			if (action === 'export') {
				// 行内导出
				const exportConfig = this.element.exportConfig;
				this.exportConfig = exportConfig;
				this.spreadsheetType = 'export';
				this.exportWay = exportConfig.exportWay || '';
				this.doExport(exportConfig, output);
				return;
			}
			// 其他导出
			if (action.toString().indexOf('export') === 0) {
				// 行内导出
				const exportConfig = actionConfig[action].eventList[0];
				exportConfig.exportType = 'targetRow';
				this.exportConfig = exportConfig;
				this.spreadsheetType = 'export';
				this.exportWay = exportConfig.exportWay || '';
				this.doExport(exportConfig, output);
			}
			this.element.resolveData = output;
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element,
				resolveData: output
			});
			// 详情
			if (action === 'detail' && this.statusConfig?.rowShowDetails) {
				this.onRowClick(output);
				return;
			}
			// 关闭所有弹窗及抽屉
			this.removeModel();
			// 事件
			const actionKey = type === 'nesting' ? 'nestingActionConfig' : 'actionConfig'
			const comEvents = this.element[actionKey][action].eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.onActionItem(comEvents[i], output);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					this.onActionItem(ele, output);
				});
			}
			this.$nextTick(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					action,
					output,
					isInit: false,
					noUpdate: true
				});
			});
			return false;
		},
		onActionItem(ele) {
			if (ele.key === 'click') {
						// 跳转页面
					if (ele.actionType === 'jumpPage') {
						if (ele.linkType === 'projectPage') {
							const query = {};
							if (ele.queryList && ele.queryList.length) {
								ele.queryList.forEach((queryItem) => {
									const component = getComponentById(
										this.componentList || this.componentData,
										queryItem.componentId
									);
									this.$set(
										query,
										queryItem.key,
										component.resolveData[queryItem.feild]
									);
								});
							}
						} else if (ele.linkType === 'outPage') {
							window.open(ele.linkUrl);
						}
					} else if (ele.actionType === 'eeAction') {
						// 触发后端事件
						console.log(ele, '000000');
						// 搜集参数
					}else {
						ele.effects.forEach((effect) => {
							this.$store.commit('triggerEvents', {
								config: {
									...ele,
								...effect
								},
								element: this.element,
								EDITOR_pageUUID: this.EDITOR_pageUUID
							});
						});
					}
				}
		},
		/**
		 * @desc: 移除所有显示弹窗
		 */
		removeModel() {
			if (!this.actionConfig || !this.actionConfig.detail) return;
			const { detail } = this.actionConfig;
			if (!detail || !detail.eventList || !detail.eventList.length) return;
				const comEvents = detail.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.onRemoveItem(comEvents[i]);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					this.onRemoveItem(ele);
				});
			}
		},
		onRemoveItem(eventItem) {
			const { effects } = eventItem;
				if (effects && effects.length) {
					for (let j = 0; j < effects.length; j++) {
						const effectItem = effects[j];
						const { actions = [], targets = [] } = effectItem;
						if (actions.length && targets.length && actions.includes('show')) {
							targets.forEach((ele) => {
								if (ele.toString().indexOf('CommonDialog-') === 0) {
									// console.log(ele, '移除， 888888')
									this.$store.commit('triggerEvents', {
										config: {
											...eventItem,
										...effectItem,
										actions: ['hidden']
										},
										element: this.element,
										EDITOR_pageUUID: this.EDITOR_pageUUID
									});
								}
							});
						}
					}
				}
		},
		/**
		 * @desc: 获取状态值
		 * @param {any普通类型} value 值
		 * @param {Array} statusOptions 状态选项
		 * @return {Object} 用于状态显示的对象  label value color
		 */
		filterStatus(value, statusOptions = []) {
			if (!statusOptions || !Array.isArray(statusOptions)) {
				return {
					label: value
				};
			}
			for (let i = 0; i < statusOptions.length; i++) {
				if (
					(isNaN(value) && statusOptions[i].value === value) ||
					+statusOptions[i].value === +value
				) {
					return statusOptions[i];
				}
			}
			return {
				label: value
			};
		},
		/**
		 * @desc: 格式化时间
		 * @param {String/Object/Number} value 时间值 兼容时间戳、时间字符串、时间对象
		 * @param {String} timeFormat 格式化
		 * @param {String} connector 连接符
		 */
		filterTime(value, timeFormat, connector = '') {
			if (!value) return '';
			// 时间格式表单无法统一不做校验
			// const reg = /^(\d{4})(-|\/)(\d{2})(-|\/)(\d{2})/;
			// if ((isNaN(+value) && !reg.test(value))) return '';
			let dateObj = null;
			if (value instanceof Date) {
				dateObj = value;
			} else if (isNaN(value)) {
				dateObj = new Date(value);
			} else {
				dateObj = new Date(value * 1000);
			}
			// @凌志华 后端返回的无效时间显示为 '' (2022-02-15 15:51:51)
			if (!(dateObj instanceof Date) || dateObj.toString() === 'Invalid Date') return '';
			const year = dateObj.getFullYear();
			// 无效的时间
			if (year === 1970) return '';
			let month = this.addZero(dateObj.getMonth() + 1);
			const day = this.addZero(dateObj.getDate());
			const hour = this.addZero(dateObj.getHours());
			const minute = this.addZero(dateObj.getMinutes());
			const second = this.addZero(dateObj.getSeconds());
			let connectorStr = '-';
			if (connector === '/') {
				connectorStr = '/';
			} else if (connector === 'cn') {
				connectorStr = connector;
			}
			const defaultStr = `${year}${
				connectorStr === 'cn' ? '年' : connectorStr
			}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
				connectorStr === 'cn' ? '日' : ''
			} ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
				connectorStr === 'cn' ? '分' : ':'
			}${second}${connectorStr === 'cn' ? '秒' : ''}`;
			switch (timeFormat) {
				case 'YMDhms':
					return defaultStr;
				case 'YMDhm':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					} ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ''
					}`;
				case 'YMDh':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					} ${hour}${connectorStr === 'cn' ? '时' : ''}`;
				case 'YMD':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					}`;
				case 'YM':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr}${month}${connectorStr === 'cn' ? '月' : ''}`;
				case 'MD':
					return `${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${connectorStr === 'cn' ? '日' : ''}`;
				case 'hms':
					return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ':'
					}${second}${connectorStr === 'cn' ? '秒' : ''}`;
				case 'hm':
					return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ''
					}`;
				case 'yyyy':
					return `${year}${connectorStr === 'cn' ? '年' : ''}`;
				case 'mm':
					return `${month}${connectorStr === 'cn' ? '月' : ''}`;
				case 'dd':
					return `${day}${connectorStr === 'cn' ? '日' : ''}`;
				case 'weekDay':
					return this.weekDays[dateObj.getDay()];
				case 'week':
					return `第${this.getWeek(dateObj)}周`;
				default:
					return defaultStr;
			}
		},
		/**
		 * @desc: 数字
		 * @param {Number} value
		 * @param {Object} numberFormat
		 */
		filterNumber(value, numberFormat = {}) {
			if (isNaN(value) && isNaN(+value)) return value;
			const { type, decimalPlace, prefix = '', suffix = '' } = numberFormat;
			let result = value;
			if (type === 'float') {
				result = (+value).toFixed(isNaN(+decimalPlace) ? 0 : +decimalPlace);
			}
			return `${prefix} ${result} ${suffix}`;
		},
		/**
		 * @desc: 补零
		 * @param {Number} val 需要补零的值
		 */
		addZero(val) {
			if (isNaN(val) || val < 0 || val >= 10) return val;
			return `0${val}`;
		},
		/**
		 * @desc: 获取当前日期对象为全年第几周
		 * @param {Object<Date>} dateObj 时间对象
		 * @return {Number} 第几周
		 */
		getWeek(dateObj) {
			const firstDay = new Date(`${dateObj.getFullYear()}-1-1`);
			const timeBetween = dateObj - firstDay;
			const between = timeBetween / (7 * 24 * 60 * 60 * 1000);
			return Math.ceil(between);
		},
		/**
		 * @desc: 验证架构类型
		 * @param {Array, String} types 类型 group company project
		 */
		validArchiType(types) {
			if (!types || !Array.isArray(types) || !types.length) return true;
			if (this.$GetUserInfo('name') === 'admin') return true;
			const archiType = this.$GetTargetArchi('archiType');
			if (archiType && types.includes(archiType)) {
				return true;
			}
			return false;
		},
		/* 循环定时器请求数据 */
		doInterface() {
			if (!this.interfaceFinished) return;
			this.interfaceFinished = false;
			if (this.database && !this.hasComponentParam()) {
				const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents, this.EDITOR_pageUUID);
				if (!canPost) return;
				this.getList(this.database, search, param);
			}
			this.metadata = this.element.metadata || [];
		},
    /**
     * @description: 设置定时请求
     */
    setInterval() {
      // 清除定时器
      if (this.intervalObj) {
        clearInterval(this.intervalObj);
      }
      if (!this.interfaceInterval) return false;
      const {
        useInterval = false,
        type = 'timeout',
        timeout = 3000,
        times = -1,
        unit = 'day',
        weekDay = '',
        month = '',
        day = '',
        hms = '06:00'
      } = this.interfaceInterval;
      if (!useInterval) return false;
      this.intervalObj = null;
      this.intervalTimes = 0;
      // 定时循环
      if (type === 'timeout') {
        if (timeout <= 0) return;
        this.intervalObj = setInterval(() => {
					if (times !== -1) {
						this.intervalTimes++;
					}
          this.doInterface();
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, timeout * 1000);
        return;
      }
      // 周期_时分秒
      if (['second', 'minutes', 'hour'].includes(unit)) {
        let count = 1;
        if (unit === 'minutes') {
          count = 60;
        } else if (unit === 'hour') {
          count = 60 * 60;
        }
        this.intervalObj = setInterval(() => {
          if (times !== -1) {
						this.intervalTimes++;
					}
          this.doInterface();
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, count * 1000);
      } else {
        // 每天 每分钟循环一次
        this.intervalObj = setInterval(() => {
          const date = new Date();
          const currentMonth = date.getMonth() + 1;
          const currentDay = date.getDate();
          const currentWeekDay = date.getDay();
          const hours = date.getHours();
          const minutes = date.getMinutes();
          this.intervalTimes++;
          if (unit === 'day' && hms === `${hours}:${minutes}`) {
            // 日
            this.doInterface();
          } else if (unit === 'week') {
            // 周
            if (currentWeekDay === weekDay && hms === `${hours}:${minutes}`) {
              this.doInterface();
            }
          } else if (unit === 'month') {
            // 月
            if (day === currentDay && hms === `${hours}:${minutes}`) {
              this.doInterface();
            }
          } else if (unit === 'year') {
            // 年
            if (month === currentMonth && day === currentDay && hms === `${hours}:${minutes}`) {
              this.doInterface();
            }
          }
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, 59 * 1000);
      }
    },
		/**
     * @desc: 格式化循环容器子节点
     * @param {Number} index 下标
     */
		getLoopChildren(index, sourceData, level = 0) {
      if (!this.element?.children?.length) return {};
      const template = this.element.children[0];
      return {
        ...template,
        id: `${template.id}-${level}-${index}`,
        loopData: {
          isLoop: true,
          sourceData,
          index,
          loopId: this.element.id,
        },
        children: this.getChildren(
          template.children,
          sourceData,
          level + 1,
          index
        ),
      };
    },
		/**
     * @desc: 获取下级
     * @param {Array} arr 数组
     * @param {Object} sourceData
     * @param {Number} level
     * @param {Number} index 数据源下标
     */
		getChildren(arr = [], sourceData, level = 1, index) {
      return arr.map((ele, cIndex) => {
        return {
          ...ele,
          id: `${ele.id}-${level}-${cIndex}`,
          loopData: {
            isLoop: true,
            sourceData,
            index,
            loopId: this.element.id,
          },
          children: this.getChildren(
            ele.children,
            sourceData,
            level + 1,
            index
          ),
        };
      });
    }
	},
	beforeDestroy() {
		if (!this.EDITOR_pageUUID) {
      removeEventBus(['databaseTrigger'], this.element.id);
    }
		this.initTimer(false)
		// 清除定时器
		if (this.intervalObj) {
			clearInterval(this.intervalObj);
		}
	}
};
</script>

<style lang="less" scoped>
.table-list{
  height: 100%;
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  overflow: hidden;
	position: relative;
  .table-header{
    width: 100%;
    display: flex;
    .header-item{
      min-height: 30px;
      box-sizing: border-box;
      padding: 5px;
      line-height: 20px;
      text-align: center;
      word-break: break-all;
    }
  }
	.table-header-radius{
		border-radius: 9999px;
		overflow: hidden;
	}
  .table-body{
    flex: 1;
		// + 15px是为了隐藏滚动条
    width: calc(~"100% + 15px");
		// width: 100%;
    position: relative;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    .row-com{
			overflow: hidden;
      position: relative;
			// border: 1px solid #0f0;
    }
    .row{
      display: flex;
      width: 100%;
      position: absolute;
      .cell{
        padding: 5px 0;
        box-sizing: border-box;
        white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
      }
    }
  }
	.el-empty{
		margin: 0 auto;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.full{
		position: absolute;
		right: 10px;
		top: 10px;
		width: 16px;
		height: 16px;
		display: none;
		align-items: center;
		justify-content: center;
	}
	&:hover{
		.full{
			display: flex;
		}
	}
}
:deep(.chart-container-dialog) {
	.el-dialog__body{
		padding: 10px 20px;
	}
}
</style>

<style>
  .scroll-list-anmiate-enter-active, .scroll-list-anmiate-leave-active {
    transition: all .5s;
  }
  .scroll-list-anmiate-enter {
    transform: translateY(100%);
  }
  .scroll-list-anmiate-leave-to {
		opacity: 0;
    transform: translateY(-100%);
  }
</style>
