<!--
 * @Description: 输入框
 * @Author: luocheng
 * @Date: 2021-09-24 10:27:21
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-04-22 15:55:37
-->
<template>
  <el-input
    v-model="value"
    @input="onInput(element, 'input')"
    @change="onInput(element, 'input')"
    :placeholder="statusConfig.placeholder"
    :autofocus="statusConfig.autofocus"
    :ref="element.id"
    :id="element.id"
    :prefix-icon="statusConfig.prefixIcon"
    :suffix-icon="statusConfig.suffixIcon"
  ></el-input>
</template>

<script>
import eventMixin from '@/custom-component/eventMixin.js';
export default {
  name: 'CommonInput',
  mixins: [eventMixin],
  data() {
    return {
      value: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      const inputDom = document.querySelector(`#${this.element.id}`);
      if (!inputDom) return;
      const height = `${this.statusConfig.height}px`;
      const fontSize = `${this.statusConfig.fontSize}px`;
      inputDom.style.height = height;
      inputDom.style.lineHeight = height;
      inputDom.style.fontSize = fontSize;
      try {
        const prefixIcon = inputDom.nextSibling;
        if (prefixIcon && prefixIcon.className === 'el-input__prefix') {
          inputDom.style.paddingLeft = `${+this.statusConfig.fontSize + 20}px`;
          const iBox = prefixIcon.childNodes[0]
          iBox.style.fontSize = fontSize;
          iBox.style.lineHeight = height;
        }
        const suffixIcon = prefixIcon.nextSibling;
        if (suffixIcon && suffixIcon.className === 'el-input__suffix') {
          inputDom.style.paddingRight = `${+this.statusConfig.fontSize + 20}px`;
          const iBox = suffixIcon.childNodes[0].childNodes[0]
          iBox.style.fontSize = fontSize;
          // iBox.style.lineHeight = `${+this.statusConfig.height - 10}px`;
          iBox.style.width = `${+this.statusConfig.fontSize + 20}px`;
          iBox.style.marginTop = `${-5}px`;
        }
      } catch (err) {
        console.log(err);
      }
    });
  }
}
</script>
<style class="less" scoped>
</style>