/*
 * @Description: 基础容器
 * @Author: luocheng
 * @Date: 2021-10-15 14:52:06
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-31 16:57:24
 * @LastEditTime: 2022-04-28 10:09:40
 */

export const CommonText = {
	component: 'CommonText',
	name: '文本框',
	icon: 'iconjichu-wb',
	isLock: false, // 是否锁定
	needCommonStyle: true,
	span: -1,
	statusConfig: {
		displayShow: true,
		isShow: true, // 是否显示
		ellipsis: false, // 换行问题
		clamp: 1,
		nullText: '',
		bold: false,
		italic: false,
		useToFixed: false,
		toFixedCount: 2
	},
	style: {
		width: 'auto',
		minWidth: ['dataview', 'console'].includes(sessionStorage.getItem('editorType')) ? 0 : 200,
		height: 32,
		fontSize: 14,
		fontWeight: 500,
		lineHeight: 32,
		letterSpacing: 0,
		textAlign: 'center',
		color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
		overflowX: 'hidden',
		overflowY: 'hidden',
		rotate: 0
	},
	propValue: '文本框',
	behaviors: [
		{
			label: '改变显示文本',
			value: 'changeText',
			desc: '修改显示的文本,参数字段为text'
		},
		{
			label: '修改背景颜色',
			value: 'changeBackground',
			desc: '修改文本背景, 目前支持颜色，参数字段color: 字体颜色'
		},
		{
			label: '修改字体',
			value: 'changeFont',
			desc: '修改文本字体，参数字段color: 字体颜色， fontSize: 字体大小，fontWeight: 字重（字体粗细数字或字符）'
		}
	],
};
// 基础组件
const baseComponent = {
	type: 'baseComponent',
	title: '基础组件',
	show: true,
  key: 2,
	order: 2,
	componentList: [
		{
			component: 'CommonButton',
			type: 'button',
			name: '按钮',
			icon: 'iconjichu-an',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '按钮',
			style: {
				height: '',
				width: '',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0,
				fontSize: 12
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: 'primary', // primary / success / warning / danger / info / text
				size: '', // medium / small / mini
				plain: false,
				round: false,
				circle: false,
				loading: false,
				disabled: false,
				icon: '',
				autofocus: false,
				outId: ''
			},
			behaviors: [
				{
					label: '同步进度计划模型',
					value: 'onSyncPlanModel',
					desc: '参数配置名为schedule_id,即进度计划id'
				},
			]
		},
		{
			component: 'CommonImage',
			name: '图片',
			icon: 'iconjichu-tp',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			statusConfig: {
				displayShow: true,
				isShow: true,
				objectFit: 'cover',
				hoverEffect: '',
				hasPreview: false
			},
			style: {
				width: 200,
				height: 180,
				rotate: 0,
				mixBlendMode: 'normal'
			},
			propValue: ''
		},
		CommonText,
		// {
		// 	name: '通用文本',
		// 	component: 'TextCom',
		// 	type: 'currency',
		// 	icon: 'iconjichu-tywb',
		// 	needCommonStyle: true,
		// 	statusConfig: {
		// 		displayShow: true,
		// 		isShow: true, // 是否显示
		// 		showEffect: '',
		// 		bold: false,
		// 		italic: false,
		// 		// fontFamily: 'DIN Next LT Pro',
		// 	},
		// 	style: {
		// 		height: 32,
		// 		fontSize: 16,
		// 		textAlign: 'center',
		// 		lineHeight: 32
		// 	}
		// },
		{
			component: 'BaseIcon',
			name: '图标',
			icon: 'iconjichu-tb',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: 'auto'
			},
			style: {
				width: 'auto',
				minWidth: ['dataview', 'console'].includes(sessionStorage.getItem('editorType')) ? 0 : 100,
				height: 32,
				fontSize: 14,
				fontWeight: 500,
				lineHeight: 32,
				letterSpacing: 0,
				textAlign: 'center',
				color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
				overflowX: 'hidden',
				overflowY: 'hidden',
				rotate: 0
			},
			propValue: 'el-plus'
		},
		{
			component: 'CommonTitle',
			name: '标题',
			icon: 'iconjichu-bt',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				level: 3, // 标题级别 1 - 6
				ellipsis: false, // 换行问题
				clamp: 1,
				nullText: ''
			},
			style: {
				width: 300,
				height: 50,
				fontSize: 14,
				fontWeight: 500,
				lineHeight: 50,
				letterSpacing: 0,
				textAlign: 'left',
				color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
				overflowX: 'hidden',
				overflowY: 'hidden',
				rotate: 0
			},
			propValue: '标题'
		},
		{
			component: 'CommonAnnex',
			name: '附件',
			icon: 'iconjichu-fj',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			style: {
				width: 300,
				height: 'auot',
				fontSize: 14,
				fontWeight: 500,
				lineHeight: 50,
				letterSpacing: 0,
				textAlign: 'left',
				color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
				overflowX: 'hidden',
				overflowY: 'hidden',
				rotate: 0
			},
			propValue: '/'
		},
		{
			component: 'DropDownButton',
			name: '下拉按钮',
			icon: 'iconjichu-xlan',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			style: {
				width: 120,
				height: 'auot',
				fontSize: 14,
				fontWeight: 500,
				lineHeight: 50,
				letterSpacing: 0,
				textAlign: 'left',
				color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
				overflowX: 'hidden',
				overflowY: 'hidden',
				rotate: 0
			},
			propValue: '/',
			actionConfig: [
				{
					key: 'modify',
					type: 'modify',
					name: '编辑',
					color: '#409EFF',
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				{
					key: 'add',
					type: 'add',
					name: '新增',
					color: '#409EFF',
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				{
					key: 'rowClick',
					type: 'rowClick',
					name: '点击行',
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			]
		},
    {
      component: 'CommonSelect',
      type: 'form',
      name: '下拉框',
      icon: 'iconjichu-xlk',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        showLabel: true,
        label: '描述',
        enLabel: '英文描述',
        labelWidth: 100,
        labelPosition: 'left',
        selectSize: 'medium',
        clearable: false,
        filterable: false,
        multiple: false,
        disabled: false,
        placeholder: '请选择',
        allowCreate: false,
        noDataText: '无数据',
        prefixIcon: '',
        optionLabel: '名称',
        optionValue: 'id',
        optionValueUUID: '',
        defaultSelect: false,
        needAll: false,
        transparent: false,
        hideBorder: false,
        // selectWidth: 220,
        selectHeight: 36,
        inputColor:'',
      },
      style: {
        width: '220px',
        height: '',
        minHeight: 40
      }
    },
		{
			component: 'CommonDateButton',
			type: 'button',
			name: '日期新增',
			icon: 'iconjichu-rqxz',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '新增',
			style: {
				width: 78,
				height: 32,
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0,
				fontSize: 12
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: 'primary', // primary / success / warning / danger / info / text
				size: '', // medium / small / mini
				plain: false,
				round: false,
				circle: false,
				loading: false,
				disabled: false,
				icon: '',
				autofocus: false,
				outId: '',
				needTime: false,
				dateFormat: 'date',
				valueFormat: 'yyyy-MM-dd hh:mm:ss',
				pickerType: 'date',
				straddleYear: false,
				advance: false
			},
			actionConfig: {} // 筛选参数
		},
		{
			component: 'CommonTabs',
			type: 'tabs',
			name: 'TAB组件',
			icon: 'iconjichu-tabzj',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: '', // 风格
				tabPosition: 'top', // 位置
				useAll: false,
				useSolt: false,
				defaultColor: '',
				activeColor: '',
				fontSize: 14
			},
			propValue: '',
			style: {
				height: 44
			},
			// 用于直接渲染的数据
			containerData: null,
			tabOrigin: 'fixed', // 来源 fixed 固定 dictionary 字典(全局) dynamic 数据仓库
			tabConfig: [], // 固定参数
			dictionaryConfig: '', // 使用的字典
			dynamicConfig: {
				objectUUID: '',
				viewUUID: '',
				labelKey: '', // label字段UUID
				valueKey: '', // 值字段UUID
				fieldList: []
			}, // 使用的字典
		},
    {
      name: '超链接',
      component: 'Hyperlinks',
			icon: 'iconjichu-clj',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        hrefValue: 'http://www.baidu.com',
        target: '_blank'
      },
      style: {
        height: 32,
        fontSize: 16,
        textAlign: 'center',
      }
    },
    {
      name: '日期',
      component: 'DateCom',
      icon: 'iconjichu-rq',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        bold: false,
        italic: false,
        // fontFamily: 'DIN Next LT Pro',
        zeroFilling: true,
        timeFormat: 'YYYY-MM-DD',
        timeFormatString: '公元${YYYY}年${MM}月${DD}号',
        formatChinese: false
      },
      needCommonStyle: true,
      style: {
        height: 32,
        fontSize: 16,
        textAlign: 'center',
        lineHeight: 32
      }
    },
    {
      name: '星期几',
      component: 'DayCom',
			icon: "iconjichu-xqj",
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        bold: false,
        italic: false,
        // fontFamily: 'DIN Next LT Pro',
        dayShowType: 'chinese',
        dayFormatString: '周${day}'
      },
      needCommonStyle: true,
      style: {
        height: 32,
				width: 100,
        fontSize: 16,
        textAlign: 'center',
        lineHeight: 32
      }
    },
    {
      name: '时间',
      component: 'TimeCom',
      icon: 'iconjichu-sj',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        bold: false,
        italic: false,
        // fontFamily: 'DIN Next LT Pro',
      },
      needCommonStyle: true,
      style: {
        height: 32,
        fontSize: 16,
        textAlign: 'center',
        lineHeight: 32
      }
    },
    {
      component: 'CommonInput',
      type: 'form',
      name: '输入框',
      icon: 'iconjichu-srk',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
				placeholder: '请输入',
				height: 32,
				fontSize: 14,
				autofocus: true,
				prefixIcon: '',
				suffixIcon: '',
				globalLoading: false,
      },
      style: {
        minHeight: 40,
        height: '',
        width: '',
        rotate: 0,
        paddingTop: 18,
        paddingBottom: 0,
        paddingLeft: 15,
        paddingRight: 15
      }
    },
     // tag标签
     {
			component: 'TagMenu',
			type: 'tagMenu',
			name: '标签',
			icon: 'iconbiaoqian',
			isLock: false, // 是否锁定
			needCommonStyle: true,
      propValue: '标签一',
      style: {
				height: '',
				width: '',
				lineHeight: '',
				rotate: 0,
        borderRadius: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0,
				fontSize: 12
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				layout: 'horizontal',
				type: '',
			},
			metadata: {
        'value': 'value'
      },
			// 用于直接渲染的数据
			containerData: null,
			tagMenuConfig: {
				configList: [],
				textConfig: {
					defaultColor: '#fff',
					clickColor: '#fff',
					dblColor: '#fff',
					size: '14',
					fontWeight: '400',
					lineHeight: '20'
				},
				// 图片/icon类型
				valueType: 'img'
			}, // 固定参数
		},
	{
		component: 'CalendarFilter',
		type: 'text',
		name: '日历筛选',
		icon: 'iconrilishaixuan',
		isLock: false, // 是否锁定
		needCommonStyle: true,
		propValue: '50',
		span: 24,
		statusConfig: {
			displayShow: true,
			isShow: true, // 是否显示
			type: 'year', // year / month / week 
		},
		style: {
			height: 32,
			width: 160,
			rotate: 0,
			color: 'rgba(232, 209, 34, 1)',
			fontSize: 12,
			fontWeight: 400,
			lineHeight: 32,
		}
	}
	]
};

export default baseComponent;
