<!--
 * @Description: 弹窗
 * @Author: luocheng
 * @Date: 2021-09-28 09:59:15
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-04 18:05:48
-->
<template>
	<div v-if="contentConfig && contentConfig.formUUID !== currentFormUUID">
		<!-- @廖总 默认点击遮罩层不关闭 -->
		<!-- :close-on-click-modal="statusConfig.closeOnClickModal" -->
		<!-- :wrapperClosable="statusConfig.closeOnClickModal" -->
		<!-- statusConfig.top 因为实施不会配置暂时取消替换为默认 -->
		<component
			:is="'el-' + componentType"
			:title="
				$getLocalizedLabel({
					zhLabel: statusConfig.title,
					enLabel: statusConfig.enTitle
				}) || '提示'
			"
			:visible.sync="statusConfig.isShow"
			:custom-class="customClass"
			:width="fullDialogWidth || statusConfig.width || '400px'"
			:size="drawerWidth || 400"
			:fullscreen="statusConfig.fullscreen || (statusConfig.showExcel && preferredType === 'excel')"
			:top="componentType === 'dialog' ? 'auto' : ''"
			:modal="statusConfig.modal"
			:close-on-click-modal="false"
			:close-on-press-escape="statusConfig.closeOnPressEscape"
			:show-close="false"
			:center="statusConfig.center"
			:destroy-on-close="true"
			:lock-scroll="true"
			:wrapperClosable="false"
			append-to-body
		>
			<div
				v-if="skeleton && componentType === 'dialog' && ['outForm', 'luckySheet'].includes(contentConfig.contentType)"
				class="skeleton"
				:class="[originContentConfig.newFlow ? 'oldform-padding' : '']"
			>
				<skeleton></skeleton>
			</div>
			<header v-else class="model-header" slot="title">
				<h3>
					{{
						$getLocalizedLabel({
							zhLabel: statusConfig.title,
							enLabel: statusConfig?.enTitle
						}) || '提示'
					}}
				</h3>
				<template v-if="originContentConfig.newFlow && componentType === 'dialog'">
					<i
						v-if="needFlow && requestType !== 'edit'"
						class="iconfont collapse-icon"
						:class="{ iconzhedie01: sideShow, iconzhedie02: !sideShow }"
						@click="sideShow = !sideShow"
					></i>
				</template>
				<!-- 分享邮件/IM 基座中需要根据项目配置判断 -->
				<i
					class="iconfont iconfenxiang1"
					@click="onEmail"
					v-if="
						element.sourceConfig?.[0]?.type !== 'createData' &&
						systemConfig?.email_system &&
						(statusConfig.showShareBtn || statusConfig.showShareBtn == undefined)
					"
				>
				</i>
				<!-- 切换模式 -->
				<i
					class="iconfont"
					:class="componentType === 'dialog' ? 'iconqiehuanchouti' : 'iconqiehuandanchuang'"
					@click="onToggleComponentType"
					v-if="
						!['onlyForm', 'onlyExcel'].includes(preferredType) && !fullScreen && statusConfig.showChangeMode !== false
					"
				></i>
				<i
					class="iconfont"
					:class="fullScreen ? 'icontuichuquanping' : 'iconquanping5'"
					v-if="
						(!statusConfig.showExcel ||
							(statusConfig.showExcel && !['onlyForm', 'excel', 'onlyExcel'].includes(preferredType))) &&
						statusConfig.showFullScreen !== false
					"
					@click="onToggleFullScreen"
				></i>
				<i
					class="iconfont toggle-preferred"
					v-if="statusConfig.showExcel && !['onlyForm', 'onlyExcel'].includes(preferredType)"
					:class="{
						iconzidingyibiaodan: preferredType === 'excel',
						'iconsaidi-daohang-biaoge': preferredType === 'form'
					}"
					@click="onTogglePreferredType"
				></i>
				<!-- 关闭 -->
				<i class="iconfont iconbiaotiguanbi" v-if="statusConfig.showClose" @click="onHeaderClose"></i>
			</header>
			<article class="email-main" v-show="showEmail">
				<Email @cancel-email="onCancelEmail" @on-send="onSendEmail" :showEmail="showEmail"></Email>
			</article>
			<article
				class="dialog-main"
				:class="[
					componentType === 'drawer' ? 'drawer-main' : 'dialog-main',
					originContentConfig.newFlow ? '' : 'oldform-padding'
				]"
				v-loading="loading"
			>
				<!-- 外部表单 -->
				<template
					v-if="contentConfig && ['outForm', 'luckySheet'].includes(contentConfig.contentType) && formDesignData"
				>
					<template v-if="originContentConfig.newFlow">
						<PcParserNew
							v-show="preferredType === 'form' || preferredType === 'onlyForm'"
							@submit="onFormSubmit"
							@on-close="onEndFlow"
							@changeSkeleton="changeSkeleton"
							@edit-change="formEditChange"
							:form-data="formDesignData"
							ref="dialogForm"
							:displayData="displayData"
							:editFields="editFields"
							:disabled="formDisabled"
							:needFlow="needFlow"
							:moduleUuid="originContentConfig.formUUID || originContentConfig.objectUUID"
							:needParentFlow="needParentFlow"
							:lowerFlow="useChildFlow"
							:archField="childArchField"
							:formMode="requestType"
							:nowArchiType="nowArchiType"
							:fillData="fillData"
							:excelMetaData="excelMetaData"
							:contentConfig="originContentConfig"
							:dataIds="excelIds"
							:sideShow="sideShow"
							:otherParam="otherParam"
						>
						</PcParserNew>
					</template>
					<template v-else>
						<PcParser
							v-show="preferredType === 'form' || preferredType === 'onlyForm'"
							@submit="onFormSubmit"
							@on-close="onEndFlow"
							@changeSkeleton="changeSkeleton"
							:form-data="formDesignData"
							ref="dialogForm"
							:displayData="displayData"
							:editFields="editFields"
							:disabled="formDisabled"
							:needFlow="needFlow"
							:moduleUuid="originContentConfig.formUUID || originContentConfig.objectUUID"
							:needParentFlow="needParentFlow"
							:formMode="requestType"
							:nowArchiType="nowArchiType"
							:fillData="fillData"
							:excelMetaData="excelMetaData"
							:contentConfig="originContentConfig"
							:dataIds="excelIds"
							:otherParam="otherParam"
						>
						</PcParser>
					</template>
					<!-- luckSheet 新增数据无 excelMetaData 与 excelFormData 信息  -->
					<!-- 2024/09/13 不加div preferredType === from  组件依然会渲染  不知道为啥 -->
					<div
						v-show="statusConfig.showExcel && (preferredType === 'excel' || preferredType === 'onlyExcel')"
						style="width: 100%; height: 100%"
					>
						<Entry
							loadType="preload"
							:type="excelModel"
							ref="dialogExcel"
							:excelUuid="originContentConfig.excelTemplate"
							:objectUuid="originContentConfig.objectUUID"
							:viewUuid="originContentConfig.viewUUID"
							:formUuid="originContentConfig.formUUID || originContentConfig.objectUUID"
							:dataIds="excelIds"
							:excelMetaData="excelMetaData"
							:excelFormData="excelFormData"
							:show="preferredType === 'excel' || preferredType === 'onlyExcel'"
							:needFlow="needFlow"
							:displayData="displayData"
							:formDesignData="formDesignData"
							@submit="onFormSubmit"
							@receive="doCacheFormData"
						></Entry>
					</div>
				</template>
				<!-- 外部页面 -->
				<!-- :pageId="contentConfig.pageId"
					:pageData="pageData"
					:pageType="pageType"
					:pageConfig="pageConfig" -->
				<PageParser
					v-else-if="contentConfig && contentConfig.contentType === 'configPage' && initEnd"
					:pageUUID="contentConfig.pageId"
				></PageParser>
				<!-- 手动开发的页面 -->
				<template v-if="contentConfig && contentConfig.contentType === 'developPage'">
					<OutPage :link="developLink"></OutPage>
				</template>
			</article>
			<!-- 操作 -->
			<!-- 抽屉底部 -->
			<div
				class="dialog-footer"
				v-if="componentType === 'drawer' && (statusConfig.showConfirm || statusConfig.showClose)"
			>
				<el-button v-if="statusConfig.showCancel" :type="statusConfig.cancelType" @click="onCancel">
					{{
						$getLocalizedLabel({
							zhLabel: statusConfig.cancelText,
							enLabel: statusConfig?.enCancelText
						})
					}}
				</el-button>
				<el-button
					v-if="statusConfig.showConfirm"
					:type="statusConfig.confirmType"
					:disabled="renderLoading"
					@click="onConfirm(false)"
				>
					{{
						$getLocalizedLabel({
							zhLabel: statusConfig.confirmText,
							enLabel: statusConfig?.enConfirmText
						})
					}}
				</el-button>
				<el-button
					v-for="(btn, bIndex) in actionConfig.filter((ele) => ele.canCustom)"
					:key="bIndex"
					:type="btn.buttonType"
					@click="onButton(btn)"
				>
					{{ $getLocalizedLabel({ zhLabel: btn.text, enLabel: btn?.enText }) }}
				</el-button>
				<!-- 缓存表单数据 -->
				<el-button type="" @click="onSave(false)" v-if="statusConfig.useCache && !statusConfig.autoSave"
					>存草稿</el-button
				>
			</div>
			<!-- 弹窗 操作 -->
			<div
				slot="footer"
				class="dialog-footer"
				v-if="componentType === 'dialog' && (statusConfig.showConfirm || statusConfig.showClose)"
			>
				<!--流程操作-->
				<div
					class="flow-operate-container"
					v-if="
						requestType !== 'add' &&
						originContentConfig.newFlow &&
						requestType !== 'edit' &&
						displayData.btn &&
						displayData.btn.length !== 0
					"
				>
					<FlowOperate
						v-if="Object.keys(displayData).length && displayData.btn && displayData.btn.length"
						:edit-data="displayData"
						:flow-edit="editFieldsValue"
						@change-end="onEndFlow"
					/>
				</div>

				<el-button v-if="statusConfig.showCancel" :type="statusConfig.cancelType" @click="onCancel">
					{{
						$getLocalizedLabel({
							zhLabel: statusConfig.cancelText,
							enLabel: statusConfig?.enCancelText
						})
					}}
				</el-button>
				<!-- 缓存表单数据 onConfirm(true)-->
				<el-button type="" @click="onSave(false)" v-if="statusConfig.useCache && !statusConfig.autoSave">{{
					$t('components.dialog.342359-1')
				}}</el-button>
				<el-button
					v-if="statusConfig.showConfirm"
					:type="statusConfig.confirmType"
					:disabled="renderLoading"
					@click="onConfirm(false)"
				>
					{{
						$getLocalizedLabel({
							zhLabel: statusConfig.confirmText,
							enLabel: statusConfig?.enConfirmText
						})
					}}
				</el-button>
				<el-button
					v-for="(btn, bIndex) in actionConfig.filter((ele) => ele.canCustom)"
					:key="bIndex"
					:type="btn.buttonType"
					@click="onButton(btn)"
				>
					{{ $getLocalizedLabel({ zhLabel: btn.text, enLabel: btn?.enText }) }}
				</el-button>
			</div>
		</component>
		<!-- 保存草稿弹窗 -->
		<el-dialog
			title="保存草稿"
			:visible.sync="showSaveConfirm"
			width="400px"
			destroy-on-close
			append-to-body
			custom-class="cache-name-dialog"
		>
			<CacheName v-if="showSaveConfirm" v-model="draftName"></CacheName>
			<div slot="footer" class="dialog-footer">
				<el-button @click="showSaveConfirm = false">取 消</el-button>
				<el-button type="primary" :disabled="!draftName" @click="onConfirm(true)">保 存</el-button>
			</div>
		</el-dialog>
		<!-- 历史记录列表 -->
		<el-dialog
			title="草稿记录"
			:visible.sync="showSaveList"
			width="720px"
			destroy-on-close
			append-to-body
			custom-class="cache-list-dialog"
			@close="closeSaveList"
		>
			<CacheList
				:formUUID="contentConfig.formUUID"
				:objectUUID="contentConfig.objectUUID"
				@selectCache="selectCache"
			></CacheList>
		</el-dialog>
	</div>
</template>

<script>
import { transFieldsUUid, dataInterface } from '@/apis/data/index';
import PcParser from '@/custom-component/form/newParser/PcParser.vue';
import PcParserNew from '@/custom-component/form/newParser/PcParserNew.vue';
import {
	getComponentById,
	getQueryValue,
	doEEActionHandle,
	getLinkByTemplate,
	openUrl,
	formatTime
} from '@/utils/tools';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import { isJSONStr } from '@/utils/tools';
import Entry from '@/custom-component/common/Entry';
import OutPage from './outPage/Index';
import skeleton from '@/custom-component/form/newParser/skeleton';
import FlowOperate from '@/custom-component/flow/flow-designer/src/operation/FlowOperate';
import Email from './Email.vue';
import CacheName from './cache/Name';
import CacheList from './cache/List';

export default {
	name: 'CommonDialog',
	components: {
		PcParser,
		PcParserNew,
		Entry,
		OutPage,
		skeleton,
		FlowOperate,
		Email,
		CacheName,
		CacheList
	},
	props: {
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		// 是否在组合内
		isGroup: {
			type: Boolean
		},
		// 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
		componentList: {
			default: null
		}
	},
	data() {
		return {
			// 自定义表单相关
			objectUUID: null,
			// 表单配置数据
			formDesignData: null,
			displayData: {},
			// 流程中展示字段
			editFields: {},
			// 表单是否disable
			formDisabled: false,
			// 页面数据
			pageData: [],
			// 页面类型
			pageType: '',
			// 页面样式配置
			pageConfig: {
				height: 600,
				width: 500
			},
			loading: false,
			// 流程
			needFlow: false,
			needParentFlow: false,
			// 组件类型
			componentType: 'dialog',
			// 显示类型 form 表单  excel 图表
			preferredType: 'form',
			// 切换全屏
			fullScreen: false,
			// 全屏弹窗宽度
			fullDialogWidth: 0,
			// excel数据
			excelMetaData: null,
			excelFormData: null,
			// 缓存
			isCacheFormData: false,
			// 外部页面链接
			developLink: '',
			developHost: '',
			// 当前数据携带的archi_type
			nowArchiType: '',
			// 当前渲染的表单ID
			currentFormUUID: window.sessionStorage.getItem('currentFormUUID'),
			// 表单填充数据
			fillData: {},
			// 页面参数初始化完毕
			initEnd: false,
			// 当前提交的表单数据(可能作为参数进行后端事件传输处理@林冉)
			formDataCache: {},
			originContentConfig: null, // 格式化的表单配置
			renderLoading: true, // @蒲亚军 用户在表单渲染瞬间提交，子表还没渲染完成提交会导致子表数据为空，添加1S中的渲染延迟
			skeleton: true,
			editFieldsValue: {}, // 表单编辑触发流程条件
			useChildFlow: false, //
			childArchField: '', //
			useMainFlow: false, //
			sideShow: true, // 是否折叠流程
			otherParam: {},
			systemConfig: null,
			// 显示邮箱转发
			showEmail: false,
			// 保存历史记录
			showSaveConfirm: false,
			draftName: '',
			showSaveList: false,
			// 所选的草稿内容
			draftContent: null,
			// 0 列表式，1 覆盖式
			clearHistory: 0,
			saveInterval: null,
			copyFormDesignData: {}, // 选择草稿时临时保存的表单配置
			selectCacheObj: {}, // 选择的草稿数据
			useCacheDataId: null // 使用的cacheData的id  只在使用了cacheData后赋值
		};
	},
	inject: ['EDITOR_pageUUID'],
	computed: {
		...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus', 'subsidiaryComponentData']),
		// 取值组件列表
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return (
					this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData ||
					this.componentList ||
					this.componentData ||
					[]
				);
			}
			return this.componentList || this.componentData || [];
		},
		customClass() {
			if (
				this.componentType === 'dialog' &&
				((this.statusConfig.showExcel && ['onlyExcel', 'excel'].includes(this.preferredType)) || this.fullScreen)
			) {
				return 'common-excel-dialog common-dialog';
			}
			return this.componentType === 'dialog' ? 'common-dialog' : 'common-drawer';
		},
		// 特殊样式
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		// 内容配置
		contentConfig() {
			return this.element && this.element.contentConfig;
		},
		// 操作配置
		actionConfig() {
			return this.element && this.element.actionConfig;
		},
		// 请求类型
		requestType() {
			const types = {
				createData: 'add',
				updateData: 'edit',
				dataInfo: 'detail'
			};
			return types[this.element?.sourceConfig?.[0]?.type || ''];
		},
		// 抽屉宽度
		drawerWidth() {
			if (this.fullScreen || (this.statusConfig.showExcel && ['onlyExcel', 'excel'].includes(this.preferredType))) {
				return '100vw';
			}
			if (!this.statusConfig) return 400;
			if (isNaN(this.statusConfig.drawerWidth)) {
				return this.statusConfig.drawerWidth;
			}
			return +this.statusConfig.drawerWidth || 400;
		},
		// 表格模式
		excelModel() {
			if (!this.element.sourceConfig || !this.element.sourceConfig.length) return 'add';
			const sourceType = this.element.sourceConfig[0].type;
			if (sourceType === 'updateData') {
				return 'edit';
			}
			if (sourceType === 'dataInfo') {
				return 'info';
			}
			return 'add';
		},
		// 表格的id
		excelIds() {
			const params = this.getParams();
			return params.id || params.ids || params.data_id;
		},
		// 更新规则
		updateRulesConfig() {
			return (
				this.element?.updateRulesConfig || {
					pattern: '',
					rules: []
				}
			);
		}
	},
	created() {
		this.componentType = this.statusConfig?.firstType || 'dialog';
		this.preferredType = this.statusConfig?.preferredType || 'form';
		this.systemConfig = JSON.parse(localStorage.getItem('systemConfig'));
		// 测试
		// this.systemConfig.email_system = 1;
		if (this.contentConfig.contentType !== 'outForm') {
			// 表单之外让他删除disable效果
			setTimeout(() => {
				// @蒲亚军 用户在表单渲染瞬间提交，子表还没渲染完成提交会导致子表数据为空，添加1S中的渲染延迟
				this.renderLoading = false;
			}, 500);
		}
		this.fullScreen = this.statusConfig.fullscreen || false;
		this.setSaveInterval();
	},
	watch: {
		contentConfig: {
			handler() {
				if (!this.contentConfig) return;
				this.fillData = this.getFillData();
				const configResult = this.getFormContentConfigs(this.contentConfig);
				this.originContentConfig = JSON.parse(JSON.stringify(configResult));
				const { contentType } = this.contentConfig;
				if (contentType === 'outForm') {
					// 来至自定义表单@曾伟
					this.objectUUID = null;
					// 表单填充值
					this.initFormDesign(configResult);
				} else if (contentType === 'luckySheet') {
					// excel 表格
				} else if (contentType === 'configPage') {
					// 来自外部配置页面
					// 获取部分参数配置
					const params = this.getParams();
					sessionStorage.setItem(`dialogRelationParams_${configResult.pageId}`, JSON.stringify(params));
					this.initEnd = true;
				} else if (this.contentConfig.contentType === 'developPage') {
					// 外部首页页面
					// this.developHost = process.env.VUE_APP_V3_HOST || window.location.origin || 'https://dev.bimcc.net';
					const params = this.getParams();
					let paramsString = '';
					for (let key in params) {
						paramsString += `${key}=${params[key] ?? ''}&`;
					}
					this.developHost = process.env.VUE_APP_BASE_URL;
					this.developLink = `${this.developHost}${configResult.developPageRoute}?${paramsString}`;
				}
			},
			deep: true,
			immediate: true
		},
		// 切换显示修复层级问题
		'statusConfig.isShow': {
			handler() {
				this.$nextTick(() => {
					try {
						const modalBox = document.querySelector('.v-modal');
						if (modalBox && modalBox.style) {
							modalBox.style.zIndex = '3000';
						}
					} catch (err) {
						console.log(err, '切换显示修复层级问题--');
					}
				});
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		/**
		 * @description: 设置自动保存（30s）
		 * @return {*}
		 */
		setSaveInterval() {
			if (this.statusConfig.useCache && this.statusConfig.autoSave) {
				if (this.saveInterval) {
					clearInterval(this.saveInterval);
				}
				this.saveInterval = setInterval(() => {
					// 修改为自动保存
					this.clearHistory = 1;
					this.onSave(true);
					clearInterval(this.saveInterval);
				}, 30 * 1000);
			}
		},
		/**
		 * @description: 保存
		 */
		onSave(isAutoSave = true) {
			this.draftName = `${this.$getLocalizedLabel({
				zhLabel: this.statusConfig.title,
				enLabel: this.statusConfig.enTitle
			})}-草稿`;
			if (isAutoSave) {
				this.onConfirm(true);
			} else {
				this.clearHistory = 0;
				this.showSaveConfirm = true;
			}
		},
		/**
		 * @description: 转发邮件
		 */
		onEmail() {
			this.showEmail = true;
		},
		/**
		 * @description: 发送邮件
		 */
		onSendEmail(data) {
			const loading = this.$loading();
			const { receive_users, type } = data;
			const param = this.getParams();
			dataInterface(
				{
					data_id: param?.data_id, // 数据id
					object_uuid: this.originContentConfig.objectUUID, // 对象uuid/表单uuid
					receive_users, // 分享用户数组
					type, // 分享类型,可选值:IM/Email
					other_data: JSON.stringify({
						...param,
						object_uuid: this.originContentConfig.objectUUID,
						view_uuid: this.originContentConfig.viewUUID,
						detailData: this.originContentConfig.formUUID,
						title: this.statusConfig.title,
						archi_type: this.nowArchiType
					}), // 附属信息
					pc_path: '/public-form-page', //  PC调转页面
					pc_query: JSON.stringify({
						object_uuid: this.originContentConfig.objectUUID,
						form_uuid: this.originContentConfig.formUUID,
						data_id: param?.data_id,
						title: this.statusConfig.title,
						archi_type: this.nowArchiType
					}), // PC调转参数
					h5_path: '/h5/formRender/detail', // H5地址
					h5_query: JSON.stringify({
						detailConfig: {
							...param,
							object_uuid: this.originContentConfig.objectUUID,
							view_uuid: this.originContentConfig.viewUUID,
							detailData: this.originContentConfig.formUUID,
							title: this.statusConfig.title,
							editVisible: false,
							deleteVisible: false,
							isNewFlow: 1
						}
					}) // H5跳转参数
				},
				'/api/shareMsg'
			)
				.then((res) => {
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success('发送成功！');
						this.showEmail = false;
					}
					loading.close();
				})
				.catch((err) => {
					console.log(err);
					this.$message.error('分享失败！');
					loading.close();
				});
		},
		/**
		 * @description: 放弃
		 */
		onCancelEmail() {
			this.showEmail = false;
		},
		/**
		 * 开启或关闭骨架屏
		 */
		changeSkeleton(value) {
			this.skeleton = value;
		},
		formEditChange(val) {
			this.editFieldsValue = val;
		},
		/**
		 * @desc: 切换全屏
		 */
		onToggleFullScreen() {
			this.fullScreen = !this.fullScreen;
			if (this.fullScreen) {
				this.fullDialogWidth = '100vw';
			} else {
				this.fullDialogWidth = '';
			}
		},
		/**
		 * @desc: 切换表格和表单模式
		 */
		onTogglePreferredType() {
			const toggleType = {
				form: 'excel',
				excel: 'form'
			};
			this.preferredType = toggleType[this.preferredType];
		},
		/**
		 * @desc: 切换组件显示类型
		 */
		onToggleComponentType() {
			if (this.componentType === 'dialog') {
				this.componentType = 'drawer';
			} else {
				this.componentType = 'dialog';
			}
			// 特殊处理
			this.$nextTick(() => {
				const { sourceConfig } = this.element;
				const isInfo = sourceConfig[0].type === 'dataInfo';
				if (isInfo) {
					const drawer = document.querySelector('.common-drawer');
					if (drawer) {
						const drawerBox = drawer.parentNode?.parentNode;
						if (drawerBox) {
							drawerBox.classList.add('Editor-drawer__wrapper');
						}
					}
				}
			});
		},
		/**
		 * @desc: 流程处理结束回调处理
		 */
		onEndFlow() {
			this.beforeClose();
			if (this.statusConfig.showExcel && (this.preferredType === 'excel' || this.preferredType === 'onlyExcel')) {
				const formData = this.$refs.dialogExcel.getFormData();
				if (formData && Object.keys(formData.metaEditFields)) {
					const newFormData = {};
					for (let key in formData.metaEditFields) {
						if (formData.metaEditFields[key] == 1) {
							newFormData[key] = formData[key];
						}
					}
					if (Object.keys(newFormData).length) {
						this.onFormSubmit(newFormData);
					}
				}
			} else {
				this.statusConfig.isShow = false;
			}
		},
		/**
		 * @desc: 确认按钮
		 */
		async onConfirm(isCacheFormData = false) {
			console.log('确认按钮, ', '----确认按钮--');
			this.isCacheFormData = isCacheFormData;
			if (this.contentConfig && this.contentConfig.contentType === 'outForm') {
				if (this.$refs && this.$refs.dialogForm && ['onlyForm', 'form'].includes(this.preferredType)) {
					if (isCacheFormData) {
						this.doCacheFormData(this.$refs.dialogForm.getFormData());
					} else {
						this.$refs.dialogForm.handleSubmit();
					}
				} else if (this.$refs && this.$refs.dialogExcel && ['onlyExcel', 'excel'].includes(this.preferredType)) {
					if (isCacheFormData) {
						this.$refs.dialogExcel.getFormData();
					} else {
						this.$refs.dialogExcel.triggerSubmit();
					}
				}
			}
			console.log('确认按钮, ', isCacheFormData);
			if (!isCacheFormData) {
				this.onCustomEvent('confirm');
			}
		},
		/**
		 * @desc: 初始化表单
		 */
		initFormDesign(contentConfig) {
			if (!contentConfig) return;
			this.loading = true;
			const params = this.getParams();
			const { objectUUID, formUUID, viewUUID } = contentConfig;
			sessionStorage.setItem('currentFormUUID', formUUID || objectUUID);
			transFieldsUUid(objectUUID, formUUID, {
				...params,
				EDITOR_closeConfigCache: !!this.statusConfig?.closeConfigCache
			})
				.then((res) => {
					if (res.data.code == 200) {
						const { sourceConfig } = this.element;
						this.formDisabled = sourceConfig[0]?.type === 'dataInfo';
						this.needFlow = !!res?.data?.data?.need_flow;
						this.needParentFlow = !!res?.data?.data?.need_parent_flow;
						this.useMainFlow = !!res?.data?.data?.use_main_flow;
						if (!this.useMainFlow) {
							// 子表单使用主表单流程
							this.originContentConfig.formUUID = res?.data?.data?.uuid;
						}
						this.nowArchiType = res?.data?.data?.archi_type || '';
						// 公司端使用下级流程发起
						this.useChildFlow = !!(res?.data?.data?.use_child_flow || false); // 是否使用下级流程发起
						this.childArchField = res?.data?.data?.archi_field; // 表单中的组织id字段值
						// 编辑或详情
						if (
							sourceConfig?.[0]?.type === 'updateData' ||
							sourceConfig?.[0]?.type === 'dataInfo' ||
							this.statusConfig.createWithDetails
						) {
							// 编辑
							// 获取已经有的数据进行绑定
							let config = res.data.data;
							let ruuids = [];
							for (let i = 0; i < config.fields.length; i++) {
								let item = config.fields[i];
								if (item.__config__.tagIcon == 'form') {
									ruuids.push({ relationship_uuid: item.__vModel__ });
								}
							}
							dataInterface({
								...params,
								object_uuid: objectUUID,
								view_uuid:
									['dataInfo', 'updateData'].includes(sourceConfig[0].type) || this.statusConfig.createWithDetails
										? viewUUID
										: '',
								ruuids,
								__method_name__: 'dataInfo',
								allMetadata: 1
							}).then((res1) => {
								let data = res1.data.data;
								if (data?.recover?.cells || (this.contentConfig.newFlow && sourceConfig?.[0]?.type === 'createData')) {
									// 详情数据是否是新流程发起
									this.originContentConfig.newFlow = true;
								} else if (data?.recover?.pens) {
									this.originContentConfig.newFlow = false;
								}
								this.formDesignData = res.data.data;
								this.excelFormData = {
									[objectUUID]: JSON.parse(JSON.stringify(data))
								};
								this.excelMetaData = JSON.parse(JSON.stringify(res1.data.metadata));
								this.displayData = data;
								if (
									data.editFields instanceof Array ||
									this.statusConfig.createWithDetails ||
									sourceConfig?.[0]?.type === 'updateData'
								) {
									this.editFields = {};
								} else {
									this.editFields = data.editFields || {};
								}
								this.loading = false;
								this.loadChildComplete(); // 判断关联子表是否加载完成，加载完成后开启提交按钮
							});
						} else {
							this.loadChildComplete(); // 判断关联子表是否加载完成，加载完成后开启提交按钮
							if (this.statusConfig.useCache && sourceConfig[0].type === 'createData') {
								const cacheData = res?.data?.data?.cache_data;
								// 判断是否开启自动缓存 1、开启自动缓存，并且有cacheData  直接提示是否填充  2、没有自动缓存  并且没有多条手动新增数据（cache_multiple），并且有cache_data  直接提示是否使用缓存
								if (
									(this.statusConfig.autoSave || (!this.statusConfig.autoSave && !res?.data?.data?.cache_multiple)) &&
									cacheData
								) {
									this.$confirm('是否使用缓存数据填充？', '提示')
										.then(() => {
											try {
												this.formDesignData = res.data.data;
												this.displayData = JSON.parse(cacheData) || {};
												this.useCacheDataId = res?.data?.data?.cache_id;
												this.loading = false;
											} catch (cacheErr) {
												console.log(cacheErr);
											}
										})
										.catch(() => {
											this.formDesignData = res.data.data;
											this.useCacheDataId = null;
											this.loading = false;
										});
								} else if (!this.statusConfig.autoSave && res?.data?.data?.cache_multiple) {
									// 判断配置  没有自动缓存并且有多条手动保存缓存
									this.copyFormDesignData = res.data.data;
									this.showSaveList = true;
								} else {
									this.formDesignData = res.data.data;
									this.loading = false;
								}
							} else {
								this.formDesignData = res.data.data;
								this.loading = false;
							}
						}
					}
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
		loadChildComplete() {
			// 判断关联子表是否加载完成，加载完成后才取消提交按钮禁用
			let timer = setInterval(() => {
				if (this.$refs.dialogForm) {
					const data = this.$refs.dialogForm.getFormData();
					let loadedNum = 0,
						loadingNum = 0;
					for (const dataKey in data) {
						if (data[dataKey] instanceof Array) {
							let len = sessionStorage.getItem(dataKey);
							if (len) {
								loadingNum++;
								if (parseInt(len) === data[dataKey].length) {
									loadedNum++;
								}
							}
						}
					}
					if (loadedNum === loadingNum) {
						this.renderLoading = false;
						clearInterval(timer);
					}
				}
			}, 1000);
		},
		/**
		 * @desc: 获取配置
		 * @param {Object} contentConfig 内容配置
		 */
		getFormContentConfigs(contentConfig) {
			const { configType = '', dynamicComponent = '' } = contentConfig;
			if (!configType) {
				return contentConfig;
			}
			// 动态配置 dynamic
			let result = {};
			const originData = this.getOutput(dynamicComponent);
			for (let key in contentConfig) {
				const fieldUUID = contentConfig[key];
				result[key] = originData[fieldUUID];
			}
			return result;
		},
		/**
		 * @desc: 获取表单填充值
		 */
		getFillData() {
			const { formFillConfig = [] } = this.element;
			let result = {};
			formFillConfig.forEach((ele) => {
				const {
					fieldUUID = '',
					sourceType = 'component',
					componentId = '', // 组件ID
					componentField = '', // 组件取值字段key
					systemKey = '', // 系统取值对象
					systemCode = '', // 系统取值参数
					urlParamsKey = '', // 链接请求参数key
					fixedValue = '', // 固定值
					statusCode = '' // 页面状态字段
				} = ele;
				if (fieldUUID) {
					let value = '';
					if (sourceType === 'component') {
						// 组件来源
						const resolveData = this.getOutput(componentId);
						value = resolveData?.[componentField];
					} else if (sourceType === 'url') {
						// 链接query
						value = getQueryValue(urlParamsKey);
					} else if (sourceType === 'system') {
						// 系统参数
						if (systemKey === 'userInfo') {
							value = this.$GetUserInfo(systemCode);
						} else if (systemKey === 'targetArchi') {
							value = this.$GetTargetArchi(systemCode);
						} else {
							let systemObject = JSON.parse(localStorage.getItem(systemKey));
							if (Object.prototype.toString.call(systemObject) !== '[object Object]') {
								systemObject = {};
							}
							value = systemObject?.[systemCode] || '';
						}
					} else if (sourceType === 'pageStatus') {
						// 页面状态
						value =
							this._PageCustomStatus[statusCode] === undefined
								? this._APPCustomStatus[statusCode] || null
								: this._PageCustomStatus[statusCode];
					} else if (sourceType === 'fixed') {
						// 固定值
						value = fixedValue;
					}
					result[fieldUUID] = value;
				}
			});
			let _outsideParam = sessionStorage.getItem(
				`dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
			);
			_outsideParam = _outsideParam ? JSON.parse(_outsideParam) : {};
			this.otherParam = _outsideParam;
			// 可能存在覆盖情况（注意不要key重复了）
			return {
				...result,
				..._outsideParam
			};
		},
		/**
		 * @desc: 表单提交
		 * @param {Object} formData 自定义表单中提交的数据
		 */
		onFormSubmit(formData) {
			this.formDataCache = formData;
			if (this.isCacheFormData) {
				this.doCacheFormData(formData);
				return false;
			}
			const { actionConfig } = this.element;
			const btnConfig = actionConfig.find((ele) => ele.key === 'confirm');
			if (!btnConfig || !btnConfig.effect) {
				this.statusConfig.isShow = false;
				// 测试
				// this.beforeClose();
				// 测试完毕
				return;
			}
			// 附加作用一般是发送请求
			if (btnConfig.effectType === 'postData') {
				let params = {};
				// 发送请求 整理参数
				const sourceParams = this.getParams() || {};
				// // 开发环境排查参数重复问题
				// if (
				//   window.location.href.includes('/modify-page?') ||
				//   window.location.href.includes('/page-view?')
				// ) {
				//   let repeatKeys = '';
				//   let repeatCount = 0;
				//   /* eslint-disable */
				//   for (let key in formData) {
				//     // eslint-disable-next-line no-prototype-builtins
				//     if (key !== 'data_id' && sourceParams.hasOwnProperty(key)) {
				//       repeatKeys += `【${key}】、`;
				//       repeatCount++;
				//     }
				//   }
				//   if (repeatKeys) {
				//     this.$message.error(
				//       `表单输出数据与弹窗绑定参数存在重复，重复次数：【${repeatCount}】次！重复的参数值为：${repeatKeys.substring(
				//         0,
				//         repeatKeys.length - 1
				//       )}！`
				//     );
				//     return;
				//   }
				// }
				params = {
					__method_name__: btnConfig.postType,
					object_uuid: btnConfig.objectUUID,
					// 表单的字段
					...formData,
					...sourceParams
				};
				this.$loading();
				// 发送请求
				dataInterface(params)
					.then((res) => {
						this.$loading().close();
						if (res.code || (res.status && res.status === 200 && res?.data?.code === 200)) {
							this.beforeClose();
						}
						// 如果为选择草稿新增提交，则需要删除对应草稿数据
						if (
							this.statusConfig?.useCache &&
							(this.useCacheDataId || (!this.statusConfig?.autoSave && this.selectCacheObj?.id))
						) {
							dataInterface({
								object_uuid: 'object61bbffbdabbbe',
								data_id: this.useCacheDataId || this.selectCacheObj?.id,
								__method_name__: 'deleteData'
							});
						}
					})
					.catch((err) => {
						console.log(err);
						this.$loading().close();
					});
			}
		},
		/**
		 * @desc: 缓存表单数据
		 * @param {Object} formData
		 */
		/**
		 * @description: 缓存表单数据
		 * @param {Object} value 表单数据
		 */
		doCacheFormData(value) {
			if (!value) {
				this.$message.warning('暂无可保存数据!');
				return false;
			}
			const loading = this.$loading();
			const param = {
				// __method_name__: 'createData',
				// object_uuid: 'object61bbffbdabbbe',
				// user_id: this.$GetUserInfo('id'),
				// key: `formcache-${this.contentConfig.formUUID || this.contentConfig.objectUUID}`,
				// value
				key: `formcache-${this.contentConfig.formUUID || this.contentConfig.objectUUID}`,
				value,
				clearHistory: this.clearHistory || 0, // 默认按照列表式保存
				name: this.clearHistory ? `自动保存-${formatTime(new Date().getTime(), 'YMDHMS')}` : this.draftName
			};
			dataInterface(param, '/api/form4/dataCache')
				.then((res) => {
					if (res.status === 200) {
						this.$message.success('操作成功！');
						this.clearHistory = 0;
						this.draftName = '';
						this.showSaveConfirm = false;
						this.setSaveInterval(); // 每次保存后 清除自动保存 重新计时
					}
					loading && loading.close();
				})
				.catch((err) => {
					console.log(err);
					loading && loading.close();
				});
		},
		/**
		 * @desc: 关闭前需要将组件的暴露数据清空
		 */
		beforeClose() {
			this.statusConfig.isShow = false;
			// 如果是对表格中的数据进行编辑需要对数据进行更新
			// 对于参照相关参数的需要进行获取
			// 更新规则 pattern: '' 默认规则, rules 强规则， compatible 兼容
			const { pattern = '', rules = [] } = this.updateRulesConfig;
			if (!pattern || pattern === 'compatible') {
				// 默认或兼容模式
				const sourceConfig = this.element.sourceConfig || [];
				for (let i = 0; i < sourceConfig.length; i++) {
					if (sourceConfig[i] && !sourceConfig[i].originType && sourceConfig[i].componentId) {
						eventBus.$emit('databaseTrigger', {
							action: 'dataList',
							componentId: sourceConfig[i].componentId,
							isInit: sourceConfig[i].componentId.indexOf('CommonTree-') > -1,
							isUpdate: true,
							output:
								sourceConfig[i].componentId.indexOf('CommonTabs-') > -1
									? this.getOutput(sourceConfig[i].componentId)
									: ''
						});
					}
				}
			}
			if (['rules', 'compatible'].includes(pattern)) {
				rules.forEach((ele) => {
					eventBus.$emit('databaseTrigger', {
						action: 'dataList',
						componentId: ele.componentId,
						isInit: ele.componentId.indexOf('CommonTree-') > -1,
						isUpdate: true,
						output: ele.componentId.indexOf('CommonTabs-') > -1 ? this.getOutput(ele.componentId) : ''
					});
				});
			}
		},
		/**
		 * @desc: 获取数据数据
		 */
		getOutput(comId) {
			let componentItem = getComponentById(this.subComponentData, comId);
			if (!componentItem && this.isGroup && this.groupComponents.length) {
				componentItem = getComponentById(this.groupComponents, comId);
			}
			return componentItem?.resolveData || '';
		},
		/**
		 * @desc: 点击按钮
		 */
		onButton(btnConfig) {
			if (!btnConfig) return false;
			this.onCustomEvent(btnConfig.key);
			if (btnConfig?.effectType === 'eeAction') {
				const { objectUUID, viewUUID, eventName, eeType, interfaceUrl, sourceList } = btnConfig;
				if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
					this.$message.error('请正确配置事件!');
					return false;
				}
				const usedSourceList = Array.isArray(sourceList) && sourceList.length ? sourceList : null;
				const sourceParams = this.getParams(usedSourceList);
				const data_id = sourceParams?.data_id;
				if (!eeType && !data_id) {
					this.$message.error('事件未配置触发对象!');
					return false;
				}
				this.loading = true;
				// 触发后端事件
				this.doEEAction(btnConfig, eeType === 'blueprint' ? sourceParams : { data_id: sourceParams?.data_id });
			}
		},
		/**
		 * @description: 触发后端事件
		 * @param {Object} btnConfig
		 * @param {Object} params
		 */
		doEEAction(btnConfig, params = {}, eventKey) {
			this.$loading();
			window.$EditorDebug.startEEAction(this.element);
			const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } = btnConfig;
			if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
				this.$message.error('请正确配置事件!');
				window.$EditorDebug.failEEAction(this.element);
				return false;
			}
			if (eeType === 'blueprint') {
				dataInterface(params, `/api${interfaceUrl}`)
					.then((res) => {
						this.$loading().close();
						if (res.status === 200 && res.data.code === 200) {
							this.$message.success('操作成功！');
							window.$EditorDebug.successEEAction(this.element, `/api${interfaceUrl}`);
							doEEActionHandle(res.data?.__adds__);
						}
						// 确认按钮默认执行关闭事件2023.2.4
						if (eventKey === 'confirm' || btnConfig.key === 'confirm') {
							this.beforeClose();
						}
					})
					.catch(() => {
						this.$loading().close();
						window.$EditorDebug.errorEEAction(this.element, `/api${interfaceUrl}`);
					});
				return true;
			}
			dataInterface({
				__method_name__: 'customEventCall',
				object_uuid: objectUUID,
				view_uuid: viewUUID,
				event: eventName,
				...params,
				_formData: this.formDataCache
			})
				.then((res) => {
					this.$loading().close();
					if (res.status === 200 && res.data?.code === 200) {
						this.$message.success('操作成功！');
						window.$EditorDebug.successEEAction(this.element, eventName);
						// 后续操作
						doEEActionHandle(res.data?.__adds__);
						// 确认按钮默认执行关闭事件2023.2.4
						if (eventKey === 'confirm' || btnConfig.key === 'confirm') {
							this.beforeClose();
						}
					}
				})
				.catch((err) => {
					console.log(err, '00000后端事件错误');
					this.$loading().close();
					window.$EditorDebug.errorEEAction(this.element, eventName);
				});
		},
		/**
		 * @desc: 获取绑定参数
		 */
		getParams(sourceList) {
			const sourceConfig = sourceList || this.element.sourceConfig || [];
			const sourceParams = {};
			for (let i = 0; i < sourceConfig.length; i++) {
				const {
					componentId,
					field,
					key,
					originType = '',
					urlParamKey = '',
					statusCode = '',
					fixedValue = '',
					systemKey = '',
					systemCode = ''
				} = sourceConfig[i];
				if (originType === 'pageStatus' && statusCode) {
					const result =
						this._PageCustomStatus[statusCode] === undefined
							? this._APPCustomStatus[statusCode] || null
							: this._PageCustomStatus[statusCode];
					this.$set(sourceParams, key, result);
				} else if (originType === 'url' && urlParamKey) {
					// 从url获取参数
					const result = getQueryValue(urlParamKey);
					this.$set(sourceParams, key, result);
				} else if (componentId && field && key) {
					// 普通从组件获取
					let sourceComponent = getComponentById(this.subComponentData, componentId);
					if (!sourceComponent && this.isGroup && this.groupComponents.length) {
						sourceComponent = getComponentById(this.groupComponents, componentId);
					}
					const componentName = componentId.toString().split('-')[0];
					if (
						field === 'DATAVIEWSELECT' &&
						['CommonTree', 'CommonTableContainer', 'CommonLoopContainer'].includes(componentName)
					) {
						this.$set(sourceParams, key, sourceComponent?.resolveData || []);
					} else {
						const result = sourceComponent?.resolveData ? sourceComponent.resolveData[field] : '';
						this.$set(sourceParams, key, result);
					}
				} else if (originType === 'fixed') {
					// 固定参数
					this.$set(sourceParams, key, fixedValue);
				} else if (originType === 'system') {
					// 系统参数
					let value = '';
					if (systemKey === 'userInfo') {
						value = this.$GetUserInfo(systemCode);
					} else if (systemKey === 'targetArchi') {
						value = this.$GetTargetArchi(systemCode);
					} else {
						let systemObject = JSON.parse(localStorage.getItem(systemKey));
						if (Object.prototype.toString.call(systemObject) !== '[object Object]') {
							systemObject = {};
						}
						value = systemObject?.[systemCode] || '';
					}
					this.$set(sourceParams, key, value);
				}
			}
			return sourceParams;
		},
		/**
		 * @desc: 页面
		 */
		initPageDesign(contentConfig) {
			dataInterface({
				__method_name__: 'dataInfo',
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: '175c6fe7-c8a2-4530-99be-80a8f1113f3c',
				data_id: contentConfig.pageId
			})
				.then((res) => {
					this.loading = false;
					if (res.status === 200 && res.data?.code === 200 && res.data?.data) {
						const pageData = res.data.data['f708a9c6-2514-47ad-9056-3c3a1c37496f'];
						let { componentData, editorConfig, editorType } = isJSONStr(pageData) ? JSON.parse(pageData) : pageData;
						this.pageData = componentData;
						this.pageType = editorType;
						this.pageConfig = editorConfig;
						this.initEnd = true;
					}
				})
				.catch(() => {
					this.loading = false;
				});
		},
		/**
		 * @desc: 取消显示
		 */
		onCancel() {
			this.onCustomEvent('cancel');
			this.statusConfig.isShow = false;
		},
		/**
		 * @description: 头部关闭
		 */
		onHeaderClose() {
			this.statusConfig.isShow = false;
		},
		/**
		 * @desc: 触发自定义事件
		 */
		onCustomEvent(eventKey) {
			const config = this.actionConfig.find((ele) => ele.key === eventKey);
			if (!config || !config.eventList || !Array.isArray(config.eventList) || !config.eventList.length) return;
			const comEvents = config.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.doActionItem(comEvents[i], eventKey);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList;
				result.forEach((ele) => {
					this.doActionItem(ele, eventKey);
				});
			}
		},
		/**
		 * @desc: 触发事件
		 * @param {Object} ele
		 */
		doActionItem(ele, eventKey) {
			if (ele.key === 'click') {
				// 跳转页面
				if (ele.actionType === 'jumpPage') {
					if (ele.linkType === 'projectPage') {
						const query = {};
						ele.queryList &&
							ele.queryList.forEach((queryItem) => {
								let component = getComponentById(this.subComponentData, queryItem.componentId);
								if (!component && this.isGroup && this.groupComponents.length) {
									component = getComponentById(this.groupComponents, queryItem.componentId);
								}
								this.$set(query, queryItem.key, component.resolveData[queryItem.feild]);
							});
						this.$router.push({
							path: ele.pageId,
							query
						});
						return;
					} else if (ele.linkType === 'outPage') {
						// window.open(ele.linkUrl);
						openUrl(ele.linkUrl, ele.linkUrl);
					} else if (ele.linkType === 'custom') {
						const customLink = getLinkByTemplate(ele.linkTemplate);
						openUrl(customLink, customLink);
						// window.open(customLink);
					}
				} else if (ele.actionType === 'eeAction') {
					// 触发后端事件
					console.log(ele, '0000001111111111触发后端事件');
					const { objectUUID, viewUUID, eventName, eeType, interfaceUrl, sourceList } = ele;
					if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
						this.$message.error('请正确配置事件!');
						return false;
					}
					const usedSourceList = Array.isArray(sourceList) && sourceList.length ? sourceList : null;
					const sourceParams = this.getParams(usedSourceList);
					this.doEEAction(ele, eeType === 'blueprint' ? sourceParams : { data_id: sourceParams?.data_id }, eventKey);
					// 搜集参数
				} else if (ele.actionType === 'componentBehavior') {
					//触发组件行为
					if (ele.behaviors?.length) {
						ele.behaviors.forEach((behavior) => {
							this.$store.commit('triggerEvents', {
								config: {
									behavior,
									isBehavior: true
								},
								ele
							});
						});
					}
				} else {
					// 页面事件
					ele.effects.forEach((effect) => {
						this.$store.commit('triggerEvents', {
							config: {
								...ele,
								...effect
							},
							element: this.element,
							EDITOR_pageUUID: this.EDITOR_pageUUID
						});
					});
				}
			}
			// 导出事件(只能导出当前行的数据)
			if (ele.actionType === 'export') {
				eventBus.$emit('exportData', ele);
			}
		},
		/* 选择草稿 */
		selectCache(data) {
			this.selectCacheObj = data;
			this.displayData = data.value || {};
			this.formDesignData = JSON.parse(JSON.stringify(this.copyFormDesignData));
			this.showSaveList = false;
			this.loading = false;
		},
		closeSaveList() {
			this.formDesignData = JSON.parse(JSON.stringify(this.copyFormDesignData));
			this.loading = false;
		}
	},
	beforeDestroy() {
		sessionStorage.removeItem(`dialogRelationParams_${this.contentConfig.pageId}`);
		sessionStorage.removeItem('currentFormUUID');
		if (this.saveInterval) {
			clearInterval(this.saveInterval);
		}
	}
};
</script>

<style lang="less" scoped>
:deep(.common-dialog) {
	margin: auto;
	height: auto;
	max-height: calc(100vh - 45px);
	overflow-y: hidden;
	// overflow-y: auto;
	position: relative;
	.email-main {
		position: absolute;
		top: 53px;
		left: 0;
		height: calc(100% - 53px);
		width: 100%;
		background: rgba(0, 0, 0, 0.6);
		z-index: 4000;
	}
	.type-label {
		border-left: 3px solid var(--themeColor) !important;
		padding: 0 10px;
		line-height: 1;
		font-size: 14px;
		margin: 8px 0 13px;
		font-weight: 600;
	}
	// 弹窗
	.dialog-main {
		overflow-x: auto;
	}
	.el-dialog__body {
		max-height: calc(100vh - 155px);
		// min-height: 300px; //  @凌志华
		padding: 0;
		overflow: hidden;
		display: flex;
		box-sizing: border-box;

		flex-direction: column;
		.dialog-footer {
			box-sizing: border-box;
			padding-top: 20px;
			text-align: right;
		}
	}
	.oldform-padding {
		box-sizing: border-box;
		padding: 15px 16px;
	}
	.el-dialog__header {
		border-bottom: 1px solid #f2f3f5;
	}
	.el-dialog__footer {
		padding: 15px 10px;
		border-top: 1px solid #f2f3f5;
	}
	.model-header {
		display: flex;
		box-sizing: border-box;
		// padding-right: 30px;
		cursor: pointer;
		h3 {
			flex: 1;
			line-height: 20px;
		}
		.iconfont {
			font-size: 20px;
			box-sizing: border-box;
			padding: 0 5px;
			line-height: 22px;
			color: #4d535e;
			& + .iconfont {
				margin-left: 5px;
			}
		}
		.collapse-icon {
			display: inline-block;
			padding-right: 16px;
			border-right: 1px solid #dfe3e8;
			margin: 0 5px !important;
		}
	}
	// .el-dialog__close {
	// 	// color: #4D535E;
	// 	color: #555;
	// 	line-height: 20px;
	// 	font-size: 20px;
	// }
}
.el-dialog__wrapper {
	overflow: hidden;
}
:deep(.common-excel-dialog) {
	height: 100vh !important;
	max-height: 100vh !important;
	.el-dialog__body {
		min-height: calc(100vh - 115px) !important;
		max-height: calc(100vh - 115px) !important;
		height: calc(100vh - 115px) !important;
		.dialog-main {
			height: 100%;
			width: 100%;
		}
	}
}
:deep(.common-drawer) {
	height: 100vh !important;
	overflow: hidden;
	.model-header {
		display: flex;
		box-sizing: border-box;
		padding-right: 10px;
		cursor: pointer;
		h3 {
			flex: 1;
			line-height: 20px;
		}
		.iconfont {
			font-size: 20px;
			box-sizing: border-box;
			padding: 0 5px;
			& + .iconfont {
				margin-left: 5px;
			}
		}
	}
	// 抽屉
	.el-drawer__header {
		margin-bottom: 0;
		border-bottom: 1px solid #f2f3f5;
		padding-bottom: 20px;
	}
	.el-drawer__body {
		box-sizing: border-box;
		padding: 15px 16px;
		display: flex;
		flex-direction: column;
		.drawer-main {
			flex: 1;
			width: 100%;
			overflow: hidden;
			overflow-y: auto;
			.form-parser-warp {
				display: flex;
				flex-direction: column;
				.left-sider {
					width: 100%;
					overflow-x: auto;
					.shrink-icon {
						display: none;
					}
				}
				.right-sider {
					border-left: none;
				}
			}
		}
		.dialog-footer {
			padding-top: 15px;
			text-align: right;
			border-top: 1px solid #f2f3f5;
		}
	}
	.type-label {
		border-left: 3px solid var(--themeColor) !important;
		padding: 0 10px;
		line-height: 1;
		font-size: 14px;
		margin: 0 0 16px;
		font-weight: 600;
		text-align: left;
	}
}

.Editor-drawer__wrapper {
	width: 100vw;
}
.skeleton {
	padding: 20px;
}
:deep(.cache-name-dialog) {
	.el-dialog__header {
		height: 56px;
		box-sizing: border-box;
		border-radius: 8px;
	}
	.el-dialog__body {
		padding: 0 24px;
		padding-bottom: 8px;
	}
	.el-dialog__footer {
		padding: 16px 24px;
		.el-button {
			border-radius: 4px;
		}
	}
}
:deep(.cache-list-dialog) {
	box-sizing: border-box;
	.el-dialog__header {
		height: 56px;
		box-sizing: border-box;
		padding-left: 20px;
		padding-right: 20px;
		border-radius: 8px;
	}

	.el-dialog__body {
		padding: 0 20px;
		box-sizing: border-box;
	}
}
</style>
