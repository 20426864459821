<!--
    @name: widget-password
    @description：widget-password
    @author: ZengWei
    @date: 2022-03-25 09:29
-->
<template>
	<div>
		<div v-if="disabled || element.config.disabled" class="input-show">{{ element.value ? element.value : '-' }}</div>
		<el-input
			v-else
			v-model="element.value"
			type="password"
			:placeholder="
				$getLocalizedLabel({ zhLabel: element.config.zhPlaceholder, enLabel: element?.config.enPlaceholder })
			"
			:disabled="disabled || element.config.disabled"
		></el-input>
	</div>
</template>

<script>
import { Input } from 'element-ui';
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';

export default {
	name: 'WidgetPassword',
	components: {
		'el-input': Input
	},
	mixins: [formItemMinix],
	props: ['element', 'disabled', 'displayData'],
	data() {
		return {};
	},
	methods: {}
};
</script>

<style scoped></style>
