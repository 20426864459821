/*
 * @Description:
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-11-04 10:57:29
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-04 10:57:30
 */
/*
 * @Date: 2023-04-07 10:16:57
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-03 15:07:28
 * @FilePath: \dataview-viewer-test\src\utils\indexDB.js
 */
/* eslint-disable */
export class CacheDB {
	constructor(name, tableName, version) {
		this.db = null;
		this.name = name;
		this.version = version || '1.0';
		this.tableName = tableName;
	}

	init() {
		if (!indexedDB) return Promise.resolve();
		return new Promise((rol) => {
			const req = indexedDB.open(this.name, this.version);

			//只有升级或者创建才会触发的方法
			req.onupgradeneeded = (event) => {
				const db = event.target.result;
				const tn = this.tableName;

				if (!db.objectStoreNames.contains(tn)) {
					const store = db.createObjectStore(tn, {
						keyPath: 'key'
						// autoIncrement: true, //自增
					});
				}
			};

			req.onsuccess = (event) => {
				const db = event.target.result;

				this.db = db;

				rol(true);
			};

			req.onerror = (error) => {
				rol();
			};
		});
	}

	async addData(key, data, method = 'add') {
		if (!this.db) await this.init();
		if (!this.db) return Promise.resolve();

		return new Promise((rol) => {
			const tn = this.tableName;
			const db = this.db;

			const t = db.transaction([tn], 'readwrite');
			const store = t.objectStore(tn);
			let req = null;
			if (method === 'put') {
				req = store.put({
					key: key,
					data
				});
			} else {
				req = store.add({
					key: key,
					data
				});
			}

			req.onsuccess = (event) => {
				rol(false);
			};

			req.onerror = (error) => {
				rol(false);
			};
		});
	}

	async getData(key) {
		if (!this.db) await this.init();
		if (!this.db) return Promise.resolve();

		return new Promise((rol) => {
			const tn = this.tableName;
			const db = this.db;

			const t = db.transaction([tn], 'readwrite');
			const store = t.objectStore(tn);

			let req = null;
			if (!key) {
				req = store.getAll();
			} else {
				req = store.get(key);
			}

			req.onsuccess = (event) => {
				if (!event.target) return rol();
				rol(event.target.result);
			};

			req.onerror = (error) => {
				rol();
			};
		});
	}

	async deleteDB(key) {
		return new Promise((resolve) => {
			const t = this.db.transaction([this.tableName], 'readwrite');
			const store = t.objectStore(this.tableName);
			const request = store.delete(key);

			request.onsuccess = function () {
				console.log('数据删除成功');
				resolve(true);
			};

			request.onerror = function () {
				console.log('数据删除失败');
				resolve(false);
			};
		});
	}
}

/**
 * @description: 获取语言列表数据
 * @param {String} key
 */
export const getLangMapData = async (key) => {
	return new Promise(async (resolve) => {
		const dbObj = new CacheDB('i18n_lang_map', 'langMapList');
		await dbObj.init();
		const result = dbObj.getData(key);
		resolve(result);
	});
};

/**
 * @description: 保存语言列表数据
 */
export const saveLangMapData = async (key, langData) => {
	return new Promise(async (resolve) => {
		const dbObj = new CacheDB('i18n_lang_map', 'langMapList');
		await dbObj.init();
		const result = await dbObj.addData(key, langData, 'put');
		resolve(result);
	});
};
